<app-modal>
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{labels.connect_existing_user}}
        </span>
    </div>

    <div class="modal_body" #modal_body style="overflow: hidden; padding: 15px;">
        <span [innerHtml]="labels.connect_existing_user_question"></span>

        <data-table (onSelectRow)="handleSelectUser($event)" [configOptions]="usersTableConfig"
            *ngIf="usersTableConfig">
        </data-table>
    </div>

    <div class="modal_footer" #modal_footer>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
            <button [disabled]="selectedUser" mat-stroked-button (click)="cancel()">{{labels.no}}</button>
            <button [disabled]="!selectedUser" color="primary" mat-raised-button
                (click)="continue()">{{labels.yes}}</button>
        </div>
    </div>
</app-modal>