import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { ApiResponse, ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {
  constructor(
    private api: ApiService,
  ) { }

  public createInvoice(userId: string, activityId: string): Promise<ApiResponse<string>> {
    return this.api.post<string>(`users/${userId}/activity/${activityId}/invoice`).pipe(take(1)).toPromise();
  }
}
