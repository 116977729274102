import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { FileService } from './file.service';

@Injectable({
    providedIn: 'root',
})
export class PhotoUploadService {
    constructor(
        public router: Router,
        private afStorage: AngularFireStorage,
        private fileService: FileService
    ) { }

    public uploadBase64Image(data: string, path: string, progressHandler: Function): Promise<string> {
        const task: AngularFireUploadTask = this.afStorage.ref(path).putString(data, 'data_url');

        return this.fileService.handleUploadWithTask(task, progressHandler);
    }

    public uploadFileImage(file: File, path: string, progressHandler: Function): Promise<string> {
        const task: AngularFireUploadTask = this.afStorage.upload(path, file);

        return this.fileService.handleUploadWithTask(task, progressHandler);
    }
}
