import { User } from 'app/shared/model';
import { Component, Input, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import moment from 'moment';
import { differenceInCalendarISOYears } from 'date-fns';
import { LabelService, UtilitiesService } from 'app/core/services';

//todo clean still in use?

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit, OnChanges {
  @Input() user: User;
  public age: number;
  public labels: any = {};

  constructor(
    private utilitiesService: UtilitiesService,
    private labelService: LabelService
  ) { }

  async ngOnInit() {
    this.labels = (await this.labelService.getLabels('app-personal-details')).data;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.utilitiesService.isNewChange(changes.age)) {
      if (this.age) {
        this.age = Math.abs(differenceInCalendarISOYears(this.user.birthday, new Date()));
      }
    }
  }

  birthday(date: string) {
    return moment(date).year();
  }
}
