import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  LabelService, RouterService,
  LocalStorageService,
  UtilitiesService,
  ServiceRegistrationService,
  OrgServiceService,
  UserPromptsService,
  UserService,
  SearchService
} from '@core/services';
import { MenuItem, Role, Service, User } from '@shared/model';
import { IDataTableConfig, ITableData } from '@shared/components';
import { take } from 'rxjs/operators';
import { Sub } from '@shared/subscriptions';
import { Subscription } from 'rxjs';
import { keys, find, uniq, orderBy, uniqBy } from 'lodash';
import { RoleTypes } from '@shared/enum';
import { IObjectMap } from '@shared/interface';
import {
  ServiceRegistrationHistoryModalComponent
} from '../service-registration-history-modal/service-registration-history-modal.component';
import { ServiceRegistration, ServiceRegistrationTypes, ServiceRegStatusTypes } from '@models/model/serviceRegistration';
import { InternalServiceRegistrationModalComponent } from 'app/service-registration/internal-service-registration/components/internal-service-registration-modal/internal-service-registration-modal.component';
import { CustomerServiceRegistrationModalComponent } from 'app/service-registration/customer-service-registration/components/customer-service-registration-modal/customer-service-registration-modal.component';
import { NetworkServiceRegistrationModalComponent } from 'app/service-registration/network-service-registration/components/network-service-registration-modal/network-service-registration-modal.component';
import { PageEvent } from '@angular/material/paginator';

export type FetchType = 'workingArea' | 'networkPartner';
export interface ServiceRegFilter {
  statusFilter?: ServiceRegStatusTypes[];
  serviceFilter?: string;
  textFilter?: string;
  limit?: number;
}

@Component({
  selector: 'app-service-registration-list',
  templateUrl: './service-registration-list.component.html',
  styleUrls: ['./service-registration-list.component.scss']
})
export class ServiceRegistrationListComponent implements OnInit, OnDestroy {
  public labels: any = {};
  public serviceRegistrations: ServiceRegistration[];
  public services: Service[] = [];
  private tableData: ITableData[];
  protected tableCfg: IDataTableConfig;
  public loading: boolean;
  public serviceRegStatuses: ServiceRegStatusTypes[];
  public ServiceRegStatusTypes = ServiceRegStatusTypes;
  public shouldHideServiceFilter: boolean;
  protected serviceRegFilter: ServiceRegFilter = {};
  private sub: Sub = new Sub();
  private listSubscription: Subscription;
  private userRole: Role;
  private networkPartnersMap: IObjectMap<User> = {};
  protected isMobile: boolean = false;
  public isSearchButtonDisabled = false;
  public paginationOptions = this.resetPaginatorOptions();
  private count: number;
  private searchedSubscription: Subscription;
  private fetchType: FetchType;
  private userId: string;
  private countQuery: string;
  
  constructor(
    private labelService: LabelService,
    private routerService: RouterService,
    private localStorageService: LocalStorageService,
    private utilitiesService: UtilitiesService,
    private serviceRegistrationService: ServiceRegistrationService,
    private orgServiceService: OrgServiceService,
    private userPromptsService: UserPromptsService,
    private userService: UserService,
    private searchService: SearchService,
  ) { }

  async ngOnInit() {
    this.userRole = JSON.parse(this.localStorageService.getItemSync('user_role'));
    this.userId = this.localStorageService.getItemSync('user_id');
    this.getFetchType();
    this.setDefaultStatusFilter();
    this.sub.add(
      this.userPromptsService.activeMediaQuery$.subscribe(alias => {
        this.isMobile = alias === 'xs';
      })
    )
    this.serviceRegStatuses = this.utilitiesService.getKeysForEnum(ServiceRegStatusTypes);
    this.fetchRolesData();

    this.labels = (await this.labelService.getLabels('app-service-registration-list')).data;

    const menuItem: MenuItem = Object.assign({}, new MenuItem(), {
      title: this.labels.service_registrations
    });
    this.routerService.setMainMenuItem(menuItem);
  }

  private fetchRolesData(): void {
    this.loading = true;
    switch (this.userRole.roleType) {
      case RoleTypes.professional: {
        this.sub.add(
          this.orgServiceService.getServicesFromRefs(keys(this.userRole.workingArea.services)).subscribe(services => {
            this.services = services.filter(s => !!s);
              this.fetchServiceRegistrationsForFirstLoad();

          })
        );

        break;
      }

      case RoleTypes.coordinator: {
        this.services = [this.userRole.service];
        this.shouldHideServiceFilter = true;
        this.fetchServiceRegistrationsForFirstLoad();
        break;
      }

      case RoleTypes.networkPartner: {
        this.fetchNetworkPartnerRegistrations();
        break;
      }
    }
  }

  private async fetchNetworkPartnerRegistrations() {
    const regs = await this.serviceRegistrationService
      .getServiceRegistrationsForNetworkPartner(this.localStorageService.getItemSync('user_id'))
      .pipe(take(1)).toPromise();

    const serviceIds: string[] = regs.map(reg => reg.serviceId);
    this.services = await this.orgServiceService.getServicesFromRefs(uniq(serviceIds))
      .pipe(take(1)).toPromise();

    this.fetchServiceRegistrationsForFirstLoad();
  }

  private getFetchType() {
    if (this.userRole.roleType === RoleTypes.professional || this.userRole.roleType === RoleTypes.coordinator) {
      this.fetchType = 'workingArea';
      this.countQuery = this.userRole.workingAreaId
    } else if (this.userRole.roleType === RoleTypes.networkPartner) {
      this.fetchType = 'networkPartner';
      this.countQuery = this.userId;
    }

    this.serviceRegistrationService.getServiceRegistrationCount(this.countQuery, this.fetchType).then(count => {
      this.count = count;
      this.paginationOptions.totalSize = this.count
    })
  }

  private fetchServiceRegistrationsForFirstLoad() {
    this.serviceRegFilter.limit = 30;
    let selectedServiceIds: string[] = [this.serviceRegFilter.serviceFilter] || this.services.map(s => s.id);
    if (selectedServiceIds.length > 0) {
      this.listSubscription = this.serviceRegistrationService.getServiceRegistrationModal(this.serviceRegFilter, this.fetchType, selectedServiceIds, this.userRole.workingAreaId, this.userId).subscribe(rgs => {
        this.serviceRegistrations = rgs;
        this.paginationOptions.lastRef = this.serviceRegistrations[this.serviceRegistrations.length - 1]?.['__doc'];
        this.paginationOptions.firstRef = this.serviceRegistrations[0]?.['__doc'];
        this.paginationOptions.serviceRegistrationsPerPage = this.serviceRegFilter.limit;
        this.loadData(0, this.serviceRegFilter.limit);
      });
    } else {
      this.tableData = [];
    }
  }

  private async loadData(from?: number, to?: number) {
    await this.updateTableData(this.serviceRegistrations);
    this.getTableConfig(this.tableData.filter(data => !!data).slice(from, to));
  }

  private async updateTableData(rgs: ServiceRegistration[]) {
    const statuses: ServiceRegStatusTypes[] = this.serviceRegFilter.statusFilter || [];

    const newUserIds: string[] = uniq(rgs.map(rg => rg.log.createdBy))
      .filter(id => {
        return id && !this.networkPartnersMap[id];
      });

    const newUsers: User[] = await this.userService.getUsersFromIds(newUserIds).pipe(take(1)).toPromise();
    newUsers.forEach(user => this.networkPartnersMap[user.id] = user);

    this.tableData = rgs
      .filter(item => {
        return statuses.indexOf(item.status) >= 0;
      })
      .map(rg => {
        const service = find(this.services, { id: rg.serviceId });
        const networkPartner = this.networkPartnersMap[rg.log.createdBy];

        return {
          service: service.name,
          customer: `${rg.customer.firstname || ''} ${rg.customer.lastname || ''}`,
          networkPartner: networkPartner ? `${networkPartner.firstname} ${networkPartner.lastname}` : '',
          showRowBtn: true,
          _rowClass: `service_reg_status-${rg.status}`,
          _metadata: {
            originalData: rg
          }
        };
      });
  }

  private resetPaginatorOptions() {
    return {
      totalSize: this.count,
      serviceRegistrationsPerPage: 30,
      pageIndex: 0,
      lastRef: null,
      firstRef: null
    };
  }

  checkTextFilterValid() {
    if (!this.serviceRegFilter.textFilter) {
      return this.isSearchButtonDisabled = false;
    }
    if (this.serviceRegFilter.textFilter && this.serviceRegFilter.textFilter.length > 0 && this.serviceRegFilter.textFilter.length <= 1) {
      this.isSearchButtonDisabled = true;
    } else {
      this.isSearchButtonDisabled = false;
    }
  }

  public clearFilters() {
    this.serviceRegFilter = {
      textFilter: '',
      serviceFilter: '',
      statusFilter: [ServiceRegStatusTypes.submitted],
    };
    this.serviceRegistrations = [];
    this.isSearchButtonDisabled = false;
    this.listSubscription?.unsubscribe();
    this.paginationOptions = this.resetPaginatorOptions();
    this.fetchServiceRegistrationsForFirstLoad();
  }

  public handleFilterSubmit() {
    if (this.isSearchButtonDisabled) {
      return;
    }
    this.serviceRegistrations = [];
    this.listSubscription?.unsubscribe();
    this.getTableConfig([]);
    this.paginationOptions = this.resetPaginatorOptions();
    this.fetchServiceRegistrationUsingFilter(true, false);
  }

  public anyNonSearchTextFiltersSet() {
    if ((this.serviceRegFilter.serviceFilter)
      || (!this.serviceRegFilter.statusFilter?.includes(ServiceRegStatusTypes.submitted))
    ) {
      return true;
    } else {
      return false;
    }
  }

  public onPaginationChange(evt: PageEvent): void {
    if ((this.anyNonSearchTextFiltersSet() || this.serviceRegFilter.textFilter) && (this.serviceRegistrations.length > 0)) {
      const newFromIndex = evt.pageIndex * evt.pageSize;
      const newToIndex = (evt.pageIndex + 1) * evt.pageSize;
      this.paginationOptions.serviceRegistrationsPerPage = evt.pageSize;
      this.paginationOptions.pageIndex = evt.pageIndex;
      this.loadData(newFromIndex, newToIndex);
    } else {
      this.paginationOptions.pageIndex = evt.pageIndex;
      this.paginationOptions.serviceRegistrationsPerPage = evt.pageSize;
      this.fetchServiceRegistrationUsingFilter(evt.previousPageIndex <= evt.pageIndex, false); //= is for resize of page count scenario
    }
  }

  private async fetchServiceRegistrationUsingFilter(isForwardNavigation: boolean, isReloadedContext: boolean) {
    this.loading = true;
    const opts = this.paginationOptions;

    const fromIndex = opts.pageIndex * opts.serviceRegistrationsPerPage;
    const toIndex = (opts.pageIndex + 1) * opts.serviceRegistrationsPerPage;
    let selectedServiceIds: string[] = [this.serviceRegFilter.serviceFilter] || this.services.map(s => s.id);

    if (this.anyNonSearchTextFiltersSet()) { //any non-search text filter is set
      if (this.serviceRegFilter.textFilter) {
        this.searchedSubscription = this.serviceRegistrationService.searchServiceRegistrationModal(this.serviceRegFilter, this.fetchType, selectedServiceIds, this.userRole.workingAreaId, this.userId).subscribe(serviceRegistration => {
          const filteredServiceRegistration: ServiceRegistration[] = this.searchService.searchListBySearchIndex(serviceRegistration, this.serviceRegFilter.textFilter); //returns based on relevancy
          this.serviceRegistrations = orderBy(uniqBy(filteredServiceRegistration.concat(serviceRegistration), 'id'), 'countMatch', 'desc');
          this.paginationOptions.totalSize = this.serviceRegistrations.length;
          this.loadData(fromIndex, toIndex);
        })
      } else {
        this.listSubscription = this.serviceRegistrationService.getAllServiceRegistrationByFilter(this.serviceRegFilter, this.fetchType, selectedServiceIds, this.userRole.workingAreaId, this.userId).subscribe(serviceRegistration => {
          this.serviceRegistrations = serviceRegistration;
          this.paginationOptions.totalSize = this.serviceRegistrations.length;
          this.loadData(fromIndex, toIndex);
        })
      }
      this.sub.add(this.listSubscription);
    } else if (this.serviceRegFilter.textFilter) { //only search text filter is present
      this.searchedSubscription = this.serviceRegistrationService.searchServiceRegistrationModal(this.serviceRegFilter, this.fetchType, selectedServiceIds, this.userRole.workingAreaId, this.userId).subscribe(serviceRegistration => {
        this.serviceRegistrations = serviceRegistration;
        this.paginationOptions.totalSize = this.serviceRegistrations.length;
        this.loadData(fromIndex, toIndex);
      })
    } else {
      this.count = await this.serviceRegistrationService.getServiceRegistrationCount(this.countQuery, this.fetchType);
      this.listSubscription?.unsubscribe();
      if (isForwardNavigation) {
        this.listSubscription = this.serviceRegistrationService.getServiceRegistrationByFilter(this.serviceRegFilter, this.fetchType, selectedServiceIds, this.userRole.workingAreaId, this.userId, opts.serviceRegistrationsPerPage,
          isReloadedContext ? this.paginationOptions.firstRef : this.paginationOptions.lastRef, isForwardNavigation, isReloadedContext)
          .subscribe(serviceRegistrations => {
            if (!isReloadedContext) { //to prevent 'no users found' displayed on navigating back from user details page
              this.serviceRegistrations = [];
            }
            if (serviceRegistrations.length) {
              this.serviceRegistrations = serviceRegistrations;
              this.paginationOptions.lastRef = this.serviceRegistrations[this.serviceRegistrations.length - 1]['__doc'];
              this.paginationOptions.firstRef = this.serviceRegistrations[0]['__doc'];
              this.paginationOptions.totalSize = this.count;
              this.loadData()
            }
          });
      } else {
        this.listSubscription = this.serviceRegistrationService.getServiceRegistrationByFilter(this.serviceRegFilter, this.fetchType, selectedServiceIds, this.userRole.workingAreaId, this.userId, opts.serviceRegistrationsPerPage, this.paginationOptions.firstRef, isForwardNavigation, false)
          .subscribe(serviceRegistrations => {
            this.serviceRegistrations = [];
            if (serviceRegistrations.length) {
              this.serviceRegistrations = serviceRegistrations;
              this.paginationOptions.lastRef = this.serviceRegistrations[this.serviceRegistrations.length - 1]['__doc'];
              this.paginationOptions.firstRef = this.serviceRegistrations[0]['__doc'];
              this.paginationOptions.totalSize = this.count;
              this.loadData()
            }
          })
      }
      this.sub.add(this.searchedSubscription);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setDefaultStatusFilter() {
    let defaultStatuses: ServiceRegStatusTypes[] = [ServiceRegStatusTypes.submitted];

    if (this.userRole.roleType === RoleTypes.networkPartner) {
      defaultStatuses = this.utilitiesService.getKeysForEnum(ServiceRegStatusTypes);
    }
    this.serviceRegFilter.statusFilter = defaultStatuses;
  }

  public handleRowSelect(payload: ITableData) {
    const reg: ServiceRegistration = payload._metadata.originalData as ServiceRegistration;
    delete reg['__doc'];
    let component: any = NetworkServiceRegistrationModalComponent;

    if (reg.type === ServiceRegistrationTypes.internal) {
      component = InternalServiceRegistrationModalComponent;
    } else if (reg.type === ServiceRegistrationTypes.customer || reg.isCsr) {
      component = CustomerServiceRegistrationModalComponent;
    }

    console.log(component);

    this.userPromptsService.showDialogue(
      component,
      {
        workingAreaId: this.userRole.workingAreaId,
        serviceId: reg.serviceId,
        serviceRegistration: reg
      },
      null, true
    );
  }

  private getColumnsForRole(): string[] {
    switch (this.userRole.roleType) {
      case RoleTypes.professional: {
        return ['service', 'customer', 'networkPartner'];
      }

      case RoleTypes.networkPartner: {
        return ['service', 'customer'];
      }

      case RoleTypes.coordinator: {
        return ['customer', 'networkPartner'];
      }
    }
  }

  private getTableConfig(data: ITableData[]) {
    this.tableCfg = {
      data: data,
      displayProperties: this.getColumnsForRole(),
      headers: {
        service: this.labels.service,
        customer: this.labels.customer,
        networkPartner: this.labels.redirected_by
      },
      rowOptions: [],
      rowButton: { icon: 'message_text_outline', color: 'primary' },
      allowSelection: false,
      displayHeaders: true
    };
    this.loading = false;
  }

  public rowIconClicked(row: ITableData) {
    const reg = row._metadata.originalData as ServiceRegistration;

    this.userPromptsService.showDialogue(
      ServiceRegistrationHistoryModalComponent,
      { serviceRegistration: reg }, null, false,
      { width: '400px' }
    );
  }
}
