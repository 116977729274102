<app-modal>
  <div class="modal_header" #modal_header>
    <span class="modal_title">
      {{labels.publish_request}}
    </span>
  </div>

  <div class="modal_body" #modal_body style="overflow: hidden; padding: 15px;">
    <p>{{labels.publish_request}}</p>

    <div fxLayout="column">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="scope">
        <mat-radio-button style="margin: 10px;" value="org">{{data.org.fullName}}</mat-radio-button>
        <mat-radio-button style="margin: 10px;" value="workingArea">{{data.workingArea.name}}</mat-radio-button>
        <mat-radio-button style="margin: 10px;" value="service">{{data.service.name}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div><br>
      <p>{{labels.options_optional}}</p>

      <div fxLayout="column" >
          <div fxFlex="1 1 50%">
            <mat-form-field appearance="outline">
              <mat-label>{{labels.max_reactions}}</mat-label>
              <input matInput type="number" [(ngModel)]="maxReactions">
            </mat-form-field>
    
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="20px">
        <mat-form-field appearance="outline">
          <mat-label>{{labels.week_days}}</mat-label>
          <mat-select [(ngModel)]="selectedWeekdays" multiple>
            <mat-option *ngFor="let w of weekdays" [value]="w.prop">
              {{w.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="modal_footer" #modal_footer>
    <button (click)="save()" [disabled]="!scope" color="primary" mat-raised-button>{{labels.submit}}</button>
  </div>
</app-modal>