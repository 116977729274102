import { cloneDeep } from 'lodash';
import { SearchIndex } from './searchIndex';
import { DocumentLog } from '../../interfaces';
import { IObjectMap } from '../interface';

export const INVENTORY_SEARCH_FIELDS = [
  'title',
  'color',
  'category',
  'status',
  'loanDetails.volunteer.firstName',
  'loanDetails.volunteer.lastName',
  'loanDetails.loanedToUser.firstName',
  'loanDetails.loanedToUser.lastName',
  'loanDetails.loanedOutByUser.firstName',
  'loanDetails.loanedOutByUser.lastName',
];

export enum InventoryColor {
  red = 'red',
  green = 'green',
  blue = 'blue',
  yellow = 'yellow',
  pink = 'pink',
  purpure = 'purpure'
}

export enum InventoryQuality {
  low = 1,
  normal = 2,
  medium = 3,
  hight = 4,
  best = 5
}

export enum IReservationStatus {
  option = 1,
  booked = 2,
}

export enum InventoryStatus {
  free = 'free',
  outOfOrder = 'outOfOrder',
  onLoan = 'onLoan'
}
export interface IInventoryUser {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
}

export interface IInventoryReservation {
  id: string;
  /**
  * id of IInventoryItem
  */
  itemId: string;
  date: Date;
  starttime: string;
  endtime: string;
  reservationRemark?: string;
  status: IReservationStatus;
  log?: DocumentLog;
}

export interface IInventoryLoanDetails {
  endDate: string;
  volunteer: IInventoryUser;
  loanNote: string;
  loanStart?: string;
  loanedToUser: IInventoryUser;
  loanedOutByUser: IInventoryUser;
}
export interface IInventoryLoanToFreeResponseModel {
  endDate: string;
  returnDate: string;
  returnNote: string;
}
export interface IInventoryLoanHistory {
  id: string;
  serviceId: string;
  workingAreaId: string;
  loanedToUserId: string;
  itemId: string;
  inventoryTitle: string;
  startdate: string;
  endDate: string;
  returnDate: string;
  returnNote: string;
  volunteerId: string;
  loanNote: string;

  log?: DocumentLog;
}

export interface LoanHistoryExportItem {
  startDate: string;
  endDate: string;
  returnDate: string;
  loanNote: string;
  returnNote: string;
  voluteerId: string;
  inventoryId: string;
  title: string;
  description: string;
  category: string;
  color: InventoryColor;
  loanedToUser: string;
  volunteer: string;
}

export interface InventoryExportItem {
  id: string;
  title: string;
  description: string;
  category: string;
  color: InventoryColor;
  replaceValue: number;
  quality: InventoryQuality;
  startDate: string;
  endDate: string;
  status: string;
  volunteer: string;
  loanedOutByUser: string;
  loanedToUser: string;
  loanNote: string;
}

export interface IInventoryItem {
  id?: string;
  workingareaId?: string;
  serviceId?: string;
  title: string;
  reservationApprovalRequired?: boolean;
  reservationAvailable?: boolean;
  description: string;
  category: string;
  color: InventoryColor;
  replaceValue: number;
  status: InventoryStatus;
  quality: InventoryQuality;
  log?: DocumentLog;
  searchIndex?: SearchIndex;
  loanDetails?: IInventoryLoanDetails;
  lastLoanDate?: string;
  descriptionRequired: boolean;
  code?: string;
}

export class InventoryItem implements IInventoryItem {
  id?: string;
  workingareaId?: string;
  serviceId?: string;
  title: string;
  description: string;
  reservationApprovalRequired?: boolean;
  reservationAvailable?: boolean;
  category: string;
  color: InventoryColor;
  replaceValue: number;
  status: InventoryStatus;
  quality: InventoryQuality;
  log?: DocumentLog;
  searchIndex?: SearchIndex;
  loanDetails?: IInventoryLoanDetails;
  lastLoanDate?: string;
  descriptionRequired: boolean;
  code?: string;

  constructor(model?: IInventoryItem) {
    if (!model) {
      model = Object.create(null);
    }

    this.id = model.id;
    this.workingareaId = model.workingareaId;
    this.serviceId = model.serviceId;
    this.title = model.title || '';
    this.description = model.description || '';
    this.category = model.category || '';
    this.color = model.color || InventoryColor.green;
    this.replaceValue = model.replaceValue;
    this.status = model.status || InventoryStatus.free;
    this.quality = model.quality || InventoryQuality.medium;
    this.log = model.log ? cloneDeep(model.log) : undefined;
    this.reservationApprovalRequired = !!model.reservationApprovalRequired;
    this.reservationAvailable = !!model.reservationAvailable;
    this.descriptionRequired = model.descriptionRequired || false;
    this.code = model.code || '';

    this.searchIndex = {
      properties: [...INVENTORY_SEARCH_FIELDS],
      index: {}
    };

    const d: IInventoryLoanDetails = model.loanDetails || Object.create(null);
    this.loanDetails = {
      endDate: null,
      loanNote: '',
      volunteer: {
        firstName: d.volunteer?.firstName || null,
        fullName: d.volunteer?.lastName || null,
        id: d.volunteer?.id || null,
        lastName: d.volunteer?.lastName || null,
      },
      loanedOutByUser: {
        firstName: d.loanedOutByUser?.firstName || null,
        fullName: d.loanedOutByUser?.fullName || null,
        id: d.loanedOutByUser?.id || null,
        lastName: d.loanedOutByUser?.lastName || null,
      },
      loanedToUser: {
        firstName: d.loanedToUser?.firstName || null,
        fullName: d.loanedToUser?.fullName || null,
        id: d.loanedToUser?.id || null,
        lastName: d.loanedToUser?.lastName || null,
      }
    };
    Object.assign(this.loanDetails, model.loanDetails || {});
  }
}

export const InventoryImportMandatory: IObjectMap<string> = {
  title: 'title',
  code: 'code'
};

export const InventoryImportOptional: IObjectMap<string> = {
  description: 'description',
  category: 'category'
};
