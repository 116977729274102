<app-modal [alignFooterStart]="true" *ngIf="emailOption">
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{labels.preview}}
        </span>
    </div>

    <div class="modal_body" #modal_body style="padding: 15px;">
        <div class="pb">
            <div><small><strong>{{labels.from}}:</strong> {{emailOption.from}}</small></div>
            <div><small><strong>{{labels.to}}:</strong> {{recipientNames()}}</small></div>
            <div><small><strong>{{labels.subject}}:</strong> {{emailOption.subject}}</small></div>
            <div><small><strong>{{labels.attachments}}:</strong> {{attachmentsName()}}</small></div>
        </div>

        <div fxLayout="column">
            <p [innerHTML]="sanitize(emailOption.actionHtml)"></p>
            <p class="carriage_returns" [innerHTML]="sanitize(emailOption.infoText)"></p>
        </div>
    </div>

    <div class="modal_footer" #modal_footer>
        <button (click)="sendEmail()" color="primary" [disabled]="loading" mat-raised-button>{{
            labels.send_email }}</button>
    </div>
</app-modal>