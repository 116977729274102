import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { LabelService } from './labels.service';
import { CloudFunctionService } from './functions.service';
import { User, Organization, Activity, Service, ActivityKindTypes, ActivityAttachment } from '@shared/model';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { OrganizationService } from './organization.service';
import { EmailAttachmentData, IEmailOption, IEmailRecipient } from '@shared/interface';
import { ObjectMap } from '../../../../functions/src/interfaces';
import { uniq } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    public constructor(
        private userService: UserService,
        private labelService: LabelService,
        private cloudFunctionService: CloudFunctionService,
        private orgService: OrganizationService
    ) { }

    // formats email messages for profiles and sends to current active school
    public buildPersonsEmail(
        profiles: User[], actionHtml: string, bodyTexts: string[],
        opts: ObjectMap<any>,
        emailType: string,
        attachments?: EmailAttachmentData[]
    ) {
        return new Promise<IEmailOption[]>(async (resolve) => {
            const label: ILabelsProvider = (await this.labelService.getLabels('global-labels')).data;
            const currentUser: User = await this.userService.getUserById(this.userService.getCurrentUserId()).pipe(take(1)).toPromise();
            const org: Organization = await this.orgService.getCurrentOrganization().pipe(take(1)).toPromise();

            const emails: IEmailOption[] = profiles.map((profile, index) => {
                const opt: IEmailOption = {
                    to: profile.email,
                    from: opts.fromEmail || org.email,
                    logoUrl: org?.webLogo || org?.fullLogoUrl,
                    orgId: org.id,
                    orgName: opts.fromName || org.fullName,
                    subject: opts.bodySubject,
                    bodySubject: opts.bodySubject,
                    bodyText: bodyTexts[index],
                    infoText: this.getInfoText(emailType, { org: org, user: currentUser, label }),
                    actionHtml: actionHtml,
                    attachments
                };
                return opt;
            });
            resolve(emails);
        });
    }

    private formatMessageBodyWithLineBreaks(msg: string): string {
        return msg
            .replace(new RegExp('\n', 'g'), '<br>')
            .replace(new RegExp('\r', 'g'), '<br>');
    }


    public async sendEmailToPersons(emails: IEmailOption[]) {
        await this.cloudFunctionService.sendEmails(emails, null, true);
    }

    private getInfoText(type: string, data: { org: Organization, user: User, label: ILabelsProvider }) {
        const fallback = `${data.label.labels['email-info-text']} ${data.org.email} <br/><br/>`;
        if (type == 'organization') return this.formatMessageBodyWithLineBreaks(data.org.settings?.emailSettings.defaultFooter) || fallback;
        else return this.formatMessageBodyWithLineBreaks(data.user.settings?.emailFooter) || fallback;
    }

    private getAttachmentsList(attachments: ActivityAttachment[]): string {
        let str = '';

        if (attachments && attachments.length > 0) {
            str += `<br><strong>Attachments</strong><ul>`;

            attachments.forEach(att => {
                str += `<li><a href="${att.url}">${att.filename}</a></li>`;
            });

            str += `<ul><br>`;
        }

        return str;
    }

    public sendEmailForNewActivity(user: User, activity: Activity, service: Service): Promise<void> {
        return new Promise(async (resolve) => {
            if ((user.email || user.emailAuthorizedPerson) && activity.amount > 0 && service.settings.sendEmailAfterSalesActivity) {
                const label: ILabelsProvider = (await this.labelService.getLabels('global-labels')).data;
                const currentUser: User = await this.userService.getUserById(this.userService.getCurrentUserId()).pipe(take(1)).toPromise();
                const org: Organization = await this.orgService.getCurrentOrganization().pipe(take(1)).toPromise();

                // adds action initiated_by clause to action html
                let actionHtml = `${service.settings.salesEmailToCustomer}<br><br>
                    <strong>${label.title}</strong>: ${activity.title}<br>
                    <strong>${label.amount}</strong>: ${activity.amount}<br>
                    <strong>${label.description}</strong>: ${activity.description}<br>
                    ${this.getAttachmentsList(activity.attachments)}
                    <br><br>
                    <div style='color: #bbbbbb;'>${label.email_action_initiated_by}</div>
                    <div>${currentUser.firstname} ${currentUser.lastname}</div>`;

                switch (activity.kind) {
                    case ActivityKindTypes.sale: {
                        const recipients: IEmailRecipient[] = uniq([user.email, user.emailAuthorizedPerson].filter(e => !!e)).map(r => {
                            return { email: r, name: '' }
                        });

                        const emailOptions: IEmailOption[] = recipients.map(r => {
                            return {
                                to: r.email,
                                from: `noreply@${org.email.substr(org.email.indexOf('@') + 1)}`,
                                logoUrl: org?.webLogo || org.fullLogoUrl,
                                orgId: org.id,
                                orgName: org.fullName,
                                subject: activity.title,
                                bodySubject: '',
                                bodyText: '',
                                infoText: `${label.labels['email-info-text']} ${org.email} <br/><br/>`,
                                actionHtml
                            };
                        });

                        await this.cloudFunctionService.sendEmails(emailOptions, 'customer');
                        break;
                    }

                    default: {
                        throw Error('Activity type not implemented yet!');
                    }
                }
            }

            resolve();
        });
    }
}
