<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">
            {{address.street}} {{address.number}} 
            {{address.postalcode}} {{address.city}}</span>
    </div>

    <div class="modal_body" #modal_body>
            <mat-card style="margin: 15px;" *ngIf="address?.geo?.latitude && address?.geo?.longitude">
                <img id="map-img" [src]="locationUrl()" alt="">
            </mat-card>
    </div>
</app-modal>