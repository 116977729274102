<app-modal>
    <div class="modal_header" #modal_header fxLayout="column">
        <div class="modal_title">{{labels.open_requests}}</div>
        <div class="modal_subtitle">{{userName}}</div>
    </div>

    <div class="modal_body" #modal_body>
        <div style="padding: 15px;">
            <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
            <section>
                <mat-radio-group [(ngModel)]="requestFilter" (ngModelChange)="filterCustomerRequest()">
                    <mat-radio-button value="open">{{labels.open}}</mat-radio-button>
                    <mat-radio-button value="all">{{labels.all}}</mat-radio-button>
                </mat-radio-group>
            </section>

            <div *ngIf="dataTableConfig?.data?.length" [style.opacity]="isLoading ? .5 : 1">
                <data-table [configOptions]="dataTableConfig" (onSelectRow)="handleRowSelect($event)">
                </data-table>
            </div>
        </div>
    </div>
</app-modal>