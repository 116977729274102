import { Component, Inject, OnInit } from '@angular/core';
import { User } from '@shared/model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ModalComponent } from '@shared/components';
import { LabelService } from 'app/core';
import { EditUserComponent } from '@users/components/edit-user/edit-user.component';

@Component({
  selector: 'app-view-user-dialog',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent extends ModalComponent implements OnInit {
  public user: User;
  public labels: any = {};

  constructor(
    private labelService: LabelService,
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<ViewUserComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(dialogRef, breakpointObserver);
  }

  async ngOnInit() {
    this.labels = (await this.labelService.getLabels('app-view-user-dialog')).data;
    this.user = this.data.user;
  }

  openEditUserDialog() {
    this.dialogRef.close();
    this.dialog.open(EditUserComponent, {
      width: '800px',
      // height: '620px',
      maxWidth: '100vw',
      data: { user: this.user }
    });
  }
}
