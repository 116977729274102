<div class="main">
  <mat-progress-bar [style.opacity]="isLoading ? 1 : 0" mode="indeterminate"></mat-progress-bar>

  <data-table *ngIf="users.length > 0"
    [configOptions]="getTableConfig()"
    (onSelectRow)="handleRowSelect($event)"
    (onSelectRowAction)="handleRowAction($event)">
  </data-table>

  <div *ngIf="users.length === 0 && !isLoading" class="no_data_found" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="center center">
     <div class="title"> {{labels.no_results_found}}</div>
     <div class="action">
        <button mat-button (click)="createNewUser()"> {{labels.create_new_user}}</button>
     </div>
  </div>
</div>
