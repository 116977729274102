import { Injector } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirestoreService } from './firestore.service';
import { OrgServiceService } from './org-service.service';
import { WorkingAreaService } from './workingarea.service';
import { LocalStorageService } from './local-storage.service';
import { ICrudService } from './i-crud.service';
export abstract class BaseCrudService<T extends {
  id: string;
}> implements ICrudService<T> {
  protected afsDB: FirestoreService;
  protected orgServiceService: OrgServiceService;
  protected workingAreaService: WorkingAreaService;
  protected localStorageService: LocalStorageService;
  protected constructor(protected injector: Injector, protected entitySuffixPath: string) {
    this.afsDB = injector.get(FirestoreService);
    this.orgServiceService = injector.get(OrgServiceService);
    this.workingAreaService = injector.get(WorkingAreaService);
    this.localStorageService = injector.get(LocalStorageService);
  }
  private get orgId(): string {
    return this.localStorageService.getItemSync('user_organization');
  }
  get(id: string): Observable<T> {
    return this.afsDB.docWithId$(`${this.getRef()}/${id}`);
  }
  getList(): Observable<T[]> {
    return from(this.afsDB.colWithIds$(this.getRef())).pipe(map(arr => (arr ? (arr as T[]) : [])));
  }
  create(model: T): Observable<void> {
    return from(this.afsDB.setDoc(`${this.getRef()}/${model.id}`, Object.assign({}, model as any)));
  }
  update(model: T): Observable<void> {
    return from(this.afsDB.update(`${this.getRef()}/${model.id}`, Object.assign({}, model as any)));
  }
  delete(id: string): Observable<void> {
    return from(this.afsDB.remove(`${this.getRef()}/${id}`));
  }
  /**
   * overrate it if need other path
   * - default is `organizations/${this.orgId}/${this.entitySuffixPath}`
   */
  getRef(): string {
    const ref = `organizations/${this.orgId}/${this.entitySuffixPath}`;
    return ref;
  }
}
