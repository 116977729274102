<app-modal>
  <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
     <span class="icon">
        <b> <mat-icon>{{ data.icon }}</mat-icon> </b>
     </span>
     <span>{{ data.title | uppercase }}</span> 
  </div>

  <div class="modal_body" #modal_body>
        <div *ngIf="form">
            <form [formGroup]="form" fxLayout="column" role="form">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>{{labels.leave_a_message}}</mat-label>
                    <textarea matInput  cdkTextareaAutosize cdkAutosizeMinRows minRows="7" formControlName="feedback"></textarea>
                </mat-form-field>
            </form>
        </div>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
       <button mat-button [disabled]="!form.valid" (click)="sendFeedback()">Send</button>
  </div>
</app-modal>
