import { DocumentLog } from './documentLog';
import { SearchIndex } from './searchIndex';

export enum ReservationStatusTypes {
    reservation_booked = 1,
    reservation_option,
    reservation_empty
}

export type ReservationStatusClass = 'status_booked' | 'status_option' | 'status_empty';

export interface TimeGridCell {
    timeText: string;
    time: number;
    cellIndex: number;
    reservation?: Reservation;
    statusClass?: ReservationStatusClass;
    tooltip?: string;
}

export interface DateGridItem {
    date: Date;
    times: TimeGridCell[];
}

export enum ReservationRepeatTypes {
    daily = 1,
    weekly,
    two_weeks,
    monthly
}

export enum ReservationRepeatFormatTypes {
    first = 1,
    second,
    third,
    fourth,
    last
}

export class ReservationSeriesConfig {
    endDate: Date; // date + time to end
    repeatKey: string; // used to identify reservations documents that belong to this series
    repeatType: ReservationRepeatTypes;
    repeatFormat: ReservationRepeatFormatTypes;
    repeatMonthOnceCtrl: boolean;

    constructor() {
        this.repeatType = ReservationRepeatTypes.weekly;
        this.repeatFormat = ReservationRepeatFormatTypes.first;
        this.repeatMonthOnceCtrl = true;
    }
}

export class Reservation {
    id?: string;
    inventoryId: string;
    inventoryTitle?: string;
    date: Date;
    year: number;
    month: number;
    day: number;
    startTime: number;
    endTime?: number;
    remark: string;
    status: ReservationStatusTypes;
    log?: DocumentLog;
    searchIndex: SearchIndex;
    isReservationSeries: boolean;
    seriesConfig: ReservationSeriesConfig;
}
