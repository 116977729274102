import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'organizations-list',
  templateUrl: './organization-list.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class OrganizationListDialog implements OnInit {
  public form: FormGroup;
  public organizations: any[];
  public organizationsForView: any[];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<OrganizationListDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.organizations = this.data.organizations || [];    
    this.organizationsForView = this.organizations.slice();
    this.createForm();

    // here we listen for when the filter text changes
    this.form
      .get('filterText')
      .valueChanges.pipe(
        startWith(''),
        distinctUntilChanged()
      )
      .subscribe((value: string) => this.filterOrganizations(value));
  }

  filterOrganizations(text: string) {
    this.organizationsForView = this.organizations.slice();

    if (text) {
      this.organizationsForView = this.organizationsForView.filter(org => {
        return org.fullName.indexOf(text) !== -1 || org.id.indexOf(text) !== -1;
      });
    }
  }

  continue(org): void {
    this.dialogRef.close(org);
  }

  createForm() {
    this.form = this.fb.group({
      filterText: this.fb.control('')
    });
  }
}
