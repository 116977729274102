import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Address } from '@models/model';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrl: './map-dialog.component.scss'
})
export class MapDialogComponent implements OnInit {

  protected address: Address;

  constructor(
    public dialogRef: MatDialogRef<MapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { address: Address },
    private domSanitizer: DomSanitizer
  ) { } 
  
  ngOnInit(): void {
    this.address = cloneDeep(this.data.address);
  }

  protected locationUrl() {
    const position = this.address?.geo;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=620x320&maptype=roadmap&markers=color:red%7Clabel:C%7C${position.latitude},${position.longitude}&key=${environment.googleMapAPIKey}`);
  }
}
