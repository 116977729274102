import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LabelService } from '@core/services/labels.service';
import { NetworkPartner, User, NetworkInstitution, SocialRelation, SocialRelationKindTypes, Institution } from '@shared/model';
import { CustomerNetworkService, UserPromptsService, UserService, InstitutionService, UtilitiesService } from '@core/services';
import { Subscription } from 'rxjs';
import { IDataTableConfig } from '../data-table/data-table.component';
import { NetworkPartnerModalComponent, NetworkInstitutionModalComponent, SocialRelationsModalComponent } from '@shared/entry-components';
import { take } from 'rxjs/operators';
import { uniq, find, keys } from 'lodash';

@Component({
    selector: 'app-customer-network',
    templateUrl: './customer-network.component.html',
    styleUrls: ['./customer-network.component.scss']
})
export class CustomerNetworkComponent implements OnInit, OnDestroy, OnChanges {
    @Input() customer: User;

    public labels: any = {};
    public networkPartnersTableConfig: IDataTableConfig;
    public networkInstitutionsTableConfig: IDataTableConfig;
    public socialRelationsTableConfig: IDataTableConfig;

    private sub: Subscription = new Subscription();
    private usersPartners: User[] = [];
    private usersSocialRelations: User[] = [];
    private partnerInstitutions: Institution[] = [];

    constructor(
        private labelService: LabelService,
        private customerNetworkService: CustomerNetworkService,
        private userPromptsService: UserPromptsService,
        private userService: UserService,
        private institutionService: InstitutionService,
        private utilitiesService: UtilitiesService
    ) { }

    async ngOnInit() {
        this.labels = (await this.labelService.getLabels('app-customer-network')).data;

        this.pushSub(
            this.customerNetworkService.getNetworkPartners(this.customer.id).subscribe(partners => {
                const userIds: string[] = partners.map(p => p.partnerId);
                this.userService.getUsersFromIds(uniq(userIds)).pipe(take(1)).subscribe(users => {
                    this.usersPartners = users;
                    this.updateNetworkPartnersTableData(partners);
                });
            }),
            this.customerNetworkService.getNetworkInstitutions().subscribe(partnerInstitutionDocs => {
                const institutionIds: string[] = partnerInstitutionDocs.map(p => p.institutionId);
                this.institutionService.getInstitutionsFromIds(uniq(institutionIds))
                    .pipe(take(1))
                    .subscribe(institutions => {
                        this.partnerInstitutions = institutions;
                        this.updateNetworkInstitutionsTableData(partnerInstitutionDocs);
                    });
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.utilitiesService.isNewChange(changes.customer)) {
            this.customer.socialRelations = this.customer.socialRelations || {};
            const userIds: string[] = keys(this.customer.socialRelations);

            this.userService.getUsersFromIds(uniq(userIds)).pipe(take(1)).subscribe(users => {
                this.usersSocialRelations = users.filter(u => !!u);
                this.updateSocialRelationsTableData(this.customer);
            });
        }
    }

    private openEditNetworkPartner(partner: NetworkPartner) {
        this.userPromptsService.showDialogue(NetworkPartnerModalComponent, {
            partner, customer: this.customer
        }, null, false, { width: '400px' });
    }

    public addNetworkPartner() {
        const partner: NetworkPartner = new NetworkPartner(this.customer.id);
        this.openEditNetworkPartner(partner);
    }

    private openEditNetworkInstitution(doc: NetworkInstitution) {
        this.userPromptsService.showDialogue(NetworkInstitutionModalComponent, {
            networkInstitution: doc
        }, null, false, { width: '400px' });
    }

    public addNetworkInstitution() {
        const networkInstitution: NetworkInstitution = new NetworkInstitution(this.customer.id);
        this.openEditNetworkInstitution(networkInstitution);
    }

    private openEditSocialRelation(doc: SocialRelation) {
        this.userPromptsService.showDialogue(SocialRelationsModalComponent, {
            relation: doc,
            customer: this.customer
        }, null, false, { width: '400px' });
    }

    public addSocialNetwork() {
        const relation: SocialRelation = new SocialRelation(this.customer.id);
        this.openEditSocialRelation(relation);
    }

    public handleRowSelect(payload) {
        switch (payload.type) {
            case 'network_partner': {
                return this.openEditNetworkPartner(payload.originalData);
            }

            case 'network_institution': {
                return this.openEditNetworkInstitution(payload.originalData);
            }

            case 'social_relation': {
                return this.openEditSocialRelation(payload.originalData);
            }
        }
    }

    private updateNetworkPartnersTableData(partners: NetworkPartner[]) {
        this.networkPartnersTableConfig = {
            data: partners.map(p => {
                const user = find(this.usersPartners, { id: p.partnerId });
                return {
                    partner: `${user.firstname} ${user.lastname}`,
                    kind: p.networkPartnerKind,
                    originalData: p,
                    type: 'network_partner'
                };
            }),
            displayProperties: ['partner', 'kind'],
            rowOptions: [],
            allowSelection: false,
            displayHeaders: false,
            headers: {
                partner: this.labels.partner,
                kind: this.labels.partner_kind
            }
        };
    }

    private updateNetworkInstitutionsTableData(networkInstitutions: NetworkInstitution[]) {
        this.networkInstitutionsTableConfig = {
            data: networkInstitutions.map(p => {
                const institution = find(this.partnerInstitutions, { id: p.institutionId });
                return {
                    name: institution.name,
                    originalData: p,
                    type: 'network_institution'
                };
            }),
            displayProperties: ['name'],
            rowOptions: [],
            allowSelection: false,
            displayHeaders: false,
            headers: {
                name: this.labels.name
            }
        };
    }

    private updateSocialRelationsTableData(customer: User) {
        this.socialRelationsTableConfig = {
            data: keys(customer.socialRelations).map(p => {
                const relation = customer.socialRelations[p];
                const user = find(this.usersSocialRelations, { id: p });
                return user ? {
                    name: user.fullname,
                    relation: SocialRelationKindTypes[relation.socialRelationKind],
                    picture: user.picture,
                    originalData: relation,
                    type: 'social_relation'
                } : null;
            }).filter(user => !!user),
            displayProperties: ['picture', 'name', 'relation'],
            rowOptions: [],
            allowSelection: false,
            displayHeaders: false,
            propertyWithImage: 'picture',
            headers: {
                name: this.labels.name,
                relation: this.labels.relation
            }
        };
    }

    private pushSub(...subs: Subscription[]) {
        subs.forEach(sub => {
            this.sub.add(sub);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
