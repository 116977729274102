import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LabelService } from '@core/services/labels.service';
import { IObjectMap } from '@shared/interface';

export interface PromptInputConfig {
    type?: 'input' | 'textarea' | 'number' | 'password' | 'phone';
    required?: boolean;
    initialValue?: string;
    requireMatchText?: string;
    disableCancel?: boolean;
}

@Component({
    selector: 'app-prompt-dialogue',
    styleUrls: ['./prompt-dialogue.component.css'],
    templateUrl: './prompt-dialogue.component.html',
})
export class PromptDialogueComponent implements OnInit {
    public promptForm: FormGroup;
    public labels: any = {};
    public config: PromptInputConfig;

    constructor(
        public dialogRef: MatDialogRef<PromptDialogueComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IObjectMap<any>,
        private fb: FormBuilder,
        private labelService: LabelService
    ) { }

    async ngOnInit() {
        this.config = Object.assign({}, {
            type: 'input'
        }, this.data.config);

        if (this.config.required || this.config.disableCancel) {
            this.dialogRef.disableClose = true;
        }

        this.createForm();
        this.labels = (await this.labelService.getLabels('app-prompt-dialogue')).data;
    }

    public continue() {
        this.dialogRef.close(this.promptForm.value.inputValue);
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public createForm() {
        this.promptForm = this.fb.group({
            inputValue: [this.config.initialValue || '']
        });
    }

    public onPhone(ph: string): void {
        this.promptForm.get('inputValue').setValue(ph);
    }

    public canSubmit(): boolean {
        const matched = !this.config.requireMatchText || this.config.requireMatchText === this.promptForm.value.inputValue;

        return (!this.config.required || this.promptForm.value.inputValue)
            && matched;
    }
}
