import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { IDataTableConfig, ITableData } from '../data-table/data-table.component';
import { NgSub } from 'ng-sub';
import { Role, RoleTypes, UsageActionTypes, UsageActivity } from '@models/index';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService, UsageService } from '@core/services';
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';

@Component({
  selector: 'app-usage-display-model',
  templateUrl: './usage-display-model.component.html',
  styleUrls: ['./usage-display-model.component.scss']
})
export class UsageDisplayModelComponent implements OnInit, OnDestroy {

  public docTableConfig: IDataTableConfig;
  public labels: any = {};
  private sub = new NgSub();
  public role: Role;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { userId: string },
    private labelService: LabelService,
    private usageService: UsageService
  ) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-usage-display-model')).data;

    this.usageService.getUsageCollectionGroup('usage', () => {
      return [['actionTo.userId', '==', this.data.userId]];
    }, { orderBy: [{ field: 'timestamp', val: 'desc' }], limit: 30 }
    ).then(usages => {
      this.docToTableConfig(usages);
    })
  }

  private docToTableConfig(docs: UsageActivity[]): void {
    const config: IDataTableConfig = {
      data: docs.map((doc: UsageActivity) => {
        const r: ITableData = cloneDeep(doc);
        r.action = this.labels[UsageActionTypes[doc.action]];
        r.date = format(doc.timestamp, 'DD-MM-YYYY');
        r.role = this.labels[RoleTypes[doc.actionBy.role]];
        r.actionBy = `${doc.actionBy.firstName} ${doc.actionBy.lastName}`
        r._metadata = {
          originalData: doc
        }
        return r;
      }),
      displayProperties: ['action', 'actionBy', 'date', 'role'],
      rowOptions: [],
      allowSelection: false,
      displayHeaders: true,
    }
    this.docTableConfig = config;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
