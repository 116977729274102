import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService } from '@core/services/labels.service';

@Component({
  selector: 'app-edit-expiry-date',
  templateUrl: './edit-expiry-date.component.html',
  styleUrls: ['./edit-expiry-date.component.scss']
})
export class EditExpiryDateComponent implements OnInit {
  public labels: any = {};
  public date: Date;

  constructor(
    public dialogRef: MatDialogRef<EditExpiryDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { date: Date },
    private labelService: LabelService
  ) { }

  async ngOnInit() {
    this.date = this.data.date || new Date();

    this.labels = (await this.labelService.getLabels('app-edit-expiry-date')).data;

    this.dialogRef.updateSize(
      '400px',
      '300px',
    );
  }

  public continue() {
    this.dialogRef.close(this.date);
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
