import { combineLatest, BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  subjects = {};

  public constructor() { }

  next(name, value) {
    if (this.subjects[name]) {
      this.subjects[name].next(value);
    }
  }

  setItem(name, value) {
    localStorage.setItem(name, value);
    this.next(name, value);
  }

  removeItem(name) {
    localStorage.removeItem(name);
    this.next(name, undefined);
  }

  getItem(name: string): BehaviorSubject<string> {
    if (this.subjects[name]) {
      return this.subjects[name];
    } else {
      this.subjects[name] = new BehaviorSubject(localStorage.getItem(name));

      return this.subjects[name];
    }
  }

  getItemSync(name): string {
    return localStorage.getItem(name);
  }

  getItems(keys: string[]): Observable<any> {
    const subjects = keys.map(key => this.getItem(key));
    return combineLatest(
      subjects
    );
  }

  clear() {
    localStorage.clear();

    Object.keys(this.subjects).forEach((key) => {
      this.subjects[key].next(null);
    });
  }
}
