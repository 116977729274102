<app-modal [alignFooterStart]="true" [hideCloseButton]="true">
  <div class="modal_header" #modal_header fxLayout="row">
    <button *ngIf="currentActionStep !== ActionStepsTypes.CUSTOMER_DETAILS" mat-icon-button
      (click)="switchPhase(false)">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <div fxLayout="column" fxFlex="stretch" fxLayoutAlign="flex-start" fxLayoutGap="8px">
      <span class="modal_title">
        {{isEditing ? labels.edit_service_registration : labels.service_registration}}
      </span>
      <span class="modal_subtitle">
        {{workingArea?.name}} - {{service?.name}}
      </span>
    </div>
  </div>

  <div class="modal_body" #modal_body style="padding: 0; overflow: hidden;">
    <div class="body-content">
      <div [ngSwitch]="currentActionStep">
        <ng-container *ngSwitchCase="ActionStepsTypes.CUSTOMER_DETAILS">
          <ng-container *ngTemplateOutlet="customerDetails"> </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="ActionStepsTypes.CUSTOM_FORMS">
          <ng-container *ngTemplateOutlet="customForms"> </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="ActionStepsTypes.EXTRA_DETAILS">
          <ng-container *ngTemplateOutlet="extraDetails"> </ng-container>
        </ng-container>
      </div>
    </div>

    <mat-progress-bar class="body-progress" mode="determinate" [value]="progressPercent"></mat-progress-bar>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
      <div fxLayout="row" fxLayoutGap="10px" *ngIf="currentActionStep !== ActionStepsTypes.EXTRA_DETAILS">
        <button mat-button (click)="closeModal()">{{labels.cancel}}</button>
        <button mat-raised-button color="primary" [disabled]="!canNextPhase()" (click)="switchPhase(true)">
          {{labels.next}}
        </button>
      </div>

      <div fxLayout="row" fxLayoutGap="10px" *ngIf="currentActionStep === ActionStepsTypes.EXTRA_DETAILS">
        <button mat-stroked-button color="warn" (click)="deleteServiceReg()">
          {{labels.delete}}
        </button>

        <button mat-raised-button color="primary" (click)="acceptServiceReg()">
          {{labels.accept}}
        </button>
      </div>
    </div>
  </div>
</app-modal>

<ng-template #customerDetails>
  <div style="padding: 15px 0;">
    <app-service-catalog-customer-form *ngIf="serviceRegistration?.customer"
      [serviceRegistration]="serviceRegistration" (formChanged)="customerFormUpdated($event)">
    </app-service-catalog-customer-form>
  </div>
</ng-template>

<ng-template #customForms>
  <div *ngIf="customForm" style="padding-bottom: 30px;">
    <app-custom-form-group [disabled]="true" (formChanged)="onCustomFormUpdated($event)"
      [customForm]="customForm" [formValues]="serviceRegistration.customForm?.values">
    </app-custom-form-group>
  </div>
</ng-template>

<ng-template #extraDetails>
  <div style="padding: 1em 0;">
    <form [formGroup]="extraDetailsForm" fxLayout="column" fxLayoutGap="20px">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{labels.additional_information}}</mat-label>
        <textarea matInput formControlName="additionalInformation" cdkAutosizeMinRows="3" cdkTextareaAutosize
          minRows="6"></textarea>
      </mat-form-field>

      <div>
        <p>{{labels.complete_application_process}}</p>
      </div>
    </form>
  </div>
</ng-template>