<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{labels.contact_details}}</span>
    </div>

    <div class="modal_body" #modal_body style="padding: 1em;">
        <div class="py-4">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        </div>
        <div>
            @for (data of dataSource; track $index) {
            <div style="display: grid; grid-template-columns: 30% 25% 5% 35% 5% ; gap: 0px;">
                <div>
                    <mat-divider></mat-divider>
                    <mat-list-item lines="2">
                        <div matListItemTitle>{{data.name}}</div>
                        <div matListItemLine>{{data.role}}</div>
                    </mat-list-item>
                </div>

                <div>
                    <mat-divider></mat-divider>
                    @if (data.phone) {
                    <div>
                        <mat-list-item lines="2">
                            <div matListItemTitle>{{data.phone}}</div>
                            <div matListItemLine>{{data.phoneRemark}}</div>
                        </mat-list-item>
                    </div>
                    }
                    @if (data.phone2) {
                    <mat-divider></mat-divider>
                    <div>
                        <mat-list-item lines="2">
                            <div matListItemTitle>{{data.phone2}}</div>
                            <div matListItemLine>{{data.phoneRemark2}}</div>
                        </mat-list-item>
                    </div>
                    }
                </div>

                <div>
                    <mat-divider></mat-divider>
                    @if (data.phone) {
                    <div>
                        <mat-nav-list lines="2">
                            <div matListItemTitle>
                                <ng-container *ngTemplateOutlet="copy; context: {$implicit: data.phone}"></ng-container>
                            </div>
                        </mat-nav-list>
                    </div>
                    }

                    @if (data.phone2) {
                    <mat-divider></mat-divider>
                    <div>
                        <mat-nav-list lines="2">
                            <div matListItemTitle>
                                <ng-container
                                    *ngTemplateOutlet="copy; context: {$implicit: data.phone2}"></ng-container>
                            </div>
                        </mat-nav-list>
                    </div>
                    }
                </div>

                <div>
                    <mat-divider></mat-divider>
                    @if (data.email) {
                    <div>
                        <mat-list-item lines="2">
                            <div matListItemTitle>{{data.email}}</div>
                            <div matListItemLine>{{data.emailRemark}}</div>
                        </mat-list-item>
                    </div>
                    }
                    @if (data.email2) {
                    <mat-divider></mat-divider>
                    <div>
                        <mat-list-item lines="2">
                            <div matListItemTitle>{{data.email2}}</div>
                            <div matListItemLine>{{data.emailRemark2}}</div>
                        </mat-list-item>
                    </div>
                    }
                </div>

                <div>
                    <mat-divider></mat-divider>
                    @if (data.email) {
                    <div>
                        <mat-nav-list lines="2">
                            <div matListItemTitle>
                                <ng-container *ngTemplateOutlet="copy; context: {$implicit: data.email}"></ng-container>
                            </div>
                        </mat-nav-list>
                    </div>
                    }
                    @if (data.email2) {
                    <mat-divider></mat-divider>
                    <div>
                        <mat-nav-list lines="2">
                            <div matListItemTitle>
                                <ng-container
                                    *ngTemplateOutlet="copy; context: {$implicit: data.email2}"></ng-container>
                            </div>
                        </mat-nav-list>
                    </div>
                    }
                </div>
            </div>
            }
        </div>
    </div>
</app-modal>

<ng-template #copy let-data>
    <button mat-icon-button (click)="copyData(data)"><mat-icon>content_copy</mat-icon></button>
</ng-template>