import { Injectable } from '@angular/core';
import { MediaMarshaller } from '@ngbracket/ngx-layout';
import { CommonMaterialModule } from '@shared/common-material-module';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    constructor(
        private m: MediaMarshaller,
    ) {
        new CommonMaterialModule(this.m);
    }

    public scrollToBottomState$: Subject<boolean> = new Subject();

    public updateScrollState(status: boolean) {
        this.scrollToBottomState$.next(status);
    }
}
