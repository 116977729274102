import { Injectable } from '@angular/core';
import { TimeGridCell } from '@shared/model';
import { addHours, startOfDay } from 'date-fns';
import { UtilitiesService } from './utilities.service';
import { AbstractControl } from '@angular/forms';
import { find } from 'lodash';
import { IObjectMap } from '@models/interface';

@Injectable({
  providedIn: 'root'
})
export class TimeIntervalService {
  private gridCellIndices: number;
  private gridTimesStep: number;

  constructor(private utilitiesService: UtilitiesService,) { 
  }

  
  public setInterval(amount: number) {
    // initializations
    this.gridTimesStep = amount / 60;
    this.gridCellIndices = 1 / this.gridTimesStep * 24;
  }
  
  public cellIndexToTimeGrid(index: number, date: Date): TimeGridCell {
    const time = index * this.gridTimesStep;
    const upDated = addHours(date, time);
    // const timeText = format(upDated, 'hh:mm a');
    const timeText = this.utilitiesService.getLocaleTimeString(upDated);
    return {
      cellIndex: index,
      time,
      timeText,
      // statusClass: this.getStatusClass()
    };
  }

  public generateDateCells(date: Date): TimeGridCell[] {
    const cells: TimeGridCell[] = [];
    date = startOfDay(new Date());

    for (let i = 0; i < this.gridCellIndices; i++) {
      cells.push(this.cellIndexToTimeGrid(i, date));
    }
    return cells;
  }

  public timeToTimetextTwo(date: Date): TimeGridCell {
    let time = '';
    const dateStr = new Date(date).toLocaleTimeString().split(':');
    time += dateStr[0] + ":";
    time += dateStr[1];
    time += dateStr[2].substring(2);
    const computedTime = this.generateDateCells(date).find((date) => {
      if (date.timeText === time) {
        return date;
      }
    })
    if (computedTime) {
      return computedTime
    }
    return null;
  }

  public timeToTimetext(hourTime: number, date: Date): string {
    date = startOfDay(date);
    const upDated = addHours(date, hourTime);
    const timeText = this.utilitiesService.getLocaleTimeString(upDated);

    return timeText;
  }

  public updateEndDateList(timeList: TimeGridCell[], formData: {form : AbstractControl<any, any>, prop: string}, occupiedTimes: IObjectMap<boolean>): TimeGridCell[] {
    const validEndDates = [];
    const startTime: number = formData.form.get(formData.prop).value;

    if (startTime >= 0 && startTime !== null) {
      const timesCopy: TimeGridCell[] = timeList.slice();
      const copy = timesCopy[0];

      const nextDayTime: TimeGridCell = {
        cellIndex: timesCopy.length + 1,
        time: copy.time,
        timeText: copy.timeText,
      };

      timesCopy.push(nextDayTime);

      let currCell = find(timesCopy, { time: startTime });
      currCell = timesCopy[currCell.cellIndex + 1];

      while (!!currCell) {
        validEndDates.push(currCell);
        if (occupiedTimes[currCell.time]) {
          break;
        }
        currCell = timesCopy[currCell.cellIndex + 1];
      }
    }
    return validEndDates;
  }

}
