import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Guid } from '@core/guid';
import { InventoryLoanHistoryService, InventoryService, UtilitiesService } from '@core/services';
import { LabelService } from '@core/services/labels.service';
import { UserPromptsService } from '@core/services/user-prompt.service';
import { IInventoryItem, IInventoryLoanHistory, IInventoryLoanToFreeResponseModel, InventoryItem, InventoryStatus, User } from '@models/model';
import { IDataTableConfig, ITableData } from '@shared/components/data-table/data-table.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { InventoryLoanToFreeDialogComponent } from 'app/inventory-module/components/inventory-loan-to-free-dialog/inventory-loan-to-free-dialog.component';
import { LoanHistoryModalComponent } from 'app/inventory-module/components/loan-history-modal/loan-history-modal.component';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import { NgSub } from 'ng-sub';
import { forkJoin, from } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { InventoryLoanHistoryListModalComponent } from '../inventory-loan-history-list-modal/inventory-loan-history-list-modal.component';

@Component({
  selector: 'app-user-inventory-loan',
  templateUrl: './user-inventory-loan.component.html',
  styleUrls: ['./user-inventory-loan.component.scss']
})
export class UserInventoryLoanComponent extends ModalComponent implements OnInit, OnDestroy {
  @ViewChild('loanExtraBtn', { static: true }) loanExtraBtn: TemplateRef<any>;

  public labels = this.labelService.defaultProvider();
  public customer: User;
  public tableConfig: IDataTableConfig;

  private sub = new NgSub();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { customer: User; volunteerId?: string; },
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<UserInventoryLoanComponent>,
    private labelService: LabelService,
    private userPromptsService: UserPromptsService,
    private inventoryService: InventoryService,
    private utilitiesService: UtilitiesService,
    private loanHistoryService: InventoryLoanHistoryService,
  ) {
    super(dialogRef, breakpointObserver);
  }

  async ngOnInit() {
    this.dialogRef.disableClose = true;
    this.customer = this.data.customer;

    this.labels = (await this.labelService.getLabels('app-user-inventory-loan')).data;

    this.inventoryService.getInventoriesForCustomer(this.customer.id).pipe(takeUntil(this.sub)).subscribe({
      next: data => {
        this.tableConfig = {
          data: data.map(d => {
            const item: ITableData = {
              _metadata: { originalData: d },
              title: d.title,
              startDate: d.loanDetails.loanStart ? format(new Date(d.loanDetails.loanStart), 'DD MMM, YYYY') : '',
              customCell: {
                body: this.loanExtraBtn,
              },
            };

            return item;
          }),
          displayProperties: ['title', 'startDate', 'customCell'],
          headers: {
            title: this.labels.title,
            startDate: this.labels.startDate,
          },
          rowOptions: [],
          allowSelection: false,
          displayHeaders: true,
        };
      },
      error: e => console.error(e),
    });
  }

  public openLoanToFreeDialog(row: ITableData): void {
    const item: IInventoryItem = row._metadata.originalData as IInventoryItem;

    this.userPromptsService.showDialogue(
      InventoryLoanToFreeDialogComponent,
      { model: cloneDeep(item) },
      (updateModel: IInventoryLoanToFreeResponseModel | boolean) => {
        if (updateModel) {
          const newItem = new InventoryItem(item);
          const val = updateModel as IInventoryLoanToFreeResponseModel;
          newItem.status = InventoryStatus.free;
          newItem.lastLoanDate = val.returnDate;

          const historyModel: IInventoryLoanHistory = {
            id: Guid.createQuickGuidAsString(),
            serviceId: newItem.serviceId,
            workingAreaId: newItem.workingareaId,
            volunteerId: newItem.loanDetails.volunteer?.id || '',
            loanedToUserId: newItem.loanDetails.loanedToUser?.id || '',
            inventoryTitle: item.title,
            itemId: newItem.id,
            loanNote: newItem.loanDetails.loanNote,
            endDate: val.endDate,
            returnDate: val.returnDate,
            returnNote: val.returnNote,
            startdate: newItem.loanDetails.loanStart,
          };

          newItem.loanDetails.volunteer.id = null;
          newItem.loanDetails.volunteer.firstName = null;
          newItem.loanDetails.volunteer.lastName = null;
          newItem.loanDetails.loanedToUser.fullName = null;
          newItem.loanDetails.loanedToUser.firstName = null;
          newItem.loanDetails.loanedToUser.lastName = null;
          newItem.loanDetails.loanedToUser.id = null;
          newItem.loanDetails.loanedOutByUser.fullName = null;
          newItem.loanDetails.loanedOutByUser.firstName = null;
          newItem.loanDetails.loanedOutByUser.lastName = null;
          newItem.loanDetails.loanedOutByUser.id = null;
          newItem.loanDetails.loanNote = null;
          newItem.loanDetails.loanStart = null;
          newItem.loanDetails.endDate = null;

          forkJoin([
            from(this.inventoryService.updateInventory(this.utilitiesService.toPlainObject(newItem))).pipe(
              take(1),
              tap(() => Object.assign(item, newItem))
            ),
            this.loanHistoryService.create(historyModel).pipe(take(1))
          ]).pipe(
            takeUntil(this.sub),
            take(1)
          )
            .subscribe();
        }
      }, true,
    );
  }

  public addItem(): void {
    this.userPromptsService.showDialogue(InventoryLoanHistoryListModalComponent, {
      customer: this.customer,
      volunteerId: this.data.volunteerId,
    }, null, true);
  }

  public showLoanHistory(): void {
    this.userPromptsService.showDialogue(LoanHistoryModalComponent, {
      customer: this.customer,
    }, null, false, { width: '400px' });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}