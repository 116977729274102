import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LabelService, UserPromptsService, UtilitiesService } from '@core/services';
import { Address, IArea } from '@models/model';
import { AddressModalComponent } from '../address-modal/address-modal.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-address-modal-opener',
  templateUrl: './address-modal-opener.component.html',
  styleUrls: ['./address-modal-opener.component.scss']
})
export class AddressModalOpenerComponent implements OnInit, OnChanges {

  @Input() currentAddress: Address;
  @Output() address = new EventEmitter<{ address: Address, area: IArea }>();

  public labels = this.labelService.defaultProvider();
  protected form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddressModalOpenerComponent>,
    private labelService: LabelService,
    private userPromptsService: UserPromptsService,
    private fb: FormBuilder,
    private utilitiesService: UtilitiesService
  ) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-address-modal-opener')).data;
    this.setupForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.utilitiesService.isNewChange(changes.currentAddress)) {
      this.setupForm();
    }
  }

  private setupForm() {
    this.form = this.fb.group({
      address: [this.contactAddressStr(this.currentAddress)],
    })
  }

  private contactAddressStr(address: Address) {
    return `${address?.street || ''} ${address?.number || ''} ${address?.letter || ''} ${address?.postalcode || ''}  ${address?.city || ''}`;
  }

  protected openAddressModal() {
    this.userPromptsService.showDialogue(AddressModalComponent, { address: this.currentAddress }, (data: { address: Address, area: IArea }) => {
      if (data) {
        this.form.get('address').setValue(this.contactAddressStr(data.address))
        this.address.emit(data);
      }
    });
  }
}
