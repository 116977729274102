import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ModalComponent } from '@shared/components';
import {
    OrgServiceService, LabelService,
    WorkingAreaService, ExportUsersService, UserService,
    IExportUserSource, LocalStorageService
} from '@core/services';
import { Role, Service, User, WorkingArea, IMailinglistItem } from 'app/shared/model';
import { uniq, keyBy, keys, flatten, values, uniqBy } from 'lodash';
import { IObjectMap } from '@shared/interface';
import { take, map, mergeAll } from 'rxjs/operators';
import { getMonth } from 'date-fns';
import { combineLatest, of } from 'rxjs';
// import { EmailConfigInput, EmailDialogueComponent } from '@shared/entry-components/email-dialogue/email-dialogue.component';

@Component({
    selector: 'app-export-users-dialog',
    templateUrl: './export-users.component.html',
    styleUrls: ['./export-users.component.scss']
})
export class ExportUsersComponent extends ModalComponent implements OnInit {
    public isGenerating = false;
    public workareas: WorkingArea[];
    public selectedWorkAreas: WorkingArea[] = [];
    public uniqueUsers = false;
    public exportProfessionals = true;
    public birthdayMonth;
    public exportExcecutors = true;
    public exportCoordinators = true;
    public exportCustomers = false;
    public exportOthers = true;
    public userRole: Role;
    public usersMap: IObjectMap<User>;
    public servicesMap: IObjectMap<Service>;
    public labels: any = {};
    public mailinglist: IMailinglistItem;

    constructor(
        public breakpointObserver: BreakpointObserver,
        public dialogRef: MatDialogRef<ExportUsersComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userService: UserService,
        private exportUserService: ExportUsersService,
        private orgServiceService: OrgServiceService,
        private localStorageService: LocalStorageService,
        private workingAreaService: WorkingAreaService,
        private labelService: LabelService,
        // private userPromptsService: UserPromptsService
    ) {
        super(dialogRef, breakpointObserver);
    }

    async ngOnInit() {
        if (this.data.mailinglist) {
            this.mailinglist = this.data.mailinglist;
        }

        await this.initFilter();
        this.generateDataMaps();

        this.labels = (await this.labelService.getLabels('app-export-users-dialog')).data;
    }

    async initFilter() {
        this.userRole = JSON.parse(this.localStorageService.getItemSync('user_role'));

        if (this.mailinglist) {
            this.workareas = await this.workingAreaService.getWorkingAreas().pipe(take(1)).toPromise();
        } else {
            if (this.userRole.workingArea) {
                this.workareas = [this.userRole.workingArea];
            } else {
                this.workareas = await this.workingAreaService.getWorkingAreas().pipe(take(1)).toPromise();
            }
        }

        this.selectedWorkAreas = this.workareas.slice();
        return true;
    }

    async generateDataMaps() {
        // professionals and serviceids from work areas list
        let profIds: string[] = [];
        let serviceIds: string[] = [];
        this.selectedWorkAreas.forEach(w => {
            profIds = uniq(profIds.concat(keys(w.professionals)));
            serviceIds = uniq(serviceIds.concat(keys(w.services)));
        });

        this.orgServiceService.getServicesFromRefs(serviceIds).pipe(
            map(services => {
                // coordinators and volunteers
                let coordIds: string[] = [];
                let vIds: string[] = [];
                services.forEach(s => {
                    coordIds = uniq(coordIds.concat(keys(s.coordinators)));
                    vIds = uniq(vIds.concat(keys(s.excecutors)));
                });

                let userIds: string[];
                if (this.mailinglist) {
                    //TODO: Don't really think we'll need to mage a change here
                    // userIds = keys(this.mailinglist.subscribers).filter(userId => !!this.mailinglist.subscribers[userId]);
                } else {
                    userIds = uniq(flatten(profIds.concat(coordIds).concat(vIds)));
                }
                userIds = userIds.filter(id => id !== 'undefined');

                return combineLatest([
                    of(services),
                    this.userService.getUsersFromIds(userIds)
                ]);
            }),
            mergeAll()
        ).subscribe({
            next: arr => {
                this.servicesMap = keyBy(arr[0], 'id');
                this.usersMap = keyBy(arr[1], 'id');
            }
        });
    }

    public async exportUsers(retrieveUsers?: boolean) {
        this.isGenerating = true;
        let usersToExport: IExportUserSource[] = [];
        const userWithRole: string[] = [];

        // 2- if prof is selected => get all profs from working area's + push users
        for (const area of this.selectedWorkAreas) {
            const users: User[] = values(this.usersMap).filter(user => area.professionals[user.id]);
            users.forEach((user: User) => {
                if (this.exportProfessionals) {
                    usersToExport.push(
                        Object.assign({}, user, {
                            workareaName: area.name,
                            roleName: 'Professional'
                        })
                    );
                } else {
                    userWithRole.push(user.id);
                }
            });
        }

        for (const area of this.selectedWorkAreas) {
            // 3- if coordinator is selected => get all coordinators from services + push users

            for (const serviceId of keys(area.services)) {
                const service: Service = this.servicesMap[serviceId];
                if (service) {
                    const users: User[] = values(this.usersMap).filter(user => service.coordinators[user.id]);

                    users.forEach((user: User) => {
                        if (this.exportCoordinators) {
                            usersToExport.push(
                                Object.assign({}, user, {
                                    workareaName: area.name,
                                    serviceName: service.name,
                                    roleName: 'Coordinator'
                                })
                            );
                        } else {
                            userWithRole.push(user.id);
                        }
                    });
                }
            }
            // todo
            // 4- if executor is selected => get all executors from services + push users
            for (const serviceId of keys(area.services)) {
                const service: Service = this.servicesMap[serviceId];
                if (service) {
                    const users: User[] = values(this.usersMap).filter(user => service.excecutors[user.id]);

                    users.forEach((user: User) => {
                        if (this.exportExcecutors) {
                            usersToExport.push(
                                Object.assign({}, user, {
                                    workareaName: area.name,
                                    serviceName: service.name,
                                    roleName: 'Executor',
                                    activeRole: user.roles.services[service.id] &&
                                        user.roles.services[service.id].executor &&
                                        user.roles.services[service.id].executor.disabled === true ? 'inactive' : ''
                                })
                            );
                        } else {
                            userWithRole.push(user.id);
                        }
                    });
                }
            }

            if (this.mailinglist && this.exportOthers) {
                // get missing users from map
                values(this.usersMap).forEach(user => {
                    const inList = userWithRole.find(u => u === user.id) || usersToExport.find(u => u.id === user.id);

                    if (!inList) {
                        usersToExport.push(
                            Object.assign({}, user, {
                                workareaName: '',
                                serviceName: '',
                                roleName: ''
                            })
                        );
                    }
                });
            }
        }

        if (this.birthdayMonth) {
            usersToExport = usersToExport
                .filter(user => getMonth(user.birthday) === (this.birthdayMonth - 1));
        }

        if (usersToExport.length > 0) {
            if (retrieveUsers) {
                this.isGenerating = false;
                return uniqBy(usersToExport, 'id');
            } else {
                await this.exportUserService.makeUsersExport({
                    users: usersToExport,
                    isUnique: this.uniqueUsers
                });
            }
        } else {
            alert(this.labels.no_recipients);
        }

        this.isGenerating = false;
    }
}
