import { Component, OnInit, OnDestroy } from '@angular/core';
import { LabelService, RouterService, OrgServiceService, UserPromptsService, WorkingAreaService } from '@core/services';
import { MenuItem, Service, WorkingArea, ServiceCatalogActionTypes } from '@shared/model';
import { ActivatedRoute } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { chunk, flatten, orderBy } from 'lodash';
import { NgSub } from 'ng-sub';
import { NetworkServiceRegistrationModalComponent } from 'app/service-registration/network-service-registration/components/network-service-registration-modal/network-service-registration-modal.component';

@Component({
    selector: 'app-services-catalog',
    templateUrl: './services-catalog.component.html',
    styleUrls: ['./services-catalog.component.scss']
})
export class ServicesCatalogComponent implements OnInit, OnDestroy {
    public labels: any = {};
    public services: Service[][] = [];
    public workingArea: WorkingArea;
    public ServiceCatalogActionTypes = ServiceCatalogActionTypes;

    private sub = new NgSub();
    private workingAreaId: string;
    private gridSize = 3;

    constructor(
        private labelService: LabelService,
        private routerService: RouterService,
        private orgServiceService: OrgServiceService,
        private activatedRoute: ActivatedRoute,
        private userPromptsService: UserPromptsService,
        private workingAreaService: WorkingAreaService
    ) { }

    async ngOnInit() {
        const params = await this.activatedRoute.params.pipe(take(1)).toPromise();
        this.workingAreaId = params.workingAreaId;

        this.userPromptsService.activeMediaQuery$.pipe(takeUntil(this.sub)).subscribe(m => {
            if (m === 'xs') {
                this.gridSize = 1;
            } else if (m === 'sm') {
                this.gridSize = 2;
            } else {
                this.gridSize = 3;
            }

            this.services = chunk(flatten(this.services), this.gridSize);
        });

        this.sub.add(
            this.orgServiceService.getServicesWithActiveCatalog(this.workingAreaId).subscribe(services => {
                this.services = chunk(orderBy(services, ['name'], ['asc']), this.gridSize);
            }),
            this.workingAreaService.getWorkingAreaById(this.workingAreaId).subscribe(w => {
                this.workingArea = w;
            })
        );

        this.labels = (await this.labelService.getLabels('app-services-catalog')).data;

        const menuItem: MenuItem = Object.assign({}, new MenuItem(), {
            title: this.labels.services_catalog,
            backButtonEnabled: true
        });
        this.routerService.setMainMenuItem(menuItem);
    }

    public async addRegistration(service: Service) {
        const status = await this.userPromptsService.showAlertDialogue<boolean>(
            this.labels.start_application,
            this.labels.service_reg_msg_body
        );

        if (status) {
            this.userPromptsService.showDialogue(
                NetworkServiceRegistrationModalComponent,
                { workingAreaId: this.workingAreaId, serviceId: service.id },
                null, true
            );
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
