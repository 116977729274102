export interface FeedbackMenuItem {
  id: number;
  title: string;
  icon: string;
}

export enum FeedbackTypes {
  feature_request = 1,
  bug_report,
  help,
  compliments,
  improve
}

export class Feedback {
  public orgId: string;
  public firstName: string;
  public lastName: string;
  public ticketId: string;
  public feedbackType: FeedbackTypes;
  public id?: string;
  public colorCode: string;
  public email: string;
  public feedback: string;
  public status: string;
  public tag: string;
  public date: {
    year: number,
    month: number,
    week: number,
    timeStamp: Date;
  };
}
