import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService, WeekDayItem } from '@core/services/labels.service';
import { Organization, RequestPublishScope, Service, WorkingArea } from '@models/model';

@Component({
  selector: 'app-publish-request-modal',
  templateUrl: './publish-request-modal.component.html',
  styleUrls: ['./publish-request-modal.component.scss']
})
export class PublishRequestModalComponent implements OnInit {
  public labels = this.labelService.defaultProvider();
  public scope: RequestPublishScope;
  public maxReactions: number = null;
  public weekdays: WeekDayItem[] = [];
  public selectedWeekdays: string[];
  
  constructor(
    public dialogRef: MatDialogRef<PublishRequestModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { service: Service; workingArea: WorkingArea; org: Organization; },
    private labelService: LabelService
  ) { }

  async ngOnInit() {
    this.labels = (await this.labelService.getLabels('app-publish-request-modal')).data;

    this.labelService.getWeekdayList().then(w => {
      // changes order so that monday is first and sunday is last
      this.weekdays = w.slice(1).concat([w[0]]);
    });
  }

  public save() {
    this.dialogRef.close({ scope: this.scope, maxReactions: this.maxReactions, weekdays: this.selectedWeekdays });
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
