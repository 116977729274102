import { Injectable } from '@angular/core';
import { IObjectMap } from '@shared/interface';
import * as XLSX from 'xlsx';
import { LocalStorageService } from './local-storage.service';

import firebase from 'firebase/compat/app';
import { BehaviorSubject } from 'rxjs';

export interface FileUploadResponse {
    fileUrl: string;
    fileName: string;
    fileRef: string;
    metaData: firebase.storage.FullMetadata
}

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {
    private orgId: string;
    public uploadPercent = new BehaviorSubject(0);
    constructor(private localStorageService: LocalStorageService) {
        this.localStorageService.getItem('user_organization').subscribe((res) => {
            this.orgId = res;
        });
    }

    getDataFromExcelFile(target: DataTransfer): Promise<IObjectMap<string>[]> {
        return new Promise((resolve) => {
            const reader: FileReader = new FileReader();
            reader.readAsBinaryString(target.files[0]);

            reader.onload = (event: any) => {
                /* read workbook */
                const bstr: string = event.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                /* save data */
                const data: any[] = XLSX.utils.sheet_to_json(ws, { header: 1 });

                /* get the excel data in the right format*/
                resolve(this.formatExcelDataAsKeyedJson(data[0], data.slice(1)));
            };
        });
    }

    public fileMatchesFormat(file: File, formats: string[]): boolean {
        let hasMatch = false;

        if (file) {
            const ext = file.name.split('.').reverse()[0].toLowerCase();
            formats.forEach((format) => {
                if (!hasMatch) {
                    hasMatch = format.toLowerCase() === ext;
                }
            });
        }
        return hasMatch;
    }

    // this function converts the excel row data into a json file of keyed objects
    formatExcelDataAsKeyedJson(headerObject: IObjectMap<string>, data: IObjectMap<string>[]) {
        return data.map((item) => {
            const keyedItem = {};
            Object.keys(headerObject).forEach((index) => {
                const header = headerObject[index];
                keyedItem[header] = item[index];
            });

            return keyedItem;
        });
    }

    uploadFile(file: File, userId: string): Promise<FileUploadResponse> {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();
            const metadata = {};
            const ref = `organizations/${this.orgId}/users/${userId}/docs/${Date.now()}-${file.name}`;

            const location = storageRef.child(ref);
            const uploadTask = location.put(file, metadata);

            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot) => {
                    this.uploadPercent.next(snapshot.bytesTransferred / snapshot.totalBytes * 100);
                },
                () => {
                    return reject();
                },
                async () => {
                    const res = uploadTask.snapshot.ref

                    return resolve({
                        fileName: file.name,
                        fileUrl: await res.getDownloadURL(),
                        fileRef: ref,
                        metaData: await res.getMetadata()
                    });

                }
            );
        });
    }

    deleteFile(fileRef: string): Promise<any> {
        const storageRef = firebase.storage().ref().child(fileRef);
        return storageRef.delete();
    }

    getFileInfo(fileRef: string): Promise<firebase.storage.FullMetadata> {
        const storageRef = firebase.storage().ref().child(fileRef);
        return storageRef.getMetadata();
    }

    public formatFileSize(bytes: number, decimalPoint?: number) {
        if (bytes == 0) return '0 Bytes';
        const k = 1000;
        const dm = decimalPoint || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    public convertFileToBase64(file: File) {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                const str = reader.result as string;
                const index = str.indexOf(',');
                const result = str.substring(index + 1);
                resolve(result);
            };
            reader.onerror = function (error) {
                reject(error);
            };
        })
    }
}
