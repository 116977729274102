<app-modal [alignFooterStart]="partner.id">
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{partner.id ? labels.update_network_partner : labels.create_network_partner}}
        </span>
    </div>

    <div class="modal_body" #modal_body>
        <div style="padding: 1em;" fxLayout="column" fxLayoutGap="20px">
            <form [formGroup]="form" fxLayout="column" fxLayoutGap="10px">
                <mat-form-field appearance="outline" fxFlex="stretch">
                    <mat-label>{{labels.partner_kind}}</mat-label>
                    <mat-select matInput type="text"
                        formControlName="networkPartnerKind">
                        <mat-option *ngFor="let option of currentOrg?.networkPartnerTypes" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div fxFlex="stretch">
                    <app-options-list [resetListOnSelect]="true" [disabled]="!form.value.networkPartnerKind"
                        fxFlex="stretch" [defaultValue]="getSelectedPartnerName()" (selectOption)="addPartner($event)"
                        [optionsArr]="usersList" [excluded]="[customer]" [placeholder]="labels.add_partner"
                        [displayProperties]="['firstname', 'lastname']">
                    </app-options-list>
                </div>

                <mat-form-field appearance="outline" fxFlex="stretch">
                    <mat-label>{{labels.note}}</mat-label>
                    <textarea cdkTextareaAutosize cdkAutosizeMinRows maxlength="noteMaxLength" minRows="3" matInput type="text"
                        formControlName="note"></textarea>
                    <mat-hint [align]="'end'">{{form.value.note.length}}/{{noteMaxLength}}</mat-hint>
                </mat-form-field>
            </form>

            <div class="log_text_footer" fxLayout="column" fxLayoutGap="10px" *ngIf="partner.id">
                <span>{{labels.created_by}}: {{createdByUser?.firstname}} {{createdByUser?.lastname}}</span>
                <span>{{labels.created_at}}: {{partner.log.createdAt | date}}</span>
            </div>
        </div>
    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px">
        <div fxFlex="stretch" *ngIf="partner.id" fxLayoutAlign="flex-start">
            <button color="warn" mat-stroked-button (click)="deleteItem()">{{labels.delete}}</button>
        </div>

        <div>
            <button color="primary" mat-raised-button [disabled]="form.invalid || form.pristine"
                (click)="save()">{{partner.id ? labels.update : labels.create}}</button>
        </div>
    </div>
</app-modal>