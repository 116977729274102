import { User, Note, Role, WorkingArea, Management } from 'app/shared/model';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IObjectMap } from '@shared/interface';
import { isEqual } from 'lodash';
import { RoleTypes } from 'app/shared/enum';
import { filter } from 'lodash';

import {
  UserService,
  LocalStorageService,
  UserPromptsService,
  WorkingAreaService,
  OrgServiceService,
  LabelService
} from '@core/services';
import { NoteFormComponent } from '@shared/entry-components/note-form/noteform.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-customer-notes',
  templateUrl: './customer-notes.component.html',
  styleUrls: ['./customer-notes.component.css']
})
export class CustomerNotesComponent implements OnInit, OnChanges {
  @Input() public customer: User;
  @Input() management: Management;

  public labels: any = {};
  public notes: Note[] = [];
  public userRole: Role = JSON.parse(this.localStorageService.getItemSync('user_role'));
  public showMore = false;
  public searchText = '';
  public workingAreasForNotes: IObjectMap<string> = {};
  public servicesForNotes: IObjectMap<string> = {};
  public addedByForNotes: IObjectMap<string> = {};

  constructor(
    private labelService: LabelService,
    private orgServiceService: OrgServiceService,
    private workingAreaService: WorkingAreaService,
    private userPromptsService: UserPromptsService,
    private localStorageService: LocalStorageService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.labels = (await this.labelService.getLabels('app-customer-notes')).data;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customer && this.isNewChange(changes.customer)) {
      this.initializeNotes();
    }
  }

  private isNewChange(change: any) {
    const prev = change.previousValue;
    const current = change.currentValue;

    return !isEqual(prev, current);
  }

  public initializeNotes() {
    this.userService.getUserNotes(this.customer.id).subscribe((notes: Note[]) => {
      this.notes = notes;

      if (this.userRole.roleType === RoleTypes.professional) {
        this.notes = filter(this.notes, { areaId: this.userRole.workingAreaId });
      }

      if (this.userRole.roleType === RoleTypes.coordinator) {
        this.notes = filter(this.notes, (note: Note) => {
          if (note.serviceId) {
            return note.serviceId === this.userRole.serviceId;
          } else {
            return note.areaId === this.userRole.workingAreaId;
          }
        });
      }

      if (this.userRole.roleType === RoleTypes.excecutor) {
        this.notes = filter(this.notes, (note: Note) => {
          if (note.serviceId) {
            return note.serviceId === this.management.serviceId;
          } else {
            return note.areaId === this.management.workareaId;
          }
        });
      }

      this.updateServicesAndWorkingAreasForNotes();
    });
  }

  public updateServicesAndWorkingAreasForNotes() {
    setTimeout(async () => {
      for (const note of this.notes) {
        // we get a map of all the working areas for the notes
        const area: WorkingArea = await this.workingAreaService.getWorkingAreaById(note.areaId).pipe(take(1)).toPromise();

        if (area) {
          this.workingAreasForNotes[note.id] = area.name;
        }

        // here we get a map of all the services for the notes
        if (note.serviceId) {
          const service = await this.orgServiceService.getServiceById(note.serviceId).pipe(take(1)).toPromise();
          if (service) {
            this.servicesForNotes[note.id] = service.name;
          }
        }

        // here we get a map of all the creators for the notes
        const user: User = await this.userService.getUserById(note.log.createdBy).pipe(take(1)).toPromise();
        this.addedByForNotes[note.id] = user.fullname;
      }
    });
  }

  public async saveNote(note: Note) {
    if (note.id) {
      await this.userService.updateNote(note, this.customer.id);
      this.userPromptsService.showToast(this.labels.note_updated);
    } else {
      await this.userService.addNote(note, this.customer.id);
      this.userPromptsService.showToast(this.labels.note_added);
    }
  }

  public getNotes(): Note[] {
    if (this.searchText && !this.showMore) {
      this.showMore = true;
    }

    const notes = this.showMore || this.searchText ? this.notes.slice(0, 30) : this.notes.slice(0, 3);

    return notes.filter(note => {
      const wA = this.workingAreasForNotes[note.id];
      const sN = this.servicesForNotes[note.id];
      const aB = this.addedByForNotes[note.id];

      return this.searchText ?
        note.note.toLocaleLowerCase().includes(this.searchText)
        || (wA && wA.toLocaleLowerCase().includes(this.searchText))
        || (sN && sN.toLocaleLowerCase().includes(this.searchText))
        || (aB && aB.toLocaleLowerCase().includes(this.searchText))
        : true;
    });
  }

  public openNoteForm(note?: Note) {
    this.userPromptsService.showDialogue(NoteFormComponent, {
      note: note || {
        note: '',
        areaId: this.userRole.workingAreaId || this.management.workareaId,
        serviceId: this.userRole.serviceId || this.management.serviceId || '',
        customerId: this.customer.id
      }
    }, (updatedNote?: Note) => {
      if (updatedNote) {
        this.saveNote(updatedNote);
      }
    });
  }

  public removeNote(note: Note) {
    this.userPromptsService.showConfirmDialogue(
      'Are you sure',
      'Delete action is not reversible for deleting notes',
      (confirm) => {
        if (confirm) {
          this.userService.deleteNote(note.id, this.customer.id);
          this.userPromptsService.showToast(this.labels.note_deleted);
        }
      }
    );
  }

  public canEditNote(note: Note): boolean {
    return this.userRole.roleType === RoleTypes.professional ||
      note.log.createdBy === this.localStorageService.getItemSync('user_id');
  }

  public getNoteTimestamp(note: Note) {
    const date = new Date(note.log.createdAt);
    return note ? `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}` : '';
  }
}
