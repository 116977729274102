import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { CloudFunctionService, LabelService, OrganizationService, RequestService, UserPromptsService, UserService } from '@core/services';
import { IEmailOption, IObjectMap } from '@models/interface';
import { Organization, Service, Request, RequestReaction, User } from '@models/model';
import { orderBy } from 'lodash';
import { NgSub } from 'ng-sub';
import { take, takeUntil } from 'rxjs/operators';
import { IDataTableConfig, ITableData } from '../data-table/data-table.component';

@Component({
  selector: 'app-request-reactions',
  templateUrl: './request-reactions.component.html',
  styleUrls: ['./request-reactions.component.scss']
})
export class RequestReactionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() request: Request;
  @Input() service: Service;

  @ViewChild('customRxnIcon', { static: true }) customRxnIcon: TemplateRef<any>;

  public tableDataConfig: IDataTableConfig;
  public labels = this.labelService.defaultProvider();

  private sub = new NgSub();

  constructor(
    private requestService: RequestService,
    private labelService: LabelService,
    private userPromptsService: UserPromptsService,
    private cloudFunctionService: CloudFunctionService,
    public userService: UserService,
    public organizationService: OrganizationService,

  ) { }

  async ngOnInit() {

    this.labels = (await this.labelService.getLabels('app-request-reactions')).data;
    this.requestService.getRequestReactions(this.request.id).pipe(takeUntil(this.sub)).subscribe({
      next: rxns => {
        this.updateTableData(rxns);
      },
      error: e => console.log(e),
    });


  }

  ngOnChanges(changes: SimpleChanges): void {
    this.request = changes.request.currentValue;
  }

  public async handleRowActionSelect(opt: IObjectMap<any>) {
    const rxn: RequestReaction = opt.data._metadata.originalData;
    this.userPromptsService.showPromptDialogue<string>(
      this.labels.confirm,
      opt.option.id === 'cancel' ? this.labels._translate('confirm_cancelled', { firstName: rxn.firstName }) :
        this.labels._translate('confirm_assigned', { firstName: rxn.firstName }),
      { type: 'textarea', initialValue: rxn.remarkByCoordinator || '', }
    ).then(remark => {
      if (remark) {
        rxn.remarkByCoordinator = remark;
        this.requestService.updateRequestReaction(this.request.id, rxn);
        this.rowSelectActions(rxn, opt.option.id, remark)

      }
    });
  }


  public async rowSelectActions(rxn: RequestReaction, option: string, remark: string) {

    const executor: User = await this.userService.getUserById(rxn.userId).pipe(take(1)).toPromise();

    let txtBodyText: string;

    if (option === 'cancel') {
      this.request.publishDetails.numberOfReactions--;

      txtBodyText = this.labels._translate('email_cancelled_executor', { firstName: executor.firstname });

      await Promise.all([
        this.requestService.deleteRequestReaction(this.request.id, rxn.id),
        this.requestService.updateRequest(this.request),
      ]);
      this.userPromptsService.showToast(this.labels.reaction_processed);
    } else if (option === 'assignExecutor') {

      delete this.request['__doc'];
      if (this.request.management.executorId) {
        this.request.management.team[rxn.userId] = {
          userId: rxn.userId,
          firstname: rxn.firstName,
          lastname: rxn.lastName,
          picture: rxn.picture,
        };
      } else {
        this.request.management.executorId = rxn.userId;
        this.request.management.executor = {
          userId: rxn.userId,
          firstname: rxn.firstName,
          lastname: rxn.lastName,
          picture: rxn.picture
        };
        if (this.service.settings.defaultStatusUpdatedExecutor) {
          this.request.status = (this.service.settings.defaultStatusUpdatedExecutor as any) === 'null'
            ? this.request.status : this.service.settings.defaultStatusUpdatedExecutor;
        }
      }

      txtBodyText = this.labels._translate('email_assigned_executor', { firstName: executor.firstname });

      await this.requestService.updateRequest(this.request);
      this.userPromptsService.showToast(this.labels.reaction_processed);

    }

    const organization: Organization = await this.organizationService.getOrganizationById(
      localStorage.getItem('user_organization')
    ).pipe(take(1)).toPromise();

    const emailOptions: IEmailOption = {
      subject: this.request.title,
      bodySubject: this.request.title,
      from: `noreply@${organization.email.substring(organization.email.indexOf('@') + 1)}`,
      to: executor.email,
      logoUrl: organization?.webLogo || organization.fullLogoUrl,
      bodyText: txtBodyText,
      orgName: organization.fullName,
      orgId: organization.id,
      actionHtml: remark,
      infoText: `
            ${this.labels['email-info-text']} ${organization.email} <br/><br/>
            ${this.labels['email-best-regards']} <br> ${organization.fullName}`
    };

    this.cloudFunctionService.sendEmails([emailOptions]).catch(console.log);

  }

  private updateTableData(rxns: RequestReaction[]) {
    // @ts-ignore
    const tableData: ITableData[] = orderBy(rxns, ['log.createdAt'], ['desc'])
      .map(rxn => {
        return {
          // @ts-ignore
          remark: rxn.remark,
          // @ts-ignore
          fullName: `${rxn.firstName} ${rxn.lastName}`,
          // @ts-ignore
          _statusValue: { val: this.isExecInRxn(rxn) ? 1 : 20, org: null, },
          customCell: {
            body: this.customRxnIcon,
          },
          _metadata: {
            originalData: rxn
          },
        };
      });

    this.tableDataConfig = {
      data: tableData,
      displayProperties: ['status-row', 'fullName', 'remark', 'customCell'],
      headers: {},
      rowOptions: [
        {
          id: 'assignExecutor',
          title: this.labels.assign_as_executor,
          icon: 'person'
        },
        {
          id: 'cancel',
          title: this.labels.cancel,
          icon: 'cancel'
        },
      ],
      allowSelection: false,
      displayHeaders: false,
    };
  }

  public openRemarkIcon(row: ITableData): void {
    const rxn: RequestReaction = row._metadata.originalData as any;

    this.userPromptsService.showPromptDialogue<string>(
      this.labels.remark,
      null,
      { type: 'textarea', initialValue: rxn.remarkByCoordinator || '', }
    ).then(remark => {
      if (remark) {
        rxn.remarkByCoordinator = remark;
        this.requestService.updateRequestReaction(this.request.id, rxn);
      }
    });
  }

  public isExecInRxn(rxn: RequestReaction): boolean {
    const isExec = rxn.userId === this.request.management.executorId
      || !!this.request.management.team[rxn.userId];

    return isExec;
  }

  public rxnIconColor(row: ITableData): string {
    const rxn: RequestReaction = row._metadata.originalData as any;

    return rxn.remarkByCoordinator ? 'text-primary' : 'text-grey';
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
