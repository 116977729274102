<app-modal>
  <div class="modal_header" #modal_header>
    <span class="modal_title">
      {{labels.log_info}}
    </span>
  </div>

  <div class="modal_body" #modal_body style="overflow: hidden; padding: 15px;">
    <div *ngIf="!form" fxLayout="row" fxLayoutAlign="center center" style="padding: 30px;">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>

    <form [formGroup]="form" *ngIf="form" fxLayout="column" fxLayoutGap="5px">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{labels.created_at}}</mat-label>
        <input readonly matInput type="text" formControlName="createdAt">
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{labels.created_by}}</mat-label>
        <input readonly matInput type="text" formControlName="createdBy">
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{labels.updated_at}}</mat-label>
        <input readonly matInput type="text" formControlName="updatedAt">
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{labels.updated_by}}</mat-label>
        <input readonly matInput type="text" formControlName="updatedBy">
      </mat-form-field>
    </form>
  </div>

  <!-- <div class="modal_footer" #modal_footer>
    <button mat-stroked-button (click)="closeModal()">{{labels.close}}</button>
  </div> -->
</app-modal>