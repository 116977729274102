import { Injectable, SimpleChange } from '@angular/core';
import { isEqual } from 'lodash';
import { IObjectMap } from '@shared/interface';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { format, parse, setHours, setMinutes, isEqual as fnsIsEqual, isAfter } from 'date-fns';
import { keys, words, upperFirst, each, endsWith } from 'lodash';
import { Role } from '@shared/model';
import { RoleTypes } from '@shared/enum';
import { TimestampDate } from 'timestamp-date';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  private excludedTypes: string[];
  private timestampConverter = new TimestampDate();

  constructor() {
    this.excludedTypes = [
      'string',
      'String',
      'Boolean',
      'boolean',
      'number',
      'Number',
      'Date',
      'date',
      'DocumentReference'
    ];
  }

  public toJSON<T>(obj: any): T {
    const result = JSON.parse(JSON.stringify(obj));
    return this.timestampConverter.parseStringToDate(result);
  }

  public isEqualOrAfter(date1: Date, date2: Date): boolean {
    return fnsIsEqual(date1, date2) || isAfter(date1, date2);
  }

  public intToTime(val: number): string {
    const hour = parseInt((val / 60).toString()).toString();
    const min = (val % 60).toString();

    return `${this.padStart(hour, 2, '0')}:${this.padStart(min, 2, '0')}`;
  }

  public generateRandomString(size: number = 20): string {
    const result: string[] = [];
    const possibleCharacters = '0123456789qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM';
    const charSize = possibleCharacters.length;

    for (let i = 0; i < size; i++) {
      result.push(
        possibleCharacters.charAt(Math.floor(Math.random() * charSize))
      );
    }

    return result.join('');
  }

  public padStart(str: string, max: number, fill: string): string {
    if (str.length >= max) {
      return str;
    } else {
      const res = fill + str;
      return this.padStart(res, max, fill);
    }
  }

  public delay(duration?: number): Observable<null> {
    return of(null).pipe(
      delay(duration)
    );
  }

  public errHandler(err: any): void {
    let e: string;
    if (typeof err === 'string') {
      e = err;
    } else {
      e = JSON.stringify({ Error: err });
    }

    console.log(e);
    throw new Error(e);
  }

  private formatUserName(userName: string): string {
    let outpгtUserName = userName;
    const userWords = words(userName);
    if (userWords.length === 1) {
      outpгtUserName = upperFirst(userName);
    } else {
      const first = (userWords[0] || '').toLowerCase();
      const NAME_PREFIXES = ['van', 'van de', 'ter', 'van der', 'de'];

      if (userWords.length === 2) {
        each(NAME_PREFIXES, prefix => {
          if (prefix === first) {
            outpгtUserName = this.formatUserParts(userName, prefix, userWords, 1);
            return false;
          }
        });
      } else if (userWords.length > 2) {
        const two = first + ' ' + (userWords[1] || '').toLowerCase();
        each(NAME_PREFIXES, prefix => {
          if (prefix === two) {
            outpгtUserName = this.formatUserParts(userName, prefix, userWords, 2);
            return false;
          }
        });
      }
    }

    return outpгtUserName;
  }

  private isInExclusionList(data: any): boolean {
    if (data) {
      const instanceName = data.constructor.name;
      return this.excludedTypes.indexOf(instanceName) >= 0;
    } else {
      return true;
    }
  }

  public toPlainObject(entity: any) {
    if (this.isInExclusionList(entity)) {
      return entity;
    } else {
      if (Array.isArray(entity)) {
        return entity.map(this.toPlainObject.bind(this));
      } else {
        const newObject = Object.assign({}, entity);

        Object.keys(newObject).forEach(key => {
          newObject[key] = this.toPlainObject(newObject[key]);
        });

        return newObject;
      }
    }
  }


  private formatUserParts(inputUserName: string, prefix: string, wordsToChange: string[], shiftCount: number): string {
    let count = 0;
    while (count < shiftCount) {
      wordsToChange.shift();
      count++;
    }
    wordsToChange[0] = upperFirst(wordsToChange[0]);
    wordsToChange.unshift(prefix);
    const name = wordsToChange.join(' ');
    if (endsWith(inputUserName, ' ')) {
      return name + ' ';
    }
    return name;
  }

  private capitalizeTextFirstLetter(str: string): string {
    const isFirstCharacterUppercase = str.substr(0, 1).toUpperCase() === str.substr(0, 1);

    return isFirstCharacterUppercase ? str : str.substr(0, 1).toUpperCase() + str.substr(1);
  }

  public capitalizeFirstLetter(str: string, isName: boolean): string {
    if (str) {
      return isName ? this.formatUserName(str) : this.capitalizeTextFirstLetter(str);
    } else {
      return null;
    }
  }


  public elfProefValidation(value, type) {
    let returnValue = false;
    if (!value || value?.length === 0) {
      return true;
    }
    if (value === '00000000000' || value.length !== 9) {
      return false;
    }
    const values = value.split('');
    const firstCharacter = parseInt(values[0], 10);
    const lastCharacter = parseInt(values[values.length - 1], 10);
    const [a, b, c, d, e, f, g, h, i] = values.map((char) => parseInt(char, 10));
    let result = 0;

    if (type === 'bsn') {
      result = 9 * a + 8 * b + 7 * c + 6 * d + 5 * e + 4 * f + 3 * g + 2 * h + -1 * i;
      returnValue = result > 0 && result % 11 === 0;
    } else if (type === 'own') {
      result = 9 * a + 8 * b + 7 * c + 6 * d + 5 * e + 4 * f + 3 * g + 2 * h;
      returnValue = result > 0 && firstCharacter === 1 && result % 11 === lastCharacter + 5;
    } else {
      returnValue = false;
    }

    return returnValue;
  }
  
  public getObjectChangedProps(obj1: IObjectMap<any>, obj2: IObjectMap<any>): string[] {
    const record: IObjectMap<any> = {};

    keys(obj1).forEach((key: string) => {
      if (!isEqual(obj1[key], obj2[key])) {
        record[key] = obj1[key];
      }
    });

    return keys(record);
  }

  public updateTimeForDate(dateVal: Date, time: string): Date {
    let date = new Date(dateVal.getTime());
    const [hours, minutes] = time.split(':');

    date = setHours(date, parseInt(hours, 10));
    date = setMinutes(date, parseInt(minutes, 10));

    return date;
  }

  public extractTimeFromDate(dateVal?: Date): string {
    const date = this.currentDate(dateVal);
    return format(date, 'HH:mm');
  }

  public currentDate(date: Date): Date {
    return date ? parse(date) : new Date();
  }

  // or
  public rolesMatch(...roles: RoleTypes[]): boolean {
    const userRole: Role = JSON.parse(localStorage.getItem('user_role')) || {};
    return roles.indexOf(userRole.roleType) >= 0;
  }

  public copyTextToClipboard(text: string): Promise<void> {
    return new Promise(resolve => {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = text;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      resolve();
    });
  }

  public distance(lat1: number, lon1: number, lat2: number, lon2: number, unit: 'K' | 'N'): string {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') { dist = dist * 1.609344; }
    if (unit === 'N') { dist = dist * 0.8684; }

    return dist.toFixed(1);
  }

  public getKeysForEnum(enumObj: Object): number[] {
    const results: number[] = [];
    Object.keys(enumObj).map((type) => {
      if (!isNaN(parseInt(type, 10))) {
        results.push(parseInt(type, 10));
      }
    });

    return results;
  }

  public getValuesForEnum(enumObj: Object): string[] {
    const results: string[] = [];
    Object.keys(enumObj).map((type) => {
      if (isNaN(parseInt(type, 10))) {
        results.push(type);
      }
    });

    return results;
  }

  public isNewChange(change: SimpleChange) {
    return !isEqual(change && change.previousValue, change && change.currentValue);
  }

  public getLocaleDateString(date: Date, options?: IObjectMap<any>, includeTime = false): string {
    const defaultOptions: any = {
      day: 'numeric',
      month: 'short',
      weekday: 'short'
    };
    if (includeTime) {
      defaultOptions.hour = 'numeric';
      defaultOptions.minute = 'numeric';
    }

    return new Date(date).toLocaleDateString(undefined, Object.assign({}, defaultOptions, options || {}) as any);
  }

  public getLocaleTimeString(date: Date, options?: IObjectMap<any>): string {
    const defaultOptions = {
      hour: 'numeric',
      minute: 'numeric'
    };

    return new Date(date).toLocaleTimeString(undefined, Object.assign({}, defaultOptions, options || {}) as any);
  }

  public getLocaleDateWithYear(date: Date, skipYear: boolean = false, includesTime?: boolean): string {
    const options: any = {};
    if (!skipYear) options.year = 'numeric'
    return this.getLocaleDateString(date, options, includesTime);
  }

  public timeAgo(date: Date) {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    date = parse(date);
    if (date.constructor.name.toLowerCase() !== 'date') {
      return 'Invalid date';
    }

    const current = new Date().getTime();
    const previous = date.getTime();
    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + ' seconds ago';
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' minutes ago';
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ' hours ago';
    } else if (elapsed < msPerMonth) {
      return 'about ' + Math.round(elapsed / msPerDay) + ' days ago';
    } else if (elapsed < msPerYear) {
      return 'about ' + Math.round(elapsed / msPerMonth) + ' months ago';
    } else {
      return 'about ' + Math.round(elapsed / msPerYear) + ' years ago';
    }
  }

  public convertFloatNumToTime(number: number) {
    // Check sign of given number
    let sign: any = (number >= 0) ? 1 : -1;

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    const hour = Math.floor(number);
    let decpart = number - hour;

    const min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);

    let minute = Math.floor(decpart * 60) + '';

    // Add padding if need
    if (minute.length < 2) {
      minute = '0' + minute;
    }

    // Add Sign in final result
    sign = sign == 1 ? '' : '-';

    // Concate hours and minutes
    const time = sign + hour + ':' + minute;

    return time;
  }

  public gotoHtmlEditor() {
    window.open('https://html-online.com', '_blank');
  }
}
