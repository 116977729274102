import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Municipality } from '@models/model';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class MunicipalityService {
  private municipalities: Municipality[];

  constructor(
    private http: HttpClient,
  ) { }

  public getMunicipalities(): Observable<Municipality[]> {
    const obs = new Subject<Municipality[]>();

    if (this.municipalities) {
      setTimeout(() => {
        obs.next(this.municipalities);
      }, 500);
    } else {
      const config = {
        params: {},
        headers: {
          'Authorization': 'Bearer ' + environment.asToolApiKey
        }
      };

      this.http.get<ApiResponse<Municipality[]>>(`${environment.asToolApi}/municipalities`, config).pipe(
        map(r => r.data || []),
      ).subscribe(r => {
        this.municipalities = r;
        obs.next(r);
      });
    }

    return obs.asObservable();
  }

  public getMunicipalityById(id: string): Observable<Municipality> {
    const config = {
      params: {},
      headers: {
        'Authorization': 'Bearer ' + environment.asToolApiKey
      }
    };

    return this.http.get<ApiResponse<Municipality>>(`${environment.asToolApi}/municipalities/${id}`, config).pipe(
      map(r => r.data || null),
    );;
  }
}
