import { Injectable, Injector } from '@angular/core';
import { IInventoryLoanHistory } from '@shared/model';
import { BaseCrudService } from './base-crud.service';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { flatten } from 'lodash';

/**
 * not included in shared module, will be work only in InventoryModule
 */
@Injectable({
  providedIn: 'root'
})
export class InventoryLoanHistoryService extends BaseCrudService<IInventoryLoanHistory> {
  constructor(protected injector: Injector) {
    super(injector, 'inventoryLoanHistory');
  }

  public getLoanHistoriesForInventory(itemId: string): Observable<IInventoryLoanHistory[]> {
    return this.afsDB.colWithIds$(this.getRef(), ref => {
      return ref.where('itemId', '==', itemId);
    });
  }

  public getLoanHistoriesForUser(userId: string): Observable<IInventoryLoanHistory[]> {
    return this.afsDB.colWithIds$(this.getRef(), ref => {
      return ref.where('loanedToUserId', '==', userId);
    });
  }

  public getLoanHistoriesForInventoryList(itemIds: string[]): Observable<IInventoryLoanHistory[]> {
    return combineLatest(itemIds.map(id => {
      return this.getLoanHistoriesForInventory(id);
    })).pipe(
      map(listArr => {
        return flatten(listArr);
      })
    );
  }
}
