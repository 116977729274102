<form class="options-form">
  <mat-form-field appearance="outline" class="options-full-width">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput type="text" (blur)="hadleBlurEvent()" [formControl]="myControl"
      [matAutocomplete]="auto" />
    <mat-icon matSuffix style="cursor: pointer" *ngIf="canClearText && !disabled" (click)="clearSelection()">close
    </mat-icon>
  </mat-form-field>

  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
    <mat-option fxLayout="row" fxLayoutAlign="left" (click)="onSelectOption($event, option)"
      *ngFor="let option of filteredOptions" [value]="option">
      <div class="options-content">
        <thumbnail *ngIf="!hidePhoto" [image]="option.picture"> </thumbnail>
        <div class="text" fxLayout="column">
          <span>{{ getOptionStr(option) }}</span>
          <div *ngIf="subtitleConstructor" class="extra_info">{{subtitleConstructor(option)}}</div>
        </div>
        <div>
          <div *ngIf="getDistanceTo(option)" matTooltip="Distance form the executor to the customer" class="distance">
            {{getDistanceTo(option)}}km
          </div>
          <div matTooltip="Assigned and in-progress requests of the executor"
            *ngIf="displayRequestsCount && option.volunteerDetails && option.volunteerDetails.requestsCounts"
            class="requests-count">
            {{(option.volunteerDetails.requestsCounts[3] || 0) + (option.volunteerDetails.requestsCounts[4] || 0)}}
          </div>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>

  <div>
    <mat-chip-listbox aria-label="Fish selection">
      <mat-chip-option *ngFor="let municipality of displayChips" (removed)="remove(municipality)">{{municipality?.text}}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</form>