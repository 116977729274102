import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Feedback, FeedbackMenuItem } from '@shared/model';
import { LabelService, UserService } from '@core/services';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-feedback-form',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
    public form: FormGroup;
    public labels = this.labelService.defaultProvider();
    
    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<FeedbackComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FeedbackMenuItem,
        private usersService: UserService,
        private labelService: LabelService,
    ) { }

    async ngOnInit() {
        this.createForm();
        this.labels = (await this.labelService.getLabels('app-feedback-form')).data;
    }

    createForm() {
        this.form = this.fb.group({
            feedback: ['', Validators.required]
        });
    }

    async sendFeedback() {
        const user = await this.usersService.getCurrentUser().pipe(take(1)).toPromise();
        const feedback: Feedback = new Feedback();

        feedback.feedbackType = this.data.id;
        feedback.email = localStorage.getItem('user_email');
        feedback.feedback = this.form.get('feedback').value;
        feedback.orgId = localStorage.getItem('user_organization');
        feedback.firstName = user.firstname;
        feedback.lastName = user.lastname;
        this.dialogRef.close(feedback);
    }
}
