"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NgSub = void 0;
const rxjs_1 = require("rxjs");
class NgSub extends rxjs_1.Subject {
  constructor() {
    super();
    this.d$ = new rxjs_1.Subject();
    this.subscription = new rxjs_1.Subscription();
  }
  unsubscribe() {
    this.next();
    this.d$.complete();
    this.d$.unsubscribe();
    // for the subject extended super class
    super.next();
    super.complete();
    super.unsubscribe();
    this.subscription.unsubscribe();
  }
  add(...subs) {
    subs.forEach(sub => {
      this.subscription.add(sub);
    });
  }
}
exports.NgSub = NgSub;
