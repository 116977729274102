"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LanguageTranslation = exports.Label = exports.TranslatorApp = exports.LabelSegment = exports.LanguageEditor = exports.LanguageSegment = exports.Language = exports.LanguageTypes = exports.DocumentLog = exports.translationsBaseUrl = void 0;
exports.translationsBaseUrl = 'https://us-central1-translatorapp-as.cloudfunctions.net/translations/v2';
var DocumentLog = /** @class */function () {
  function DocumentLog() {}
  return DocumentLog;
}();
exports.DocumentLog = DocumentLog;
var LanguageTypes;
(function (LanguageTypes) {
  LanguageTypes[LanguageTypes["en"] = 1] = "en";
  LanguageTypes[LanguageTypes["nl"] = 2] = "nl";
  LanguageTypes[LanguageTypes["fr"] = 3] = "fr";
  LanguageTypes[LanguageTypes["de"] = 4] = "de";
  LanguageTypes[LanguageTypes["it"] = 5] = "it";
})(LanguageTypes = exports.LanguageTypes || (exports.LanguageTypes = {}));
var Language = /** @class */function () {
  function Language() {}
  return Language;
}();
exports.Language = Language;
var LanguageSegment = /** @class */function () {
  function LanguageSegment(id) {
    this.id = id;
    this.translations = {};
  }
  return LanguageSegment;
}();
exports.LanguageSegment = LanguageSegment;
var LanguageEditor = /** @class */function () {
  function LanguageEditor() {}
  return LanguageEditor;
}();
exports.LanguageEditor = LanguageEditor;
var LabelSegment = /** @class */function () {
  function LabelSegment() {}
  return LabelSegment;
}();
exports.LabelSegment = LabelSegment;
var TranslatorApp = /** @class */function () {
  function TranslatorApp(supportedLanguages, name) {
    this.name = name;
    this.supportedLanguages = supportedLanguages;
    this.editors = {};
    this.segments = {
      'global-labels': {
        id: 'global-labels',
        totalLabels: 0,
        completed: 0,
        dateAdded: new Date(),
        lastUpdated: new Date(),
        starred: true
      }
    };
  }
  return TranslatorApp;
}();
exports.TranslatorApp = TranslatorApp;
var Label = /** @class */function () {
  function Label() {}
  return Label;
}();
exports.Label = Label;
var LanguageTranslation = /** @class */function () {
  function LanguageTranslation() {}
  return LanguageTranslation;
}();
exports.LanguageTranslation = LanguageTranslation;