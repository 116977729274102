import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { LabelService, UserPromptsService } from '@core/services';
import { EmailService } from '@core/services/email.service';
import { IEmailOption } from '@models/index';

@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss']
})
export class EmailPreviewComponent implements OnInit {

  public emailOption: IEmailOption;
  public labels: any = {};
  public loading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: IEmailOption[],
    public dialogRef: MatDialogRef<EmailPreviewComponent>,
    private domSanitizer: DomSanitizer,
    private userPromptService: UserPromptsService,
    private labelService: LabelService,
    private emailService: EmailService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-email-preview-dialogue')).data;
    this.emailOption = this.data[0];
  }

  public sanitize(value: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }

  public async sendEmail() {
    this.loading = true;
    await this.emailService.sendEmailToPersons(this.data);
    this.dialogRef.close();
    this.userPromptService.showToast(this.labels.email_sent_to_recipients);
  }

  public recipientNames() {
    return this.helperFunction('to');
  }

  public attachmentsName() {
    return this.helperFunction('attachments');
  }

  private helperFunction(prop: string) {
    let text = '';
    let data: any[] = this.getData(prop);
    if (!data.length) return '';
    if (data.length > 4) {
      text = `${data.splice(0, 4).join(', ')} +${data.length}`;
      return text;
    }
    return text = data.toString();
  }

  private getData(prop: string) {
    if (prop == 'attachments') {
      return this.emailOption.attachments.map(attachment => attachment.filename);
    } else {
      return this.data.map(emailOption => emailOption[prop]);
    };
  }
}
