import { Injectable } from '@angular/core';
import { IObjectMap, IEmailOption, EmailAttachmentData } from '@shared/interface';
import { Organization } from '@shared/model';
// import { AngularFireFunctions } from '@angular/fire/functions';
import { map, take } from 'rxjs/operators';
import { ApiService } from './api.service';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { LabelService } from './labels.service';
import { FileService } from './file.service';
import { FirestoreService } from '..';

@Injectable({
  providedIn: 'root',
})
export class CloudFunctionService {
  constructor(
    // private afFn: AngularFireFunctions,
    private apiService: ApiService,
    private labelService: LabelService,
    private fileService: FileService,
    private firesoreService: FirestoreService
  ) { }

  // public callable(functionName: string) {
  //   return this.afFn.httpsCallable(functionName);
  // }

  // public callablePromiseHandler<Req, Res>(name: string, payload?: Req): Promise<Res> {
  //   return new Promise((resolve, reject) => {
  //     this.callable(name)(payload).pipe(take(1)).toPromise().then(data => {
  //       if (!data || !data.err) {
  //         resolve(data);
  //       } else {
  //         reject(data.err);
  //       }
  //     }).catch(e => {
  //       console.log(e);
  //       reject('parse_error');
  //     });
  //   });
  // }

  public getRequestCount(orgId: string, params: IObjectMap<any>): Promise<any> {
    return this.apiService.get(`requests/${orgId}/count`, params).pipe(take(1)).toPromise();
  }

  public moveService(payload: IObjectMap<any>): Promise<any> {
    return this.apiService.post<any>('services/move', payload).pipe(take(1)).toPromise();
  }

  public syncInstitutionToEvent(orgId: string, payload: IObjectMap<any>) {
    return this.apiService.get<any>(`orgs/${orgId}/sync-institution-to-event`, payload).pipe(take(1)).toPromise();
  }

  public deleteInstitution(payload: IObjectMap<any>): Promise<any> {
    return this.apiService.post<any>('institution/handle/delete', payload).pipe(take(1)).toPromise();
  }

  public updateInstitution(payload: IObjectMap<any>): Promise<any> {
    return this.apiService.post<any>('institution/handle/update', payload).pipe(take(1)).toPromise();
  }

  public toggle2FA(payload: IObjectMap<any>): Promise<any> {
    return this.apiService.post<any>('mfa/toggle', payload).pipe(take(1)).toPromise();
  }

  public getUserOrgsByEmail(email: string): Promise<{ orgs: Organization[] }> {
    return this.apiService.post<any>('users/orgs-by-email', { email }).pipe(
      take(1),
      map(res => {
        return res.data;
      })
    ).toPromise();
  }

  public async sendEmails(opts: IEmailOption[], templateName?: string, extraMail?: boolean): Promise<any> {
    if (extraMail ===true) { 
      opts.push(await this.sendEmailDetailsToEmailSender(opts)) };
    return Promise.all(opts.map(opt => {
      return this.apiService.post('actions/send-email', { opt, template: templateName }).pipe(take(1)).toPromise();
    }));
  }

  public handleUpdateTheme(data: IObjectMap<any>): Promise<any> {
    return this.apiService.post('themes/actions/update', data).pipe(take(1)).toPromise();
  }

  public getFindCompanyApiKey(): Promise<any> {
    return this.apiService.get('find-company/api-key/get').pipe(take(1)).toPromise();
  }

  private sendEmailDetailsToEmailSender(options: IEmailOption[]) {
    return new Promise<IEmailOption>(async (resolve) => {
      const label: ILabelsProvider = (await this.labelService.getLabels('global-labels')).data;
      const sentMailData = options[0];
      const org = await this.firesoreService.docWithIdNew$(`organizations/${localStorage.getItem('user_organization')}`).pipe(take(1)).toPromise();

      let emailSentTo = 'Emails were sent to the following';
      const subject = `[#v4] ${options[0].subject}`;
      const toEmail = options.map(email => email.to);

      for await (const email of toEmail) {
        emailSentTo += '\n';
        emailSentTo += email;
      }
      // create the file
      const url = await this.fileService.createTextFile(`organizations/${org.id}/attachments/sentEmails`, emailSentTo, { contentType: 'text/plain' });
      const data = await this.fileService.getDocFromUrl(url); // get the file and its url
      const attachments: EmailAttachmentData[] = [{ content: data.file.split(',')[1], filename: label.sender_list, type: 'text/plain' }];
      options[0].attachments ? attachments.push(...options[0].attachments) : null;
      this.fileService.deleteFileByUrl(data.url); // delete the file after reading
      resolve({
        to: localStorage.getItem('user_email'),
        from: `noreply@${org.email.substr(org.email.indexOf('@') + 1)}`,
        logoUrl: org.fullLogoUrl,
        orgId: org.id,
        orgName: sentMailData.orgName,
        subject: subject,
        bodySubject: sentMailData.bodySubject,
        bodyText: `${label.copy_to_sender} <br><br> ${sentMailData.bodyText}`,
        infoText: sentMailData.infoText,
        actionHtml: sentMailData.actionHtml,
        attachments
      })

    })
  }
}
