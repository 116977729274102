<app-modal *ngIf="contactPerson">
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{contactPerson.firstName}} {{contactPerson.lastName}}</span>
    </div>

    <div class="modal_body" #modal_body style="padding: 15px;">
        <ul>
            <li *ngIf="contactPerson.socialRelationKind">{{labels[socialKind[contactPerson.socialRelationKind]]}}</li>
            <li *ngIf="contactPerson.phone">{{contactPerson.phone}} <ng-container *ngTemplateOutlet="copy; context: {$implicit: contactPerson.phone}"></ng-container></li>
            <li *ngIf="contactPerson.email">{{contactPerson.email}} <ng-container *ngTemplateOutlet="copy; context: {$implicit: contactPerson.email}"></ng-container></li>
            <li style="padding-top: 10px;">{{contactPerson.remark}}</li>
        </ul>
    </div>

</app-modal>

<ng-template #copy let-content>
    <mat-icon style="max-height: fit-content; margin-right: 5px;" matSuffix class="cursor_pointer"
        (click)="copyToClipboard(content || '')">content_copy</mat-icon>
</ng-template>