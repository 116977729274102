import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LabelService } from '@core/services/labels.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NetworkPartner, User, Organization } from '@shared/model';
import { UtilitiesService, UserService, CustomerNetworkService, UserPromptsService, OrganizationService } from '@core/services';
import { take } from 'rxjs/operators';
import { Sub } from '@shared/subscriptions';

@Component({
    selector: 'app-network-partner-modal',
    templateUrl: './network-partner-modal.component.html',
    styleUrls: ['./network-partner-modal.component.scss']
})
export class NetworkPartnerModalComponent extends ModalComponent implements OnInit, OnDestroy {
    public labels: any = {};
    public form: FormGroup;
    public partner: NetworkPartner;
    public selectedPartner: User;
    public noteMaxLength = 100;
    public createdByUser: User;
    public customer: User;
    public currentOrg: Organization;
    public usersList: User[] = [];

    private sub: Sub = new Sub();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public breakpointObserver: BreakpointObserver,
        public dialogRef: MatDialogRef<NetworkPartnerModalComponent>,
        public utilitiesService: UtilitiesService,
        private userPromptsService: UserPromptsService,
        private labelService: LabelService,
        private formBuilder: FormBuilder,
        private customerNetworkService: CustomerNetworkService,
        private userService: UserService,
        private organizationService: OrganizationService
    ) {
        super(dialogRef, breakpointObserver);
    }

    async ngOnInit() {
        this.customer = this.data.customer;
        this.partner = this.data.partner;
        this.createForm();

        if (this.partner.id) {
            this.userService.getUserById(this.partner.log.createdBy).pipe(take(1)).subscribe(user => {
                this.createdByUser = user;
            });
        }

        // gets organization document
        this.sub.add(
            this.organizationService.getCurrentOrganization().subscribe(org => {
                org.networkPartnerTypes = org.networkPartnerTypes || [];
                this.currentOrg = org;
            })
        );

        this.labels = (await this.labelService.getLabels('app-network-partner')).data;
    }

    private createForm() {
        this.form = this.formBuilder.group({
            networkPartnerKind: [this.partner.networkPartnerKind],
            partnerId: [this.partner.partnerId, Validators.required],
            note: [this.partner.note, Validators.maxLength(this.noteMaxLength)]
        });

        if (this.partner.partnerId) {
            this.userService.getUserById(this.partner.partnerId).pipe(take(1)).subscribe(user => {
                this.addPartner(user);
            });
        }

        this.form.get('networkPartnerKind').valueChanges.subscribe(kind => {
            if (kind) {
                this.addPartner();
                this.userService.getUsersForNetworkPartner(kind).pipe(take(1)).toPromise().then(users => {
                    this.usersList = users;
                });
            }
        });
    }

    public deleteItem() {
        this.userPromptsService.showConfirmDialogue(
            this.labels.confirm_action,
            this.labels.sure_to_delete_network_partner_body,
            async (sure: boolean) => {
                if (sure) {
                    await this.customerNetworkService.deleteNetworkPartner(this.partner.id);
                    this.userPromptsService.showToast(this.labels.network_partner_deleted);
                    this.dialogRef.close();
                }
            }
        );
    }

    public getSelectedPartnerName(): string {
        return this.selectedPartner ? `${this.selectedPartner.firstname} ${this.selectedPartner.lastname}` : '';
    }

    public addPartner(user?: User) {
        this.form.controls.partnerId.setValue(user ? user.id : '');
        this.selectedPartner = user;

        this.userService.handleUserExpirySync(user.id);
    }

    public async save() {
        if (this.selectedPartner) {
            const model = this.form.value;
            const partner: NetworkPartner = Object.assign({}, this.partner, {
                networkPartnerKind: model.networkPartnerKind,
                partnerId: this.selectedPartner.id,
                note: model.note
            });

            if (partner.id) {
                await this.customerNetworkService.updateNetworkPartner(partner);
                this.userPromptsService.showToast(this.labels.network_partner_updated);
            } else {
                await this.customerNetworkService.createNetworkPartner(partner);
                this.userPromptsService.showToast(this.labels.network_partner_created);
            }
            this.dialogRef.close();
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
