"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimestampDate = void 0;
const timestamp_1 = require("./actions/timestamp");
const string_1 = require("./actions/string");
class TimestampDate {
  constructor() {
    this.tsHandler = new timestamp_1.TimestampHandler();
    this.strHandler = new string_1.StringDateHandler();
  }
  getServerTimestamp() {
    return this.tsHandler.getServerTimestamp();
  }
  getServerDate() {
    return this.getServerTimestamp().toDate();
  }
  timestampToDate(timestamp) {
    return this.tsHandler.timestampToDate(timestamp);
  }
  dateToTimestamp(date) {
    return this.tsHandler.dateToTimestamp(date);
  }
  parseTimestampToDate(data) {
    return this.tsHandler.parseTimestampToDate(data);
  }
  parseDateToTimestamp(data) {
    return this.tsHandler.parseDateToTimestamp(data);
  }
  parseStringToDate(data) {
    return this.strHandler.parseStringToDate(data);
  }
  parseDateToString(data) {
    return this.strHandler.parseDateToString(data);
  }
}
exports.TimestampDate = TimestampDate;
