import { DocumentLog } from './documentLog';
import { ExpiryItemFieldModel } from '../interface';
import { Municipality } from './postcodeAddress';

export type OrganizationStatus = 'active' | 'pending' | 'archived';

export enum ExecutorLabelTypes {
  trainer = 1,
  volunteer,
  teacher,
  consultant,
  therapist,
  mediator,
  coach,
  mentor
}

export enum CoordinatorLabelTypes {
  icoordinator = 1,
  manager,
  supervisor,
  employee
}

export enum ProfessionalLabelTypes {
  iprofessional = 1,
}

export enum WorkingAreaLabelTypes {
  working_area = 1,
  location,
}

export enum ServiceLabelTypes {
  iservice = 1,
  location,
  area
}

export enum RequestLabelTypes {
  irequest = 1,
  case,
  supportDemand,
}

export enum CustomerLabelTypes {
  icustomer = 1,
  client,
  localResident,
  resident,
  member,
  coachee,
  trainee
}

export enum ServicePointLabelTypes {
  iservicePoint = 1
}

export interface IThemeColor {
  primary: string;
  accent: string;
  primaryLight: string;
  primaryDark: string;
  background: string;
  surface: string;
  onPrimary: string;
  onAccent: string;
  onBackground: string;
  onSurface: string;
}

export interface IMailinglistItem {
  id?: string;
  name: string;
  active: boolean;
  userCanSubscribe: boolean;
  // owners: IObjectMap<boolean>;
  // subscribers: IObjectMap<boolean>;
  log?: DocumentLog;
}

export class ReservationSettings {
  email: string;
  categories: string[];

  constructor() {
    this.categories = [];
  }
}

export class OrganizationTheme {
  activeUrl: string;
  previewUrl: string;
  colors: IThemeColor;
  previewColors?: IThemeColor;

  constructor() {
    this.colors = {
      primary: '#b22735',
      accent: '#ff6800',
      primaryLight: '#ea5b5f',
      primaryDark: '#7b0010',
      background: '#FFFFFF',
      surface: '#FFFFFF',
      onPrimary: '#FFFFFF',
      onAccent: '#FFFFFF',
      onBackground: '#000000',
      onSurface: '#000000'
    };
  }
}

export interface OrganizationApiClient {
  id?: string;
  apiKey: string;
  name: string;
  isActive: boolean;
  log?: DocumentLog;
}

export interface OrganizationLegalEntity {
  url: string;
  validFrom: Date;
}

export interface OrgExpiryConfig {
  autoDeleteRequests: boolean;
  autoDeleteUsers: boolean;
  requests: ExpiryItemFieldModel;
  users: ExpiryItemFieldModel;
}

export interface CustomLabels {
  executorLabelName: ExecutorLabelTypes;
  workingAreaLabelName: WorkingAreaLabelTypes;
  requestLabelName: RequestLabelTypes;
  serviceLabelName: ServiceLabelTypes;
  coordinatorLabelName: CoordinatorLabelTypes;
  professionalLabelName: ProfessionalLabelTypes;
  customerLabelName: CustomerLabelTypes,
  servicePointLabelName: ServicePointLabelTypes
}

export interface SnelStartConfig {
  clientKey: string;
}

export interface EboekhoudenConfig {
  username: string;
  securityCode1: string;
  securityCode2: string;
}
export interface AfasConfig {
  apiBaseUrl: string;
  tokenHint: string;
  encryptedToken: string;
  autoCreateExecutors: boolean;
  getPersonConnector: string;
}

export interface AccountingSystemSettings {
  name: string;
  id: 'snelstart' | 'eBoekhouden' | 'afas';
  snelStartConfig?: SnelStartConfig;
  eboekhoudenConfig?: EboekhoudenConfig;
  afas?: AfasConfig;
}

export interface OrganizationSettings {
  canShowAllUsers?: boolean;
  financialActivityTracking?: boolean;
  publishRequest?: boolean;
  accountingSystem?: AccountingSystemSettings;
  timeTracking?: boolean;
  showUserPersonalNumber?: boolean;
  eventSettings?: EventSettings;
  membershipSettings?: MembershipSettings;
  emailSettings?: EmailSettings;
  isYouthCareOrg?: boolean;
  activateOrgSecurity?: boolean;
}

export class EventSettings {
  // email: string;
  categories: string[];

  constructor() {
    this.categories = [];
  }
}

export class EmailSettings {

  defaultFooter: string;

  constructor() {
    this.defaultFooter = '';
  }
}

export class MembershipSettings {
  membershipKinds: string[];

  constructor() {
    this.membershipKinds = [];
  }
}

export class Organization {
  id?: string;
  shortName: string;
  email: string;
  fullName: string;
  iconLogoUrl: string;
  fullLogoUrl: string;
  appLogo?: string;
  webLogo?: string;
  municipalities?: Municipality[];
  log: DocumentLog;
  loginPageText: string;
  status: OrganizationStatus;
  theme: OrganizationTheme;
  networkPartnerTypes: string[];
  reservationSettings: ReservationSettings;
  organizationName: string;
  orginizationUrl: string;
  expiry: OrgExpiryConfig;
  customLabels: CustomLabels;
  settings: OrganizationSettings;
  legal: {
    privacy: OrganizationLegalEntity;
    terms: OrganizationLegalEntity;
  };
  audiences: string[];

  constructor() {
    // this.log = new DocumentLog(); // Will be added when creating the doc
    this.theme = new OrganizationTheme();
    this.networkPartnerTypes = [];
    this.audiences = [];
    this.settings = {};
    this.customLabels = {
      executorLabelName: ExecutorLabelTypes.volunteer,
      workingAreaLabelName: WorkingAreaLabelTypes.working_area,
      requestLabelName: RequestLabelTypes.irequest,
      serviceLabelName: ServiceLabelTypes.iservice,
      coordinatorLabelName: CoordinatorLabelTypes.icoordinator,
      professionalLabelName: ProfessionalLabelTypes.iprofessional,
      customerLabelName: CustomerLabelTypes.icustomer,
      servicePointLabelName: ServicePointLabelTypes.iservicePoint
    };

    this.expiry = {
      autoDeleteRequests: true,
      autoDeleteUsers: true,
      requests: { value: 1, timeframe: 'year', isActive: false },
      users: { value: 1, timeframe: 'year', isActive: false },
    };
  }
}
