<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{labels[data.title]}}</span>
    </div>
    <div class="modal_body" #modal_body>
        <mat-progress-bar [style.opacity]="isLoading ? 1 : 0" mode="indeterminate"></mat-progress-bar>
        <form *ngIf="creationForm" class="content" [formGroup]="creationForm" fxLayout="column" role="form">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.name}}</mat-label>
                <input matInput type="text" formControlName="name">
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.description}}</mat-label>
                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows minRows="2"
                    formControlName="description">
                </textarea>
            </mat-form-field>
        </form>
    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px" fxLayout="row">
        <button [disabled]="!creationForm?.valid || isLoading" mat-raised-button color="primary" (click)="save()">
            {{labels[data.title]}}
        </button>
    </div>
</app-modal>