<app-modal>
  <div class="modal_header" #modal_header>
    <div fxLayout="row">
      <div fxFlex="auto" fxLayoutAlign="start center">
        <h3>
          {{model.title}}
        </h3>
      </div>
    </div>
  </div>

  <div class="modal_body" #modal_body style="padding: 1em;">
    <ng-container *ngIf="labels$ | async as labels">
      <form [formGroup]="form" class="content">
        <mat-form-field appearance="outline" class="full-width" floatLabel="always" (click)="endDatePicker.open()">
          <mat-label>{{labels.label_endDate}}</mat-label>
          <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" readonly />
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width" floatLabel="always" (click)="returnDatePicker.open()">
          <mat-label>{{labels.label_returnDate}}</mat-label>
          <input matInput [matDatepicker]="returnDatePicker" formControlName="returnDate" />
          <mat-datepicker-toggle matSuffix [for]="returnDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #returnDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width" floatLabel="always">
          <mat-label>{{labels.label_returnNote}}</mat-label>
          <textarea
            matInput
            formControlName="returnNote"
            cdkTextareaAutosize
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="5"
            [maxlength]="maxNoteLen"
          >
          </textarea>
          <mat-hint align="end">{{form.get('returnNote').value.length}} / {{maxNoteLen}}</mat-hint>
        </mat-form-field>
      </form>
    </ng-container>
  </div>
  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
    <ng-container *ngIf="labels$ | async as labels">
      <button mat-raised-button (click)="closeModal()">{{labels.btn_cancel}}</button>
      <button [disabled]="form.invalid" mat-raised-button color="primary" (click)="onSave()">
        {{labels.btn_save}}
      </button>
    </ng-container>
  </div>
</app-modal>
