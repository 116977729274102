import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { LabelService, SettingsService, UserPromptsService, UtilitiesService } from '@core/services';
import { AddressService } from '@core/services/address.service';
import { Address, AddressApi, CountryApi, IArea, MatchingAddresses } from '@models/model';
import { NgSub } from 'ng-sub';
import { supportedCountries } from './countries';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnChanges {
  @ViewChild('addressSearch') addressSearch: ElementRef<HTMLButtonElement>;
  @ViewChild('letter') letterDropdown: MatSelect;
  @ViewChild('houseNumberInput') houseNumberInput: ElementRef<HTMLInputElement>;
  
  @Input() currentAddress: Address;
  @Output() address = new EventEmitter<{ address: Address;  area: IArea}>();

  public isMobile: boolean;
  public labels = this.labelService.defaultProvider();
  protected selectedCountry: string = "NLD";
  protected matchedAddress: MatchingAddresses;
  protected letters: string[] = [];
  protected countries: CountryApi[];
  private sub = new NgSub();
  protected addressStringLine1: string;
  protected addressStringLine2: string;
  public houseLetter: string = '';
  private userAddress: any;
  public postalCode: string = '';
  public houseNumber: number;
  private isLetterInput: boolean = false;
  protected isLoading = false;
  private area: IArea;
  
  constructor(
    private labelService: LabelService,
    private userPromptsService: UserPromptsService,
    private addressService: AddressService,
    private utilitiesService: UtilitiesService,
    private settingsService: SettingsService
  ) { }


  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-address')).data;
    this.userPromptsService.activeMediaQuery$.subscribe(media => {
      this.isMobile = media === 'xs';
    })
    if (this.currentAddress?.city != null) {
      const address = this.currentAddress;
      this.addressStringLine1 = `${address.street || ''} ${address.number || ''} ${address.letter || ''}`;
      this.addressStringLine2 = `${address.postalcode || ''}  ${address.city || ''}`;

    }
    this.countries = supportedCountries.map(country => {
      country.name = this.labels[country.name];
      return country;
    });

    // this.addressService.getCountries().then((res) => {
    //   this.countries = res;
    // }, error => console.error(error))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.utilitiesService.isNewChange(changes['currentAddress'])) {
      const address = this.currentAddress || {} as any;
      this.addressStringLine1 = `${address?.street || ''} ${address?.number || ''} ${address?.letter || ''}`;
      this.addressStringLine2 = `${address?.postalcode || ''}  ${address?.city || ''}`;      
    }

  }

  public displayAddress(address: any) {
    return `${address.value}` || '';
  }

  protected searchAddress(text: any, enterHit?: boolean) {
    if (text) {
      this.sub.add(
        this.addressService.getAddresses(this.selectedCountry.toLowerCase(), text).subscribe(data => {
          this.isLetterInput = data.matches[0].precision == 'Address';
          if (!enterHit) this.matchedAddress = data;
          else if (enterHit && this.isLetterInput) {
            if (this.matchedAddress?.matches[0]) this.selectAddress(this.matchedAddress.matches[0]);
            this.matchedAddress = null;
          }
        })
      );
    }
  }

  protected enterKeyPressed(text: string) {
    this.searchAddress(text, true);
  }

  @HostListener('keyup', ['$event']) tabKeyClicked(event: KeyboardEvent) {
    if (event.code == 'Tab' || event.code == 'Enter') {
      const text = this.addressSearch.nativeElement.value;
      if (text) this.enterKeyPressed(text);
    }
  }

  protected selectAddress(matched: AddressApi) {
    if (matched.precision != 'Address') {
      this.addressService.getAddresses(matched.context, matched.value).subscribe(addresses => {
        this.matchedAddress = addresses;
        this.addressSearch.nativeElement.click();
      })
    } else {
      this.isLoading = true;
      this.addressService.getSelectedAddress(matched.context, this.selectedCountry.toLowerCase()).subscribe(async (mainAddress: any) => {
        this.userAddress = mainAddress;
        this.area = await this.settingsService.getAreaFromPostalCode(this.userAddress?.address?.postcode || this.userAddress.postcode)
        this.setFormValues();
      })
    }
  }

  public postalCodeInput(type: string) {
    switch (type) {
      case 'postalCode': {
        this.postalCode = this.postalCode.toUpperCase();
      }
        break;
      case 'houseNumber': this.houseNumber = this.houseNumber;
        break;
    }
    // this.fetchAddressByPostalCode();
  }

  public fetchAddressByPostalCode() {
    if (this.postalCode && this.postalCode.length === 6 && this.houseNumber >= 1) {
      this.isLoading = true;
      this.addressService.getAddressByPostalCode(this.postalCode.toUpperCase(), this.houseNumber, this.houseLetter).subscribe(async matchedAddress => {
        this.userAddress = matchedAddress;
        this.letters = (this.userAddress.houseNumberAdditions as string[]).filter(letter => !!letter);
        if (this.letters.length) {
          setTimeout(() => {
            this.letterDropdown.focus();
            this.letterDropdown.open();
          }, 0);
        }
        this.area = await this.settingsService.getAreaFromPostalCode(this.userAddress?.address?.postcode || this.userAddress.postcode)
        this.setFormValues(false);
      }, (_error) => {
        this.userPromptsService.showToast(this.labels.postal_code_or_number_not_correct);
      })
    }
  }

  public selectLetter(letter: string) {
    this.userAddress.houseNumberAddition = letter;
    this.setFormValues();
  }

  public async setFormValues(closeLetters: boolean = true) {
    if (!Object.keys(this.userAddress).length) return;
    this.isLoading = true;
    const address = new Address();

    address.city = this.userAddress?.address?.locality || this.userAddress.city;
    address.street = this.userAddress?.address?.street || this.userAddress.street;
    address.postalcode = this.userAddress?.address?.postcode || this.userAddress.postcode;
    address.number = this.userAddress?.address?.buildingNumber || this.userAddress.houseNumber;
    address.letter = this.userAddress?.address?.buildingNumberAddition || this.userAddress.houseNumberAddition;
    address.geo = {
      latitude: this.userAddress?.location?.latitude || this.userAddress.latitude,
      longitude: this.userAddress?.location?.longitude || this.userAddress.longitude
    };
    address.mailLines = this.userAddress?.mailLines || [];
    
    this.addressStringLine1 = `${address.street} ${address.number} ${address.letter || ''}`;
    this.addressStringLine2 = `${address.postalcode}  ${address.city}`;

    if (!this.area) this.area = await this.settingsService.getAreaFromPostalCode(address.postalcode);
    
    this.address.emit({ address, area: this.area });
    
    this.postalCode = '';
    this.houseNumber = null;
    this.houseLetter = '';
    if (closeLetters) this.letters = [];
    this.matchedAddress = null;
    this.addressSearch.nativeElement.value = ''
    this.isLoading = false;
  }

}
