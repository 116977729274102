import { IObjectMap } from "../interface";
import { DocumentLog } from "./documentLog";
import { SearchIndex } from "./searchIndex";

export interface IProduct {
    id: string;
    name: string;
    code: string;
    description: string;
    unit: UnitTypes;
    unitPrice: number;
    active: boolean
    searchIndex?: SearchIndex;
    log: DocumentLog;
    signalValue: number;
}

export class Product implements IProduct {
    id: string;
    name: string;
    code: string;
    description: string;
    unit: UnitTypes;
    unitPrice: number;
    active: boolean
    searchIndex?: SearchIndex;
    log: DocumentLog;
    signalValue: number;
    defaultActivityMinutes: number;

    constructor(product?: Product) {
        if (!product) {
            product = Object.create(null);
        }
        this.id = product.id || '';
        this.name = product.name || ''
        this.code = product.code || '';
        this.description = product.description || '';
        this.unit = product.unit || null;
        this.unitPrice = product.unitPrice || null;
        this.active = product.active !== null ? product.active : true;
        this.log = product.log || null; // @Note: null has to be here to avoid incomplete log
        this.defaultActivityMinutes = product.defaultActivityMinutes || null
        this.signalValue = product?.signalValue || null
        this.searchIndex = {
            properties: ['name', 'code'],
            index: {}
        }
    }
}
export const ProductImportMandatory: IObjectMap<string> = {
    name: 'name',
    code: 'code'
};

export const ProductImportOptional: IObjectMap<string> = {
    description: 'description',
    unit: 'unit',
    unitPrice: 'unitPrice',
};


export enum UnitTypes {
    // weeks = 'weeks',
    days = 'days',
    dayparts = 'dayparts',
    hours = 'hours',
    minutes = 'minutes',
    meters = 'meters',
    number = 'number'
}