import { distinctUntilChanged } from 'rxjs/operators';
import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
    selector: '[scrollBottom]'
})
export class ScrollBottomDirective {
    @Output() public isReachingBottom = new EventEmitter();
    @Input() public bottomOffset: number = 100;

    @HostListener('scroll', ['$event'])
    onScroll($event: Event) {
        this.elementScrollEvent($event);
    }

    private detectBottom$ = new Subject();

    ngOnInit(): void {
        this.detectBottom$.pipe(distinctUntilChanged()).subscribe((status) => {
            if (status) {
                this.isReachingBottom.emit();
            }
        });
    }

    protected elementScrollEvent($event: Event) {
        const target = <HTMLElement>$event.target;
        const scrollPosition = target.scrollHeight - target.scrollTop;
        const offsetHeight = target.offsetHeight;
        const isReachingBottom = (scrollPosition - offsetHeight) < this.bottomOffset;
        this.detectBottom$.next(isReachingBottom);
    }
}
