import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  LabelService, WorkingAreaService, OrgServiceService,
  UtilitiesService, ServiceRegistrationService,
  UserPromptsService, CustomFormsService, UserService,
  RequestService, OrganizationService,
  CloudFunctionService, UsageService, DocItem
} from '@core/services';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  WorkingArea, Service, ICustomFormModel,
  User, Management, Request, ICustomFormModelData,
  Organization, UsageActionTypes
} from '@shared/model';
import { take, map, takeUntil } from 'rxjs/operators';
import { FormUpdatePayload } from '@shared/components';
import { Observable, combineLatest, of, from } from 'rxjs';
import { flatten, uniqBy } from 'lodash';
import { IEmailOption } from '@shared/interface';
import { DomSanitizer } from '@angular/platform-browser';
import { ServiceRegistration, ServiceRegStatusTypes, ServiceRegHistory } from '@models/model/serviceRegistration';
import { NgSub } from 'ng-sub';
import { ExistingUsersListModalComponent } from 'app/service-registration/service-registration-module/components/existing-users-list-modal/existing-users-list-modal.component';

interface InputPayload {
  serviceId: string;
  workingAreaId: string;
  serviceRegistration?: ServiceRegistration;
}

enum ActionStepsTypes {
  CUSTOMER_DETAILS = 1,
  CUSTOM_FORMS,
  EXTRA_DETAILS
}

@Component({
  selector: 'app-customer-service-registration-modal',
  templateUrl: './customer-service-registration-modal.component.html',
  styleUrls: ['./customer-service-registration-modal.component.scss']
})
export class CustomerServiceRegistrationModalComponent implements OnInit {
  public labels: any = {};
  public workingArea: WorkingArea;
  public service: Service;
  public customerForm: FormGroup;
  public customFormGroup: FormGroup;
  public progressPercent = 0;
  public currentActionStep: ActionStepsTypes;
  public ActionStepsTypes = ActionStepsTypes;
  public extraDetailsForm: FormGroup;
  public isEditing: boolean;
  public customForm: ICustomFormModel;
  public isUnsavedChanges: boolean;
  public serviceRegistration: ServiceRegistration;

  private sub = new NgSub();

  constructor(
    private labelService: LabelService,
    private dialogRef: MatDialogRef<CustomerServiceRegistrationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputPayload,
    private formBuilder: FormBuilder,
    private workingAreaService: WorkingAreaService,
    private orgServiceService: OrgServiceService,
    private utilitiesService: UtilitiesService,
    private serviceRegistrationService: ServiceRegistrationService,
    private userPromptsService: UserPromptsService,
    private customFormsService: CustomFormsService,
    private userService: UserService,
    private requestService: RequestService,
    private organizationService: OrganizationService,
    public domSanitizer: DomSanitizer,
    private cloudFunctionService: CloudFunctionService,
    private usageService: UsageService,
  ) { }

  async ngOnInit() {
    // disables backdrop click
    this.dialogRef.disableClose = true;
    this.currentActionStep = ActionStepsTypes.CUSTOMER_DETAILS;

    if (this.data.serviceRegistration) {
      this.isEditing = true;
      this.serviceRegistration = this.data.serviceRegistration;
      this.data.workingAreaId = this.data.serviceRegistration.workAreaId;
    } else {
      throw Error('Missing service registration payload for csr');
    }

    this.sub.add(
      this.workingAreaService.getWorkingAreaById(this.data.workingAreaId).subscribe(w => {
        this.workingArea = w;
      }),
      this.orgServiceService.getServiceById(this.data.serviceId).subscribe(s => {
        this.service = s;
        this.setProgressPercent();

        const formId = this.getCustomFormId();
        if (formId) {
          this.customFormsService.getCustomFormById(formId).pipe(take(1)).toPromise().then(c => {
            this.customForm = c;
          });
        }
      })
    );

    this.setupForm();
    this.setProgressPercent();

    this.labels = (await this.labelService.getLabels('app-customer-service-registration-modal')).data;
  }

  public customerFormUpdated(payload: FormUpdatePayload) {
    this.customerForm = payload.form;

    if (!payload.init) {
      this.isUnsavedChanges = true;
    }
  }

  public onCustomFormUpdated(payload: FormUpdatePayload) {
    this.customFormGroup = payload.form;

    if (!payload.init) {
      this.isUnsavedChanges = true;
    }
  }

  public getCustomFormId(): string {
    if (this.serviceRegistration.customForm) {
      return this.serviceRegistration.customForm.id;
    } else {
      const settings: any = this.service && this.service.settings;
      return settings && settings.csr.customFormId;
    }
  }

  public canNextPhase(): boolean {
    if (this.currentActionStep === this.ActionStepsTypes.CUSTOMER_DETAILS) {
      return this.customerForm.valid;
    } else if (this.currentActionStep === this.ActionStepsTypes.CUSTOM_FORMS) {
      return this.customFormGroup && this.customFormGroup.valid;
    } else if (this.currentActionStep === this.ActionStepsTypes.EXTRA_DETAILS) {
      return true;
    }

    return false;
  }

  public switchPhase(next?: boolean) {
    if (next) {
      this.currentActionStep++;
    } else {
      this.currentActionStep--;
    }

    const steps = this.getValidFormSteps();
    const shouldSkip = steps.indexOf(ActionStepsTypes.CUSTOM_FORMS) < 0 && this.currentActionStep === ActionStepsTypes.CUSTOM_FORMS;
    if (shouldSkip) {
      this.switchPhase(next);
    } else {
      this.setProgressPercent();
    }
  }

  // get valid steps for form editing
  private getValidFormSteps(): ActionStepsTypes[] {
    return this.utilitiesService.getKeysForEnum(ActionStepsTypes).filter(item => {
      const settings: any = this.service && this.service.settings;
      if (settings && settings.catalog) {
        return this.getCustomFormId() ? true : item !== ActionStepsTypes.CUSTOM_FORMS;
      }

      return true;
    });
  }

  private setProgressPercent(): void {
    const steps = this.getValidFormSteps();

    const ratio = this.currentActionStep / steps.length;
    this.progressPercent = ratio * 100;
  }

  private setupForm() {
    this.extraDetailsForm = this.formBuilder.group({
      additionalInformation: [this.serviceRegistration.additionalInformation]
    });

    this.extraDetailsForm.valueChanges.pipe(takeUntil(this.sub)).subscribe(() => {
      this.isUnsavedChanges = true;
    });
  }

  private saveHistory(reg: ServiceRegistration, remark: string) {
    const history: ServiceRegHistory = {
      action: reg.status,
      remark
    };

    this.serviceRegistrationService.createServiceRegistrationHistory(
      history,
      this.serviceRegistration.id
    ).then(() => { });
  }

  private sendStatusChangeEmail(reg: ServiceRegistration): Promise<void> {
    return new Promise(async (resolve) => {
      const remark = (await this.userPromptsService.showPromptDialogue<string>(
        this.labels.remark_action,
        this.labels.remark_text_for_action,
        { type: 'textarea', required: false }
      )) || '';

      resolve();
      const currentUserEmail: User = await this.userService.getCurrentUser().pipe(take(1)).toPromise();
      const organization: Organization = await this.organizationService.getOrganizationById(
        localStorage.getItem('user_organization')
      ).pipe(take(1)).toPromise();

      const remarkMail = this.domSanitizer.sanitize(SecurityContext.HTML, remark).replace(new RegExp('&#10;', 'g'), '<br>');

      const msg = `
                    <h4>${this.labels.info}</h4>
                    ${this.labels.title}: ${this.serviceRegistration.title}<br/><br/>
                    ${this.labels.customer}: ${reg.customer.firstname} ${reg.customer.lastname}<br/><br/>
                    ${this.labels.remark}: ${remarkMail}
                `;

      const type = reg.status;
      const subjectLabelField = `service_registration_${ServiceRegStatusTypes[type]}_subject`;
      const bodyLabelField = `service_registration_${ServiceRegStatusTypes[type]}_body`;

      const emailOptions: IEmailOption = {
        subject: this.labels[subjectLabelField],
        bodySubject: this.labels[subjectLabelField],
        from: reg.status === ServiceRegStatusTypes.submitted ?
          currentUserEmail.email : this.service.settings.catalog.emailNotification,
        to: reg.status === ServiceRegStatusTypes.submitted ?
          this.service.settings.catalog.emailNotification : currentUserEmail.email,
        cc: reg.status === ServiceRegStatusTypes.submitted ?
          currentUserEmail.email : this.service.settings.catalog.emailNotification,
        logoUrl: organization?.webLogo || organization.fullLogoUrl,
        bodyText: this.labels[bodyLabelField],
        orgName: organization.fullName,
        orgId: organization.id,
        actionHtml: msg,
        infoText: `
                        ${this.labels['email-info-text']} ${organization.email} <br/><br/>
                        ${this.labels['email-best-regards']} <br> ${organization.fullName}`
      };

      this.saveHistory(reg, remark);
      this.cloudFunctionService.sendEmails([emailOptions]).then(() => { }).catch(() => { });
    });
  }

  private addUsageActivity(reg: ServiceRegistration, type: UsageActionTypes, user?: User) {
    // create activity here
    const userInfo = {
      userId: '',
      firstName: reg.customer.firstname,
      lastName: reg.customer.lastname
    };

    if (user) {
      userInfo.userId = user.id;
      userInfo.firstName = user.firstname;
      userInfo.lastName = user.lastname;
    }

    return this.usageService.addActivity(type, userInfo);
  }

  public async deleteServiceReg() {
    const sure = await this.userPromptsService.confirmPromise(
      this.labels.confirm_action,
      this.labels.sure_to_delete_service_registration
    );

    if (sure) {
      await this.serviceRegistrationService.deleteRegistration(this.serviceRegistration.id);
      this.isUnsavedChanges = false;
      this.closeModal();
    }
  }

  private async approveServiceRegistration(user?: User) {
    if (!user) {
      // check duplicate email address
      const userWithEmail: User = this.serviceRegistration.customer.email
        ? await this.userService.getUserByEmail(this.serviceRegistration.customer.email)
        : null;

      if (userWithEmail) {
        return this.userPromptsService.showToast(this.labels.user_email_duplicate_error);
      }

      // first create user
      let newUser = Object.assign({}, new User(), this.serviceRegistration.customer);

      const newDoc = await this.userService.saveUser(newUser);

      newUser.id = newDoc.id;
      user = newUser;
    }

    this.serviceRegistration.status = ServiceRegStatusTypes.accepted;
    await this.sendStatusChangeEmail(this.serviceRegistration);

    this.addUsageActivity(
      this.serviceRegistration,
      UsageActionTypes.accept_service_registration,
      user
    ).then(() => { });

    const management: Management = Object.assign({}, new Management(), {
      customer: {
        firstname: user.firstname,
        lastname: user.lastname,
        phone: user.phone
      },
      executorId: null,
      coordinatorId: null,
      workareaId: this.serviceRegistration.workAreaId,
      serviceId: this.serviceRegistration.serviceId,
      customerId: user.id
    });

    const request: Request = Object.assign({}, new Request(), {
      management: JSON.parse(JSON.stringify(management)),
      district: user.area?.district || null,
      neighborhood: user.area?.neighbourhood || null,
      title: this.serviceRegistration.title,
      description: this.serviceRegistration.additionalInformation,
      status: this.service.settings.defaultStatusCreated,
    });

    request.checklistItems = this.service?.settings?.defaultChecklist?.items || [];
    const requestId = await this.requestService.createRequest(request);

    this.serviceRegistration.requestId = requestId;
    this.serviceRegistrationService.updateRegistration(this.serviceRegistration as DocItem);

    // custom form
    if (this.serviceRegistration.customForm) {
      const userForm: ICustomFormModelData = {
        fields: this.serviceRegistration.customForm.values,
        userId: user.id,
        formId: this.customForm.id
      };

      this.customFormsService.createUserCustomForm(userForm);
    }

    this.userPromptsService.showToast(this.labels.service_registration_accepted);
    this.isUnsavedChanges = false;
    this.closeModal();
  }

  public async acceptServiceReg() {
    // check if customer already exists
    const firstname = this.serviceRegistration.customer.firstname;
    const lastname = this.serviceRegistration.customer.lastname;
    const address: any = this.serviceRegistration.customer.address;
    const email: string = this.serviceRegistration.customer.email;

    let namesObs: Observable<User[]>;
    let addressObs: Observable<User[]>;
    let emailObs: Observable<User[]>;

    if (firstname && lastname) {
      namesObs = this.userService.getUsersMatchingName(firstname, lastname);
    } else {
      namesObs = of<User[]>([]);
    }

    if (address && address.postalcode && address.postalcode && address.postalcode.length === 6
      && address.number && address.number.length > 0) {
      addressObs = this.userService.getUsersMatchingAddress(address.postalcode, address.number);
    } else {
      addressObs = of<User[]>([]);
    }

    if (email) {
      emailObs = from(this.userService.getUserByEmail(email)).pipe(
        map(user => [user])
      );
    } else {
      emailObs = of<User[]>([]);
    }

    combineLatest([namesObs, addressObs, emailObs]).pipe(
      take(1),
      map(usersArr => {
        return flatten(usersArr).filter(user => !!user);
      })
    ).subscribe((users: User[]) => {
      if (users.length > 0) {
        this.userPromptsService.showDialogue(ExistingUsersListModalComponent, {
          users: uniqBy(users, 'id')
        }, (selectedUser?: User) => {
          if (selectedUser !== undefined) {
            this.approveServiceRegistration(selectedUser);
          }
        }, null, { width: '400px' });
      } else {
        this.approveServiceRegistration();
      }
    });
  }

  public async closeModal() {
    if (this.isUnsavedChanges) {
      this.userPromptsService.showConfirmDialogue(
        this.labels.confirm_action,
        this.labels.unsaved_changes_text,
        (sure: boolean) => {
          if (sure) {
            this.dialogRef.close();
          }
        }
      );
    } else {
      this.dialogRef.close();
    }
  }
}
