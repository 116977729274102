<app-modal [alignFooterStart]="true">
  <div class="modal_header" #modal_header>
    <div class="modal_title">{{user?.fullname}}</div>
    <div class="modal_subtitle">{{labels.availability}}</div>
  </div>

  <div class="modal_body" #modal_body>
    <div class="p-3" fxLayout="column" fxLayoutGap="15px" *ngIf="user.availability as av">
      <div fxLayout="row" fxLayout.xs="column">

        <div class="mt-3" fxLayout="row" fxLayoutGap="10px" style="margin-bottom: -20px;">
          <div fxFlex="120px"></div>
          <mat-form-field appearance="outline" class="full-width" style="width: 150px;">
            <mat-label>{{labels.availability_status}}</mat-label>
            <mat-select [(ngModel)]="av.availability" (ngModelChange)="setCurrentDate($event)">
              <mat-option *ngFor="let m of availabilityStatus" [value]="m.id">
                {{ labels[m.status] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="av.availability === 2" class="mt-3" fxLayout="row" fxLayoutGap="10px" style="margin-bottom: -20px;">
          <div fxFlex="120px"></div>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{labels.not_available_until}}</mat-label>
            <input style="margin-bottom: -15px;" matInput type="date" [(ngModel)]="av.notAvailableUntil">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="120px"></div>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.availability_comment}}</mat-label>
          <input matInput [(ngModel)]="av.comment">
        </mat-form-field>
      </div>

      <div *ngFor="let week of weekdays" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <div class="" fxLayout="row" fxLayoutGap="7px" fxFlex="120px" fxLayoutAlign="start center">
          <div fxFlex="stretch"> {{week.name}} </div>

          <div>
            <mat-checkbox color="primary" [(ngModel)]="av.days[week.prop].available"> </mat-checkbox>
          </div>
        </div>

        <div *ngIf="av.days[week.prop].available" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px"
          fxFlex="stretch">
          <mat-form-field appearance="outline" fxFlex="stretch">
            <mat-label>{{labels.availability}}</mat-label>
            <mat-select multiple [(ngModel)]="availabilityDays[week.prop]">
              <mat-option *ngFor="let m of availabilitySessions" [value]="m">
                {{ labels[m] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="stretch">
            <mat-label>{{labels['remark']}}</mat-label>
            <input matInput [(ngModel)]="av.days[week.prop].remark">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
    <div fxFlex="stretch" fxLayoutAlign="start center">
    </div>

    <div>
      <button mat-button color="primary" (click)="saveAvailability()">{{labels.btn_save}}</button>
    </div>
  </div>
</app-modal>