<div fxLayout="column">
    <div class="note_action" fxLayout="row" fxLayoutAlign="start center">
        <div class="fill-remaining-space">
            <mat-form-field appearance="outline">
                <mat-label>{{labels.search}}</mat-label>
                <input matInput type="text" [(ngModel)]="searchText" placeholder="{{labels.search}}">
            </mat-form-field>
        </div>

        <div>
            <button mat-raised-button color="primary" (click)="openNoteForm()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="column" fxLayoutGap="15px">
        <div *ngFor="let note of getNotes()">
            <div class="note" fxLayout="column">
                <div fxLayout="row">
                    <div fxFlex="stretch">{{addedByForNotes[note.id]}}</div>
                    <div fxFlex="auto" fxLayoutAlign="end" *ngIf="canEditNote(note)">
                        <button mat-icon-button [matMenuTriggerFor]="controls">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #controls="matMenu">
                            <button mat-menu-item (click)="openNoteForm(note)">
                                <mat-icon>edit</mat-icon>
                                Edit
                            </button>

                            <button mat-menu-item (click)="removeNote(note)">
                                <mat-icon>delete</mat-icon>
                                Delete
                            </button>
                        </mat-menu>
                    </div>
                </div>

                <div class="note_body">
                    <span class="carriage_returns">{{note.note}}</span>
                </div>

                <div fxLayout="row" class="note_header">
                    <div fxFlex="stretch">{{getNoteTimestamp(note)}}</div>
                    <div style="white-space: nowrap;">{{workingAreasForNotes[note.id]}} - {{servicesForNotes[note.id]}}
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>

    <div fxLayoutAlign="center center" *ngIf="notes.length > 3">
        <button mat-button (click)="showMore = !showMore">
            {{showMore ? labels.less : labels.more}}
            <mat-icon>
                {{showMore ? 'arrow_drop_up' : 'arrow_drop_down'}}
            </mat-icon>
        </button>
    </div>
</div>