import { EventEmitter, Injectable } from '@angular/core';
import { SpecialCharacters } from '@models/model/special-chars.model';

export interface IToolbarSearchEvent {
  event: string;
  val: string;
}

@Injectable({
  providedIn: 'root'
})
export class ToolbarSearchService {
  private events: EventEmitter<IToolbarSearchEvent> = new EventEmitter();

  constructor() { }

  public reset(text: string) {
    this.events.emit({
      event: 'reset',
      val: text
    });
  }

  public setSearchWord(text: string) {
    // format the right text here    
    const effective = SpecialCharacters.fromSpecial(text).replace(/[^A-Za-z0-9 _-]/g, ' ');

    this.events.emit({
      event: 'search',
      val: effective,
    });
  }


  public getEmitter(): EventEmitter<IToolbarSearchEvent> {
    return this.events;
  }
}
