"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimestampHandler = void 0;
const timestamp_1 = require("../models/timestamp");
const utils_1 = require("./utils");
class TimestampHandler {
  getServerTimestamp() {
    return timestamp_1.Timestamp.now();
  }
  timestampToDate(timestamp) {
    return timestamp.toDate();
  }
  dateToTimestamp(date) {
    return timestamp_1.Timestamp.fromDate(date);
  }
  parseTimestampToDate(data) {
    let doc = data;
    if (doc) {
      if (Array.isArray(doc)) {
        doc = doc.map(this.checkTimestampPropertyValue.bind(this));
      }
      if (doc.constructor.name === 'Object') {
        Object.keys(doc).forEach(key => {
          doc[key] = this.checkTimestampPropertyValue(doc[key]);
        });
      }
    }
    return doc;
  }
  parseDateToTimestamp(data) {
    let doc = data;
    if (doc) {
      if (Array.isArray(doc)) {
        doc = doc.map(this.checkDateTimestampPropertyValue.bind(this));
      }
      if (doc.constructor.name === 'Object') {
        Object.keys(doc).forEach(key => {
          doc[key] = this.checkDateTimestampPropertyValue(doc[key]);
        });
      }
    }
    return doc;
  }
  checkTimestampPropertyValue(element) {
    if (element) {
      if ((typeof element._seconds === 'number' || typeof element.seconds === 'number') && (typeof element._nanoseconds === 'number' || typeof element.nanoseconds === 'number')) {
        element = new Date((element._seconds || element.seconds) * 1000);
      } else {
        element = this.parseTimestampToDate(element);
      }
    }
    return element;
  }
  checkDateTimestampPropertyValue(element) {
    if (element) {
      const date = new Date(element);
      if ((0, utils_1.isValid)(date) && element instanceof Date) {
        element = this.dateToTimestamp(date);
      } else {
        element = this.parseDateToTimestamp(element);
      }
    }
    return element;
  }
}
exports.TimestampHandler = TimestampHandler;
