import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService, UserService, UtilitiesService } from '@core/services';
import { DocumentLog, User } from '@models/model';
import { uniq } from 'lodash';
import { NgSub } from 'ng-sub';
import { takeUntil } from 'rxjs/operators';
import { AlertDialogueComponent } from '../alert-dialogue/alert-dialogue.component';

@Component({
  selector: 'app-log-info',
  templateUrl: './log-info.component.html',
  styleUrls: ['./log-info.component.scss']
})
export class LogInfoComponent implements OnInit, OnDestroy {
  public labels = this.labelService.defaultProvider();
  public form: FormGroup;

  private sub = new NgSub();

  constructor(
    public dialogRef: MatDialogRef<AlertDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { log: DocumentLog; },
    private labelService: LabelService,
    private userService: UserService,
    private fb: FormBuilder,
    private utilitiesService: UtilitiesService,
  ) { }

  async ngOnInit() {
    this.labels = (await this.labelService.getLabels('app-log-info')).data;

    const userIds = uniq([this.data.log.createdBy, this.data.log.modifiedBy].filter(a => !!a));

    if (userIds.length > 0) {
      this.userService.getUsersFromIds(userIds).pipe(takeUntil(this.sub)).subscribe({
        next: users => {
          this.setupForm(users);
        },
        error: e => console.log(e),
      });
    } else {
      this.setupForm([]);
    }
  }

  private setupForm(users: User[]): void {
    const createdBy = users.find(u => u.id === this.data.log.createdBy);
    const updatedBy = users.find(u => u.id === this.data.log.modifiedBy);

    this.form = this.fb.group({
      createdAt: [this.utilitiesService.getLocaleDateWithYear(this.data.log.createdAt)],
      updatedAt: [this.utilitiesService.getLocaleDateWithYear(this.data.log.updatedAt)],
      createdBy: [createdBy ? `${createdBy.firstname} ${createdBy.lastname}` : this.labels.user_deleted],
      updatedBy: [updatedBy ? `${updatedBy.firstname} ${updatedBy.lastname}` : this.labels.user_deleted],
    });

  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
