<div fxLayout="row" class="main">

  <div style="padding-right: 20px;">
    <mat-card class="no-header" style="margin: 0 auto; max-width: fit-content; min-width: 650px;  padding-top: 0px">
      @if (isLoading) {<mat-progress-bar mode="indeterminate"></mat-progress-bar>}
      <mat-card-header>
        <div fxLayout="row" fxFlex="stretch">
          <mat-form-field appearance="outline" style="width: 150px; padding-right: 10px;">
            <mat-label>{{labels.search}}</mat-label>
            <input matInput [(ngModel)]="userFilter.textFilter" (ngModelChange)="checkTextFilterValid()"
              (keydown.enter)="handleFilterSubmit()">
          </mat-form-field>

          <div style="padding-left: 5px; padding-right: 10px; margin-top: 4px;">
            <button mat-mini-fab [disabled]="!anyNonSearchTextFiltersSet() && !userFilter.textFilter"
              (click)="clearFilters()">
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <div fxFlex></div> <!-- This will push the button to the right -->

          <button style="margin-top: 4px;" mat-raised-button color="primary"
            (click)="createNewUser()">{{labels.add_user}}</button>
        </div>

      </mat-card-header>
      <mat-selection-list>
        <mat-list-item *ngIf="dataTableConfig?.length === 0">
          <mat-list-item>
            <mat-icon matListItemIcon>information</mat-icon>
            <div matListItemTitle>{{isAllEmpty ? labels.empty_users_title : labels.empty_users_title_alt}}
            </div>
            <div matListItemLine>{{isAllEmpty ? labels.empty_users_desc : labels.empty_users_desc_alt}}</div>
          </mat-list-item>

        </mat-list-item>
        <div class="grid-container">
          @for (data of dataTableConfig; track $index) {
          <div class="grid-item" style="display: grid; grid-template-columns: 270px 250px 100px; gap: 0px;">

            <div>
              <mat-divider></mat-divider>
              <mat-list-item (click)="handleRowSelect(data, 'details')" lines="3">
                <span matListItemIcon>
                  <thumbnail [image]="data.picture"></thumbnail>
                </span>
                <span matListItemTitle>{{data.firstname}} {{data.lastname}}</span>
                <span>{{data.userRoles}}</span>

              </mat-list-item>

            </div>
            <div>
              <mat-divider></mat-divider>
              <mat-list-item (click)="handleRowSelect(data, 'address')" lines="3">
                <div matListItemTitle>{{data.street}} {{data.number}}</div>
                <div matListItemLine>{{data.postalcode}} - {{data.neighborhood}}</div>
                <div matListItemLine>{{data.decedent}}{{data.usernote}}</div>
              </mat-list-item>
            </div>
            <div>
              <mat-divider></mat-divider>

              <div>

                <div fxLayout="column" fxLayoutAlign="center" style="padding-top: 5px;">
                  <div fxLayout="row" fxLayoutAlign="end">
                    <button mat-icon-button color="accent" (click)="openUserRequests(data.originalData)">
                      <mat-icon>handshake</mat-icon></button>
                    <button mat-icon-button (click)="openUsersInfoDialog(data.originalData)">
                      <mat-icon>
                        <span class="material-symbols-outlined">contact_page</span>
                      </mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </mat-selection-list>
    </mat-card>
  </div>

  <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="20px">
    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '90%', 'min-height': '150px', 'max-width': '500px'}"
      fxLayoutAlign.xs="space-around">

      <!-- <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <mat-form-field appearance="outline">
          <mat-label>{{labels.order_by}}</mat-label>
          <mat-select [(ngModel)]="userFilter.orderByFilter">
            <mat-option value="last-name">
              {{labels.last_name}}
            </mat-option>
            <mat-option value="first-name">
              {{labels.first_name}}
            </mat-option>
            <mat-option value="street">
              {{labels.street}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-stroked-button color="primary" aria-label="Advance" (click)="advance()">
          {{!advanceFilterEnabled ? labels.more_filters : labels.less_filters}}
        </button>
      </div> -->

      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <mat-form-field appearance="outline">
          <mat-label>{{labels.postalcode}}</mat-label>
          <input matInput [(ngModel)]="userFilter.postalCodeFilter" pattern="[0-9]{4}[A-Za-z]{2}" mask="0000SS"
            (ngModelChange)="checkPostalCodeValid()" (keydown.enter)="handleFilterSubmit()">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Nr</mat-label>
          <input matInput #houseNumberInput [(ngModel)]="userFilter.houseNumberFilter" mask="0*"
            (keydown.enter)="handleFilterSubmit()" (keydown.Tab)="handleHouseNumberTabOut($event)">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <mat-form-field appearance="outline" *ngIf="org">
          <mat-label>{{labels.membership}}</mat-label>
          <mat-select [(ngModel)]="userFilter.membership" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let membership of org.settings?.membershipSettings?.membershipKinds"
              [value]="membership">
              {{membership}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="mailinglists">
          <mat-label>{{labels.mailinglists}}</mat-label>
          <mat-select [(ngModel)]="userFilter.mailingList" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let mailing of mailinglists" [value]="mailing.id">
              {{mailing.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card>

    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '90%', 'min-height': '150px', 'max-width': '500px'}"
      fxLayoutAlign.xs="space-around">
      @if (fetchingMunicipalities) {<mat-progress-bar style="margin-bottom: 5px;" mode="indeterminate"></mat-progress-bar>}
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <mat-form-field appearance="outline">
          <mat-label>{{labels.role}}</mat-label>
          <mat-select [(ngModel)]="userFilter.rolesFilter" (ngModelChange)="handleRoleChange()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option value="exec">
              {{labels.executor}}
            </mat-option>
            <mat-option value="coord">
              {{labels.coordinator}}
            </mat-option>
            <mat-option value="prof">
              {{labels.professional}}
            </mat-option>
            <mat-option value="cus">
              {{labels.customer}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{labels.status}}</mat-label>
          <mat-select
            [disabled]="(userFilter.rolesFilter != 'exec' && userFilter.rolesFilter != 'cus')|| !userFilter.serviceFilter"
            [(ngModel)]="userFilter.statusFilter" (ngModelChange)="handleRoleChange()">
            <mat-option [value]="false"> <!-- the value uses disabled property to query. So its disabled: false-->
              {{labels.active}}
            </mat-option>
            <mat-option [value]="true"> <!-- the value uses disabled property to query. So its disabled: true-->
              {{labels.inactive}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <mat-form-field appearance="outline">
          <mat-label>{{labels.working_area}}</mat-label>
          <mat-select [(ngModel)]="userFilter.workingAreaFilter" (ngModelChange)="getServices()"
            (selectionChange)="handleFilterSubmit()"
            [disabled]="(userRole.roleType === 2 || userRole.roleType === 3 || !userFilter.rolesFilter || (userFilter.rolesFilter === '-1'))">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let v of workingAreas" [value]="v.id">
              {{v.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{labels.service}}</mat-label>
          <mat-select [(ngModel)]="userFilter.serviceFilter"
            [disabled]="((userRole.roleType === 2 && !userFilter.rolesFilter) || userFilter.rolesFilter === 'prof' || !userFilter.rolesFilter || (userFilter.rolesFilter === '-1'))"
            (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let s of services | sort:'name'" [value]="s.id">
              {{s.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.municipality}}</mat-label>
          <input [disabled]="municipalities?.length === 0" type="text" matInput
            [(ngModel)]="userFilter.municipalityFilter" [matAutocomplete]="auto"
            (ngModelChange)="onMunicipality($event)">
          <mat-icon matSuffix style="cursor: pointer" *ngIf="canClearText && municipalities?.length" (click)="clearSelection()">close
          </mat-icon>
          <mat-icon matSuffix style="cursor: pointer" *ngIf="!municipalities?.length"
            (click)="loadMunicipalities()">refresh</mat-icon>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayMunicipalityName">
            <mat-option *ngFor="let m of filterMunicipalities()" [value]="m">
              {{m.text}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <mat-form-field appearance="outline">
          <mat-label>{{labels.district}}</mat-label>
          <mat-select [disabled]="!userFilter.municipalityFilter" [(ngModel)]="userFilter.districtFilter"
            name="district" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">{{labels.show_all}}</mat-option>
            <mat-option *ngFor="let district of districts;" [value]="district.code">{{district.text}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{labels.neighborhood}}</mat-label>
          <mat-select [disabled]="!userFilter.districtFilter" [(ngModel)]="userFilter.neighborhoodFilter"
            name="neighborhood" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="''">{{labels.show_all}}</mat-option>
            <mat-option *ngFor="let neighborhood of getNeighborhoodList()" [value]="neighborhood.code">
              {{neighborhood.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card>

    <div fxLayout="row" fxLayoutGap="15px">
      <button button mat-mini-fab [disabled]="!anyNonSearchTextFiltersSet() && !userFilter.textFilter"
        [color]="anyNonSearchTextFiltersSet() || userFilter.textFilter ? 'primary' : ''"
        (click)="openEmailDialog()"><mat-icon>mail</mat-icon></button>

      <button button mat-mini-fab [disabled]="!anyNonSearchTextFiltersSet() && !userFilter.textFilter"
        [color]="anyNonSearchTextFiltersSet() || userFilter.textFilter ? 'primary' : ''"
        (click)="downloadAddress()"><mat-icon>download</mat-icon></button>

      <button button mat-mini-fab [disabled]="!userFilter.rolesFilter"
        [color]="anyNonSearchTextFiltersSet() || userFilter.textFilter ? 'primary' : ''"
        (click)="openBirthdayExportUsersDialog()"><mat-icon>cake</mat-icon></button>

      <div *ngIf="userRole.roleType === roleTypes.orgAdmin">
        <button mat-raised-button [matMenuTriggerFor]="controls" class="import-users-button">
          {{labels.options}} <mat-icon>arrow_drop_down</mat-icon>
        </button>

        <mat-menu #controls="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="importcontrols">
            {{labels.import}}
          </button>

          <button mat-menu-item (click)="openExportUsersDialog()">
            {{labels.export}}
          </button>
        </mat-menu>

        <mat-menu #importcontrols="matMenu">
          <button mat-menu-item (click)="openImportUsersDialog(ImportUserTypes.users)">
            {{labels.users}}
          </button>
          <button mat-menu-item (click)="openImportUsersDialog(ImportUserTypes.coaches)">
            {{labels.coaches}}
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div *ngIf="dataTableConfig?.length > 0">

    <div style="margin-top: -10px;" class="footer">
      <mat-paginator (page)="onPaginationChange($event)" [length]="paginationOptions.totalSize"
        [pageSize]="paginationOptions.usersPerPage" [pageIndex]="paginationOptions.pageIndex"
        [pageSizeOptions]="[5, 10, 30, 50]" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>

  <!-- <div *ngIf="dataTableConfig && dataTableConfig.data.length === 0 && !userFilter.defaultLoadLastSeen">
    <app-empty-list [actionText]="labels.create_new_user" (onAction)="createNewUser()"
      [actionDisabled]="isLoading || (letters.length > 0 && !userAddress.houseNumberAddition)"
      [title]="isAllEmpty ? labels.empty_users_title : labels.empty_users_title_alt"
      [description]="(isAllEmpty ? labels.empty_users_desc : labels.empty_users_desc_alt) + '\n'+ addressString">
      <div belowDescription>
        <div *ngIf="letters.length">
          <mat-form-field appearance="outline">
            <mat-label>{{labels.letter}}</mat-label>
            <mat-select #letter [(ngModel)]="houseLetter" [disabled]="letters?.length === 0">
              <mat-option *ngFor="let letter of letters" [value]="letter" (onSelectionChange)="selectLetter(letter)">
                {{letter}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </app-empty-list>
  </div> -->

  <!-- <div *ngIf="dataTableConfig && dataTableConfig.data.length === 0 && !isLoading && userFilter.defaultLoadLastSeen">
    <app-empty-list [actionText]="labels.create_new_user" (onAction)="createNewUser()"
      [title]="isAllEmpty ? labels.empty_last_seen_users_title : labels.empty_last_seen_users_title_alt"
      [description]="isAllEmpty ? labels.empty_last_seen_users_desc : labels.empty_last_seen_users_users_desc_alt">
    </app-empty-list>
  </div> -->
</div>