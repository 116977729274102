<app-modal [alignFooterStart]="true" [hideCloseButton]="false">
  <div class="modal_header" #modal_header fxLayout="row">
    <div fxLayout="column" fxFlex="stretch" fxLayoutAlign="flex-start" fxLayoutGap="8px">
      <span class="modal_title">
        {{isEditing ? labels.edit_service_registration : labels.service_registration}}
      </span>
      <span class="modal_subtitle">
        {{workingArea?.name}} - {{service?.name}}
      </span>
    </div>
  </div>

  <div class="modal_body" #modal_body style="padding: 0; overflow: hidden;">
    <div class="body-content">
      <form [formGroup]="form" *ngIf="form">
        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>{{labels.title}}</mat-label>
            <input [readonly]="isEditing" matInput required minlength="2"
              formControlName="title">
          </mat-form-field>
        </div>

        <div style="padding-left: 5px;">
          <app-custom-form-group *ngIf="customForm" [disabled]="isEditing" (formChanged)="onCustomFormUpdated($event)"
            [customForm]="customForm" [formValues]="serviceRegistration.customForm?.values">
          </app-custom-form-group>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{labels.additional_information}}</mat-label>
            <textarea [readonly]="isEditing" matInput formControlName="additionalInformation" cdkAutosizeMinRows="3"
              cdkTextareaAutosize minRows="6"></textarea>
          </mat-form-field>

          <mat-slide-toggle color="primary" [disabled]="isEditing" class="full-width" (change)="toggle($event)" style="margin-top: 20px;"
            formControlName="termsAccepted">
            {{labels.terms_accepted_redirect}}
          </mat-slide-toggle>

          <mat-slide-toggle color="primary" *ngIf="!isEditing" (change)="toggleCloseOriginalRequest($event)" style="margin-top: 20px;"
            formControlName="closeOriginalRequest">
            {{labels.close_original_request}}
          </mat-slide-toggle>

          <div *ngIf="isEditing">
            <p>{{labels.complete_application_process}}</p>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
    <div fxFlex="stretch" fxLayoutAlign="flex-start"></div>

    <div fxLayout="row" fxLayoutGap="10px">
      <button *ngIf="!isEditing" mat-stroked-button (click)="closeModal(true)">{{labels.cancel}}</button>
      <button *ngIf="!isEditing " mat-raised-button color="primary" [disabled]="form?.invalid || !termsAccepted"
        (click)="saveAndClose()">
        {{labels.submit}}
      </button>

      <button *ngIf="isEditing && serviceRegistration?.status !== ServiceRegStatusTypes.accepted" mat-stroked-button
        color="warn" (click)="deleteServiceReg()">
        {{labels.delete}}
      </button>
      <button *ngIf="isEditing && serviceRegistration?.status !== ServiceRegStatusTypes.accepted" mat-raised-button
        color="primary" (click)="acceptServiceReg()">
        {{labels.accept}}
      </button>
    </div>
  </div>
</app-modal>