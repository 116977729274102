import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { uniqBy, get, cloneDeep } from 'lodash';
import { SpecialCharacters } from '@models/model/special-chars.model';

export interface ISearchQuery {
    searchText: string;
    ref: string;
    order?: string[];
    limit?: number;
    where?: any[][];
}

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    constructor(
        private firestoreService: FirestoreService
    ) { }

    public searchCollectionIndex<T>(searchQuery: ISearchQuery): Promise<T[]> {
        return new Promise(async (resolve) => {
            searchQuery.searchText = SpecialCharacters.fromSpecial(searchQuery.searchText.toLowerCase().trim());
            const docsObj: any = {};

            if (searchQuery.searchText.length > 0) {
                // here we break down the words and search individually
                const searchTerms = searchQuery.searchText.split(' ')
                    .map(text => text.trim())
                    // .filter(text => {
                    //     const result = !!text
                    //         && !text.includes('.')
                    //         && !text.includes('de')
                    //         && !text.includes('der')
                    //         && !text.includes('den')
                    //         && !text.includes('van')
                    //     console.log(text);
                    //     return result
                    // }
                    // );

                for (const term of searchTerms) {
                    const splitQuery = { ...searchQuery };
                    splitQuery.searchText = term;
                    splitQuery.where = cloneDeep(splitQuery.where);
                    let docs: any[] = [];
                    try {
                        docs = await this.getOptionsFromDB(splitQuery);
                    } catch (e) {
                        console.log('Error here ', e);
                    }

                    docs.forEach((doc) => {
                        docsObj[doc.id] = doc;
                    });
                }
            }

            resolve(Object.keys(docsObj).map(id => docsObj[id]));
        });
    }

    public getOptionsFromDB(splitQuery: ISearchQuery): Promise<any[]> {

        return this.firestoreService.colWithIdsNoCache(splitQuery.ref, (ref) => {
            let clause = ref.where(`searchIndex.index.${splitQuery.searchText}`, '==', true);
            while (splitQuery.where && splitQuery.where.length > 0) {
                const where = splitQuery.where.shift();
                clause = clause.where(where[0], where[1], where[2]);
            }

            if (splitQuery.order) {
                clause = clause.orderBy(splitQuery.order[0], splitQuery.order[1] as any);
            }

            if (splitQuery.limit) {
                clause = clause.limit(splitQuery.limit);
            }
            return clause;
        }, true);
    }

    public searchListBySearchIndex<T>(list: T[], searchText: string): T[] {
        searchText = SpecialCharacters.fromSpecial(searchText.toLowerCase().trim());

        if (searchText.length > 0) {
            const result: T[] = [];

            // here we break down the words and search individually
            const searchTerms = searchText.split(' ')
                .map(text => text.trim())
                .filter(text => !!text);

            list.forEach((item: any) => {
                for (const text of searchTerms) {
                    if (item.searchIndex.index[text]) {
                        result.push(item);
                        break;
                    }
                }
            });

            // @ts-ignore
            return this.orderItemsBySearchRelevance(uniqBy(result, 'id'), searchText);
        } else {
            return list;
        }
    }

    public orderItemsBySearchRelevance<T>(list: T[], text: string): T[] {
        // and query
        const searchParts: string[] = text.toLocaleLowerCase().split(' ')
            .map(textPart => textPart.trim())
            .filter(textPart => !!textPart);

        if (searchParts.length > 1) {
            list.forEach((item: any) => {
                let countMatch = 0
                for (const part of searchParts) {
                    if (item.searchIndex.index[part]) {
                        countMatch = countMatch + 1
                    }
                }
                item.countMatch = countMatch

            });

            // @ts-ignore
            return uniqBy(list, 'id');
        } else {
            return list;
        }
    }

    public searchObjectByProps<T>(list: T[], text: string, props: string[]): T[] {
        let result: T[];

        if (text && props.length > 0) {
            text = text.toLocaleLowerCase();

            result = list.filter(item => {
                for (const prop of props) {
                    const val = get(item, prop);

                    if (typeof val === 'string') {
                        const match = val.toLocaleLowerCase().indexOf(text) >= 0;

                        if (match) {
                            return true;
                        }
                    }
                }

                return false;
            });
        } else {
            result = list;
        }

        return result;
    }
}
