import { Address } from './address';
import { DocumentLog } from './documentLog';
import { IObjectMap } from '../interface';

export enum ServiceRegistrationTypes {
    internal = 1,
    network,
    customer,
}

export class ServiceRegCustomerDetails {
    id: string;
    address?: Address;
    firstname: string;
    lastname: string;
    birthday?: string;
    gender: string;
    phone: string;
    phone2?: string;
    email?: string;
}

export enum ServiceRegStatusTypes {
    notSubmitted = 1,
    submitted,
    accepted,
    rejected
}

export interface ServiceRegHistory {
    id?: string;
    action: ServiceRegStatusTypes;
    remark: string;
    log?: DocumentLog;
}

export class ServiceRegistration {
    id?: string;
    title: string;
    workAreaId: string;
    serviceId: string;
    customer: ServiceRegCustomerDetails;
    type: ServiceRegistrationTypes;
    isCsr?: boolean;
    status: ServiceRegStatusTypes;
    log?: DocumentLog;
    termsAccepted: boolean;
    additionalInformation?: string;
    requestId?: string;
    customForm?: {
        id: string;
        values: IObjectMap<any>;
    };

    constructor(workingAreaId: string, serviceId: string) {
        this.workAreaId = workingAreaId;
        this.serviceId = serviceId;
        this.status = ServiceRegStatusTypes.notSubmitted;
        this.customer = new ServiceRegCustomerDetails();
        this.termsAccepted = false;
    }
}
