<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{labels.select_users}}</span>
    </div>

    <div class="modal_body" #modal_body style="padding: 0;">
        <mat-progress-bar *ngIf="fetchingData" mode="indeterminate"></mat-progress-bar>

        <div class="panel-body scroll-body" [ngStyle.xs]="{'height': '75vh'}" scrollBottom
            (isReachingBottom)="getUsers(true)">
            <div fxLayout="column" fxLayoutGap="10px">
                <form [formGroup]="form" *ngIf="form" autocomplete="off" fxLayout.xs="column-reverse" fxLayout="row">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{labels.search}}</mat-label>
                        <input matInput type="text" formControlName="search" />
                        <mat-icon matSuffix class="cursor-pointer" (click)="clearSearch()">close</mat-icon>
                    </mat-form-field>
                </form>

                <div>
                    <div class="selector_controls">
                        <div fxLayout="row" fxLayoutAlign="flex-start">
                            <span class="fill-remaining-space">
                                <mat-checkbox color="primary" (change)="isMasterToggled = $event.checked; toggleSelectAll()">
                                    {{labels.select}}
                                </mat-checkbox>
                            </span>

                            <span>{{ getSelectedNumberOfUsers() | number }} {{labels.selected}}</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="userListForView.length === 0 && !fetchingData" style="height: 150px;"
                    fxLayoutAlign="center center">
                    <span>{{labels.empty_list}}</span>
                </div>

                <div *ngIf="userSelectionTableConfig">
                    <data-table (rowSelectionChange)="handleSelectProfile($event)"
                        [configOptions]="userSelectionTableConfig"></data-table>
                </div>
            </div>
        </div>
    </div>

    <div class="modal_footer" #modal_footer>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" *ngIf="userSelectionTableConfig">
            <button [disabled]="getSelectedNumberOfUsers() === 0" mat-raised-button color="primary"
                (click)="proceedWithAction()">
                {{ data.buttonLabel }}
            </button>
        </div>
    </div>
</app-modal>