<ng-container *ngIf="items && items.length">
  <ng-container *ngIf="isCssIcons">
    <span
      class="app-rate-item"
      [matTooltip]="item.value"
      aria-label="Rate"
      *ngFor="let item of items; let idx = index"
      (click)="onSelect(idx)"
      [ngClass]="{selectedIcon: item.isSelected,unselectedIcon:!item.isSelected  } "
    >
    </span>
  </ng-container>
  <ng-container *ngIf="!isCssIcons">
    <mat-icon
      [matTooltip]="item.value"
      aria-label="Rate"
      color="accent"
      class="app-rate-item"
      (click)="onSelect(idx)"
      *ngFor="let item of items; let idx = index"
    >
      {{item.isSelected?selectedIcon:unselectedIcon}}
    </mat-icon>
  </ng-container>
</ng-container>
