import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService, UtilitiesService, UserPromptsService, UserService } from '@core/services';
import { Address, IArea, User, UserPartner } from '@models/model';

import { take, map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgSub } from 'ng-sub';
import { Observable, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmNewUserComponent } from '../confirm-new-user/confirm-new-user.component';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public labels = this.labelService.defaultProvider();
  public showPartner: boolean;
  public showContactPerson: boolean;
  public showExtra: boolean;
  private sub = new NgSub();
  public socialRelation_lists: { id: number; name: string; }[] = [];

  constructor(
    private dialogRef: MatDialogRef<EditUserComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) protected data: { user: User },
    private labelService: LabelService,
    private utilitiesService: UtilitiesService,
    private userPromptsService: UserPromptsService,
    private userService: UserService,
  ) { }

  async ngOnInit() {
    this.dialogRef.disableClose = true;
    this.labelService.getLabels('app-new-user').then(lbs => this.labels = lbs.data);
    this.labels = (await this.labelService.getLabels('app-social-relations')).data;
    this.socialRelation_lists = await this.labelService.getSocialRelations();
    this.showPartner = this.data.user?.userPartner?.partnerRegistration;
    this.showContactPerson = this.data.user?.userContactPerson?.contactPersonRegistration;
    this.setupForm();
  }

  public closeDialog(): void {
    if (this.form.dirty) {
      this.userPromptsService.confirmPromise(this.labels.confirm_action, this.labels.discard_unsaved_changes_warning).then(sure => {
        if (sure) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  private setupForm(): void {
    const user = this.data.user;

    this.form = this.fb.group({
      usernote: [user.usernote],
      firstname: [user.firstname, [Validators.required]],
      lastname: [user.lastname, [Validators.required]],
      birthday: [user.birthday],
      gender: [user.gender],
      address: this.fb.group({
        city: [user.address?.city],
        number: [user.address?.number, [Validators.min(0)]],
        postalcode: [user.address?.postalcode],
        street: [user.address?.street],
        letter: [user.address?.letter],
        geo: [user?.address?.geo]
      }),
      partnerRegistration: [user?.userPartner?.partnerRegistration],
      contactPersonRegistration: [user?.userContactPerson?.contactPersonRegistration],
      extra: [false],
      userPartner: this.fb.group({
        firstname: [user.userPartner?.firstname],
        lastname: [user.userPartner?.lastname],
        birthday: [user.userPartner?.birthday],
        gender: [user.userPartner?.gender],
        phone: [user.userPartner?.phone],
        remark: [user.userPartner?.remark],
        email: [user.userPartner?.email],
      }),
      userContactPerson: this.fb.group({
        firstName: [user?.userContactPerson?.firstName],
        lastName: [user?.userContactPerson?.lastName],
        phone: [user?.userContactPerson?.phone],
        remark: [user?.userContactPerson?.remark],
        email: [user?.userContactPerson?.email],
        socialRelationKind: [user?.userContactPerson?.socialRelationKind],
      }), 
      userContactPerson2: this.fb.group({
        firstName: [user?.userContactPerson2?.firstName],
        lastName: [user?.userContactPerson2?.lastName],
        phone: [user?.userContactPerson2?.phone],
        remark: [user?.userContactPerson2?.remark],
        email: [user?.userContactPerson2?.email],
        socialRelationKind: [user?.userContactPerson2?.socialRelationKind],
      }),
      decedent: [user.decedent],
      termsAccepted: [user.termsAccepted],
    });

    this.listenToFormChanges();
  }

  public updatePhoneField(form: FormGroup, field: string, value: { phone: string; valid: boolean; }) {
    const ctrl = form.get(field);
    ctrl.setValue(value.phone);
    ctrl.setErrors(value.valid ? null : { invalid: true });

    form.markAsDirty();
  }

  private formValueChanges(field?: string, duration?: number): Observable<any> {
    const ctrl = field ? this.form.get(field) : this.form;

    return ctrl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(duration || 100)
    );
  }

  private listenToFormChanges() {
    this.formValueChanges('firstname').subscribe((data: string) => {
      const val = this.utilitiesService.capitalizeFirstLetter(data, true);

      if (val) {
        this.form.get('firstname').setValue(val);
      }
    });

    this.formValueChanges('gender').subscribe((data: string) => {
      if (data) {
        if (!this.form.get('firstname').value) {
          this.form.get('firstname').setValue(this.labels[`title_${data}`]);
        }
      }
    });

    this.formValueChanges('lastname').subscribe((data: string) => {
      const val = this.utilitiesService.capitalizeFirstLetter(data, true);

      if (val) {
        this.form.get('lastname').setValue(val);
      }
    });

    this.formValueChanges(null, 300).subscribe(() => {
      this.form.markAsDirty();
    });

    this.formValueChanges('partnerRegistration').subscribe(data => {
      this.showPartner = data;
    });

    this.formValueChanges('userPartner.firstname').subscribe((data: string) => {
      const val = this.utilitiesService.capitalizeFirstLetter(data, true);

      if (val) {
        this.form.get('userPartner.firstname').setValue(val);
      }
    });

    this.formValueChanges('userPartner.lastname').subscribe((data: string) => {
      const val = this.utilitiesService.capitalizeFirstLetter(data, true);

      if (val) {
        this.form.get('userPartner.lastname').setValue(val);
      }
    });

    this.formValueChanges('contactPersonRegistration').subscribe(value => {
      this.showContactPerson = value;
    });

    this.formValueChanges('userContactPerson.firstName').subscribe((data: string) => {
      const val = this.utilitiesService.capitalizeFirstLetter(data, true);

      if (val) {
        this.form.get('userContactPerson.firstName').setValue(val);
      }
    });
    this.formValueChanges('userContactPerson2.firstName').subscribe((data: string) => {
      const val = this.utilitiesService.capitalizeFirstLetter(data, true);

      if (val) {
        this.form.get('userContactPerson2.firstName').setValue(val);
      }
    });

    this.formValueChanges('userContactPerson.lastName').subscribe((data: string) => {
      const val = this.utilitiesService.capitalizeFirstLetter(data, true);

      if (val) {
        this.form.get('userContactPerson.lastName').setValue(val);
      }
    });

    this.formValueChanges('userContactPerson2.lastName').subscribe((data: string) => {
      const val = this.utilitiesService.capitalizeFirstLetter(data, true);

      if (val) {
        this.form.get('userContactPerson2.lastName').setValue(val);
      }
    });

    this.formValueChanges('extra').subscribe(data => {
      this.showExtra = data;
    });
  }

  private setFormValues(payload: { address: Address; area: IArea; }) {
    this.data.user.area = payload.area
    this.form.patchValue({
      address: {
        city: payload.address.city,
        street: payload.address.street,
        postalcode: payload.address.postalcode,
        number: payload.address.number,
        letter: payload.address.letter,
        geo: payload.address.geo,
        mailLines: payload.address.mailLines
      } as Partial<Address>
    });
  }

  public checkUserDuplicatesByAddress(payload: { address: Address; area: IArea; }): void {
    this.utilitiesService.delay(500)
      .pipe(
        takeUntil(this.sub),
      ).subscribe(async () => {
        if (payload.address.postalcode && payload.address.postalcode.length === 6 && payload.address.number > 0) {
          this.userService.getUsersMatchingAddress(payload.address.postalcode, payload.address.number)
            .pipe(take(1)).subscribe(users => {
              if (users.length > 0) {
                this.userPromptsService.showDialogue(ConfirmNewUserComponent, {
                  users, address: payload.address
                }, null, null, { width: '400px' });
              }
              this.setFormValues(payload);
            });
        }
      });
  }

  public checkUserDuplicatesByNameOrEmail(isEmailCheck?: boolean): void {
    this.utilitiesService.delay(500)
      .pipe(
        takeUntil(this.sub),
      ).subscribe(() => {
        const firstname = this.form.value.firstname;
        const lastname = this.form.value.lastname;

        let obs: Observable<User[]>;

        if (isEmailCheck && this.form.value.email) {
          obs = from(this.userService.getUserByEmail(this.form.value.email)).pipe(
            map(user => [user]),
            filter(user => !!user[0])
          );
        }

        if (!isEmailCheck && firstname && lastname) {
          obs = this.userService.getUsersMatchingName(firstname, lastname);
        }

        if (obs) {
          obs.pipe(take(1)).subscribe(users => {
            if (users.length > 0) {
              this.userPromptsService.showDialogue(ConfirmNewUserComponent, {
                users, firstname, lastname,
                email: isEmailCheck ? this.form.value.email : null
              }, null, null, { width: '400px' });
            }
          });
        }
      });
  }

  public async saveUser() {
    const model = this.form.value;
    const user: User = Object.assign({}, this.data.user, model);
    user.userPartner.partnerRegistration = model.partnerRegistration;
    user.userContactPerson.contactPersonRegistration = model.contactPersonRegistration;
    delete (user as any).partnerRegistration;
    delete (user as any).contactPersonRegistration;
    if (!this.form.controls.partnerRegistration.value) user.userPartner = this.checkUserPartner();
    // check duplicate email address
    const userWithEmail: User = user.email
      ? await this.userService.getUserByEmail(user.email)
      : null;
    if (user?.decedent) {
      user.roles = {
        isCoordinator: false,
        isProfessional: false,
        isExecutor: false,
        services: {},
        workingAreas: {},
        institutions: {},
        isInstitutionContact: false,
        isCustomer: false
      };
      user.moduleReservations = { coordinator: false, user: false };
    }
    if (userWithEmail && user.id !== userWithEmail.id) {
      this.userPromptsService.showToast(this.labels.user_email_duplicate_error);
    } else {
      await this.userService.updateUser(user, null);
      this.dialogRef.close(user);
    }
  }

  private checkUserPartner() {
    return {
      firstname: '',
      lastname: '',
      birthday: '',
      gender: ''
    } as UserPartner
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
