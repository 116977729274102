<app-modal [alignFooterStart]="relation.log">
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{relation.log ? labels.update_social_network : labels.create_social_network}}
        </span>
    </div>

    <div class="modal_body" #modal_body>
        <div style="padding: 1em;" fxLayout="column" fxLayoutGap="20px">
            <form [formGroup]="form" fxLayout="column" fxLayoutGap="10px">
                <mat-form-field appearance="outline" fxFlex="stretch">
                    <mat-label>{{labels.social_kind}}</mat-label>
                    <mat-select matInput type="text"
                        formControlName="socialRelationKind">
                        <mat-option *ngFor="let option of socialRelation_lists"
                            [value]="option.id">
                            {{option.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div fxFlex="stretch">
                    <app-options-list fxFlex="stretch" [subtitleConstructor]="getUserSubtitleConstruct()"
                        [defaultValue]="getSelectedRelationName()" (selectOption)="addRelation($event)"
                        [optionsRef]="usersOptionsRef" [excluded]="[customer]" [placeholder]="labels.person"
                        [displayProperties]="['firstname', 'lastname']">
                    </app-options-list>
                </div>

                <mat-form-field appearance="outline" fxFlex="stretch">
                    <mat-label>{{labels.note}}</mat-label>
                    <textarea cdkTextareaAutosize cdkAutosizeMinRows maxlength="noteMaxLength" minRows="3" matInput type="text"
                         formControlName="note"></textarea>
                    <mat-hint [align]="'end'">{{form.value.note.length}}/{{noteMaxLength}}</mat-hint>
                </mat-form-field>
            </form>

            <div class="log_text_footer" fxLayout="column" fxLayoutGap="10px" *ngIf="relation.log">
                <span>{{labels.created_by}}: {{createdByUser?.firstname}} {{createdByUser?.lastname}}</span>
                <span>{{labels.created_at}}: {{relation.log.createdAt | date}}</span>
            </div>
        </div>
    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px">
        <div fxFlex="stretch" *ngIf="relation.log" fxLayoutAlign="flex-start">
            <button color="warn" mat-stroked-button (click)="deleteItem()">{{labels.delete}}</button>
        </div>

        <button color="primary" mat-raised-button [disabled]="form.invalid || form.pristine"
            (click)="save()">{{relation.log ? labels.update : labels.create}}</button>
    </div>
</app-modal>