<form [formGroup]="form">
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>{{labels.expiry_value}}</mat-label>
    <input type="number" matInput class="form-control" formControlName="value">
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width">
    <mat-select formControlName="timeframe">
      <mat-option *ngFor="let v of timeframes" [value]="v.id">
        {{v.text}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>