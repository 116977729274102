import { Injectable } from '@angular/core';
import {
    GroupActivityTemplate, GroupActivity,
    Group, GeneratedGroupActivityModel, GroupMember,
    GroupActivityTemplateFrequency, DurationTypes,
    GroupActivityTemplateFrequencyTypes,
    GroupActivityMember
} from 'app/shared/model';
import { IObjectMap } from 'app/shared/interface';
import { UtilitiesService } from './utilities.service';
import { getISOWeek, getYear, getMonth, getDayOfYear, isBefore, isEqual, addDays } from 'date-fns';
import { find, findIndex, cloneDeep } from 'lodash';
import { FirestoreService } from './firestore.service';
import { GroupActivityService } from './group-activity.service';

interface GenerateGroupActivityOption {
    template: GroupActivityTemplate;
    group: Group;
    dateItem: Date;
    executors: IObjectMap<GroupActivityMember>;
}

@Injectable({
    providedIn: 'root',
})
export class GroupActivitiesGeneratorService {
    constructor(
        private utilitiesService: UtilitiesService,
        private afDB: FirestoreService,
        private groupActivityService: GroupActivityService
    ) { }

    private getExecutorsInGroupActivity(executors: GroupMember[], selectedIds: string[]): IObjectMap<GroupActivityMember> {
        const members: IObjectMap<GroupMember> = {};

        selectedIds.forEach(executorId => {
            const executor = find<GroupMember>(executors, { id: executorId });
            members[executorId] = executor;
        });

        return this.groupActivityService.groupMembersToActivityMembers(members);
    }

    public getTemplateEndtime(template: GroupActivityTemplate): string {
        let [hours, minutes] = template.startTime.split(':').map(item => parseInt(item, 10));

        switch (template.durationInterval) {
            case DurationTypes.minutes: {
                minutes += template.duration;
                break;
            }

            case DurationTypes.days: {
                hours += (24 * template.duration);
                break;
            }

            default: {
                hours += template.duration;
                break;
            }
        }

        return `${hours}:${minutes}`;
    }

    private generateGroupActivity(config: GenerateGroupActivityOption): GeneratedGroupActivityModel {
        const startTime = this.utilitiesService.updateTimeForDate(config.dateItem, config.template.startTime);
        const endTime = this.utilitiesService.updateTimeForDate(startTime, this.getTemplateEndtime(config.template));

        const generatedGroupActivity: GroupActivity = {
            name: config.template.name,
            workingAreaId: config.group.workingAreaId,
            serviceId: config.group.serviceId,
            groupId: config.group.id,
            location: config.template.location,
            week: getISOWeek(startTime),
            weekDay: startTime.getDay(),
            year: getYear(startTime),
            month: getMonth(startTime) + 1,
            dayOfYear: getDayOfYear(startTime),
            description: config.template.description,
            tags: config.template.tags,
            executors: config.executors,
            participants: {},
            startDate: startTime,
            endDate: endTime,
            completed: false
        };

        return {
            groupActivity: generatedGroupActivity,
            date: config.dateItem
        };
    }

    private getNextDateForGroupActivity(template: GroupActivityTemplate, prevDate: Date): Date {
        const weekDays: number[] = template.weekDays.sort();

        let daysInterval = 7; // every week
        if (template.weekFrequency === GroupActivityTemplateFrequency.every_two_weeks) {
            daysInterval = 14;
        }

        // get distance in days from previous according to selected weekdays in template
        const currentWeekDay: number = prevDate.getDay();
        const currentIndex = findIndex(weekDays, d => d === currentWeekDay);
        let distanceIndays = 0;

        if (currentWeekDay === weekDays[weekDays.length - 1]) {
            // get distance of week day from previous if over the weekend
            // daysToSkip takes us to the beginning of the next valid week
            const daysToSkip = daysInterval - currentWeekDay;
            distanceIndays = daysToSkip + weekDays[0];
        } else {
            // just distance from former to next
            distanceIndays = weekDays[currentIndex + 1] - currentWeekDay;
        }

        return addDays(prevDate, distanceIndays);
    }

    private canCreateNextGroupActivityDate(newDate: Date, dateItems: Date[], template: GroupActivityTemplate): boolean {
        switch (template.repeatEnd) {
            case GroupActivityTemplateFrequencyTypes.number_of_times: {
                return dateItems.length < template.repeatValueNumber;
            }

            case GroupActivityTemplateFrequencyTypes.until_date: {
                return isBefore(newDate, template.repeatValueDate)
                    || isEqual(newDate, template.repeatValueDate);
            }

            case GroupActivityTemplateFrequencyTypes.repeat_forever: {
                console.log('This has not be discussed');
                return false;
            }

            default: {
                return false;
            }
        }
    }

    private generateGroupActivityDates(template: GroupActivityTemplate): Date[] {
        const dateItems: Date[] = [];

        if (template.weekFrequency === GroupActivityTemplateFrequency.once) {
            return [template.startDate];
        } else {
            while (true) {
                let prevDate: Date = template.startDate;
                if (dateItems.length > 0) {
                    prevDate = dateItems[dateItems.length - 1];
                }

                const nextDate = this.getNextDateForGroupActivity(template, prevDate);
                if (this.canCreateNextGroupActivityDate(nextDate, dateItems, template)) {
                    dateItems.push(nextDate);
                } else {
                    break;
                }
            }

            return dateItems;
        }
    }

    private updateParticipantsForGroupActivities(groupActivities: GroupActivity[], members: IObjectMap<GroupMember>): GroupActivity[] {
        const groupActivityMembers = this.groupActivityService.groupMembersToActivityMembers(members);

        return groupActivities.map(groupActivity => {
            groupActivity.participants = cloneDeep(groupActivityMembers);
            return groupActivity;
        });
    }

    public saveGroupActivities(groupActivityItems: GeneratedGroupActivityModel[], members: IObjectMap<GroupMember>): Promise<any> {
        return new Promise<void>(async (resolve, reject) => {
            const groupActivities: GroupActivity[] = groupActivityItems.map(item => {
                item.groupActivity.id = this.afDB.getNewId();

                return item.groupActivity;
            });

            if (groupActivities.length === 0) {
                reject();
            } else {
                const groupActivitiesToSave = this.updateParticipantsForGroupActivities(groupActivities, members);
                await this.groupActivityService.batchSetGroupActivities(groupActivitiesToSave);
                resolve();
            }
        });
    }

    public createGroupActivities(
        template: GroupActivityTemplate, executors: GroupMember[], group: Group
    ): GeneratedGroupActivityModel[] {
        const groupActivitiesData: GeneratedGroupActivityModel[] = [];
        const executorsInGroupActivity = this.getExecutorsInGroupActivity(executors, template.executors);

        // loop through template to generate each groupActivity date here
        const generatedDates: Date[] = this.generateGroupActivityDates(template);

        generatedDates.forEach(dateItem => {
            const model: GeneratedGroupActivityModel = this.generateGroupActivity({
                group,
                template,
                dateItem: dateItem,
                executors: executorsInGroupActivity
            });

            groupActivitiesData.push(model);
        });

        return groupActivitiesData;
    }
}
