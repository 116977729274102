import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DocPipe, ServicePipe } from './pipes';
import { Mat_LOADERS } from './md-loaders';
import {
  DataTableComponent,
  OptionsListComponent,
  ThumbnailComponent,
  ModalComponent,
  ProgressComponent,
  ToolbarSearchComponent,
  CustomerDetailsComponent,
  CustomerNotesComponent,
  PersonalDetailsComponent,
  CustomFormGroupComponent,
  CustomFormGroupPrintComponent
} from './components';
import {
  OrganizationListDialog,
  RolesDialogComponent,
  CustomFormComponent,
  DocInfoComponent,
  NoteFormComponent,
  FeedbackComponent,
  ActivityDialogComponent,
  ActivityItemDialogFormComponent,
  ImageHandlerModalComponent,
  BottomSheetActionListComponent,
  UsersSelectorComponent,
  PromptDialogueComponent,
  ConfirmDialogueComponent,
  AlertDialogueComponent,
  SnackbarComponent
} from './entry-components';
import {
  ImportUsersComponent,
  ExportUsersComponent
} from '@users/components';
import { ExportRequestsComponent } from 'app/requests-module/components';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UiImageLoaderDirective, ScrollBottomDirective } from './directives';
import { RateComponent } from './components/rate/rate.component';
import { CustomerNetworkComponent } from './components/customer-network/customer-network.component';
import { NetworkPartnerModalComponent } from './entry-components/network-partner-modal/network-partner-modal.component';
import { NetworkInstitutionModalComponent } from './entry-components/network-institutions-modal/network-institutions-modal.component';
import { SocialRelationsModalComponent } from './entry-components/social-relations-modal/social-relations-modal.component';
import { EmailDialogueComponent } from './entry-components/email-dialogue/email-dialogue.component';
import { PrintRequestComponent } from './components/print-request/print-request.component';
import { ServiceCatalogCustomerFormComponent } from 'app/service-registration/service-registration-module/components';
import { ArraySortPipe } from './pipes/sort.pipe';
import { ExpiryItemFieldComponent } from './components/expiry-item-field/expiry-item-field.component';
import { EditExpiryDateComponent } from './entry-components/edit-expiry-date/edit-expiry-date.component';
import { EmptyListComponent } from './components/empty-list/empty-list.component';
import { FindCompanyRequestComponent } from './components/find-company-request/find-company-request.component';
import { AvailabilitySelectorComponent } from './entry-components/availability-selector/availability-selector.component';
import { PhoneFieldComponent } from './components/phone-field/phone-field.component';
import { ExecutorTeamComponent } from '@users/components/executor-team/executor-team.component';
import { ChecklistModalComponent } from './entry-components/checklist-modal/checklist-modal.component';
import { EditAppointmentModalComponent } from './entry-components/edit-appointment-modal/edit-appointment-modal.component';
import { UserInventoryLoanComponent } from './entry-components/user-inventory-loan/user-inventory-loan.component';
import { InventoryLoanHistoryListModalComponent } from './entry-components/inventory-loan-history-list-modal/inventory-loan-history-list-modal.component';
import { SearchPersonComponent } from './entry-components/search-person/search-person.component';
import { PublishRequestModalComponent } from './entry-components/publish-request-modal/publish-request-modal.component';
import { RequestReactionsComponent } from './components/request-reactions/request-reactions.component';
import { LogInfoComponent } from './entry-components/log-info/log-info.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { NewRequestFormComponent } from './entry-components/new-request-form/new-request-form.component';
import { PersonTabComponent } from './components/person-tab/person-tab.component';
import { ImagePreviewComponent } from './entry-components/image-preview/image-preview.component';
import { CustomerTabComponent } from './components/customer-tab/customer-tab.component';
import { CreationDialogComponent } from './components/creation-dialog/creation-dialog.component';
import { DocumentsModelComponent } from './components/documents-model/documents-model.component';
import { ToastComponent } from './components/toast/toast.component';
import { UsageDisplayModelComponent } from './components/usage-display-model/usage-display-model.component';
import { ImageViewComponent } from './components/image-view/image-view.component';
import { EmailFooterComponent } from './entry-components/email-footer/email-footer.component';
import { EmailPreviewComponent } from './entry-components/email-preview/email-preview.component';
import { AddressComponent } from './components/address/address.component';
import { NgxMaskModule } from 'ngx-mask';
import { MoveActivityComponent } from './components/move-activity/move-activity.component';
import { RepeatDialogComponent } from './components/repeat-dialog/repeat-dialog.component';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { AddressModalOpenerComponent } from './components/address-modal-opener/address-modal-opener.component';
import { ContactPersonDetailsDialogComponent } from './components/contact-person-details-dialog/contact-person-details-dialog.component';
import { SaveActivityComponent } from './components/save-activity/save-activity.component';
import { CustomerOpenRequestsComponent } from './components/customer-open-requests/customer-open-requests.component';
import { ViewUserComponent } from './components/view-user/view-user.component';
import { VisibleForDialogComponent } from './entry-components/activity-item-dialog-form/visible-for-dialog/visible-for-dialog.component';
import { RequestUsersInfoDialogComponent } from './components/request-users-info-dialog/request-users-info-dialog.component';
import { CardActionButtonComponent } from './components/card-action-button/card-action-button.component';
import { MapDialogComponent } from './components/map-dialog/map-dialog.component';
import { UserRequestsComponent } from './components/user-requests/user-requests.component';

@NgModule({
  declarations: [
    DocPipe,
    ArraySortPipe,
    ServicePipe,
    OptionsListComponent,
    DataTableComponent,
    ThumbnailComponent,
    OrganizationListDialog,
    RolesDialogComponent,
    CustomFormComponent,
    DocInfoComponent,
    NoteFormComponent,
    FeedbackComponent,
    PromptDialogueComponent,
    ConfirmDialogueComponent,
    AlertDialogueComponent,
    SnackbarComponent,
    EmailDialogueComponent,
    ViewUserComponent,
    ExecutorTeamComponent,
    ImportUsersComponent,
    ExportUsersComponent,
    ToolbarSearchComponent,
    ExportRequestsComponent,
    ModalComponent,
    CustomerDetailsComponent,
    PersonalDetailsComponent,
    CustomerNotesComponent,
    UiImageLoaderDirective,
    ScrollBottomDirective,
    ActivityDialogComponent,
    ActivityItemDialogFormComponent,
    ImageHandlerModalComponent,
    ProgressComponent,
    CustomerNetworkComponent,
    NetworkPartnerModalComponent,
    NetworkInstitutionModalComponent,
    SocialRelationsModalComponent,
    RateComponent,
    BottomSheetActionListComponent,
    UsersSelectorComponent,
    CustomFormGroupComponent,
    CustomFormGroupPrintComponent,
    PrintRequestComponent,
    ServiceCatalogCustomerFormComponent,
    ExpiryItemFieldComponent,
    EditExpiryDateComponent,
    EmptyListComponent,
    FindCompanyRequestComponent,
    AvailabilitySelectorComponent,
    PhoneFieldComponent,
    ChecklistModalComponent,
    EditAppointmentModalComponent,
    UserInventoryLoanComponent,
    InventoryLoanHistoryListModalComponent,
    SearchPersonComponent,
    PublishRequestModalComponent,
    RequestReactionsComponent,
    LogInfoComponent,
    PhoneInputComponent,
    NewRequestFormComponent,
    PersonTabComponent,
    ImagePreviewComponent,
    CustomerTabComponent,
    CreationDialogComponent,
    DocumentsModelComponent,
    ToastComponent,
    UsageDisplayModelComponent,
    ImageViewComponent,
    EmailFooterComponent,
    EmailPreviewComponent,
    AddressComponent,
    MoveActivityComponent,
    RepeatDialogComponent,
    AddressModalComponent,
    AddressModalOpenerComponent,
    ContactPersonDetailsDialogComponent,
    SaveActivityComponent,
    CustomerOpenRequestsComponent,
    VisibleForDialogComponent,
    RequestUsersInfoDialogComponent,
    CardActionButtonComponent,
    MapDialogComponent,
    UserRequestsComponent
  ],
  exports: [
    FormsModule,
    BottomSheetActionListComponent,
    ReactiveFormsModule,
    CommonModule,
    ExpiryItemFieldComponent,
    ImageHandlerModalComponent,
    RouterModule,
    Mat_LOADERS.IMPORTS,
    DocPipe,
    ArraySortPipe,
    PrintRequestComponent,
    ServicePipe,
    OptionsListComponent,
    DataTableComponent,
    ThumbnailComponent,
    PhoneInputComponent,
    OrganizationListDialog,
    RolesDialogComponent,
    PromptDialogueComponent,
    ConfirmDialogueComponent,
    AlertDialogueComponent,
    SnackbarComponent,
    CustomFormComponent,
    DocInfoComponent,
    NoteFormComponent,
    FeedbackComponent,
    ToolbarSearchComponent,
    ViewUserComponent,
    ChecklistModalComponent,
    ExecutorTeamComponent,
    ImportUsersComponent,
    ExportUsersComponent,
    ExportRequestsComponent,
    ModalComponent,
    UiImageLoaderDirective,
    ScrollBottomDirective,
    ActivityDialogComponent,
    ActivityItemDialogFormComponent,
    ProgressComponent,
    CustomerNetworkComponent,
    NetworkPartnerModalComponent,
    NetworkInstitutionModalComponent,
    SocialRelationsModalComponent,
    RateComponent,
    UsersSelectorComponent,
    CustomFormGroupComponent,
    EmailDialogueComponent,
    CustomFormGroupPrintComponent,
    ServiceCatalogCustomerFormComponent,
    ExpiryItemFieldComponent,
    EmptyListComponent,
    PhoneFieldComponent,
    PersonTabComponent,
    CustomerTabComponent,
    DocumentsModelComponent,
    FindCompanyRequestComponent,
    AddressComponent,
    AddressModalComponent,
    AddressModalOpenerComponent,
    SaveActivityComponent,
    CustomerOpenRequestsComponent,
    RequestUsersInfoDialogComponent,
    CardActionButtonComponent,
    MapDialogComponent,
    UserRequestsComponent
  ],
  imports: [
    Mat_LOADERS.IMPORTS,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    ImageCropperModule,
    NgxMaskModule.forRoot(),
  ],
  providers: []
})
export class SharedModule {
  constructor() { }
}
