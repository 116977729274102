<form class="user-edit-form" [formGroup]="form">
    <div class="form-row">
        <mat-form-field appearance="outline" *ngIf="!csr">
            <mat-label>{{labels.title}}</mat-label>
            <input [readonly]="csr" matInput required minlength="2" formControlName="title">
        </mat-form-field>
    </div>

    <div class="form-row">
        <mat-form-field appearance="outline">
            <mat-label>{{labels.firstname}}</mat-label>
            <input matInput required minlength="2" formControlName="firstname">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{labels.lastname}}</mat-label>
            <input matInput required formControlName="lastname" minlength="2">
        </mat-form-field>
    </div>

    <div class="form-row">
        <mat-form-field appearance="outline" class="gender-select">
            <mat-label>{{labels.gender}}</mat-label>
            <mat-select formControlName="gender" required>
                <mat-option value="male" selected>{{labels.male}}</mat-option>
                <mat-option value="female">{{labels.female}}</mat-option>
                <mat-option value="unkown">{{labels.unkown}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{labels.birthday}}</mat-label>
            <input type="date" formControlName="birthday" matInput>
        </mat-form-field>
    </div>

    <div formGroupName="address">
        <div class="form-row">
            <mat-form-field appearance="outline">
                <mat-label>{{labels.postalcode}}</mat-label>
                <input matInput formControlName="postalcode" pattern="[0-9]{4}[A-Za-z]{2}" mask="0000SS">
            </mat-form-field>

            <mat-form-field appearance="outline" class="small">
                <mat-label>{{labels.housenumber}}</mat-label>
                <input type="number" matInput formControlName="number" (change)="fetchAddressByPostalCode()">
            </mat-form-field>
            <mat-form-field appearance="outline" class="small">
                <mat-label>{{labels.letter}}</mat-label>
                <mat-select #letter formControlName="letter">
                    <mat-option *ngFor="let letter of letters" [value]="letter"
                        (onSelectionChange)="selectLetter(letter)">
                        {{letter}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="form-row">
            <mat-form-field appearance="outline">
                <mat-label>{{labels.address}}</mat-label>
                <input matInput readonly formControlName="street">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{labels.city}}</mat-label>
                <input matInput readonly formControlName="city">
            </mat-form-field>
        </div>
    </div>

    <div class="form-row">
        <mat-form-field appearance="outline">
            <mat-label>{{labels.phone}}</mat-label>
            <input #phoneNumber required matInput formControlName="phone">
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="csr">
            <mat-label>{{labels.email}}</mat-label>
            <input matInput required formControlName="email" type="email">
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="!csr">
            <mat-label>{{labels.phone2}}</mat-label>
            <input #phoneNumber2 matInput formControlName="phone2">
        </mat-form-field>
    </div>

    <div class="form-row" *ngIf="!csr">
        <mat-form-field appearance="outline">
            <mat-label>{{labels.email}}</mat-label>
            <input matInput formControlName="email" type="email">
        </mat-form-field>
    </div>
</form>