<form [formGroup]="form" fxLayout="column" role="form" *ngIf="form">
    <div *ngFor="let group of customForm?.groups">
        <h4>{{group.groupLabel}}</h4>

        <div class="groups" fxLayout="row" fxLayoutAlign="space-between center">
            <div *ngFor="let field of mappedFields[group.groupName]" fxFlex="{{getFieldWidth(field)}}">
                <ng-container *ngIf="!field.hidden">
                    <div class="d-print-block print-label"
                        *ngIf="field && (field.type !== 'radio' && field.type !== 'checkbox')">
                        {{getFieldLabel(field)}}
                    </div>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'text'">
                        <mat-label>{{field.label}}</mat-label>
                        <input type="text"matInput [formControlName]="field.fieldName"
                            [required]="getIsRequiredField(field)" />
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="field.type === 'date'">
                        <mat-label>{{field.label}}</mat-label>
                        <input type="date"matInput [formControlName]="field.fieldName"
                            [required]="getIsRequiredField(field)" />
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="field.type === 'number'">
                        <mat-label>{{field.label}}</mat-label>
                        <input type="number"matInput [formControlName]="field.fieldName"
                            [required]="getIsRequiredField(field)" />
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="field.type === 'select'">
                        <mat-label>{{field.label}}</mat-label>
                        <mat-select [formControlName]="field.fieldName"
                            [required]="getIsRequiredField(field)">
                            <mat-option *ngFor="let value of field.values" [value]="value">{{value}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="field.type === 'multiple'">
                        <mat-label>{{field.label}}</mat-label>
                        <mat-select [formControlName]="field.fieldName" multiple
                            [required]="getIsRequiredField(field)">
                            <mat-option *ngFor="let value of field.values" [value]="value">{{value}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="field.type === 'textarea'">
                        <div class="d-print-block print-text">{{form.value[field.fieldName]}}</div>
                        <mat-form-field appearance="outline" class="d-print-none">
                            <mat-label>{{field.label}}</mat-label>
                            <textarea cdkTextareaAutosize cdkAutosizeMinRows matInput [formControlName]="field.fieldName"
                            [required]="field.required"></textarea>
                        </mat-form-field>
                    </ng-container>

                    <div *ngIf="field.type === 'checkbox'" class="checkbox">
                        <mat-checkbox color="primary" [formControlName]="field.fieldName" [required]="getIsRequiredField(field)">
                            {{field.label}} <span class="text-primary">{{getIsRequiredField(field)? '*' : ''}}</span>
                        </mat-checkbox>
                    </div>

                    <div *ngIf="field.type === 'radio'" class="radio" fxLayoutGap="10px" fxLayout="row">
                        <mat-label>
                            {{field.label}}
                            <span class="text-primary">{{getIsRequiredField(field)?' * :':':'}}</span>
                        </mat-label>
                        <mat-radio-group [formControlName]="field.fieldName" [required]="getIsRequiredField(field)">
                            <mat-radio-button *ngFor="let value of field.values" [value]="value">{{value}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</form>