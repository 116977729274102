
import { distinctUntilChanged, debounceTime, take } from 'rxjs/operators';
import { Component, DoCheck, EventEmitter, Input, IterableDiffers, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchService, UtilitiesService } from 'app/core';
import { Municipality, User } from '@shared/model';
import { findIndex } from 'lodash';

@Component({
    selector: 'app-options-list',
    templateUrl: './options-list.component.html',
    styleUrls: ['./options-list.component.scss']
})
export class OptionsListComponent implements OnInit, DoCheck, OnChanges {
    public myControl: FormControl = new FormControl();

    @Input() public hidePhoto: boolean;
    @Input() public subtitleConstructor: Function;
    @Input() public placeholder: string;
    @Input() public resetInputOnSelect: boolean;
    @Input() public defaultValue: string;
    @Input() public optionsArr: any[];
    @Input() public optionsRef: string;
    @Input() public excluded: any[];
    @Input() public displayProperties: string[];
    @Input() public displayRequestsCount: string[];
    @Input() public distanceTo: User;
    @Input() public disabled: boolean;
    @Input() resetListOnSelect: boolean;
    @Input() optionsFn: (search: string) => Promise<void>;
    @Input() displayChips: Municipality[];
    @Output() public selectOption = new EventEmitter();

    public options: any[];
    public filteredOptions: string[];
    public canClearText = false;

    private iterableDiffer: any;

    constructor(
        private _iterableDiffers: IterableDiffers,
        private searchService: SearchService,
        private utilitiesService: UtilitiesService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.defaultValue) {
            if (this.isNewChange(changes.defaultValue)) {
                this.myControl.setValue(changes.defaultValue.currentValue);
            }
        }

        // sets enable/disable property of form depending on any change that result in disabled true
        if (this.disabled) {
            this.myControl.disable();
        } else {
            this.myControl.enable();
        }
    }

    private isNewChange(change: any) {
        const prev = JSON.stringify(change.previousValue);
        const current = JSON.stringify(change.currentValue);

        return prev !== current;
    }

    ngOnInit() {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);

        this.myControl.valueChanges.pipe(
            distinctUntilChanged(),
            debounceTime(300),
        ).subscribe(async val => {
            this.canClearText = !!val;

            if (val) {
                this.filteredOptions = [];

                if (this.optionsRef && val.length >= 2) {
                    this.options = await this.searchService.searchCollectionIndex({
                        searchText: val,
                        ref: this.optionsRef
                    });

                    if (this.excluded && this.excluded.length > 0) {
                        this.excluded.forEach(item => {
                            const index = findIndex(this.options, { id: item.id });
                            if (index >= 0) {
                                this.options.splice(index, 1);
                            }
                        });
                    }
                } else if (this.optionsFn && val.length >= 2) {
                    await this.optionsFn(val);
                    await this.utilitiesService.delay(300).pipe(take(1)).toPromise();
                }

                const options = this.options ? this.options.slice() : [];
                this.filteredOptions = val ? this.filter(val) : options;

                this.filteredOptions = this.filteredOptions.sort((a: any, b: any) => {
                    if (typeof a === 'object') {
                        a = this.getOptionStr(a);
                        b = this.getOptionStr(b);
                    }

                    if (a < b) {
                        return -1;
                    } else if (a > b) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }
        });
    }

    filter(val: string): string[] {
        if (this.options) {
            const filteredData = this.options.filter(option => {
                const optionStr = this.getOptionStr(option);
                return optionStr.toLowerCase().indexOf(this.getOptionStr(val).toLowerCase()) >= 0;
            });

            return filteredData;
        }

        return [];
    }

    getOptionStr(option: any) {
        if (typeof option === 'string') {
            return option;
        }

        let optionStr: string = this.displayProperties ? '' : option;

        if (this.displayProperties) {
            this.displayProperties.forEach(property => {
                optionStr += option[property] + ' ';
            });
        }

        return optionStr.trim();
    }

    ngDoCheck() {
        // check options array for changes
        const changesToOptionsArr = this.iterableDiffer.diff(this.optionsArr);
        if (changesToOptionsArr) {
            this.options = this.optionsArr ? this.optionsArr.slice() : [];
        }

        // check excluded array for channges
        const changesToExcluded = this.iterableDiffer.diff(this.excluded);
        if (changesToExcluded && this.excluded) {
            this.options = this.optionsArr ? this.optionsArr.slice() : [];
            this.excluded.forEach((data) => {
                // we should use the displayProperties as the unique identifier
                let indexInOptions = -1;

                // look for the item in the options array
                this.options.forEach((option, index) => {
                    let isSame = true;

                    if (this.displayProperties) {
                        this.displayProperties.forEach((property) => {
                            if (data[property] !== option[property]) {
                                isSame = false;
                            }
                        });
                    } else {
                        isSame = data === option;
                    }

                    if (isSame) {
                        indexInOptions = index;
                    }
                });

                if (indexInOptions > -1) {
                    this.options.splice(indexInOptions, 1);
                }
            });
        }
    }

    displayFn(option: any): string {
        return option ? this.getOptionStr(option) : '';
    }

    onSelectOption(_event, option) {
        this.selectOption.emit(option);

        if (this.resetInputOnSelect) {
            this.myControl.reset();
        }

        if (this.resetListOnSelect) {
            this.utilitiesService.delay(300).subscribe(() => {
                this.filteredOptions = [];
            });
        }
    }

    clearSelection() {
        this.myControl.reset();
        this.selectOption.emit();
    }

    getBackgroundImage(user) {
        const photoUrl = user.photoUrl || '/assets/images/drop-images.png';
        return `url('${photoUrl}')`;
    }

    hadleBlurEvent() {
        const text = this.myControl.value;
        let isExact = false;

        if (text) {
            for (const option of this.filteredOptions) {
                const strVal = this.getOptionStr(option);
                if (!isExact) {
                    isExact = strVal === text;
                }

                if (isExact) {
                    this.onSelectOption(null, option);
                }
            }
        }

        if ((!isExact || !text) && !this.resetListOnSelect) {
            this.clearSelection();
        }
    }

    public getDistanceTo(user: User): string {
        if (this.distanceTo && this.distanceTo.address && this.distanceTo.address.geo && user.address && user.address.geo) {

            return this.utilitiesService.distance(
                user.address.geo['longitude'],
                user.address.geo['latitude'],
                this.distanceTo.address.geo['longitude'],
                this.distanceTo.address.geo['latitude'],
                'K'
            );
        }

        return null;
    }

    remove(municipality: Municipality): void {
        const index = this.displayChips.indexOf(municipality);
        if (index >= 0) {
            this.displayChips.splice(index, 1);
            this.selectOption.emit(this.displayChips);
        }
    }
}
