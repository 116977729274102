import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LocalStorageService, UserService } from '@core/services';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    public constructor(
        private auth: AuthService,
        private router: Router,
        private localStore: LocalStorageService,
        private userService: UserService,
    ) { }

    public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(async (resolve) => {
            const isLoggedIn = (await this.auth.isLoggedIn());
            const isRole = !!this.localStore.getItemSync('user_role');

            if (!isLoggedIn || !isRole) {
                this.auth.redirectUrl = state.url;

                this.localStore.removeItem('user_role');

                this.router.navigateByUrl('/_/auth');
            } else {
                const user = await this.auth.authState$.pipe(take(1)).toPromise();
                const result = await user.getIdTokenResult();

                if (!Array.isArray(result?.claims?.orgs)) {
                    await this.userService.updateAuthClaims();
                }
            }

            resolve(isLoggedIn && isRole);
        });
    }
}
