import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityService, LabelService, UserPromptsService } from '@core/services';
import { Activity, Request } from '@models/model';
import { IDataTableConfig, ITableData } from '../data-table/data-table.component';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-move-activity',
  templateUrl: './move-activity.component.html',
  styleUrls: ['./move-activity.component.scss']
})
export class MoveActivityComponent implements OnInit {

  protected requests: Request[];
  private activity: Activity;
  public labels = this.labelService.defaultProvider();
  protected userName: string;
  protected dataTableConfig: IDataTableConfig;
  public isLoading = false;
  protected requestFilter: 'all' | 'open' = 'open';
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { requests: Request[]; activity: Activity; customerName: string },
    private labelService: LabelService,
    private activityService: ActivityService,
    private userPromptService: UserPromptsService
  ) { }
  
  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-move-activity')).data;
    this.requests = this.data.requests;
    this.activity = cloneDeep(this.data.activity)
    delete this.activity['__doc'];
    this.userName = this.data.customerName.trim();
    this.filterCustomerRequest();
  }

  protected filterCustomerRequest() {
    if (this.requestFilter == 'open') {
     return this.getTableConfig(this.requests.filter(request => !request.isClosed));
    }
    this.getTableConfig(this.requests);
  }

  private getTableConfig(requests: Request[]) {
    const config: IDataTableConfig = {
      data: requests.map(req => {
        return {
          _statusValue: { val: req.status, /* org: this.org*/ },
          _metadata: {
            originalData: req,
            disabled: req.id == this.activity.requestId
          },
          title: req.title,
        };
      }),
      displayProperties: ['status-row', 'title'],
      rowOptions: [],
      allowSelection: false,
      displayHeaders: false,
    };
    this.dataTableConfig = config;
  }

  protected handleRowSelect(data: ITableData) {
    this.isLoading = true;
    const request = data._metadata.originalData as Request;
    this.activity.requestId = request.id;
    this.activityService.updateActivity(this.activity)
      .then(() => {
        this.filterCustomerRequest();
        this.isLoading = false;
        this.userPromptService.showToast(`${this.labels.activity_moved_to} ${request.title}`);
      })
      .catch(() => {
        this.isLoading = false;
      });
  }
}
