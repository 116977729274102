import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService, OrganizationService, UsageService, UserPromptsService, UserService, UtilitiesService } from '@core/services';
import { Address, IArea, IMailinglistItem, Organization, UsageActionTypes, User } from '@models/model';
import { NgSub } from 'ng-sub';
import { Observable, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { ConfirmNewUserComponent } from '../confirm-new-user/confirm-new-user.component';
import { MailinglistService } from '@core/services/mailinglist/mailinglist.service';


@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})
export class NewUserComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public labels = this.labelService.defaultProvider();
  public loading = false;
  private sub = new NgSub();
  public isMobile: boolean;
  protected org: Organization;
  public mailinglists: IMailinglistItem[];
  public loggedInUser: User;
  private stillCheckDuplicate = true;

  constructor(
    private dialogRef: MatDialogRef<NewUserComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) protected data: { user: User; },
    private labelService: LabelService,
    private utilitiesService: UtilitiesService,
    private userPromptsService: UserPromptsService,
    private usageService: UsageService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private mailinglistService: MailinglistService,
  ) { }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.labelService.getLabels('app-new-user').then(lbs => this.labels = lbs.data);
    this.setupForm();
    this.getMailingList();
    this.userService.getCurrentUser().pipe(take(1)).toPromise()
      .then(user => this.loggedInUser = user);
    this.sub.add(
      this.userPromptsService.activeMediaQuery$.subscribe(media => {
        this.isMobile = media === 'xs';
      }),
      this.organizationService.getCurrentOrganization().subscribe(org => {
        this.org = org;
      })
    )
  }

  public closeDialog(): void {
    if (this.form.dirty) {
      this.userPromptsService.confirmPromise(this.labels.confirm_action, this.labels.discard_unsaved_changes_warning).then(sure => {
        if (sure) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  private setupForm(): void {
    const user = this.data.user;

    this.form = this.fb.group({
      usernote: [user.usernote],
      firstname: [user.firstname, [Validators.required]],
      lastname: [user.lastname, [Validators.required]],
      birthday: [user.birthday],
      email: [user.email, [Validators.email]],
      email2: [user.email2, [Validators.email]],
      gender: [user.gender],
      phone: [user.phone],
      phoneRemark: [user.phoneRemark],
      personalNumber: [user.personalNumber, [this.checkBSN()]],
      mailinglistSubscriptions: [user?.mailinglistSubscriptions],
      termsAccepted: [user.termsAccepted],
    });

    this.listenToFormChanges();
  }

  private checkBSN(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      this.utilitiesService.elfProefValidation(control.value, 'bsn') == true
        ? null : { wrongNumber: this.labels.wrongNumber };
  }
  
  private formValueChanges(field?: string, duration?: number): Observable<any> {
    const ctrl = field ? this.form.get(field) : this.form;

    return ctrl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(duration || 100)
    );
  }

  private listenToFormChanges() {

    this.formValueChanges('firstname').subscribe((data: string) => {
      const val = this.utilitiesService.capitalizeFirstLetter(data, true);

      if (val) {
        this.form.get('firstname').setValue(val);
      }
    });

    this.formValueChanges('gender').subscribe((data: string) => {
      if (data) {
        if (!this.form.get('firstname').value) {
          this.form.get('firstname').setValue(this.labels[`title_${data}`]);
        }
      }
    });

    this.formValueChanges('lastname').subscribe((data: string) => {
      const val = this.utilitiesService.capitalizeFirstLetter(data, true);

      if (val) {
        this.form.get('lastname').setValue(val);
      }
    });

    this.formValueChanges(null, 300).subscribe(() => {
      this.form.markAsDirty();
    });
  }

  private setFormValues(payload: { address: Address; area: IArea; }) {
    this.data.user.area = payload.area;
    this.data.user.address = {
      city: payload.address.city,
      street: payload.address.street,
      postalcode: payload.address.postalcode,
      number: payload.address.number,
      letter: payload.address.letter,
      geo: payload.address.geo,
      mailLines: payload.address.mailLines
    }
  }

  public checkUserDuplicatesByAddress(payload: { address: Address; area: IArea; }): void {
    this.utilitiesService.delay(500)
      .pipe(
        takeUntil(this.sub),
      ).subscribe(async () => {
        if (payload.address.postalcode && payload.address.postalcode.length === 6 && payload.address.number > 0) {
          this.userService.getUsersMatchingAddress(payload.address.postalcode, payload.address.number)
            .pipe(take(1)).subscribe(users => {
              this.setFormValues(payload);
              if (users.length > 0) {
                this.userPromptsService.showDialogue(ConfirmNewUserComponent, {
                  users, address: payload.address
                }, null, null, { width: '400px' });
              }
            });
        }
      });
  }

  public checkUserDuplicatesByNameOrEmail(isEmailCheck?: boolean): void {
    this.utilitiesService.delay(500)
      .pipe(
        takeUntil(this.sub),
      ).subscribe(() => {
        const firstname = this.form.value.firstname;
        const lastname = this.form.value.lastname;

        let obs: Observable<User[]>;

        if (isEmailCheck && this.form.value.email) {
          obs = from(this.userService.getUserByEmail(this.form.value.email)).pipe(
            map(user => [user]),
            filter(user => !!user[0])
          );
        }

        if (!isEmailCheck && firstname && lastname) {
          obs = this.userService.getUsersMatchingName(firstname, lastname);
        }

        if (obs) {
          obs.pipe(take(1)).subscribe(users => {
            if (!this.stillCheckDuplicate) return;
            if (users.length > 0) {
              this.userPromptsService.showDialogue(ConfirmNewUserComponent, {
                users, firstname, lastname,
                email: isEmailCheck ? this.form.value.email : null
              }, null, null, { width: '400px' });
            }
          });
        }
      });
  }

  public updatePhoneField(form: FormGroup, field: string, value: { phone: string; valid: boolean; }) {
    const ctrl = form.get(field);
    ctrl.setValue(value.phone);
    ctrl.setErrors(value.valid ? null : { invalid: true });

    form.markAsDirty();
  }

  public async saveUser() {
    if (this.stillCheckDuplicate) this.stillCheckDuplicate = false;
    const user: User = Object.assign({}, this.data.user, this.form.value);

    if (!user?.address?.street) {
      user.address = new Address();
      user.area = null;
    }
    
    // check duplicate email address
    const userWithEmail: User = user.email
      ? await this.userService.getUserByEmail(user.email)
      : null;

    if (userWithEmail && user.id !== userWithEmail.id) {
      this.userPromptsService.showToast(this.labels.user_email_duplicate_error);
    } else {
      this.loading = true;
      try {
        const newUserRef = await this.userService.saveUser(user);
        user.id = newUserRef.id;

        const userInfo = {
          userId: user.id,
          firstName: user.firstname,
          lastName: user.lastname
        };
        this.usageService.addActivity(UsageActionTypes.create_user, userInfo);

        this.dialogRef.close(user);
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    }
  }

  private getMailingList() {
    this.mailinglistService.getAciveMailinglistsUserCanSubscribe().subscribe({
      next: m => {
        this.mailinglists = m;
      },
      error: e => this.utilitiesService.errHandler(e)
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
