<app-modal [alignFooterStart]="true" [hideCloseButton]="false">
  <div class="modal_header" #modal_header fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
    <span class="modal_title">{{labels.edit_user}}</span>
  </div>

  <div class="modal_body" #modal_body>
    <div class="content">
      <form class="user-edit-form" *ngIf="form" [formGroup]="form">
        <div class="form-row">

        </div>

        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>{{labels.firstname}}</mat-label>
            <input matInput required minlength="2" formControlName="firstname">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{labels.lastname}}</mat-label>
            <input matInput required formControlName="lastname" minlength="2">
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>{{labels.gender}}</mat-label>
            <mat-select formControlName="gender">
              <mat-option value="male" selected>{{labels.male}}</mat-option>
              <mat-option value="female">{{labels.female}}</mat-option>
              <mat-option value="unknown">{{labels.unkown}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{labels.birthday}}</mat-label>
            <input type="date" formControlName="birthday" matInput>
          </mat-form-field>
        </div>

        <div>
          <app-address-modal-opener [currentAddress]="data.user.address"
            (address)="checkUserDuplicatesByAddress($event)" />
        </div>
        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>{{labels.user_note}}</mat-label>
            <textarea matInput maxlength="250" cdkTextareaAutosize minRows="2" #input
              formControlName="usernote"></textarea>
            <mat-hint align="end">{{input.value.length}}/250</mat-hint>
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-checkbox color="primary" class="ms-5 me" formControlName="partnerRegistration">
            {{labels.partner}}
          </mat-checkbox>
          <mat-checkbox color="primary" class="ms-5" formControlName="contactPersonRegistration">
            {{labels.contactperson}}
          </mat-checkbox>

          <mat-checkbox color="primary" class="ms-5 ms-a" formControlName="extra">
            {{labels.extra}}
          </mat-checkbox>
        </div>

        <div *ngIf="showPartner" formGroupName="userPartner">
          <div class="text-center m-5">{{labels.partner_details}}</div>

          <div class="form-row">
            <mat-form-field appearance="outline">
              <mat-label>{{labels.firstname}}</mat-label>
              <input matInput minlength="2" formControlName="firstname">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{labels.lastname}}</mat-label>
              <input matInput formControlName="lastname" minlength="2">
            </mat-form-field>
          </div>

          <div class="form-row">
            <div fxFlex="stretch">
              <app-phone-input [value]="form.value.userPartner.phone" [placeholder]="labels.phone"
                (update)="updatePhoneField(form, 'userPartner.phone', $event)">
              </app-phone-input>
            </div>
            <mat-form-field appearance="outline" fxFlex="stretch">
              <mat-label>{{labels.email}}</mat-label>
              <input matInput formControlName="email" type="email">
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline">
              <mat-label>{{labels.gender}}</mat-label>
              <mat-select formControlName="gender">
                <mat-option value="male" selected>{{labels.male}}</mat-option>
                <mat-option value="female">{{labels.female}}</mat-option>
                <mat-option value="unknown">{{labels.unkown}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{labels.birthday}}</mat-label>
              <input type="date" formControlName="birthday" matInput>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline">
              <mat-label>{{labels.remark}}</mat-label>
              <input matInput formControlName="remark" minlength="2">
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="showContactPerson" formGroupName="userContactPerson">
          <div class="text-center m-5">{{labels.contactperson}} 1</div>

          <div class="form-row">
            <mat-form-field appearance="outline">
              <mat-label>{{labels.firstname}}</mat-label>
              <input matInput minlength="2" formControlName="firstName">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{labels.lastname}}</mat-label>
              <input matInput formControlName="lastName" minlength="2">
            </mat-form-field>
          </div>
          <div class="form-row">
            <div fxFlex="stretch">
              <app-phone-input [value]="form.value.userContactPerson.phone" [placeholder]="labels.phone"
                (update)="updatePhoneField(form, 'userContactPerson.phone', $event)">
              </app-phone-input>
            </div>
            <mat-form-field appearance="outline" fxFlex="stretch">
              <mat-label>{{labels.email}}</mat-label>
              <input matInput formControlName="email" type="email">
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline">
              <mat-label>{{labels.remark}}</mat-label>
              <input matInput formControlName="remark" minlength="2">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{labels.social_kind}}</mat-label>
              <mat-select matInput type="text" formControlName="socialRelationKind">
                <mat-option *ngFor="let option of socialRelation_lists" [value]="option.id">
                  {{option.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="showContactPerson" formGroupName="userContactPerson2">
          <div class="text-center m-5">{{labels.contactperson}} 2</div>

          <div class="form-row">
            <mat-form-field appearance="outline">
              <mat-label>{{labels.firstname}}</mat-label>
              <input matInput minlength="2" formControlName="firstName">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{labels.lastname}}</mat-label>
              <input matInput formControlName="lastName" minlength="2">
            </mat-form-field>
          </div>
          <div class="form-row">
            <div fxFlex="stretch">
              <app-phone-input [value]="form.value.userContactPerson2.phone" [placeholder]="labels.phone"
                (update)="updatePhoneField(form, 'userContactPerson2.phone', $event)">
              </app-phone-input>
            </div>


            <mat-form-field appearance="outline" fxFlex="stretch">
              <mat-label>{{labels.email}}</mat-label>
              <input matInput formControlName="email" type="email">
            </mat-form-field>
          </div>
          <div class="form-row">

            <mat-form-field appearance="outline">
              <mat-label>{{labels.remark}}</mat-label>
              <input matInput formControlName="remark" minlength="2">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{labels.social_kind}}</mat-label>
              <mat-select matInput type="text" formControlName="socialRelationKind">
                <mat-option *ngFor="let option of socialRelation_lists" [value]="option.id">
                  {{option.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="showExtra">
          <div class="text-center m-5">{{labels.extra}}</div>
          <div fxLayout="column" fxLayoutGap="25px">
            <mat-checkbox color="primary" class="ms-5" formControlName="decedent">
              {{labels.decedent}}
            </mat-checkbox>
            <mat-slide-toggle color="primary" formControlName="termsAccepted">
              {{labels.terms_accepted}}
            </mat-slide-toggle>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
    <div fxFlex="stretch" fxLayoutGap="10px" fxLayoutAlign="flex-start"> </div>

    <span>
      <button mat-raised-button color="primary" [disabled]="!form?.valid || form?.pristine" (click)="saveUser()">
        {{labels.save}}
      </button>
    </span>
  </div>
</app-modal>