<app-modal [alignFooterStart]="true">
  <div class="modal_header" #modal_header>
    <div class="modal_title">{{labels.availability_selector}}</div>
  </div>

  <div class="modal_body" #modal_body>
    <div class="p-3" fxLayout="column" fxLayoutGap="15px">
      <div class="chips_selector">
        <mat-chip-listbox aria-label="Week days">
          <ng-container *ngFor="let day of weekdays">
            <mat-chip-option color="accent" [selected]="day.prop === selectedWeekday" (click)="selectWeekday(day)">
              {{day.name}}
            </mat-chip-option>
          </ng-container>
          <mat-chip-option color="accent" [selected]="!selectedWeekday" (click)="selectWeekday()">
            {{labels.show_all}}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>{{labels.search}}</mat-label>
          <input matInput [(ngModel)]="searchText" (ngModelChange)="onSearch($event)">
        </mat-form-field>
      </div>

      <div class="">
        <div *ngIf="users.length === 0" class="p-5" fxLayout="row" fxLayoutAlign="center center">
          {{labels.no_user_available}}
        </div>

        <div class="list_container" *ngIf="users.length > 0">
          <div fxLayout="row" fxLayoutGap="10px">
            <div fxFlex="260px"></div>
            <div *ngIf="selectedWeekday" fxFlex="stretch" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center"
              class="text-center">
              <span fxFlex="25px" class="mean_title pl-2">M</span>
              <span fxFlex="25px" class="mean_title pl-2">E</span>
              <span fxFlex="25px" class="mean_title pl-2">A</span>
              <span fxFlex="25px" class="mean_title pl-2">N</span>
              <span fxFlex="stretch"></span>
            </div>

            <div *ngIf="!selectedWeekday && field === 'coordinator'" fxFlex="stretch" fxLayout="row" fxLayoutGap="5px"
              fxLayoutAlign="center center" class="text-center">
              <span fxFlex="3px"></span>
              <span fxFlex="25px" class="mean_title">Mo</span>
              <span fxFlex="25px" class="mean_title">Tu</span>
              <span fxFlex="25px" class="mean_title">We</span>
              <span fxFlex="25px" class="mean_title">Th</span>
              <span fxFlex="25px" class="mean_title">Fr</span>
              <span fxFlex="25px" class="mean_title">Sa</span>
              <span fxFlex="25px" class="mean_title">Su</span>
              <span fxFlex="stretch"></span>
            </div>
          </div>

          <div class="user_item" [id]="user.id" (mouseenter)="hoverEffect(user.id, true)"
            (mouseleave)="hoverEffect(user.id, false)" fxLayout="row"
            [fxLayoutAlign]="'start '+(textShortener[user.id] ? 'start' : 'center')" fxLayoutGap="10px"
            [ngClass]="{'border-primary': selected?.id === user.id}" *ngFor="let user of users">
            <div (click)="userSelected(user)" class="cursor_pointer" fxLayout="row" fxFlex="250px"
              fxLayoutAlign="start center" fxLayoutGap="15px">
              <img [src]="user.picture || '/assets/images/user.png'" [alt]="user.fullname" class="thumbnail">
              <div fxFlex="stretch" fxLayout="column" fxLayoutGap="5px">
                <div>{{user.fullname}}</div>
                <div class="fs-0-8">{{userSubtitle(user)}}</div>
              </div>
            </div>

            <div *ngIf="field === 'coordinator' else executorsOnly" [fxFlex]="selectedWeekday ? '180px' : '250px'"
              fxLayout="row" fxLayoutGap="5px">
              <div class="box_item" *ngFor="let session of availabilitySessions"
                [ngClass]="{'bg-primary': isActive(user.availability?.days, session)}"></div>
            </div>
            <ng-template #executorsOnly>
              <div style="min-width: 150px; margin: auto;">
                <button class="custom-class" mat-mini-fab style="margin: 5px;"
                  *ngFor="let exeStatue of executorsStatus[user.id]"
                  [ngClass]="['status-bg-'+(exeStatue.status || '')]">
                  {{[exeStatue.count]}}
                </button>
              </div>
            </ng-template>

            <div fxFlex="stretch" class="fs-0-8 carriage_returns pl-2">
              <ng-container *ngIf="commentText(user) as txt">
                <div fxLayout="row">
                  <mat-icon class="me-1" fxFlexAlign="start"
                    [style.color]="user.availability?.availability === 1 ? 'green' : 'red'">
                    {{user.availability?.availability === 1 ? 'event_available' : 'event_busy'}}
                  </mat-icon>
                  <div>
                    {{textShortener[user.id] ? txt : txt.substr(0, 150)}} &nbsp;
                    <span *ngIf="txt.length > 150" (click)="textShortener[user.id] = !textShortener[user.id]"
                      class="link_item">{{textShortener[user.id] ? labels.show_less : labels.show_more}}</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal>