<div fxFlex="stretch" class="full-width" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
  <mat-form-field appearance="outline" fxFlex="stretch">
    <mat-label>{{labels.team}}</mat-label>
    <input matInput type="text" readonly *ngIf="team?.length === 0">
    <mat-chip-listbox>
      <mat-chip-option *ngFor="let member of team">
        {{member.firstname}} {{member.lastname}}
        <mat-icon matChipRemove (click)="removeMember(member)">cancel</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </mat-form-field>

  <div>
    <button color="primary" mat-icon-button (click)="openAvailabilityDialog()">
      <mat-icon>person</mat-icon>
    </button>
  </div>
</div>