import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserPromptsService, UserService } from '@core/services';
import { AppointmentService } from '@core/services/appointment/appointment.service';
import { LabelService } from '@core/services/labels.service';
import { TimeIntervalService } from '@core/services/time-interval.service';
import { Management, TimeGridCell } from '@models/model';
import { AppointmentModel } from '@models/model/appointment.model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { IObjectMap } from '@shared/interface';
import { addHours, format, isValid, startOfDay } from 'date-fns';
import { NgSub } from 'ng-sub';

@Component({
  selector: 'app-edit-appointment-modal',
  templateUrl: './edit-appointment-modal.component.html',
  styleUrls: ['./edit-appointment-modal.component.scss']
})
export class EditAppointmentModalComponent extends ModalComponent implements OnInit, OnDestroy {
  public labels = this.labelService.defaultProvider();
  public form: FormGroup;
  public editMode = false;
  public timeList: TimeGridCell[] = [];
  public validEndDates: TimeGridCell[] = [];
  private sub = new NgSub();
  public occupiedTimes: IObjectMap<boolean> = {};
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      appointment: AppointmentModel;
      requestId: string;
      customerId: string;
      management: Management;
      trainingId: string;
    },
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<EditAppointmentModalComponent>,
    private labelService: LabelService,
    private fb: FormBuilder,
    private appointmentService: AppointmentService,
    private userPromptsService: UserPromptsService,
    private userService: UserService,
    private timeIntervalService: TimeIntervalService,
  ) {
    super(dialogRef, breakpointObserver);
  }

  ngOnInit() {
    this.timeIntervalService.setInterval(15);
    this.timeList = this.timeIntervalService.generateDateCells(new Date());
    this.dialogRef.disableClose = true;
    this.labelService.getLabels('app-edit-appointment-modal').then(r => this.labels = r.data);

    this.editMode = !!this.data.appointment.id;
    this.setupForm();
    this.updateEndDateList()
  }

  private setupForm(): void {
    const a = this.data.appointment;    
    this.form = this.fb.group({
      title: [a.title, [Validators.required]],
      description: [a.description],
      date: [format(a.date, 'YYYY-MM-DD'), [Validators.required]],
      startTime: [a.startTime || 9, [Validators.required]],
      endTime: [a.endTime || 10, [Validators.required]],
      location: [a.location],
    });
    this.sub.add(this.form.get('startTime').valueChanges.subscribe(() => {
      this.updateEndDateList();
    }))
  }

  public getDayName(date: string, day?: 'day'): string {
    if (day === 'day') {
      return format(new Date(date), 'D');
    } else {
      return new Date(date).toLocaleDateString(undefined, { weekday: 'long' });
    }
  }

  public save(): void {
    const model = this.form.value;
    // const startDate = this.appointmentService.combineDateAndTime(model.date, model.startTime);
    // const endDate = this.appointmentService.combineDateAndTime(model.date, model.endTime);
    const date = addHours(startOfDay(model.date), model.startTime);
    const startTime = model.startTime;
    const endTime = model.endTime;
    if (!isValid(date)) {
      this.userPromptsService.showToast(this.labels.invalid_date_time_values);
      return;
    }

    const appointment: AppointmentModel = this.editMode ? this.data.appointment : JSON.parse(JSON.stringify(this.data.appointment));

    appointment.title = model.title;
    appointment.date = date;
    appointment.startTime = startTime;
    appointment.endTime = endTime;
    appointment.description = model.description;
    appointment.location = model.location || '';
    appointment.requestId = this.data.requestId || '';
    appointment.trainingId = this.data.trainingId || '';
    appointment.customerId = this.data.customerId;
    appointment.attendees = [
      this.userService.getCurrentUserId(),
      // this.data.management.customerId,
      this.data.customerId,
    ];

    this.appointmentService.saveAppointment(appointment).then(() => {
      this.dialogRef.close();
      this.userPromptsService.showToast(this.labels.appointment_saved);
    }).catch(e => this.userPromptsService.showToast(e?.message || e));
  }

  private updateEndDateList(): void {
    this.validEndDates = this.timeIntervalService.updateEndDateList(this.timeList, { form: this.form, prop: 'startTime' }, this.occupiedTimes);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}