import { Injectable, SecurityContext } from '@angular/core';
import { User, Request, WorkingArea, Service, Organization } from '@shared/model';
import { IEmailOption } from '@shared/interface';
import { UserService } from './user.service';
import { OrgServiceService } from '@core/services/org-service.service';
import { OrganizationService } from '@core/services/organization.service';
import { WorkingAreaService } from '@core/services/workingarea.service';
import { LabelService } from '@core/services/labels.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { CloudFunctionService } from './functions.service';

@Injectable({
    providedIn: 'root',
})
export class RequestNotificationsService {
    private labels: any = {};

    constructor(
        private labelService: LabelService,
        public userService: UserService,
        public orgServiceService: OrgServiceService,
        public organizationService: OrganizationService,
        public workingAreaService: WorkingAreaService,
        public localStorageService: LocalStorageService,
        public domSanitizer: DomSanitizer,
        private cloudFunctionService: CloudFunctionService
    ) {
        this.initLabels();
    }

    async initLabels() {
        this.labels = (await this.labelService.getLabels('app-request-form')).data;
    }

    public async sendUserNotification(user: User, request: Request, text: string, workingArea: WorkingArea, service: Service, status: number) {
        if (user.email) {
            const customer: User = await this.userService.getUserById(request.management.customerId).pipe(take(1)).toPromise();
            const organization: Organization = await this.organizationService.getOrganizationById(
                localStorage.getItem('user_organization')
            ).pipe(take(1)).toPromise();

            let txtBodySubject: string;
            let txtBodyText: string;
            let txtSubject: string;

            if (status === 5) { // request is closed
                txtBodySubject = `${workingArea?.name} - ${service?.name}`;
                txtBodyText = this.labels._translate('email-close-request-body-text', { firstName: user.firstname });
                txtSubject = `${this.labels.close_request_subject} ${customer.fullname}`;
            } else {
                txtBodySubject = `${workingArea?.name} - ${service?.name}`;
                txtBodyText = this.labels._translate('email-new-request-body-text', { firstName: user.firstname });
                txtSubject = `${this.labels.new_request_subject} ${customer.fullname}`;
            }

            let msg = `
               
                <h3>${request.title}</h3>

                ${request.description.replace(/(?:\r\n|\r|\n)/g, '<br>') || ''}<br/><br/>
                ${customer.firstname} ${customer.lastname}<br/>
         
                ${customer.address.street} ${customer.address.number} ${customer.address.letter ? '-' : ''} ${customer.address.letter ? customer.address.letter : ''}<br/>
                ${customer.address.postalcode} ${customer.address.city}<br/><br/>
                ${customer.phone ? customer.phone + '<br>' : ''}
                ${customer.phone2 ? customer.phone2 + '<br>' : ''}
            `;

            if (customer.address.geo) {
                msg += '<br><br><a href="https://www.google.com/maps/search/?api=1&';
                msg += `query=${customer.address.geo['latitude']},${customer.address.geo['longitude']}">
                    Google Maps</a><br/><br/>`;
            }

            const emailOptions: IEmailOption = {
                subject: txtSubject,
                bodySubject: txtBodySubject,
                from: this.localStorageService.getItemSync('user_email'), // this should not be user email
                to: user.email,
                logoUrl: organization?.webLogo || organization.fullLogoUrl,
                bodyText: this.domSanitizer.sanitize(
                    SecurityContext.HTML,
                    text || txtBodyText
                ).replace(new RegExp('&#10;', 'g'), '<br>'),
                orgName: organization.fullName,
                orgId: organization.id,
                actionHtml: msg,
                infoText: `${this.labels['getapp_mail_text']}  <br><br>
                ${this.labels['email-best-regards']} <br> ${organization.fullName} <br>`
            };

            this.cloudFunctionService.sendEmails([emailOptions], 'request', false).catch(console.log);
        }
    }
}
