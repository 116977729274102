import { Injectable } from '@angular/core';
import { User, Service, WorkingArea } from '@shared/model';
import { uniqBy } from 'lodash';
import { UserService } from './user.service';
import { OrgServiceService } from '@core/services/org-service.service';
import { WorkingAreaService } from '@core/services/workingarea.service';
import { LabelService } from '@core/services/labels.service';
import { ExportService } from './export.service';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';

export interface IExportUsers {
  firstname: string;
  lastname: string;
  birthday: string;
  gender: string;
  phone: string;
  phone2: string;
  email: string;
  city: string;
  number: string;
  postalcode: string;
  street: string;
  letter: string;
  role?: string;
  workingArea?: string;
  service?: string;
  disabledExecutor?: string;
  district?: string;
  neighborhood?: string;
  id?: string;
  employeeNote?: string;
  employeeOffers?: string;
  // availability: string;
  // notAvailableUntil: Date;
  mondayAvailability?: boolean;
  mondayRemark?: string;
  tuesdayAvailability?: boolean;
  tuesdayRemark?: string;
  wednesdayAvailability?: boolean;
  wednesdayRemark?: string;
  thursdayAvailability?: boolean;
  thursdayRemark?: string;
  fridayAvailability?: boolean;
  fridayRemark?: string;
  saturdayAvailability?: boolean;
  saturdayRemark?: string;
  sundayAvailability?: boolean;
  sundayRemark?: string;
  availabityComment?: string;
}

export interface IExportAfasUsers {
  firstname: string;
  lastname: string
  birthday: string;
  id: string;
  isExecutorAfas: boolean
  isExecutorV4work: boolean;
  bcCo: string;
  BcId: string
}

export interface IExportUserSource extends User {
  workareaName?: string;
  serviceName?: string;
  roleName?: string;
  activeRole?: string;
}

export interface IExportUserConfig {
  users: User[];
  workArea?: WorkingArea;
  service?: Service;
  role?: string;
  isUnique?: boolean;
  disabledExecutor?: string;
}

interface IExportUserAddress {
  firstname: string;
  lastname: string;
  addressLine1: string;
  postalcode: string;
  city: string;
  email: string;
  district?: string;
  neighborhood?: string;
  municipality: string,
  birthday: string;
  serviceOfferings: string;
  employability: string
}

@Injectable({
  providedIn: 'root',
})
export class ExportUsersService {
  // service labels
  global_labels: any = {};
  global_address_and_contact: any = {};
  user_gender: any = {};
  private availabilityLabels: ILabelsProvider;

  public exportUsersLabels: IExportUsers;
  public exportUserAddressLabels: IExportUserAddress;
  public exportAfasUsersLabels: IExportAfasUsers;

  constructor(
    public userService: UserService,
    public orgServiceService: OrgServiceService,
    public labelService: LabelService,
    public workingAreaService: WorkingAreaService,
    private exportService: ExportService
  ) { this.initLabels(); }

  async initLabels() {
    this.global_labels = (await this.labelService.getLabels('global-labels')).data;
    this.global_address_and_contact = (await this.labelService.getLabels('global-address-and-contact')).data;
    this.user_gender = (await this.labelService.getLabels('user-gender')).data;
    this.availabilityLabels = (await this.labelService.getLabels('app-user-availability-modal')).data;

    this.exportUsersLabels = {
      firstname: this.global_address_and_contact.firstname,
      lastname: this.global_address_and_contact.lastname,
      birthday: this.global_address_and_contact.birthday,
      gender: this.global_address_and_contact.gender,
      phone: this.global_address_and_contact.phone,
      phone2: this.global_address_and_contact.phone2,
      email: this.global_address_and_contact.email,
      city: this.global_address_and_contact.city,
      number: this.global_address_and_contact.number,
      postalcode: this.global_address_and_contact.postalcode,
      street: this.global_address_and_contact.street,
      letter: this.global_address_and_contact.letter,
      employeeNote: this.global_labels.employeeNote,
      employeeOffers: this.global_labels.employeeOffers,
      role: this.global_labels.role,
      workingArea: this.global_labels['working-area'],
      service: this.global_labels.service,
      disabledExecutor: this.global_labels.status,
      district: this.global_labels.district,
      neighborhood: this.global_labels.neighborhood,
      id: this.global_labels.id,
      mondayAvailability: this.global_labels.monday,
      mondayRemark: `${this.global_labels.monday}-${this.global_labels.remark}`,
      tuesdayAvailability: this.global_labels.tuesday,
      tuesdayRemark: `${this.global_labels.tuesday}-${this.global_labels.remark}`,
      wednesdayAvailability: this.global_labels.wednesday,
      wednesdayRemark: `${this.global_labels.wednesday}-${this.global_labels.remark}`,
      thursdayAvailability: this.global_labels.thursday,
      thursdayRemark: `${this.global_labels.thursday}-${this.global_labels.remark}`,
      fridayAvailability: this.global_labels.friday,
      fridayRemark: `${this.global_labels.friday}-${this.global_labels.remark}`,
      saturdayAvailability: this.global_labels.saturday,
      saturdayRemark: `${this.global_labels.saterday}-${this.global_labels.remark}`,
      sundayAvailability: this.global_labels.sunday,
      sundayRemark: `${this.global_labels.sunday}-${this.global_labels.remark}`,
      availabityComment: this.availabilityLabels.availability_comment,
    };

    this.exportUserAddressLabels = {
      firstname: this.global_address_and_contact.firstname,
      lastname: this.global_address_and_contact.lastname,
      email: this.global_address_and_contact.email,
      city: this.global_address_and_contact.city,
      postalcode: this.global_address_and_contact.postalcode,
      district: this.global_labels.district,
      neighborhood: this.global_labels.neighborhood,
      addressLine1: this.global_address_and_contact.addressLine1,
      municipality: this.global_labels.municipality,
      birthday: this.global_address_and_contact.birthday,
      employability: this.global_address_and_contact.employability,
      serviceOfferings: this.global_address_and_contact.serviceOfferings,
    };

    this.exportAfasUsersLabels = {
      firstname: this.global_address_and_contact.firstname,
      lastname: this.global_address_and_contact.lastname,
      birthday: this.global_address_and_contact.birthday,
      id: this.global_labels.id,
      isExecutorAfas: this.global_labels.is_afas_executor,
      isExecutorV4work: this.global_labels.is_v4work_executor,
      bcCo: this.global_labels.bcco,
      BcId: this.global_labels.bcId,
    }
  }

  public async makeUsersExport(config: IExportUserConfig) {
    const header: string[] = this.getExportUsersRow(this.exportUsersLabels, config.isUnique);
    const fields: string[][] = [header];

    if (config.isUnique) {
      config.users = uniqBy(config.users, 'id');
    }

    config.users.forEach((user: IExportUserSource) => {

      let row: IExportUsers;

      if (user.decedent) {
        row = {
          firstname: user.firstname,
          lastname: `${user.lastname} - ${this.global_labels.decedent}`,
          birthday: user.birthday,
          gender: this.user_gender[user.gender],
          phone: '',
          phone2: '',
          email: '',
          city: user.address.city,
          number: '',
          postalcode: user.address.postalcode.substr(0, 4) + ' ' + user.address.postalcode.substr(4, 2),
          street: '',
          letter: '',
          employeeNote: user.employeeDetails.remarks,
          employeeOffers: user.employeeDetails.offers,
          district: user.area?.district?.text || '',
          neighborhood: user.area?.neighbourhood?.text || '',
          id: user.id,
        };
      } else {
        row = {
          firstname: user.firstname,
          lastname: user.lastname,
          birthday: user.birthday,
          gender: this.user_gender[user.gender],
          phone: user.phone,
          phone2: user.phone,
          email: user.email,
          city: user.address.city,
          number: user.address.number > 0 ? user.address.number.toString() : '',
          postalcode: user.address.postalcode.substr(0, 4) + ' ' + user.address.postalcode.substr(4, 2),
          street: user.address.street,
          letter: user.address.letter,
          employeeNote: user.employeeDetails.remarks,
          employeeOffers: user.employeeDetails.offers,
          district: user.area?.district?.text || '',
          neighborhood: user.area?.neighbourhood?.text || '',
          id: user.id,
          // availability: user.availability.availability,
          // notAvailableUntil: user.availability.notAvailableUntil,
          mondayAvailability: user.availability?.days.monday.available || false,
          mondayRemark: user.availability?.days.monday.remark,
          tuesdayAvailability: user.availability?.days.tuesday.available || false,
          tuesdayRemark: user.availability?.days.tuesday.remark,
          wednesdayAvailability: user.availability?.days.wednesday.available || false,
          wednesdayRemark: user.availability?.days.wednesday.remark,
          thursdayAvailability: user.availability?.days.thursday.available || false,
          thursdayRemark: user.availability?.days.thursday.remark,
          fridayAvailability: user.availability?.days.friday.available || false,
          fridayRemark: user.availability?.days.friday.remark,
          saturdayAvailability: user.availability?.days.saturday.available || false,
          saturdayRemark: user.availability?.days.saturday.remark,
          sundayAvailability: user.availability?.days.sunday.available || false,
          sundayRemark: user.availability?.days.sunday.remark,
          availabityComment: user.availability?.comment || '',
        };
      }

      if (!config.isUnique) {
        row.role = config.role ? this.global_labels[config.role.toLowerCase()] : this.global_labels[user.roleName.toLowerCase()];
        row.workingArea = config.workArea ? config.workArea.name : user.workareaName;
        row.service = config.service ? config.service.name : user.serviceName;
        row.disabledExecutor = user.activeRole ? this.global_labels[user.activeRole] : '';
      }
      fields.push(this.getExportUsersRow(row, config.isUnique));
    });

    let filename: string;

    if (config.service) {
      filename = config.service.name;
    } else {
      filename = `Exported Users`;
    }

    this.exportService.downloadArray(fields, filename);
  }

  private getExportUsersRow(row: IExportUsers, isUnique: boolean): string[] {
    const res: string[] = [
      row.firstname,
      row.lastname,
      row.street,
      row.number,
      row.letter,
      row.employeeNote,
      row.employeeOffers,
      row.postalcode,
      row.city,
      row.birthday,
      row.gender,
      row.phone,
      row.phone2,
      row.email,
      row.district,
      row.neighborhood,
      row.id,
    ];

    if (!isUnique) {
      res.push(row.role);
      res.push(row.workingArea);
      res.push(row.service);
      res.push(row.disabledExecutor);
    }

    const availability: any[] = [
      row.mondayAvailability,
      row.mondayRemark,
      row.tuesdayAvailability,
      row.tuesdayRemark,
      row.wednesdayAvailability,
      row.wednesdayRemark,
      row.thursdayAvailability,
      row.thursdayRemark,
      row.fridayAvailability,
      row.fridayRemark,
      row.saturdayAvailability,
      row.saturdayRemark,
      row.sundayAvailability,
      row.sundayRemark,
      row.availabityComment,
    ];

    return res.concat(availability);
  }

  private getExportAfasUsersRow(row: IExportAfasUsers): any[] {
    return [
      row.firstname,
      row.lastname,
      row.id,
      row.birthday,
      row.isExecutorAfas,
      row.isExecutorV4work,
      row.bcCo,
      row.BcId
    ]
  }

  private getExportUsersAddressRow(row: IExportUserAddress): string[] {
    const res: string[] = [
      row.firstname,
      row.lastname,
      row.addressLine1,
      row.postalcode,
      row.city,
      row.email,
      row.municipality,
      row.district,
      row.neighborhood,
      row.birthday,
      row.serviceOfferings,
      row.employability
    ];
    return res;
  }

  public makeUserExport(users: User[], service?: Service) {
    const header: string[] = this.getExportUsersAddressRow(this.exportUserAddressLabels);
    const fields: string[][] = [header];

    users.forEach((user: IExportUserSource) => {
      const row: IExportUserAddress = {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        postalcode: user.address.postalcode,
        addressLine1: this.mapAddress(user),
        city: user.address.city,
        municipality: user.area ? user.area.municipality.text : '',
        district: user.area ? user.area.district.text : '',
        neighborhood: user.area ? user.area.neighbourhood.text : '',
        birthday: user.birthday ? user.birthday : '',
        employability: service ? user.roles.services[service.id].employability?.join(', ') : '',
        serviceOfferings: service ? user.roles.services[service.id].serviceOffering?.join(', ') : '',
      };
      fields.push(this.getExportUsersAddressRow(row));
    });

    let filename: string = `Exported Users Address`;

    this.exportService.downloadArray(fields, filename);
  }

  private mapAddress(user: User) {
    let address = '';
    if (user.address.street) address += `${user.address.street} `;
    if (user.address.number) address += `${user.address.number} `;
    if (user.address.letter) address += `${user.address.letter} `;
    return address;
  }

  public async makeAfasUsersExport(users: User[]) {
    const header: string[] = this.getExportAfasUsersRow(this.exportAfasUsersLabels);
    const fields: string[][] = [header];

    users.forEach((user) => {
      let row = {
        firstname: user.firstname,
        lastname: user.lastname,
        id: user.id,
        birthday: user.birthday,
        isExecutorV4work: user.roles.isExecutor,
        isExecutorAfas: user.afasDetails.isAfasExecutor,
        bcCo: user.afasDetails.KnPerson.BcCo,
        BcId: user.afasDetails.KnPerson.BcId
      } as IExportAfasUsers
      fields.push(this.getExportAfasUsersRow(row));
    });
    let filename: string = `afas user ${new Date().toDateString()}`;
    
    this.exportService.downloadArray(fields, filename);
  }

}
