<app-modal [alignFooterStart]="true">
  <div class="modal_header" #modal_header>
    <div class="modal_title">{{user?.fullname}}</div>
    <div class="modal_subtitle">{{labels.notes_and_activities}}</div>
  </div>

  <div class="modal_body" #modal_body>

    @if (fetchingMore) {
    <div fxLayoutAlign="center center" style="padding: 30% 0;">
      <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
    </div>
    }

    <div>
      <div fxLayout="row" fxLayoutAlign="space-between" class="p-2">
        <div *ngIf="allRequestsOptions.showAllRequests" fxLayout="column">
          <mat-chip-listbox aria-label="Area" *ngIf="workingAreaChips.length > 0">
            <ng-container *ngFor="let area of workingAreaChips | sort:'name'">
              <mat-chip-option *ngIf="workingAreaStats[area.id] > 0" color="primary"
                [selected]="filters.workingAreaId === area.id" (click)="onClickWorkingAreaChip(area)">
                {{area.name}}&nbsp;<span>({{workingAreaStats[area.id]}})</span>
              </mat-chip-option>
            </ng-container>
          </mat-chip-listbox>
          <mat-chip-listbox aria-label="Services" *ngIf="serviceChips.length > 0">
            <ng-container *ngFor="let service of serviceChips | sort:'name'">
              <mat-chip-option *ngIf="serviceStats[service.id] > 0" color="accent"
                [selected]="service.id === filters.serviceId" (click)="onClickServiceChip(service)">
                {{service.name}}&nbsp;<span>({{serviceStats[service.id]}})</span>
              </mat-chip-option>
            </ng-container>
          </mat-chip-listbox>
        </div>
        <div *ngIf="request && !allRequestsOptions.showAllRequests">{{request.title}}</div>
        <div *ngIf="request">
          <mat-slide-toggle [(ngModel)]="allRequestsOptions.showAllRequests"
            [disabled]="allRequestsOptions.canSeeAllActivities"
            (ngModelChange)="showAllRequestsChanged($event)">{{labels.all_requests}}
            ({{allRequestsOptions.allRequestCount}})</mat-slide-toggle>
        </div>
      </div>
      <hr>
      <div class="p-2">
        @if (!activities.length && !fetchingMore) {
        <div style="height: 300px" fxLayout="column" fxLayoutAlign="center center">
          {{labels.no_items_found}}
        </div>
        }

        @if (activities.length && !fetchingMore) {
        <div fxLayoutAlign="row" fxLayoutAlign="end" fxLayoutGap="20px" style="margin-bottom: 10px;">
          <button mat-button color="primary"
            (click)="openAll ? accordion().closeAll() : accordion().openAll(); openAll = !openAll">{{openAll ?
            labels.collapse_all : labels.expand_all}}</button>
        </div>
        <mat-accordion multi>
          @for (item of activities; track $index) {
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between">
              <mat-panel-description [style]="{'font-weight': isUnRead(item.activity.date) ? '700' : 'initial',
                                          'color': isUnRead(item.activity.date) ? 'black' : 'black'}">
                <div style="width: 20%; margin: auto;">
                  {{getLocaleDate(item?.activity.date || item?.activity?.log.createdAt, true)}}
                  {{getLocaleDate(item?.activity.date || item?.activity?.log.createdAt)}}
                </div>

                <div style="width: 40%; margin: auto;">
                  <span>{{item.activity.title}} </span>
                  <span *ngIf="item.activity.minutes > 0">({{labels._translate('time_in_minutes',
                    {minutes: item.activity.minutes})}})</span>
                </div>

                <div style="width: 30%; margin: auto;" *ngIf="getUser(item.activity.log.createdBy) as creator">
                  <span>
                    {{creator.fullname}}
                  </span>
                </div>

                <div style="margin: auto;">
                  <mat-icon>{{getIcon(item.activity)}}</mat-icon>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div fxLayout="column" fxLayoutGap="20px" style="margin: 0 10px;">
              <p class="carriage_returns">{{item.activity.description}}</p>

              @if (item.activity.attachments.length > 0) {
              @for (attachment of item.activity.attachments; track $index) {
              <div fxLayout="row" fxLayoutGap="10px">
                <a href="{{attachment.url}}" target="_blank">{{attachment.filename}}</a>
              </div>
              }}

              @if (request?.id && allRequestsOptions.showAllRequests) {
              <div>
                {{item.activity.titleRequest}}
              </div>
              }
            </div>

            <mat-action-row *ngIf="getUser(item.activity.log.createdBy) as creator">
              <button mat-button [matMenuTriggerFor]="menu" (click)="rowOptionClicked(item)">{{labels.options}}</button>
              <button color="primary" [disabled]="currentUserId !== creator.id && hasOrgAdminRole === false" mat-button
                (click)="editActivity(item.activity)">
                <span>{{labels.edit}}</span>
              </button>
              <mat-menu #menu="matMenu">
                <!-- <button *ngIf="canReply" mat-menu-item (click)="addReply(item.activity)">
                  <mat-icon>reply</mat-icon>
                  <span>{{labels.reply}}</span>
                </button> -->
                <button [disabled]="rowOptionBtn.disabled" mat-menu-item (click)="moveActivity()">
                  <mat-icon>menu_open</mat-icon>
                  <span>{{rowOptionBtn.label}}</span>
                </button>
                <button [disabled]="currentUserId !== creator.id && hasOrgAdminRole === false" mat-menu-item
                  (click)="deleteActivity(item.activity)">
                  <mat-icon>delete</mat-icon>
                  <span>{{labels.delete}}</span>
                </button>
              </mat-menu>
            </mat-action-row>
          </mat-expansion-panel>
          }
        </mat-accordion>

        <div class="p-3" fxLayout="row" fxLayoutAlign="center center">
          <button [disabled]="fetchingMore" *ngIf="lastActivityRef" (click)="fetchMoreActivities()" mat-stroked-button
            color="primary">{{fetchingMore ? labels.please_wait : labels.fetch_more}} {{remainingRequestCount ?
            remainingRequestCount : ''}}</button>
        </div>
        }
      </div>
    </div>
  </div>

</app-modal>