<app-modal [alignFooterStart]="networkInstitution.id">
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{networkInstitution.id ? labels.update_network_institution : labels.create_network_institution}}
        </span>
    </div>

    <div class="modal_body" #modal_body>
        <div style="padding: 1em;" fxLayout="column" fxLayoutGap="20px">
            <form [formGroup]="form" fxLayout="column" fxLayoutGap="10px">
                <div fxFlex="stretch">
                    <app-options-list fxFlex="stretch" [hidePhoto]="true" [defaultValue]="selectedInstitution?.name"
                        (selectOption)="selectInstitution($event)" [optionsRef]="institutionsOptionsRef"
                        [placeholder]="labels.institution" [displayProperties]="['name']">
                    </app-options-list>
                </div>

                <mat-form-field appearance="outline" fxFlex="stretch">
                    <mat-label>{{labels.note}}</mat-label>
                    <textarea cdkTextareaAutosize cdkAutosizeMinRows maxlength="noteMaxLength" minRows="3" matInput type="text"
                        formControlName="note"></textarea>
                    <mat-hint [align]="'end'">{{form.value.note.length}}/{{noteMaxLength}}</mat-hint>
                </mat-form-field>
            </form>

            <div class="log_text_footer" fxLayout="column" fxLayoutGap="10px" *ngIf="networkInstitution.id">
                <span>{{labels.created_by}}: {{createdByUser?.firstname}} {{createdByUser?.lastname}}</span>
                <span>{{labels.created_at}}: {{networkInstitution.log.createdAt | date}}</span>
            </div>
        </div>
    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px">
        <div fxFlex="stretch" *ngIf="networkInstitution.id" fxLayoutAlign="flex-start">
            <button color="warn" mat-stroked-button (click)="deleteItem()">{{labels.delete}}</button>
        </div>

        <button color="primary" mat-raised-button [disabled]="form.invalid || form.pristine"
            (click)="save()">{{networkInstitution.id ? labels.update : labels.create}}</button>
    </div>
</app-modal>