import { Injectable } from '@angular/core';
import { Product } from '@models/model/product';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { FirestoreService } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import { SearchService } from './search.service';
import { take as _take } from 'lodash';
import { TimestampDate } from 'timestamp-date';
import { ProductFilter } from 'app/product-module/products-list/products-list.component';
import { QueryConstraintOptions, Where } from '@shared/model/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private colRef: string;
  private timestampDate = new TimestampDate();

  constructor(
    private afDb: FirestoreService,
    private localStorageService: LocalStorageService,
    private searchService: SearchService,
  ) {
    this.colRef = `organizations/${this.localStorageService.getItemSync('user_organization')}/products`;

    this.localStorageService.getItem('user_organization').subscribe(orgId => {
      this.colRef = `organizations/${orgId}/products`;
    });
  }

  /**
   * createProduct
   */
  public async createProduct(product: Product): Promise<any> {
    const existingProduct = await this.getProductByCode(product.code);
    if (existingProduct?.id) {
      return "Can not save, Product with the same code already exist"
    }
    product.id = this.afDb.getNewId();
    return this.afDb.setDoc(`${this.colRef}/${product.id}`, this.parseProduct(product));
  }

  /**
   * updateProduct
   */
  public updateProduct(product: Product): Promise<any> {
    return this.afDb.update(`${this.colRef}/${product.id}`, this.parseProduct(product));
  }

  private parseProduct(product: Product): Product {
    let newProduct: Product = JSON.parse(JSON.stringify(product));
    return newProduct = this.timestampDate.parseStringToDate(newProduct);
  }

  /**
   * deleteProduct
   */
  public deleteProduct(id: string): Promise<any> {
    return this.afDb.remove(`${this.colRef}/${id}`);
  }

  /**
   * getList
   */
  public getList(): Observable<Product[]> {
    return this.afDb.colWithIds$<Product>(this.colRef, ref => ref.orderBy('name', 'asc'), true)
  }

  public getProductsPagination(query: ProductFilter): Observable<Product[]> {
    const constraints: Partial<QueryConstraintOptions<Product>> = {};
    constraints.limit = query.limit;
    constraints.orderBy = [{ field: 'name', val: 'asc' }];
    return this.afDb.colWithIdsNew$<Product>(this.colRef, () => this.getFilters(query), constraints, true);
  }

  public getProductById(id: string): Observable<Product> {
    return this.afDb.docWithId$(`${this.colRef}/${id}`)
  }

  public search(query: ProductFilter): Observable<Product[]> {
    const word = query.textFilter
    if (word) {
      const where = this.getFilters(query);
      return new Observable(observer => {
        this.searchService.searchCollectionIndex<Product>({
          searchText: query.textFilter,
          ref: this.colRef,
          where
        }).then(products => {
          observer.next(products)
        })
      })
    }
  }

  public getProductByCode(code: string): Promise<Product> {
    return new Promise(resolve => {
      if (code) {
        this.afDb.colWithIds$(this.colRef, (ref) => {
          return ref.where('code', '==', code).limit(1);
        }).pipe(take(1)).subscribe((res: Product[]) => {
          resolve(res.length > 0 ? res[0] : null);
        });
      } else {
        resolve(null);
      }
    })
  }

  public getProductsCount() {
    return this.afDb.getCounts(this.colRef);
  }

  public fetchAllProductByFilter(query: ProductFilter) {
    return this.afDb.colWithIdsNew$(this.colRef, ()=> this.getFilters(query))
  }

  public getProductByFilter(query: ProductFilter, limit?: number, lastOrFirstRef?: any, isForwardNavigation?: boolean, isReloadedContext?: boolean) {
    const options: Partial<QueryConstraintOptions<Product>> = {};
    if (lastOrFirstRef) {
      options.orderBy = [{ field: 'name', val: 'asc' }]
      if (isReloadedContext) {
        options.startAt = lastOrFirstRef;
        options.limit = limit || 30;
      } else {
        if (isForwardNavigation) {
          options.startAfter = lastOrFirstRef;
          options.limit = limit || 30;
        } else {
          options.endBefore = lastOrFirstRef;
          options.limitToLast = limit || 30;
        }
      }
    } else {
      options.limit = limit || 30
    }
    return this.afDb.colWithIdsNew$<Product>(this.colRef, () => this.getFilters(query), options, true);
  }

  private getFilters(query: ProductFilter) {
    const where: Where[] = [];
    if (query.unitFilter) {
      where.push(['unit', '==', query.unitFilter]);
    }

    return where;
  }
}
