import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IObjectMap } from '@shared/interface';

@Component({
    selector: 'app-alert-dialogue',
    styleUrls: ['./alert-dialogue.component.css'],
    templateUrl: './alert-dialogue.component.html',
})
export class AlertDialogueComponent {
    constructor(
        public dialogRef: MatDialogRef<AlertDialogueComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IObjectMap<any>
    ) { }

    continue(): void {
        this.dialogRef.close(true);
    }
}
