<app-modal [hideCloseButton]="!data.canClose">
  <div class="modal_header" #modal_header>
    <span class="modal_title">
      {{labels.select_your_role}}
    </span>
  </div>

  <div class="modal_body" #modal_body style="overflow: hidden;">
    <mat-progress-bar *ngIf="roles === undefined" mode="indeterminate"></mat-progress-bar>

    <div style="padding: 15px;">
      <div *ngIf="roles?.length === 0" fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="center center">
        <div class="text-center" style="padding-top: 50px;">{{labels.missing_user_roles}}</div>

        <button mat-raised-button (click)="goBack()">{{labels.go_back}}</button>
      </div>

      <mat-list *ngIf="roles?.length > 0">
        <mat-list-item *ngFor="let role of roles" (click)="continue(role)"
          [ngClass]="[isSelected(role) ? 'selected border-left-primary' : 'hover']">
          <div matListItemTitle> {{ labels[RoleTypes[role.roleType]] }} </div>
          <span matListItemLine *ngIf="role.workingArea"> {{role.workingArea.name}} </span>
          <span matListItemLine *ngIf="role.service"> {{role.service.name}} </span>
          <span matListItemLine *ngIf="role.subtitle">{{labels[role.subtitle]}}</span>
          <span matListItemLine *ngIf="role.institutionName">{{labels[role.institutionName]}}</span>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</app-modal>