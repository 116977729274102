import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-base-component',
  template: '<h1>Base component</h1>',
})
export class BaseComponent implements OnDestroy {
  protected subscriptions: Subscription[] = [];

  constructor() { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }
}
