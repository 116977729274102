import { Injectable } from '@angular/core';
import { environment } from 'app/../environments/environment';
import {
  ITranslatorConfigOptions,
  TranslatorClientService,
  ILabelsProvider,
  Language
} from '@anchor-solutions-nl/translator-as';
import { IObjectMap } from '@shared/interface';
import { UtilitiesService } from './utilities.service';
import {
  GroupActivityTemplateFrequencyTypes,
  GroupActivityTemplateFrequency,
  SocialRelationKindTypes,
  Organization,
  ExecutorLabelTypes,
  WorkingAreaLabelTypes,
  RequestLabelTypes,
  ServiceLabelTypes,
  CustomLabels,
  CoordinatorLabelTypes,
  ProfessionalLabelTypes,
  CustomerLabelTypes,
  Role
} from '@shared/model';
import { Observable, from, of } from 'rxjs';
import { filter, map, mergeAll, pluck, take } from 'rxjs/operators';
import { FirestoreService } from './firestore.service';
import { AuthService } from './auth.service';
import { keys } from 'lodash';

export interface WeekDayItem {
  id: number;
  name: string;
  prop: string;
}

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  private translatorService: TranslatorClientService;
  public lists: IObjectMap<any> = {};

  constructor(
    private utilitiesService: UtilitiesService,
    private afsDB: FirestoreService,
    private authService: AuthService
  ) {
    const options: ITranslatorConfigOptions = {
      appId: environment.labelsAppId,
      sync: true,
      labelStore: localStorage,
    };

    this.translatorService = new TranslatorClientService(options);
  }

  private toCustomLabels(lbs: CustomLabels): IObjectMap<string> {
    const customLabels: IObjectMap<string> = {};

    if (lbs) {
      // executor label names
      const exec = lbs.executorLabelName;
      if (exec) {
        customLabels.executor = ExecutorLabelTypes[exec];
        customLabels.executors = ExecutorLabelTypes[exec] + 's';
      }

      // coordinator label names
      const coord = lbs.coordinatorLabelName;
      if (coord) {
        customLabels.coordinator = CoordinatorLabelTypes[coord];
        customLabels.coordinators = CoordinatorLabelTypes[coord] + 's';
      }

      // professional label names
      const prof = lbs.professionalLabelName;
      if (prof) {
        customLabels.professional = ProfessionalLabelTypes[prof];
        customLabels.professionals = ProfessionalLabelTypes[prof] + 's';
      }

      // executor label names
      const wa = lbs.workingAreaLabelName;
      if (wa) {
        customLabels.workingArea = WorkingAreaLabelTypes[wa];
        customLabels.workingAreas = WorkingAreaLabelTypes[wa] + 's';
      }

      // executor label names
      const rqn = lbs.requestLabelName;
      if (rqn) {
        customLabels.request = RequestLabelTypes[rqn];
        customLabels.requests = RequestLabelTypes[rqn] + 's';
      }

      // executor label names
      const svn = lbs.serviceLabelName;
      if (svn) {
        customLabels.service = ServiceLabelTypes[svn];
        customLabels.services = ServiceLabelTypes[svn] + 's';
      }

      const ccl = lbs.customerLabelName;
      if (ccl) {
        customLabels.customer = CustomerLabelTypes[ccl];
        customLabels.customers = CustomerLabelTypes[ccl] + 's'
      }
    }

    for (const k of keys(customLabels)) {
      if (k === customLabels[k]) {
        customLabels[k] = 'i' + customLabels[k];
      }
    }

    return customLabels;
  }

  init() {
    return new Promise<void>(async (resolve) => {
      const org = await this.authService.authState$.pipe(
        filter(auth => auth !== undefined),
        map(auth => {
          return auth ? this.afsDB.docWithId$<Organization>(
            `/organizations/${localStorage.getItem('user_organization')}`
          ) : of<Organization>(null);
        }),
        mergeAll(),
        take(1),
      ).toPromise();
      const workingArea = (JSON.parse(localStorage.getItem('user_role')) as Role)?.workingArea;
      let customLabels = org?.customLabels;
      if (workingArea?.settings?.customLabelsWorkingArea) {
        const workingAreaCustomLabels = workingArea.settings.customLabelsWorkingArea
        const availableWorkingAreaLabels = Object.entries(workingAreaCustomLabels).map(entry => {
          if (entry[1]) return { [entry[0]]: entry[1] }
          else return null;
        }).filter(l => !!l)
        customLabels = Object.assign(org?.customLabels, ...availableWorkingAreaLabels);
      }
      await this.translatorService.init(this.toCustomLabels(customLabels));

      this.createLists();
      resolve();
    });
  }

  // gets default Ilabels provider for labels
  public defaultProvider(): ILabelsProvider {
    return {
      _translate: (val: string, _varMap?: IObjectMap<any>) => val
    };
  }

  public getLabels$(segmentId: string): Observable<ILabelsProvider> {
    return from(this.getLabels(segmentId)).pipe(
      pluck('data')
    );
  }

  public setLanguge(language: Language) {
    this.translatorService.setDefaultLanguage(language.symbol);
  }

  public updateCustomLabels(lbs: CustomLabels): void {
    this.translatorService.setCustomLabels(
      this.toCustomLabels(lbs),
    );
  }

  public getLabels(segmentId: string): Promise<{ data: ILabelsProvider }> {
    return this.translatorService.getlabels(segmentId);
  }

  getSupportedLanguages(): Language[] {
    return this.translatorService.getActiveLanguages();
  }

  private createLists() {
    this.lists['weekday_list'] = this.getWeekdayList();
    this.lists['week_frequencies'] = this.getWeekFrequencyList();
    this.lists['template_frequecy_types'] = this.getTemplateFrequencyTypes();
    this.lists['social_relation_list'] = this.getSocialRelations();
  }

  public weekIdToProp(id: number): string {
    return [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ][id];
  }

  async getWeekdayList(): Promise<WeekDayItem[]> {
    const labels: any = (await this.getLabels('weekday_list')).data;
    const items = [
      labels.sunday,
      labels.monday,
      labels.tuesday,
      labels.wednesday,
      labels.thursday,
      labels.friday,
      labels.saturday,
    ];

    return items.map((item: string, index) => {
      return {
        id: index,
        name: item,
        prop: this.weekIdToProp(index)
      };
    });
  }

  public async getMonthNames(): Promise<{ id: number; name: string; }[]> {
    const labels: any = (await this.getLabels('months_list')).data;
    const months = [
      labels.january,
      labels.february,
      labels.march,
      labels.april,
      labels.may,
      labels.june,
      labels.july,
      labels.august,
      labels.september,
      labels.october,
      labels.november,
      labels.december
    ];

    return months.map((item: string, index: number) => {
      return {
        id: index,
        name: item
      };
    });
  }

  private async getLabelOptions(segment: string, enumObj: Object) {
    const labels: any = (await this.getLabels(segment)).data;
    const result = [];

    this.utilitiesService.getKeysForEnum(enumObj).forEach(item => {
      const labelField = enumObj[item];
      result.push({
        name: labels[labelField],
        id: item
      });
    });

    return result;
  }

  private async getTemplateFrequencyTypes() {
    return this.getLabelOptions(
      'enum-template-frequency-types',
      GroupActivityTemplateFrequencyTypes
    );
  }

  public async getSocialRelations() {
    return this.getLabelOptions(
      'enum-social-relations-types',
      SocialRelationKindTypes
    );
  }

  private async getWeekFrequencyList() {
    return this.getLabelOptions(
      'enum-week-frequencies',
      GroupActivityTemplateFrequency
    );
  }
}
