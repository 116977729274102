import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreService, DocItem, QueryFN } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import { IObjectMap } from '@shared/interface';
import { GroupMember, GroupActivityTemplate, GroupActivityAttendanceTypes, GroupActivityMember, User, GroupActivity } from '@shared/model';
import { values } from 'lodash';
import { ApiService } from './api.service';
import { isAfter, isEqual, startOfDay, subDays } from 'date-fns';

@Injectable({
    providedIn: 'root',
})
export class GroupActivityService {
    private orgId: string;

    constructor(
        private localStorageService: LocalStorageService,
        private afsDB: FirestoreService,
        private apiService: ApiService
    ) {
        this.orgId = this.localStorageService.getItemSync('user_organization');
        this.localStorageService.getItem('user_organization').subscribe(orgId => {
            this.orgId = orgId;
        });
    }

    public getGroupActivityById(id: string): Observable<GroupActivity> {
        return this.afsDB.docWithId$(`/organizations/${this.orgId}/group-activities/${id}`);
    }

    public updateGroupActivity(activity: GroupActivity): Promise<any> {
        return this.afsDB.update(`/organizations/${this.orgId}/group-activities/${activity.id}`, activity as DocItem);
    }

    public deleteGroupActivities(activityIds: string[]): Promise<any> {
        return this.afsDB.batchDelete(activityIds, `/organizations/${this.orgId}/group-activities`);
    }

    public getGroupActivities(queryFn?: QueryFN): Observable<GroupActivity[]> {
        return this.afsDB.colWithIds$(`/organizations/${this.orgId}/group-activities`, queryFn);
    }

    public updateGroupActivities(activities: GroupActivity[]): Promise<any> {
        return this.afsDB.batchUpdate(activities as DocItem[], `/organizations/${this.orgId}/group-activities`);
    }

    public batchSetGroupActivities(activities: GroupActivity[]): Promise<any> {
        return this.afsDB.batchSet(activities as DocItem[], `/organizations/${this.orgId}/group-activities`);
    }

    public batchUpdateGroupActivities(activities: GroupActivity[]): Promise<any> {
        return this.afsDB.batchUpdate(activities as DocItem[], `/organizations/${this.orgId}/group-activities`);
    }

    public getGroupActivitiesForGroup(groupId: string): Observable<GroupActivity[]> {
        return this.getGroupActivities(ref => {
            return ref.where('groupId', '==', groupId);
        });
    }

    public getUserTemplates(): Observable<GroupActivityTemplate[]> {
        const userId = this.localStorageService.getItemSync('user_id');
        return this.afsDB.colWithIds$(`/organizations/${this.orgId}/users/${userId}/activity-templates`);
    }

    public saveGroupActivityTemplate(template: GroupActivityTemplate): Promise<any> {
        const userId = this.localStorageService.getItemSync('user_id');
        return this.afsDB.setDoc(
            `/organizations/${this.orgId}/users/${userId}/activity-templates/${template.id}`,
            template as DocItem
        );
    }

    public groupMembersToActivityMembers(
        members: IObjectMap<GroupMember>, attendance: GroupActivityAttendanceTypes = GroupActivityAttendanceTypes.open
    ): IObjectMap<GroupActivityMember> {
        const activityMembers: IObjectMap<GroupActivityMember> = {};

        values(members).forEach(member => {
            activityMembers[member.id] = Object.assign({}, member, {
                attendance: attendance
            });
        });

        return activityMembers;
    }

    public createGroupActivityRecords(groupId: string, groupActivityId: string) {
        return this.apiService.post('activities/group/create-records', { groupId, groupActivityId, orgId: this.orgId })
    }

    public deleteGroupActivityRecords(activityGroupId: string) {
        return this.apiService.post('activities/group/delete-records', {activityGroupId, orgId: this.orgId})
    }

    public updateRefActivities(groupActivities: GroupActivity[], addedUsers?: User[], removedMembers?: GroupMember[]) {
        const upcomingActivities: GroupActivity[] = groupActivities.filter(activity => {
            const date = startOfDay(subDays(new Date(), 1));

            return isEqual(activity.startDate, date) || isAfter(activity.startDate, date);
        });

        // added
        if (addedUsers && addedUsers.length > 0) {
            addedUsers.forEach(user => {
                upcomingActivities.forEach(activity => {
                    activity.participants[user.id] = {
                        id: user.id,
                        firstName: user.firstname,
                        lastName: user.lastname,
                        picture: user.picture || '',
                        attendance: GroupActivityAttendanceTypes.open
                    };
                });
            });
        }

        // removed
        if (removedMembers && removedMembers.length > 0) {
            upcomingActivities.forEach(activity => {
                values(removedMembers).forEach(member => {
                    delete activity.participants[member.id];
                });
            });
        }

        if (upcomingActivities.length > 0) {
            this.batchUpdateGroupActivities(upcomingActivities);
        }
    }
}
