import { Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IToolbarSearchEvent, ToolbarSearchService, LabelService, UtilitiesService } from '@core/services';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@shared/BaseComponent';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, share } from 'rxjs/operators';

@Component({
  selector: 'app-toolbar-search',
  templateUrl: './toolbar-search.component.html',
  styleUrls: ['./toolbar-search.component.scss']
})
export class ToolbarSearchComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @Input() public placeholder: string;
  @Input() hostClass = '';
  @Input() searchText: string;

  public myControl: FormControl = new FormControl();
  public isCancelEnabled = false;
  public labels: any = {};

  @HostBinding('class.expanded') enableFullWidthSearch = false;

  constructor(
    private labelService: LabelService,
    private toolbarSearchService: ToolbarSearchService,
    private utilitiesService: UtilitiesService
  ) {
    super();
  }

  async ngOnInit() {
    this.initPlaceholder();
    this.initValueChange();
    this.labels = (await this.labelService.getLabels('app-toolbar-search')).data;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.searchText && this.utilitiesService.isNewChange(changes.searchText)) {
      this.myControl = new FormControl(this.searchText);
    }
  }

  public initValueChange() {
    const newSub: Subscription = this.myControl.valueChanges
      .pipe(debounceTime(700), distinctUntilChanged(), share())
      .subscribe((val: string) => {
        this.toolbarSearchService.setSearchWord(val.trim());
        this.isCancelEnabled = val.length > 0;
      });

    this.subscriptions.push(newSub);
  }

  public initPlaceholder() {
    const newSub: Subscription = this.toolbarSearchService.getEmitter().asObservable()
      .subscribe((event: IToolbarSearchEvent) => {
        if (event.event === 'placeholder') {
          this.placeholder = event.val;
        }

        if (event.event === 'reset') {
          this.placeholder = event.val;
          this.clearInput();
        }
      });
    this.subscriptions.push(newSub);
  }

  public clearInput() {
    this.myControl.setValue('');
    /**
     * it is double, we have subscriprion in initValueChange
     */
    // this.toolbarSearchService.setSearchWord('');
  }

  public closeSearch() {
    if (this.isCancelEnabled) {
      this.clearInput();
    } else {
      this.enableFullWidthSearch = false;
    }
  }
}

