<app-modal>
  <div class="modal_header" #modal_header>
    <div fxLayout="row">
      <div fxFlex fxLayoutAlign="start center">
        <h3>{{labels['close-request']}}</h3>
      </div>
    </div>
  </div>

  <div class="modal_body" #modal_body>
    <form [formGroup]="form" fxLayout="column" role="form" *ngIf="form">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{labels.result}}</mat-label>
        <mat-select formControlName="result">
          <mat-option *ngFor="let result of results" [value]="result">
            {{ labels['closed_request_' + requestResults[result]] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" *ngIf="form.value.result === requestResults.stopped">
        <mat-label>{{labels.stopped_reason}}</mat-label>
        <mat-select formControlName="requestStoppedReason">
          <mat-option *ngFor="let reason of reasons" [value]="reason">
            {{ labels['request_stopped_reason_' + requestStoppedReasons[reason]] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" *ngIf="form.controls.result.value === requestResults.redirected">
        <mat-label>{{labels.redirectedTo}}</mat-label>
        <mat-select formControlName="redirectedTo">
          <mat-option *ngFor="let value of currentArea.settings.redirectedToValues" [value]="value">
            {{ value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" *ngIf="form.controls.result.value === requestResults.completed && 
      currentArea.settings.followupStepsValues?.length">
      <mat-label>{{labels.followup_steps}}</mat-label>
        <mat-select formControlName="followupSteps">
          <mat-option *ngFor="let value of currentArea.settings.followupStepsValues" [value]="value">
            {{ value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="textarea">
        <mat-label>{{labels.close_remark}}</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows minRows="3"
          formControlName="closeRemark">
        </textarea>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{labels.end_date}}</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-checkbox color="primary" *ngIf="service.settings.repeatRequest.activated" formControlName="repeatOption">
        {{labels.repeat_request}}</mat-checkbox>
    </form>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
    <button mat-button (click)="closeModal()">{{labels.cancel}}</button>
    <button mat-raised-button color="primary" (click)="save()" *ngIf="form" [disabled]="!form.valid || loading">
      {{labels.close_request}}
    </button>
  </div>
</app-modal>