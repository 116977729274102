import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { cloneDeep, find, has, orderBy, uniqBy } from 'lodash';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Role, Service, Activity, ActivityKindTypes, ActivityAttachment, Organization, ActivityScheduleStatus, Request, ActivityScheduleDetails } from '@shared/model';
import { RoleTypes } from '@shared/enum';
import { LabelService, UtilitiesService, FileUploadService, ActivityService, UserPromptsService, FileService, LocalStorageService, OrgServiceService, WorkingAreaService, OrganizationService } from '@core/index';
import { format, getHours, getMinutes, getSeconds, setHours, setMinutes, setSeconds } from 'date-fns';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { DocInfoComponent } from '../doc-info/docinfo.component';
import { NgSub } from 'ng-sub';
import { AccountingService } from '@core/services/accounting/accounting.service';
import { SaveActivityComponent } from '@shared/components/save-activity/save-activity.component';
import { VisibleForDialogComponent } from './visible-for-dialog/visible-for-dialog.component';

export enum ActivityAddMode {
  full = 1,
  readonly = 2,
  deny = 3,
  update = 4
}

export interface ActivityAddDialogInputData {
  role: Role;
  services: Service[];
  mode?: ActivityAddMode;
  activity: Activity;
  request?: Request
}

export interface UploadProgressAttachment {
  progress: string;
  item: ActivityAttachment;
}

@Component({
  selector: 'app-activity-item-dialog-form',
  templateUrl: './activity-item-dialog-form.component.html',
  styleUrls: ['./activity-item-dialog-form.component.scss']
})
export class ActivityItemDialogFormComponent extends ModalComponent implements OnInit, AfterViewInit {
  public form: FormGroup;
  public deny = false;
  public mode: ActivityAddMode;
  public labels: any = {};
  public activityKinds: ActivityKindTypes[];
  public ActivityKindTypes = ActivityKindTypes;
  public disableWorkArea: boolean;
  public disableServiceInput: boolean;
  public attachmentsForView: UploadProgressAttachment[];
  public isExecutor = true;
  public invoicing = false;
  public isEdit = false;

  @ViewChild('fileSelectorElem', { static: true }) fileInput: ElementRef;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public activity: Activity;
  protected request: Request;
  private selectedService: Service;
  private uploadProgressAttachments: UploadProgressAttachment[] = [];
  private attachments: ActivityAttachment[];
  private org: Organization;
  private sub = new NgSub();
  private isPlanDateModified = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ActivityAddDialogInputData,
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<ActivityItemDialogFormComponent>,
    private labelService: LabelService,
    private fb: FormBuilder,
    private utilitiesService: UtilitiesService,
    private fileUploadService: FileUploadService,
    private fileService: FileService,
    private activityService: ActivityService,
    private userPromptsService: UserPromptsService,
    private localStore: LocalStorageService,
    private orgServiceService: OrgServiceService,
    private accountingService: AccountingService,
    private workAreaService: WorkingAreaService,
    private orgService: OrganizationService,
  ) {
    super(dialogRef, breakpointObserver);
  }

  async ngOnInit() {
    let mode: ActivityAddMode;
    this.activity = this.data.activity;
    this.request = cloneDeep(this.data.request);
    this.activity.attachments = this.activity.attachments || [];
    this.isExecutor = this.utilitiesService.rolesMatch(RoleTypes.excecutor);

    if (!this.activity.id) {
      this.activity.id = this.fileService.getID();
    } else {
      this.isEdit = true;
    }

    this.attachments = this.activity.attachments;
    this.updateList();

    this.activityKinds = this.utilitiesService.getKeysForEnum(ActivityKindTypes);
    this.dialogRef.disableClose = true; // disables backdrop close for this modal to prevent data loss

    this.orgService.getCurrentOrganization().pipe(takeUntil(this.sub)).subscribe({
      next: org => {
        this.org = org;
      },
      error: e => console.log(e),
    });

    switch (this.data.role.roleType) {
      case RoleTypes.coordinator:
      case RoleTypes.excecutor:
        mode = ActivityAddMode.readonly;
        break;
      case RoleTypes.professional:
      case RoleTypes.servicePoint:
        mode = ActivityAddMode.full;
        break;
      default:
        mode = ActivityAddMode.deny;
        this.deny = true;
        break;
    }

    this.mode = mode;

    if (!this.deny) {
      this.setupForm();
    }

    this.labels = (await this.labelService.getLabels('activity-item-dialog-form-component')).data;
  }

  ngAfterViewInit(): void {
    this.utilitiesService.delay(700).subscribe(() => {
      if (this.utilitiesService.rolesMatch(RoleTypes.professional)) {
        document.getElementById('serviceInput')?.focus();
      } else {
        document.getElementById('kindInput')?.focus();
      }
    });
  }

  public addInvoice(): void {
    this.invoicing = true;

    if (!this.activity.id || !this.activity.userId) {
      this.userPromptsService.showToast(this.labels.user_not_found);
      return;
    }

    this.accountingService.createInvoice(this.activity.userId, this.activity.id).then(result => {
      this.invoicing = true;

      if (result.success) {
        this.userPromptsService.showToast(this.labels.invoice_created);
      } else {
        this.userPromptsService.showToast(result.message);
      }
    }).catch(e => {
      console.log(e);
      this.userPromptsService.showToast(this.labels.error_encountered);
    })
  }

  public sortKindsByLabels(kinds: ActivityKindTypes[]): ActivityKindTypes[] {
    const ks = kinds.map(k => {
      return { k, label: this.labels[ActivityKindTypes[k]] };
    });

    return orderBy(ks, 'label', 'asc').map(a => a.k);
  }

  public isFileUploading(): boolean {
    const progress = this.uploadProgressAttachments.find(a => {
      return a.progress !== '' && parseInt(a.progress) >= 0 && parseInt(a.progress) <= 100;
    });

    return !!progress;
  }

  public canShowAmount(): boolean {
    const kind = this.form.get('kind').value;
    return kind === ActivityKindTypes.sale || kind === ActivityKindTypes.payment;
  }

  public isSalesKind(): boolean {
    // const kind = this.form.get('kind').value;
    const kind = this.activity?.kind;
    return kind === ActivityKindTypes.sale;
  }

  private setupForm() {
    this.form = this.fb.group({
      title: [this.activity.title, [Validators.required]],
      description: [this.activity.description],
      workarea: [this.data.role.workingArea.name],
      date: [this.activity.date ? format(this.activity.date, 'YYYY-MM-DD') : null, [Validators.required]],
      kind: [this.activity.kind, [Validators.required]],
      serviceId: [this.activity.serviceId, [Validators.required]],
      amount: [this.activity.amount],
      minutes: [this.activity.minutes, [Validators.min(0)]],
      planDate: [this.activity?.scheduleDetails?.planDate ? this.activity?.scheduleDetails?.planDate : null]
    });

    if (this.activity.titleFixed) {
      this.form.get('title').disable();
    } else {
      this.form.get('title').valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe((title: string) => {
        const val = this.utilitiesService.capitalizeFirstLetter(title, false);

        if (val) {
          this.form.get('title').setValue(val);
        }
      });
    }

    if (this.activity.minutesFixed) {
      this.form.get('minutes').disable();
    }

    if (this.activity.scheduleDetails && this.activity.scheduleDetails.status === ActivityScheduleStatus.completed) {
      this.form.get('planDate').disable();
    }

    this.disableWorkArea = true;

    if (this.data.services) {
      if (has(this.data.role, 'serviceId')) {
        this.selectedService = find(this.data.services, i => i.id === this.data.role.serviceId);

        if (this.selectedService) {
          this.form.get('serviceId').setValue(this.selectedService.id);
        }
      }
    } else {
      throw new Error('Services not set in instance');
    }

    if (this.selectedService || this.isReadOnlySelect()) {
      this.disableServiceInput = true;
    }

    if (this.data.mode === ActivityAddMode.update) {
      this.mode = ActivityAddMode.update;
    }

    this.sub.add(
      this.form.get('planDate').valueChanges.subscribe(value => {
        const dateCtl = this.form.get('date');
        if (value) {
          dateCtl.setValue(null);
          dateCtl.disable();
          this.isPlanDateModified = true
        } else {
          dateCtl.enable();
        }
      })
    );
    if (!this.activity.date) this.form.get('date').disable();
    else this.form.get('date').enable();
  }

  public canShowTracking(): boolean {
    return this.org?.settings?.financialActivityTracking;
  }

  onSave(): void {
    if (this.form.valid) {
      const data = this.form.value;
      const updated: Activity = Object.assign({}, this.activity, this.updateSchedule(data));

      if (!updated.titleFixed) {
        updated.titleFixed = false
      }

      if (!updated.minutesFixed) {
        updated.minutesFixed = false
      }

      if (this.selectedService) {
        updated.serviceId = this.selectedService.id;
      }

      if (!this.canShowAmount()) {
        updated.amount = 0;
      }

      this.save(updated);
    }
  }

  private parseDate(date: any) {
    if (date) {
      const now = new Date();
      return setSeconds(setMinutes(setHours(new Date(date), getHours(now)), getMinutes(now)), getSeconds(now));
    }
    return null;
  }

  private updateSchedule(model: any) {
    if ((this.isAddMode() && model.planDate) || (model.planDate && (!this.activity.date && !this.activity.scheduleDetails.planDate))) {
      this.activity.scheduleDetails = {
        isScheduled: true,
        status: ActivityScheduleStatus.pending,
        assignedTo: this.request?.management?.executorId || '',
        planDate: this.parseDate(model.planDate)
      };
      if (model.planDate) model.date = null;
    } else if (model.planDate) {
      this.activity.scheduleDetails.planDate = this.parseDate(model.planDate);
      if (!model.date) model.date = null;
    } else {
      model.date = this.parseDate(model.date);
      this.activity.scheduleDetails = JSON.parse(JSON.stringify(new ActivityScheduleDetails()));
    }
    delete model.planDate;
    if (model.date) model.date = this.parseDate(model.date);
    return model;
  }

  private save(updated: Activity) {
    if (this.isPlanDateModified) this.dialogRef.close(updated);
    else if ((this.isUpdateMode() && !updated.date)) {
      return this.saveAndContinue(updated);
    }
    this.dialogRef.close(updated);
  }

  private saveAndContinue(activity: Activity) {
    this.userPromptsService.showDialogue(SaveActivityComponent, { activity }, (activity: Activity) => {
      if (activity) {
        this.dialogRef.close(activity);
      }
    }, false, { width: '500px' });
  }

  trackByFn(index: number, item: { id: string }): string {
    return item.id + '_' + index;
  }

  isReadOnlySelect(): boolean {
    return this.mode === ActivityAddMode.readonly;
  }

  isAddMode() {
    return this.mode !== ActivityAddMode.update;
  }

  isUpdateMode() {
    return this.mode === ActivityAddMode.update;
  }

  public openAttachment(attach: ActivityAttachment): void {
    window.open(attach.url);
  }

  public deleteFile(attach: ActivityAttachment) {
    this.userPromptsService.showConfirmDialogue(
      this.labels.delete_attachment_message_title,
      this.labels.delete_attachment_message_body,
      async (confirm: boolean) => {
        if (confirm) {
          try {
            this.fileService.deleteFileByUrl(attach.url).then();

            // remove attachment from activity
            const index = this.activity.attachments.findIndex(att => att.id === attach.id);
            this.activity.attachments.splice(index, 1);
            this.updateList();

            // update activity
            await this.activityService.saveActivity(this.activity);

            this.userPromptsService.showToast(this.labels.attachment_deleted);
          } catch (e) {
            this.userPromptsService.showToast(this.labels.cannot_delete_attachment);
          }
        }
      }
    );
  }

  public async showFileInfo(attach: ActivityAttachment) {
    const [fileMeta, service, workingArea] = await Promise.all([
      this.fileUploadService.getFileInfo(attach.ref),
      this.orgServiceService.getServiceById(this.activity.serviceId).pipe(take(1)).toPromise(),
      this.workAreaService.getWorkingAreaById(this.activity.workareaId).pipe(take(1)).toPromise()
    ]);

    this.userPromptsService.showDialogue(DocInfoComponent, {
      fileName: attach.filename,
      serviceName: service.name,
      areaName: workingArea.name,
      uploadedAt: fileMeta.timeCreated
    });
  }

  public selectFile() {
    this.fileInput.nativeElement.click();
  }

  public async onFileSelected(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    const isRightFormat = this.fileUploadService.fileMatchesFormat(target.files[0], [
      'csv',
      'xls',
      'xlsx',
      'doc',
      'docx',
      'png',
      'jpg',
      'bpm',
      'pdf',
      'txt'
    ]);

    if (isRightFormat) {
      // here we upload the file to storage then we take the reference and save it to the user
      const file = target.files[0];
      const attachment: UploadProgressAttachment = {
        item: {
          id: this.fileService.getID(),
          filename: file.name,
          ref: ''
        },
        progress: ''
      }

      attachment.item.ref = `/organizations/${this.localStore.getItemSync('user_organization')}/users/${this.activity.userId}/docs/${attachment.item.id}_${file.name}`;
      this.uploadProgressAttachments.push(attachment);
      this.updateList();

      try {
        this.fileService.uploadFile(
          file,
          attachment.item.ref,
          percent => {
            attachment.progress = percent.toFixed(0);
          }
        ).then(downloadUrl => {
          attachment.item.url = downloadUrl;
          attachment.progress = '';
          this.userPromptsService.showToast(this.labels.attachment_added);
          this.updateList();

          this.activity.attachments.push(attachment.item);
        });

        // taggun api call
        const canTaggun = !this.form.get('amount').value && this.canShowAmount();

        if (canTaggun) {
          this.fileService.transcribeFileWithTaggun(file).then(res => {
            attachment.item.taggun = res;

            if (res.totalAmount && res.totalAmount.data) {
              // show user confirm modal here
              this.userPromptsService.showPromptDialogue<number>(
                this.labels.confirm_amount,
                this.labels.confirm_amount_text,
                { type: 'number', initialValue: res.totalAmount.data, }
              ).then(val => {
                if (val > 0) {
                  this.form.get('amount').setValue(val);
                }
              });
            }
          }).catch();
        }
      } catch (e) {
        this.userPromptsService.showToast(this.labels.upload_failed);

        const index = this.uploadProgressAttachments.findIndex(a => a.item.id === attachment.item.id);
        if (index >= 0) {
          this.uploadProgressAttachments.splice(index, 1);
        }
      }
    } else {
      this.userPromptsService.showToast(this.labels.wrong_file_format);
    }
  }

  private updateList(): void {
    const atts = this.attachments.map(a => {
      return { item: a, progress: '' };
    }).concat(this.uploadProgressAttachments);

    // @ts-ignore
    this.attachmentsForView = uniqBy(atts, 'item.id');
  }

  protected openVisibleForDialog() {
    this.userPromptsService.showDialogue(VisibleForDialogComponent, { activity: this.activity }, (activity: Activity) => {
      if (activity) this.activity = activity;
    }, false, { width: '300px' });
  }
}
