<div class="container_body">
    <div class="empty_list" *ngIf="workingAreas?.length === 0">
        {{labels.no_workingareas_with_catalog}}
    </div>

    <div *ngIf="workingAreas?.length > 0" fxLayout="column" fxLayoutGap="15px">
        <div *ngFor="let group of workingAreas" fxLayout="row" fxLayoutGap="15px">
            <div *ngFor="let workingArea of group" class="catalog-item">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title style="font-size: 15px;">{{workingArea.name}}</mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="card-content">
                        <p class="carriage_returns">
                            {{workingArea.description}}
                        </p>
                    </mat-card-content>

                    <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
                        <button mat-button color="primary" (click)="gotoServicesPage(workingArea)">
                            {{labels.services}}
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</div>