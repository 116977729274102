import { Injectable } from '@angular/core';
import { filter, take as _take } from 'lodash';
import { Observable, from } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SearchService } from './search.service';
import { FirestoreService, DocItem } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import { IInventoryItem, InventoryItem } from '@models/model';
import { TimestampDate } from 'timestamp-date';
import { InventoryFilter } from 'app/inventory-module/components/inventory-list/inventory-list.component';
import { QueryConstraintOptions, Where } from '@shared/model/firestore';

export interface ISearchInventoryQuery {
  color?: string;
  category?: string;
  status?: string;
  title?: string;
}

/**
 * not included in shared module, will be work only in InventoryModule
 */
@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  private colRef: string;
  private timestampDate = new TimestampDate();
  constructor(
    private searchService: SearchService,
    private afDb: FirestoreService,
    private localStorageService: LocalStorageService
  ) {
    this.colRef = `organizations/${this.localStorageService.getItemSync('user_organization')}/inventories`;

    this.localStorageService.getItem('user_organization').subscribe(orgId => {
      this.colRef = `organizations/${orgId}/inventories`;
    });
  }

  public search(query: ISearchInventoryQuery, serviceId: string, limit?: number): Observable<IInventoryItem[]> {
    const ref = this.colRef;
    const where: string[][] = [];

    if (query.color) {
      where.push(['color', '==', query.color]);
    }

    if (query.category) {
      where.push(['category', '==', query.category]);
    }

    if (query.status) {
      where.push(['status', '==', query.status]);
    }

    if (query.title) {
      query.title = query.title.trim().toLocaleLowerCase();
    }

    let obs$: Observable<IInventoryItem[]>;
    if (where.length > 0) {
      where.push(['serviceId', '==', serviceId]);

      obs$ = from(
        this.searchService.searchCollectionIndex<IInventoryItem>({
          searchText: query.title || query.status || query.category || query.color || '',
          ref: ref,
          limit: limit ? limit : null,
          where: where
        })
      );
    } else {
      obs$ = this.getList(serviceId).pipe(take(1));
    }

    return obs$.pipe(
      map(coll => {
        if (!coll || !coll.length) {
          return [];
        }
        if (query.title) {
          return filter(coll, i => i.searchIndex.index[query.title]) as IInventoryItem[];
        }
        return coll;
      }),
      map(coll => {
        if (coll.length > limit) {
          return _take(coll, limit);
        }
        return coll;
      })
    );
  }

  public async createInventory(inventory: IInventoryItem): Promise<any> {
    const existingInventory = await this.getInventoryByCode(inventory.code);
    if (existingInventory?.id) {
      return "Can not save, Product with the same code already exist"
    }
    inventory.id = this.afDb.getNewId();
    // return this.afDb.add(this.colRef, inventory);
    return this.afDb.setDoc(`${this.colRef}/${inventory.id}`, inventory as any);
  }

  public deleteInventory(id: string): Promise<void> {
    return this.afDb.remove(`${this.colRef}/${id}`);
  }

  public updateInventory(update: DocItem): Promise<any> {
    update = this.timestampDate.parseStringToDate(update);
    return this.afDb.update(`${this.colRef}/${update.id}`, update);
  }

  // public getListOld(serviceId: string): Observable<IInventoryItem[]> {
  //   return this.afDb.colWithIds$(this.colRef, ref => {
  //     return ref.where('serviceId', '==', serviceId).orderBy('title', 'asc').limit(15);
  //   });
  // }


  public getList(serviceId: string, limit?: number, lastRef?: any): Observable<IInventoryItem[]> {
    return this.afDb.colWithIds$<IInventoryItem>(this.colRef, ref => {
      ref = ref.where('serviceId', '==', serviceId).orderBy('title', 'asc');
      if (lastRef) {
        ref = ref.startAfter(lastRef);
      }
      if (limit) {
        ref = ref.limit(limit)
      }
      return ref;
    }, true)
  }

  public fetchMoreFilterList(query: ISearchInventoryQuery, serviceId: string, limit?: number, lastRef?: any): Observable<IInventoryItem[]> {
    return from(
      this.afDb.colWithIdsNoCache<IInventoryItem>(this.colRef, ref => {
        ref = ref.where('serviceId', '==', serviceId).orderBy('title', 'asc');
        if (query.category) {
          ref = ref.where('category', '==', query.category);
        }
        if (query.color) {
          ref = ref.where('color', '==', query.color);
        }
        if (query.status) {
          ref = ref.where('status', '==', query.status);
        }

        if (lastRef) {
          ref = ref.startAfter(lastRef);
        }
        if (limit) {
          ref = ref.limit(limit);
        }
        return ref;
      }, true)
    )
  }

  public getInventoriesForCustomer(customerId: string): Observable<IInventoryItem[]> {
    return this.afDb.colWithIds$(this.colRef, ref => {
      return ref.where('loanDetails.loanedToUser.id', '==', customerId);
    });
  }

  public getInventoryById(id: string): Observable<IInventoryItem> {
    return this.afDb.docWithId$(`${this.colRef}/${id}`);
  }

  public getInventoriesWithReservations(category?: string): Observable<InventoryItem[]> {
    return this.afDb.colWithIds$(this.colRef, ref => {
      if (category) {
        ref = ref.where('category', '==', category);
      }

      return ref.where('serviceId', '==', '')
        .where('reservationAvailable', '==', true);
    });
  }

  public getInventoryByCode(code: string): Promise<InventoryItem> {
    return new Promise(resolve => {
      if (code) {
        this.afDb.colWithIds$(this.colRef, (ref) => {
          return ref.where('code', '==', code).limit(1);
        }).pipe(take(1)).subscribe((res: InventoryItem[]) => {
          resolve(res.length > 0 ? res[0] : null);
        });
      } else {
        resolve(null);
      }
    })
  }

  public getInventoryCount(query: InventoryFilter) {
    return this.afDb.getCounts(this.colRef, () => [['serviceId', '==', query.serviceFilter]]);
  }

  public getAllInventoriesByFilter(query: InventoryFilter) {
    return this.afDb.colWithIdsNew$<InventoryItem>(this.colRef, () => {
      return this.getFilters(query);
    })
  }

  public searchInventoriesNew(query: InventoryFilter): Observable<InventoryItem[]> {
    if (query.textFilter) {
      let where = this.getFilters(query);

      return new Observable((observer) => {
        this.searchService.searchCollectionIndex<InventoryItem>({
          searchText: query.textFilter,
          ref: this.colRef,
          where: where,
          // order
        }).then(requests => {
          observer.next(requests);
        });
      });
    }
  }

  public getInventoriesByFilters(query: InventoryFilter, limit?: number, lastOrFirstRef?: any, isForwardNavigation?: boolean, isReloadedContext?: boolean) {
    const options: Partial<QueryConstraintOptions<InventoryItem>> = {};

    return this.afDb.colWithIdsNew$<InventoryItem>(this.colRef, () => {

      if (lastOrFirstRef) {
        options.orderBy = [{ field: 'title', val: 'asc' }];
        if (isReloadedContext) {
          options.startAt = lastOrFirstRef;
          options.limit = limit || 30;
        } else {
          if (isForwardNavigation) {
            options.startAfter = lastOrFirstRef;
            options.limit = limit || 30;
          } else {
            options.endBefore = lastOrFirstRef;
            options.limitToLast = limit || 30;
          }
        }
      } else {
        options.limit = limit || 30
      }
      return this.getFilters(query);
    }, options, true)
  }

  private getFilters(query: InventoryFilter) {
    const where: Where[] = [];
    if (query.colorFilter) {
      where.push(['color', '==', query.colorFilter]);
    }

    if (query.categoryFilter && query.categoryFilter !== '-1') {
      where.push(['category', '==', query.categoryFilter]);
    }

    if (query.statusFilter) {
      where.push(['status', '==', query.statusFilter]);
    }

    if (query.serviceFilter) {
      where.push(['serviceId', '==', query.serviceFilter]);
    }

    return where;
  }
}
