import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService, UserPromptsService, UserService } from '@core/services';
import { Institution, Request, User, UserContactPerson, UserPartner } from '@models/model';
import { values } from 'lodash';
import { take } from 'rxjs/operators';

export interface PersonContactListItem {
  name: string;
  role: string;
  phone: string;
  phoneRemark: string;
  phone2: string;
  phoneRemark2: string;
  email: string;
  emailRemark: string;
  email2: string;
  emailRemark2: string;
}

@Component({
  selector: 'app-request-users-info-dialog',
  templateUrl: './request-users-info-dialog.component.html',
  styleUrl: './request-users-info-dialog.component.scss'
})
export class RequestUsersInfoDialogComponent implements OnInit {

  protected dataSource: PersonContactListItem[];
  public labels = this.labelService.defaultProvider();
  protected isLoading = true;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { request?: Request, user?: User, institution: Institution },
    // private dialogRef: MatDialogRef<RequestUsersInfoDialogComponent>,
    private labelService: LabelService,
    private userService: UserService,
    private userPromptService: UserPromptsService,
    private clipboard: Clipboard
  ) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-request-users-info-dialog')).data;
    if (this.data.request) this.fetchRequestUsers();
    else if (this.data.user) this.fetchUsersConnections();
    else this.getInstitutionContactPerson();
  }
  
  private fetchRequestUsers() {
    const management = this.data.request.management;
    const data: PersonContactListItem[] = []

    Promise.all([
      this.userService.getUserById(management.customerId).pipe(take(1)).toPromise(),
      this.userService.getUserById(management?.executorId).pipe(take(1)).toPromise(),
      this.userService.getUsersFromIds(values(management.team).map(t => t.userId)).pipe(take(1)).toPromise(),
      this.userService.getUserById(this.data.request?.referredByContactPerson?.userId).pipe(take(1)).toPromise()
    ]).then(users => {
      users.forEach((user, i) => {
        if (Array.isArray(user)) {
          user.forEach(user => {
            data.push(this.mapRelation(user, this.getRole(i)))
          })
        } else {
          if (i == 0) {
            data.push(this.mapRelation(user, this.getRole(i)));
            data.push(this.mapContactPerson(user.userContactPerson));
            data.push(this.mapContactPerson(user.userContactPerson2));
            data.push(this.mapPartner(user.userPartner));
          } else data.push(this.mapRelation(user, this.getRole(i)));
        }
      })
      this.dataSource = data.filter(d => !!d);
      this.isLoading = false;
    })
  }


  private fetchUsersConnections() {
    const user = this.data.user;
    const data: PersonContactListItem[] = []
    data.push(this.mapRelation(user, this.labels.user_in_view));
    data.push(this.mapContactPerson(user.userContactPerson));
    data.push(this.mapContactPerson(user.userContactPerson2));
    data.push(this.mapPartner(user.userPartner));
    this.dataSource = data.filter(d => !!d);
    this.isLoading = false;
  }

  private getInstitutionContactPerson() {
    this.userService.getInstitutionContactPersons(this.data.institution.id).pipe(take(1)).toPromise().then(contactPersons => {
      this.dataSource = contactPersons.map(person => this.mapRelation(person, this.labels.social_relation_kind)).filter(u => !!u);
      this.isLoading = false;
    })
  }

  private mapRelation(user: User, role: string) {
    if (!user) return null;
    return {
      email: user.email,
      email2: user.email2,
      emailRemark: user.emailRemark,
      emailRemark2: user.email2Remark,
      name: user.fullname,
      phone: user.phone,
      phone2: user.phone2,
      phoneRemark: user.phoneRemark,
      phoneRemark2: user.phone2Remark,
      role
    } as PersonContactListItem;
  }

  private getRole(index: number) {
    switch (index) {
      case 1: return this.labels.executor;
      case 2: return this.labels.team_member;
      case 3: return this.labels.referred_by;
      default: return this.labels.customer;
    }
  }

  protected copyData(value: string) {
    this.clipboard.copy(value);
    this.userPromptService.showToast(this.labels.copied);
  }

  private mapContactPerson(contactPerson: UserContactPerson) {
    if (!contactPerson?.firstName) return null;
    return {
      email: contactPerson?.email || '',
      name: `${contactPerson?.firstName || ''} ${contactPerson?.lastName || ''}`,
      phone: contactPerson?.phone || '',
      role: this.labels.social_relation_kind
    } as PersonContactListItem
  }

  private mapPartner(partner: UserPartner) {
    if (!partner?.firstname) return null;
    return {
      name: `${partner?.firstname || ''} ${partner?.lastname || ''}`,
      role: this.labels.partner,
      email: partner.email,
      phone: partner.phone
    } as PersonContactListItem
  }

}
