import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService } from '@core/services';
import { User } from '@shared/model';
import { IDataTableConfig, ITableData } from '@shared/components';
import { parse } from 'date-fns';
import { cloneDeep } from 'lodash';

interface InputPayload {
    users: User[];
}

@Component({
    selector: 'app-existing-users-list-modal',
    templateUrl: './existing-users-list-modal.component.html',
    styleUrls: ['./existing-users-list-modal.component.css']
})
export class ExistingUsersListModalComponent implements OnInit {
    public labels: any = {};
    public selectedUser: User;
    public usersTableConfig: IDataTableConfig;

    constructor(
        private labelService: LabelService,
        private dialogRef: MatDialogRef<ExistingUsersListModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InputPayload
    ) { }

    async ngOnInit() {
        this.updateTableConfig();

        this.labels = (await this.labelService.getLabels('app-existing-users-list-modal')).data;
    }

    private constructUserSubtitle(user: User): string {
        let result = `${user.address.street} ${user.address.number}`;

        if (user.birthday) {
            const birthday = parse(user.birthday);
            result += ` - ${birthday.toLocaleDateString(
                undefined,
                { day: 'numeric', month: 'short' }
            )}`;
        }

        return result;
    }

    private updateTableConfig(): void {
        const payload: ITableData[] = [];

        (this.data.users || []).forEach(user => {
            payload.push({
                userId: user.id,
                _rowClass: this.selectedUser && this.selectedUser.id === user.id ? 'service_reg_status-3' : '',
                user: {
                    title: `${user.firstname} ${user.lastname}`,
                    subtitle: this.constructUserSubtitle(user)
                },
                picture: user.picture,
                _metadata: {
                    originalData: user
                }
            });
        });

        this.usersTableConfig = {
            data: payload,
            displayProperties: ['picture', 'user'],
            headers: {},
            rowOptions: [],
            allowSelection: false,
            displayHeaders: false,
            propertyWithImage: 'picture'
        };
    }

    public handleSelectUser(row: ITableData) {
        const user: User = row._metadata.originalData as User;

        if (this.selectedUser && this.selectedUser.id === user.id) {
            delete this.selectedUser;
        } else {
            this.selectedUser = cloneDeep(user);
        }

        this.updateTableConfig();
    }

    public cancel() {
        this.dialogRef.close(false);
    }

    public continue() {
        this.dialogRef.close(this.selectedUser);
    }
}
