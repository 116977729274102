import { Injectable } from '@angular/core';
import { InventoryExportItem, LoanHistoryExportItem } from '@shared/model';
import { LabelService } from './labels.service';
import { ExportService } from './export.service';

@Injectable({
    providedIn: 'root',
})
export class ExportInventoryService {
    private labels: any = {};
    public exportInventoryTemplate: any;
    constructor(
        private labelService: LabelService,
        private exportService: ExportService
    ) {
        this.initLabels();
    }

    async initLabels() {
        this.labels = (await this.labelService.getLabels('export-inventory-service')).data;
        this.exportInventoryTemplate = {
            title: this.labels.title,
            description: this.labels.description,
            category: this.labels.category,
            code: this.labels.code
        }
    }

    public async makeInventoryExport(data: InventoryExportItem[] | any[], temp?: boolean) {
        const header: string[] = this.getExportHeaderRow(temp);
        const fields: string[][] = [header];

        data.forEach((item: any) => {
            fields.push(temp ? this.getExportTemplateRow(item) : this.getExportContentRow(item));
        });
        
        let fileName: string;
        if (temp) fileName = "inventory Template";
        else fileName = 'inventory';

        this.exportService.downloadArray(fields, fileName);
    }

    public async makeLoanHistoryExport(data: LoanHistoryExportItem[]) {
        const header: string[] = this.getLoanHistoryExportHeaderRow();
        const fields: string[][] = [header];

        data.forEach((item: LoanHistoryExportItem) => {
            fields.push(this.getExportLoanHistoryContent(item));
        });

        this.exportService.downloadArray(fields, `loan_history_export`);
    }

    private getExportContentRow(item: InventoryExportItem): string[] {
        return [
            item.id,
            item.title,
            item.color,
            item.category,
            item.description,
            item.replaceValue ? item.replaceValue.toString() : null,
            item.quality ? item.quality.toString() : null,
            item.startDate,
            item.status,
            item.loanNote,
            item.loanedToUser,
            item.loanedOutByUser,
            item.volunteer,
        ];
    }

    private getExportTemplateRow(item: any): string[] {
        return [
            item.title,
            item.category,
            item.description,
            item.code
        ];
    }

    private getExportLoanHistoryContent(item: LoanHistoryExportItem): string[] {
        return [
            item.startDate,
            item.endDate,
            item.loanNote,
            item.returnDate,
            item.returnNote,
            item.voluteerId,
            item.inventoryId,
            item.color,
            item.description,
            item.category,
            item.title,
            item.loanedToUser,
            item.volunteer,
        ];
    }

    private getLoanHistoryExportHeaderRow(): string[] {
        return [
            this.labels.startdate,
            this.labels.enddate,
            this.labels.loannote,
            this.labels.returndate,
            this.labels.returnnote,
            this.labels.volunteerid,
            this.labels.inventoryid,
            this.labels.color,
            this.labels.description,
            this.labels.category,
            this.labels.inventory_title,
            this.labels.title,
            this.labels.loanedToUser,
            this.labels.volunteer,
        ];
    }

    private getExportHeaderRow(temp: boolean): string[] {
        if (temp) {
            return [
                this.labels.title,
                this.labels.category,
                this.labels.description,
                this.labels.code
            ]
        }
        return [
            this.labels.id,
            this.labels.title,
            this.labels.color,
            this.labels.category,
            this.labels.description,
            this.labels.replaceValue,
            this.labels.quality,
            this.labels.startDate,
            this.labels.status,
            this.labels.loanedToUser,
            this.labels.loanedOutByUser,
            this.labels.volunteer,
            this.labels.loanNote,
        ];
    }
}
