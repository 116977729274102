<app-modal>
  <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
    <span class="modal_title">{{labels.search_person}}</span>
  </div>

  <div class="modal_body" #modal_body style="padding: 0;">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <div class="panel-body scroll-body" [ngStyle.xs]="{'height': '75vh'}">
      <div fxLayout="column" fxLayoutGap="10px" class="p-2">
        <form [formGroup]="form" *ngIf="form" autocomplete="off" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field appearance="outline">
            <mat-label>{{labels.search}}</mat-label>
            <input matInput type="text" formControlName="search" />
            <mat-icon matSuffix class="cursor-pointer" (click)="clearSearch()">close</mat-icon>
          </mat-form-field>

          <mat-slide-toggle color="primary" *ngIf="canFilterAllUsers()" [disabled]="form.value.search.length > 0"
            formControlName="lastSeen">
            {{labels.sort_on_last_seen}}
          </mat-slide-toggle>
        </form>

        <div *ngIf="userSelectionTableConfig?.data.length === 0 && !loading" style="height: 150px;"
          fxLayoutAlign="center center">
          <span>{{labels.empty_list}}</span>
        </div>

        <div *ngIf="userSelectionTableConfig?.data.length > 0">
          <data-table (onSelectRow)="onSelectRow($event)" [configOptions]="userSelectionTableConfig">
          </data-table>

          <div fxLayout="row" fxLayoutAlign="center center" *ngIf="lastRef">
            <button [disabled]="loading" mat-stroked-button color="accent"
              (click)="fetchUsers()">{{labels.fetch_more}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal_footer" #modal_footer>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
      <button mat-raised-button color="primary" (click)="proceedWithAction()">
        {{ labels.add_person }}
      </button>
    </div>
  </div>
</app-modal>