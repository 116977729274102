import { DocumentLog } from './documentLog';
import { IObjectMap } from '../interface';
import { Mailbox } from './service';
import { CoordinatorLabelTypes, CustomerLabelTypes, ExecutorLabelTypes, RequestLabelTypes, ServiceLabelTypes } from './organization';

export interface RedirectToInstitution {
  name: string;
  id: string;
  defaultText?: string;
}

export interface CustomLabelsWorkingArea {
  executorLabelName: ExecutorLabelTypes;
  requestLabelName: RequestLabelTypes;
  serviceLabelName: ServiceLabelTypes;
  coordinatorLabelName: CoordinatorLabelTypes;
  customerLabelName: CustomerLabelTypes;
}

export interface IWorkingAreaSettings {
  referredByValues?: string[];
  redirectedToValues?: string[];
  redirectedToInstitution?: RedirectToInstitution[];
  followupStepsValues?: string[];
  serviceCatalog?: boolean;
  requestKind: string[];
  customLabelsWorkingArea?: CustomLabelsWorkingArea;
}

export class WorkingArea {
  id?: string;
  name: string;
  description: string;
  professionals: IObjectMap<boolean>;
  services: IObjectMap<boolean>;
  active: boolean;
  log: DocumentLog;
  connectedCustomForms: IObjectMap<true>;
  settings: IWorkingAreaSettings;
  email: Mailbox;
  sharedMailboxFooter: string;
  
  constructor() {
    this.name = '';
    this.active = true;
    this.professionals = {};
    this.services = {};
    this.connectedCustomForms = {};
    // this.log = JSON.parse(JSON.stringify(new DocumentLog()));
    this.settings = {
      referredByValues: [],
      redirectedToValues: [],
      followupStepsValues: [],
      requestKind: [],
    };
  }
}
