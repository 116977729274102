import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';
import { times, each } from 'lodash';

interface IRateItem {
  value: number;
  isSelected: boolean;
}
@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateComponent implements OnInit {
  @Input() unselectedIcon: string;
  @Input() selectedIcon: string;
  @Input() max = 5;
  @Input() startValue = 0;
  @Input() step = 1;
  @Input() isCssIcons = false;
  @Output() selected = new EventEmitter<{ index: number; value: number }>();
  items: IRateItem[];

  constructor() {}
  ngOnInit() {
    const items = times(this.max, idx => {
      const itemNumber = idx + 1;
      const value = itemNumber * this.step;
      return {
        value: value,
        isSelected: value <= this.startValue
      };
    });
    this.items = items;
  }
  onSelect(index: number) {
    const itemNumber = index + 1;
    const value = itemNumber * this.step;
    each(this.items, item => {
      item.isSelected = item.value <= value;
    });
    this.selected.next({
      index: index,
      value: value
    });
  }
}
