import { DocumentLog } from "./documentLog";
import { SearchIndex } from "./searchIndex";
import { IObjectMap } from "../interface";
import { Address } from "./address";
import { IArea } from "./postcodeAddress";

export interface ILastView {
    lastViewedDate: Date;
    lastViewedBy: string;
}

export enum InstitutionTypes {
    school = 1,
    municipal,
    social_company,
    health_company
}

export class InstitutionContactPerson {
    id?: string;
    firstName: string;
    lastName: string;
    role: string;
    phone: string;
    email: string;
    institutionId: string;
    note: string; // max of 255 characters
    log?: DocumentLog;
    lastView: ILastView;
    searchIndex: SearchIndex;

    constructor() {
        this.note = '';
        this.lastView = {
            lastViewedBy: '',
            lastViewedDate: new Date()
        }
        this.searchIndex = {
            index: {},
            properties: [ 'firstName', 'lastName' ]
        }
    }
}

export class Institution {
    id?: string;
    name: string;
    website: string;
    offerDescription: string;
    type: InstitutionTypes;
    workingAreas: IObjectMap<true>; // working area ids map to true
    address: Address;
    area: IArea;
    searchIndex: SearchIndex;
    logoUrl: string;
    log?: DocumentLog;
    email: string;
    emailRemark: string;
    phone: string;
    phoneRemark: string;
    phone2: string;
    phone2Remark: string;
    email2: string;
    email2Remark: string;

    constructor() {
        this.logoUrl = '/assets/images/logo-default.png';
        this.searchIndex = {
            properties: ['name'],
            index: {}
        };

        this.workingAreas = {};
    }
}
