import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../auth.service';
import { combineLatest } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';

type EventNameString =
  | 'add_payment_info'
  | 'add_shipping_info'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'checkout_progress'
  | 'exception'
  | 'generate_lead'
  | 'login'
  | 'page_view'
  | 'purchase'
  | 'refund'
  | 'remove_from_cart'
  | 'screen_view'
  | 'search'
  | 'select_content'
  | 'select_item'
  | 'select_promotion'
  | 'set_checkout_option'
  | 'share'
  | 'sign_up'
  | 'timing_complete'
  | 'view_cart'
  | 'view_item'
  | 'view_item_list'
  | 'view_promotion'
  | 'view_search_results'
  | 'db_document_fetch'
  | 'db_collection_fetch';

export interface AnalyticsEvent {
  type: EventNameString;
  authId?: string;
  userId?: string;
  orgId?: string;
  url?: string;
  note?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private user: firebase.default.User;
  private orgId = '';
  private userId = '';

  constructor(
    private analytics: AngularFireAnalytics,
    private router: Router,
    private storageService: LocalStorageService,
    private authService: AuthService,
  ) { }

  public init(): void {
    this.storageService.getItems(['user_organization', 'user_id']).subscribe(arr => {
      this.orgId = arr[0];
      this.userId = arr[0];
    });

    combineLatest([
      this.router.events,
      this.authService.authState$
    ]).subscribe(arr => {
      this.user = arr[1];

      if (arr[0] instanceof NavigationEnd) {
        this.logEvent({
          type: 'page_view',
          note: 'page view',
        });
      }
    });
  }

  public logEvent(event: AnalyticsEvent): Promise<void> {
    event.authId = this.user?.uid || '';
    event.orgId = this.orgId;
    event.userId = this.userId;
    event.url = this.router.url;

    return this.analytics.logEvent(event.type, event);
  }
}
