import { Component, ContentChild, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  @ContentChild('modal_header', { static: true }) header: any;
  @ContentChild('modal_footer', { static: true }) footer: any;

  @Input() alignFooterStart: boolean;
  @Input() hideCloseButton: boolean;

  constructor(
    private matDialogRef: MatDialogRef<any>,
    public breakpointObserver?: BreakpointObserver
  ) { }

  closeModal() {
    this.matDialogRef.close();
  }
}
