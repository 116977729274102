<div fxLayout="column" *ngIf="user">
  <div class="info">
    <div></div>
    <div class="field">
      <div class="field-value">{{user.usernote}}
      </div>
    </div>
    <div class="field" fxLayout="column">
      <div class="field-label text-primary">{{labels.address}}</div>
      <div class="field-value">{{user.address?.street}} {{user.address?.number}} <span *ngIf="user.address?.letter"> - {{user.address?.letter}}</span>
      </div>
      <div class="field-value">{{user.address?.postalcode}} {{user.address?.city}}
      </div>
    </div>

    <div class="field" fxLayout="column">
      <div class="field-label text-primary" >{{labels.contact}}</div>
      <div class="field-value">
        <a class="phone" href="tel:{{user.phone}}">{{user.phone}}</a>
      </div>
      <div class="field-value">
        <a class="phone" href="tel:{{user.phone2}}">{{user.phone2}}</a>
      </div>
      <div class="field-value">
        <a class="email" href="mailto:{{user.email}}">{{user.email}}</a>
      </div>
      <div class="field-value">
        <a class="email" href="mailto:{{user.email}}">{{user.emailAuthorizedPerson}}</a>
      </div>
    </div>
    <div class="field">
      <div class="field-value">{{ labels[user.gender] }}, <span *ngIf="user.birthday">{{user.birthday | date}}</span></div>
    </div>

    <div class="field" *ngIf="user.area" fxLayout="column">
      <div class="field-value">{{user.area?.district.text}}
        <div class="field-value">{{user.area?.neighbourhood.text}}
        </div>
      </div>


    </div>
  </div>