import { DocumentLog } from './documentLog';
import { OrganizationTheme } from './organization';
import { Service } from './service';

export enum FindCompanyActionTypes {
  CREATE_OR_UPDATE_USER = 1,
}

export enum UserStatusTypes {
  created = 1,
  invited,
  started,
  active,
}

export interface FindCompanyUser {
  id?: string;
  firstName: string;
  lastName: string;
  postalcode: string;
  housenumber: number;
  location: { latitude: number; longitude: number; };
  email: string;
  companyId: string;
  serviceId: string;
  photoUrl: string;
  phoneNumber: string;
  profileBgImg: string;
  status: UserStatusTypes;
  meta: { language: string; };
  gender: string;
  dateOfBirth: string;
  lastActive?: Date;
  published?: boolean;
}

export interface FindCompanyServiceContact {
  firstName: string;
  lastName: string;
  email: string;
}

export class FindCompanyServiceModel {
  id?: string;
  name: string;
  companyId: string;
  interests: string[];
  url: string;
  isMfaEnabled: boolean;
  contact: FindCompanyServiceContact;
  logo: string;
  theme: OrganizationTheme;
  log: DocumentLog;
  audience: string[];
  showEvents: boolean; 
  
  constructor(orgId: string, service: Service) {
    this.companyId = orgId;
    this.id = service.id;
    this.name = service.name;
    this.isMfaEnabled = service.settings.findCompany.isMfaEnabled;
    this.interests = [];
    this.logo = '';
    this.theme = new OrganizationTheme();
    this.showEvents = service.settings.showEvents;
    this.audience = service.settings.audience;
    this.contact = {
      firstName: '',
      lastName: '',
      email: ''
    }
  }
}
