import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService, InventoryService, UserService, ISearchInventoryQuery, DocItem } from '@core/services';
import { IInventoryItem, IInventoryUser, InventoryItem, InventoryStatus, Role, Service, User } from '@models/model';
import { IDataTableConfig, ITableData } from '@shared/components/data-table/data-table.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { addDays } from 'date-fns';
import { NgSub } from 'ng-sub';
import { combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-inventory-loan-history-list-modal',
  templateUrl: './inventory-loan-history-list-modal.component.html',
  styleUrls: ['./inventory-loan-history-list-modal.component.scss']
})
export class InventoryLoanHistoryListModalComponent extends ModalComponent implements OnInit, OnDestroy {
  public labels = this.labelService.defaultProvider();
  public tableConfig: IDataTableConfig;
  public searchCtrl: FormControl;

  private sub = new NgSub();
  private dataSub = new NgSub();
  private filterPayload: ISearchInventoryQuery = { status: InventoryStatus.free, };
  private role: Role;
  private service: Service;
  private currentUser: User;
  private volunteer: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { customer: User; volunteerId?: string; },
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<InventoryLoanHistoryListModalComponent>,
    private labelService: LabelService,
    private inventoryService: InventoryService,
    private userService: UserService,
    private fb: FormBuilder,
  ) {
    super(dialogRef, breakpointObserver);
  }

  async ngOnInit() {
    this.dialogRef.disableClose = true;
    this.role = this.userService.getCurrentUserRole();
    this.service = this.role.service;
    this.setupForm();

    this.labels = (await this.labelService.getLabels('app-inventory-loan-history-list-modal')).data;

    this.fetchData();

    // fetch extra data
    combineLatest([
      this.userService.getCurrentUser(),
      this.data.volunteerId ? this.userService.getUserById(this.data.volunteerId) : of(null),
    ]).pipe(takeUntil(this.sub)).subscribe({
      next: arr => {
        this.currentUser = arr[0];
        this.volunteer = arr[1];
      },
      error: e => console.error(e),
    });
  }

  private fetchData(): void {
    this.dataSub.unsubscribe();
    this.dataSub = new NgSub();

    this.inventoryService.search(this.filterPayload, this.role.serviceId).pipe(takeUntil(this.dataSub)).subscribe({
      next: data => {
        this.tableConfig = {
          data: data.map(d => {
            const item: ITableData = {
              _metadata: { originalData: d },
              title: d.title,
              category: d.category,
            };

            return item;
          }),
          displayProperties: ['title', 'category'],
          headers: {
            title: this.labels.title,
            category: this.labels.category,
          },
          rowOptions: [],
          allowSelection: false,
          displayHeaders: true,
        };
      },
      error: e => console.error(e),
    });
  }

  private setupForm(): void {
    this.searchCtrl = this.fb.control('');

    this.searchCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.sub)
    ).subscribe(search => {
      this.filterPayload.title = search;
      this.fetchData();
    });
  }

  private getPersonDetails(user: User): IInventoryUser {
    if (user) {
      const item: IInventoryUser = {
        firstName: user.firstname,
        lastName: user.lastname,
        fullName: user.fullname,
        id: user.id,
      };

      return item;
    } else {
      return new InventoryItem().loanDetails.volunteer;
    }
  }

  public handleRowSelect(evt: ITableData): void {
    if (!this.currentUser) {
      return;
    }

    const inv: IInventoryItem = evt._metadata.originalData as IInventoryItem;
    inv.loanDetails = {
      volunteer: this.getPersonDetails(this.volunteer),
      loanedOutByUser: this.getPersonDetails(this.currentUser),
      loanNote: null,
      loanedToUser: this.getPersonDetails(this.data.customer),
      loanStart: new Date().toString(),
      endDate: addDays(new Date(), this.service?.settings.inventory.defaultLoanDays || 1).toString(),
    };

    this.inventoryService.updateInventory(inv as DocItem).catch();
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.dataSub.unsubscribe();
  }
}
