
import { asToolsApi } from '@models/model/api.model';
export const environment = {
  platform: 'live',
  production: true,
  labelsAppId: 'KIXnyTOfx2fkH96mXebD',
  taggunApiKey: '2da3fe90741c11eab7efc1191d38e165',
  apiEndpoint: 'https://europe-west1-v4work-prod.cloudfunctions.net/applicationApi/api/v1',
  apiPublicEndpoint: 'https://europe-west1-v4work-prod.cloudfunctions.net/publicApi/api/v1',
  asToolApi: asToolsApi.asToolApi,
  asToolApiKey: asToolsApi.asToolApiKey,
  firebase: {
    apiKey: 'AIzaSyBj1SZxkHfCE7ZWJMXJr-QjXGHvkuc5ua8',
    authDomain: 'v4work-prod.firebaseapp.com',
    projectId: 'v4work-prod',
    storageBucket: 'v4work-prod.appspot.com',
    messagingSenderId: '607836115862',
    appId: '1:607836115862:web:96f8ed46979f6c4fed43b7',
    measurementId: 'G-FLL4S82XNC'
  },
  googleMapAPIKey: 'AIzaSyCdeA9MMkG2sfQebvfWZLNzJKbV4MUXX3Q',
  recaptcha: {
    v3SiteKey: '6LfIhGwoAAAAAAaWqX-b3vGN1qfHJpqLasr8Dc-2'
  },
  addressApi: {
    key: 'r5uAxZjEHtJcy83sX6HF2LZif3HTk3xmllfLjM2CmyV',
    secret: 'YOWjneyIb2h7s4Zf9ZqSCN42uETXIZiL4MNk0BpYK4Xr9hpYx0'
  },
  exportDatabaseEndpoint: 'https://europe-west1-v4work-prod.cloudfunctions.net/exportDatabaseApi/api/v1',
};