import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { FirestoreService } from './firestore.service';
import { Observable, from } from 'rxjs';
import { Activity, ActivityKindTypes, UserConnectedServiceAndWorkingArea, Service, WorkingArea } from '@shared/model';
import { QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { DocItem } from '../../../../functions/src/interfaces';
import { UserService } from './user.service';
import { map, take } from 'rxjs/operators';
import { ApiService } from './api.service';
import { endOfMonth, endOfWeek, setMonth, setYear, startOfMonth, startOfWeek } from 'date-fns';
import { chunk, flatten } from 'lodash';
import { QueryConstraintOptions, Where } from '@shared/model/firestore';
import { RoleTypes } from '@models/enum';
import { ActivityFilter } from 'app/activity-module/components/activity-list-new/activity-list-new.component';
import { IObjectMap } from '@models/interface';

export interface ActivityItem {
    activity: Activity;
    service: Service;
    workingArea: WorkingArea;
}

export interface IActivityGroupRequestPayload {
    lastDocRef?: QueryDocumentSnapshot<any>;
    kind?: ActivityKindTypes;
    serviceId?: string;
    workingareaId?: string;
    requestId?: string;
    hasMinutes?: boolean;
    from?: Date;
    to?: Date;
    limit?: number;
    municipalityCode?: string;
    groupId?: string;
    groupActivityId?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ActivityService {
    private orgId: string;

    public constructor(
        private localStorageService: LocalStorageService,
        private afsDb: FirestoreService,
        private userService: UserService,
        private apiService: ApiService
    ) {
        this.orgId = this.localStorageService.getItemSync('user_organization');

        this.localStorageService.getItem('user_organization').subscribe(res => {
            this.orgId = res;
        });
    }

    public saveActivity(activity: Activity): Promise<void> {
        const docRef = `organizations/${this.orgId}/users/${activity.userId}/activities/${activity.id}`;
        return this.afsDb.setDoc(docRef, activity as DocItem);
    }

    public updateActivity(activity: Activity) {
        const docRef = `organizations/${this.orgId}/users/${activity.userId}/activities/${activity.id}`;
        return this.afsDb.update(docRef, activity as DocItem)
    }

    public getLastSeenActivitiesForUser(userId: string, date: Date): Observable<Activity[]> {
        const userRole = this.userService.getCurrentUserRole();
        const role = userRole.roleType;

        return this.apiService.get<Activity[]>(`users/${userId}/activities/last-seen-date`, {
            role: role,
            date: date.toISOString(),
        }).pipe(
            take(1),
            map(a => a.data || []),
        );
    }

    public updateLastSeenActivitiesForUser(userId: string, date: Date): Promise<Activity[]> {
        return this.apiService.put<Activity[]>(`users/${userId}/activities/last-seen`, {
            date: date.toISOString(),
        }).pipe(
            take(1),
            map(a => a.data),
        ).toPromise();
    }

    public getActivitiesGroup(queryFn?: any, addDocRef?: boolean, cache?: boolean): Observable<Activity[]> {
        if (cache) {
            return from(this.afsDb.colGroupWithIdsNoCache('activities', queryFn, addDocRef)) as Observable<Activity[]>;
        } else {
            return this.afsDb.colGroupWithIds$(`activities`, queryFn, addDocRef);
        }
    }

    public async getUserConnectedWorkingAreasAndServices(): Promise<IObjectMap<UserConnectedServiceAndWorkingArea>> {
        const userRole = this.userService.getCurrentUserRole();
        const role = userRole.roleType;

        const params = {
            role: role,
        };

        return this.apiService.get(`users/connected-workingareas-and-sevices`, params).pipe(
            take(1),
            map(a => (a.data || {}) as IObjectMap<UserConnectedServiceAndWorkingArea>),
        ).toPromise();
    }

    public getUserActivitiesForService(serviceId: string, userId: string): Observable<Activity[]> {
        return this.afsDb.colWithIds$(`/organizations/${this.orgId}/users/${userId}/activities`, ref => {
            return ref.where('serviceId', '==', serviceId);
        });
    }

    public getUserActivitiesForServiceCount(serviceId: string, userId: string) {
        return this.afsDb.getCounts(`/organizations/${this.orgId}/users/${userId}/activities`, () => {
            return [['serviceId', '==', serviceId]];
        });
    }

    public getActivityById(userId: string, activityId: string): Observable<Activity> {
        return this.afsDb.docWithId$(`/organizations/${this.orgId}/users/${userId}/activities/${activityId}`);
    }

    public getUserActivities(userId: string, opts: IActivityGroupRequestPayload, ref?: boolean): Observable<Activity[]> {
        const role = this.userService.getCurrentUserRole();
        return this.afsDb.colWithIds$(`/organizations/${this.orgId}/users/${userId}/activities`, ref => {
            if (opts.workingareaId && !opts.serviceId) {
                ref = ref.where('workareaId', '==', opts.workingareaId);
            }

            if (opts.serviceId) {
                ref = ref.where('serviceId', '==', opts.serviceId);
            }

            if (opts.kind) {
                ref = ref.where('kind', '==', opts.kind);
            }

            if (opts.requestId) {
                console.log('opts.requestId', opts.requestId);
                ref = ref.where('requestId', '==', opts.requestId);
            }

            if (opts.hasMinutes) {
                ref = ref.where('minutes', '>', 0);
            } else {
                if (opts.from) {
                    ref = ref.where('date', '>=', opts.from);
                }

                if (opts.to) {
                    ref = ref.where('date', '<=', opts.to);
                }

                ref = ref.orderBy('date', 'desc');
            }
     //temp fix, enable after mifration viaible for

            // if (role.roleType == RoleTypes.excecutor) {
            //     ref = ref.where('visibleFor.executor', '==', true);
            // }
            
            if (role.roleType == RoleTypes.servicePoint) {
                ref = ref.where('visibleFor.servicePoint', '==', true);
            }

            if (opts.limit > 0) {
                ref = ref.limit(opts.limit);
            }

            return opts.lastDocRef ? ref.startAfter(opts.lastDocRef) : ref;
        }, ref === undefined ? true : ref);
    }

    public getActivitiesForRequest(customerId: string, requestId: string) {
        return this.afsDb.colWithIds$<Activity>(`/organizations/${this.orgId}/users/${customerId}/activities`, ref => {
            return ref.where('requestId', '==', requestId);
        });
    }

    public getActivities(opts: IActivityGroupRequestPayload, cache?: boolean, sort: boolean = true): Observable<Activity[]> {
        const role = this.userService.getCurrentUserRole();
        return this.getActivitiesGroup(ref => {
            if (opts.workingareaId && !opts.serviceId) {
                ref = ref.where('workareaId', '==', opts.workingareaId);
            }

            if (opts.serviceId) {
                ref = ref.where('serviceId', '==', opts.serviceId);
            }

            if (opts.kind) {
                ref = ref.where('kind', '==', opts.kind);
            }

            if (opts.municipalityCode) {
                ref = ref.where('area.municipality.code', '==', opts.municipalityCode);
            }

            if (opts?.from) {
                ref = ref.where('date', '>=', opts.from);
            }

            if (opts?.to) {
                ref = ref.where('date', '<=', opts.to);
            }

            ref = ref.where('orgId', '==', this.orgId);
            
            if (sort) {
                ref = ref.orderBy('date', 'desc');
            }

            if (opts.limit > 0) {
                ref = ref.limit(opts.limit);
            }

            if (opts.requestId) {
                console.log('opts.requestId', opts.requestId);
                ref = ref.where('requestId', '==', opts.requestId)
            }

            // if (role.roleType == RoleTypes.excecutor) {
            //     ref = ref.where('visibleFor.executor', '==', true);
            // }

            if (role.roleType == RoleTypes.servicePoint) {
                ref = ref.where('visibleFor.servicePoint', '==', true);
            }

            if (opts.groupId) {
                ref = ref.where('groupId', '==', opts.groupId);
            }
            if (opts.groupActivityId) {
                ref = ref.where('groupActivityId', '==', opts.groupActivityId)
            }

            return opts.lastDocRef ? ref.startAfter(opts.lastDocRef) : ref;
        }, true, cache);
    }

    public getScheduleActivities(userId: string, requestId: string) {
        return this.afsDb.colWithIdsNew$(`organizations/${this.orgId}/users/${userId}/activities`, () => {
            return [['scheduleDetails.isScheduled', '==', true], ['requestId', '==', requestId]]
        })
    }

    public getThisWeekActivities(userId: string) {
        return this.afsDb.colWithIdsNew$<Activity>(`/organizations/${this.orgId}/users/${userId}/activities`, () => {
            const now = new Date();
            const weekStart = startOfWeek(now, { weekStartsOn: 1 });
            const weekEnd = endOfWeek(now, { weekStartsOn: 1 });
            return [['date', '>=', weekStart], ['date', '<=', weekEnd]];
        });
    }

    public async getActivitiesFromIds(activityIds: string[]) {
        return flatten(await Promise.all(
            chunk(activityIds, 30).map(ids => {
                return this.afsDb.colGroupWithIdsNoCache<Activity>('activities', ref => ref.where('id', 'in', ids));
            })
        ))
    }

    public getUserActivitiesCounts(userId: string, where?: () => Where[]) {
        return this.afsDb.getCounts(`/organizations/${this.orgId}/users/${userId}/activities`, where);
    }

    public getActivitiesCounts(where?: () => Where[]) {
        return this.afsDb.getCounts(`activities`, where, true);
    }
    
    public getIcon(kind: ActivityKindTypes) {
        switch (kind) {
            case ActivityKindTypes.note:
                return 'description';

            case ActivityKindTypes.contact_live:
                return 'home';

            case ActivityKindTypes.phonecall:
                return 'call';

            case ActivityKindTypes.contact_mail:
                return 'mail';

            case ActivityKindTypes.intake:
                return 'assignment_turned_in';

            case ActivityKindTypes.evaluation:
                return 'flag';

            case ActivityKindTypes.sale:
                return 'shopping_bag';

            case ActivityKindTypes.payment:
                return 'credit_card';

            default:
                return 'monitor';
        }
    }

    public getActivitiesByFilters(query: ActivityFilter, limit?: number, lastOrFirstRef?: any, isForwardNavigation?: boolean, isReloadedContext?: boolean) {
        const options: Partial<QueryConstraintOptions<Activity>> = {};
        
        return this.afsDb.colGroupWithIdsNew$('activities', () => {
            if (lastOrFirstRef) {
                options.orderBy = [{ field: 'date', val: 'desc' }]
                if (isReloadedContext) {
                    options.startAt = lastOrFirstRef;
                    options.limit = limit || 30;
                } else {
                    if (isForwardNavigation) {
                        options.startAfter = lastOrFirstRef;
                        options.limit = limit || 30;
                    } else {
                        options.endBefore = lastOrFirstRef;
                        options.limitToLast = limit || 30;
                    }
                }
            } else {
                options.limit = limit || 30
            }
            return this.getFilters(query);
        }, options, true)
    }

    private getFilters(query: ActivityFilter) { 
        const where: Where[] = [];
        where.push(['orgId', '==', this.orgId]);
        if (query.workingAreaFilter && !query.serviceFilter) {
            where.push(['workareaId', '==', query.workingAreaFilter]);
        }
        if (query.serviceFilter) {
            where.push(['serviceId', '==', query.serviceFilter]);
        }
        if (query.yearFilter && query.monthFilter) {
            const start = startOfMonth(setMonth(setYear(new Date(), query.yearFilter), query.monthFilter - 1));
            const end = endOfMonth(setMonth(setYear(new Date(), query.yearFilter), query.monthFilter - 1));
            where.push(['date', '>=', start]);
            where.push(['date', '<=', end]);
        }
        if (query.scheduledFilter) {
            where.push(['scheduleDetails.isScheduled', '==', query.scheduledFilter]);
        }
        return where;
    }

    public getAllActivitiesByFIlter(query: ActivityFilter) {
        return this.afsDb.colGroupWithIdsNew$<Activity>('activities', () => {
            return this.getFilters(query);
        }, undefined, true);
    }

}
