<div fxLayout="column" fxLayoutAlign="start top" class="modal" [ngStyle.xs]="{'max-height.%': 100}">
  <div *ngIf="header" class="modal_header" fxFlex="auto" fxLayout="row" fxLayoutAlign="start center" fxShrink="0"
    fxGrow="0">
    <div fxFlex="stretch">
      <ng-content select=".modal_header"></ng-content>
    </div>
    <div style="padding-right: 10px;" *ngIf="!hideCloseButton">
      <button mat-icon-button (click)="closeModal()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="modal_body" fxShrink="1" fxGrow="1">
    <ng-content select=".modal_body"></ng-content>
  </div>
  <div *ngIf="footer" class="modal_footer" [ngClass]="{'modal_footer_default_display': !alignFooterStart}">
    <ng-content select=".modal_footer"></ng-content>
  </div>
</div>