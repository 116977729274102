<app-modal [alignFooterStart]="true">
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{labels.email_footer}} - {{data.footerKind}}
        </span>
    </div>

    <div class="modal_body" #modal_body style="padding: 15px;">
        <mat-form-field appearance="outline" appearance="outline" class="full-width">
            <mat-label>{{labels.footer_message}}</mat-label>
            <textarea rows="4" [disabled]="data.disableEmailFooterField" [placeholder]="labels.email_footer_placeholder"
                matInput cdkTextareaAutosize [(ngModel)]="footerMessage" #model="ngModel"></textarea>
            <mat-hint>{{labels.email_footer_hint}}</mat-hint>
        </mat-form-field>
    </div>

    <div class="modal_footer" #modal_footer>
        <div fxLayout="row" fxLayoutGap="20px" [fxLayoutAlign]="data.disableEmailFooterField ? 'end' : 'space-between'">
            <button mat-stroked-button *ngIf="!data.disableEmailFooterField"
                (click)="htmlEditor()">{{labels.html_editor}}</button>
            <button color="primary" mat-raised-button [disabled]="model.invalid || model.pristine"
                (click)="save()">{{labels.save}}</button>
        </div>
    </div>
</app-modal>