<div class="main">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="15px">
        <mat-card fxLayout="column" [style]="{width: isMobile ? '100%' : '20%'}" fxLayoutAlign.xs="space-around">
            <div style="position: relative;">
                <div style="position: absolute; top: -20px; width: 100%;"><mat-progress-bar *ngIf="loading"
                        mode="indeterminate"></mat-progress-bar></div>
            </div>
            <div fxLayout="row">
                <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 5px;">
                    <mat-label>{{labels.search_term}}</mat-label>
                    <input matInput [(ngModel)]="serviceRegFilter.textFilter" (ngModelChange)="checkTextFilterValid()"
                        (keydown.enter)="handleFilterSubmit()">
                </mat-form-field>

                <div style="padding-left: 5px; padding-right: 10px; margin-top: 4px;">
                    <button mat-mini-fab [disabled]="!anyNonSearchTextFiltersSet() && !serviceRegFilter.textFilter"
                        (click)="clearFilters()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </mat-card>

        <mat-card fxLayout="column" [style]="{width: isMobile ? '100%' : '20%'}" fxLayoutAlign.xs="space-around">
            <div fxLayout="row">
                <mat-form-field appearance="outline" [ngClass]="[shouldHideServiceFilter ? '' : 'full-width']"
                    style="padding-left: 10px; padding-right: 10px;">
                    <mat-label>{{labels.statuses}}</mat-label>
                    <mat-select multiple [(ngModel)]="serviceRegFilter.statusFilter" (ngModelChange)="handleFilterSubmit()">
                        <mat-option [ngClass]="['status-'+status]" *ngFor="let status of serviceRegStatuses"
                            [value]="status">
                            {{labels[ServiceRegStatusTypes[status]]}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-card>

        <mat-card fxLayout="column" [style]="{width: isMobile ? '100%' : '20%'}" fxLayoutAlign.xs="space-around">
            <div fxLayout="row">
                <mat-form-field appearance="outline" *ngIf="!shouldHideServiceFilter" class="full-width"
                style="padding-left: 10px; padding-right: 10px;">
                    <mat-label>{{labels.service}}</mat-label>
                    <mat-select [(ngModel)]="serviceRegFilter.serviceFilter" (ngModelChange)="handleFilterSubmit()">
                        <mat-option [value]="null">{{labels.show_all}}</mat-option>
                        <mat-option *ngFor="let service of services" [value]="service.id">
                            {{service.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-card>

    </div>

    <div *ngIf="tableCfg?.data?.length > 0" style="padding-top: 20px;">
        <data-table [configOptions]="tableCfg" (onSelectRow)="handleRowSelect($event)"
            (rowMenuBtnClicked)="rowIconClicked($event)">
        </data-table>
    </div>

    <div *ngIf="tableCfg?.data?.length === 0 && !loading" class="py-4 empty_list">
        <app-empty-list [title]="labels.empty_service_title"
            [description]="labels.empty_service_catalog_dec"></app-empty-list>
    </div>

        <div style="margin-top: -10px;" class="footer">
            <mat-paginator (page)="onPaginationChange($event)" [length]="paginationOptions.totalSize"
                [pageSize]="paginationOptions.serviceRegistrationsPerPage" [pageIndex]="paginationOptions.pageIndex"
                [pageSizeOptions]="[5, 10, 30, 50]" aria-label="Select page">
            </mat-paginator>
        </div>
</div>