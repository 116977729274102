<app-modal>
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{labels.institutions}}
        </span>
    </div>

    <div class="modal_body" #modal_body style="padding: 15px;">
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        <div fxLayout="column" fxLayoutGap="15px">
            <div>
                @if (form && dataTableConfig?.data?.length && !isLoading) {
                <form [formGroup]="form" autocomplete="off" fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field appearance="outline">
                        <mat-label>{{labels.search}}</mat-label>
                        <input matInput type="text" formControlName="search" />
                        <mat-icon matSuffix class="cursor-pointer" (click)="clearSearch()">close</mat-icon>
                    </mat-form-field>
                </form>
                }
            </div>
            <div>
                @if (dataTableConfig && !isLoading) {
                <data-table [configOptions]="dataTableConfig" (onSelectRow)="handleRowSelect($event)">
                </data-table>
                }
                 @if (!dataTableConfig && !isLoading) {
                <app-empty-list [title]="labels.empty_title" [description]="labels.no_institution_for_org" />
                }
            </div>
        </div>
    </div>
</app-modal>