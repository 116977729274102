"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eliminateSimilarities = void 0;
var lodash_1 = require("lodash");
var eliminateSimilarities = function (a, b, comparator) {
  for (var i = 0; i < a.length; i++) {
    for (var j = 0; j < b.length; j++) {
      var condition = comparator ? (0, lodash_1.isEqual)(a[i][comparator], b[j][comparator]) : (0, lodash_1.isEqual)(a[i], b[j]);
      if (condition) {
        a.splice(i, 1);
        b.splice(j, 1);
        return (0, exports.eliminateSimilarities)(a, b, comparator);
      }
    }
  }
  return [a, b];
};
exports.eliminateSimilarities = eliminateSimilarities;