import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IObjectMap } from '@shared/interface';
import { User, Service, WorkingArea, RequestResult } from '@shared/model';
import { uniq, keyBy, values, upperCase } from 'lodash';
import { Request, RequestStoppedReason } from 'app/shared/model';
import { UserService } from './user.service';
import { OrgServiceService } from './org-service.service';
import { WorkingAreaService } from './workingarea.service';
import { LabelService } from './labels.service';
import { RequestStatus } from 'app/shared/enum';
import * as XLSX from "xlsx";
import { UnitTypes } from '@models/model/product';


export interface IExportServiceRequestSpiegel {
  personalNumber?: string;
  gender?: string;
  birthday?: string;
  postalcode: string;
  municipality?: string;
  start?: string;
  startWithCrisis: string;
  referredBy: string;
  helpKind: String;
  empty: string;
  end?: string;
  result?: string;
  requestStoppedReason?: string;
  status: string;
  firstName?: string;
  lastName?: string;
  requestKind?: string;
  title: string;
  referenceNumber: string;
}

export interface IExportServiceRequest {
  id?: string;
  workingArea?: string;
  service?: string;
  title?: string;
  start?: string;
  end?: string;
  status?: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  personalNumber?: string;
  gender?: string;
  customerNote?: string;
  executor?: string;
  coordinator?: string;
  closeRemark?: string;
  followupSteps?: string;
  redirectedTo?: string;
  referredBy?: string;
  result?: string;
  requestStoppedReason?: string;
  district?: string;
  neighbourhood?: string;
  municipality?: string;
  municipalityCode?: string;
  createdAt?: string;
  date?: string;
  budget: number;
  remainingBudget: number;
  unitKind: string;
  totalSpent: number;
  referenceNumber: string;
  productCode?: string;
  customerId: string;
  executorId: string;
  coordinatorId: string;
  workingAreaId: string;
  serviceId: string;
  contactPerson: string;
  institution: string;
}

export interface IExportUserOfRequest {
  firstname: string;
  lastname: string;
  birthday: string;
  gender: string;
  phone: string;
  phone2: string;
  email: string;
  city: string;
  number: string;
  postalcode: string;
  street: string;
  letter: string;
  customerNote: string;
  userId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  public exportRequestsLabels: IExportServiceRequest;
  public exportRequestsLabelsSpiegel: IExportServiceRequestSpiegel;
  public exportUserOfRequestLabels: IExportUserOfRequest;

  private xlsxConfig = {
    type: 'xlsx', // the type you want to download
    elementIdOrContent: 'exportItemElement', // the id of html/table element
  }

  // service labels
  global_labels: any = {};
  app_request_form_labels: any = {};
  global_address_and_contact: any = {};
  service_request_status: any = {};
  app_request_form: any = {};
  user_gender: any = {};
  app_close_request_form: any = {};
  app_reporting_export: any = {};

  constructor(
    public userService: UserService,
    public labelService: LabelService,
    public orgServiceService: OrgServiceService,
    public workingAreaService: WorkingAreaService,
  ) {
    this.initLabels();
  }

  async initLabels() {
    this.global_labels = (await this.labelService.getLabels('global-labels')).data;
    this.app_request_form_labels = (await this.labelService.getLabels('app-request-form')).data;
    this.global_address_and_contact = (await this.labelService.getLabels('global-address-and-contact')).data;
    this.service_request_status = (await this.labelService.getLabels('service-request-status')).data;
    this.app_close_request_form = (await this.labelService.getLabels('app-reporting-export')).data;
    this.app_reporting_export = (await this.labelService.getLabels('app-close-request-form')).data;
    this.app_request_form = (await this.labelService.getLabels('app-request-form')).data;
    this.user_gender = (await this.labelService.getLabels('user-gender')).data;

    this.exportRequestsLabelsSpiegel = {
      personalNumber: this.global_address_and_contact.personalNumber,
      gender: this.global_address_and_contact.gender,
      birthday: this.global_address_and_contact.birthday,
      postalcode: this.global_address_and_contact.postalcode,
      municipality: this.global_address_and_contact.municipality,
      start: this.app_request_form.startdate,
      startWithCrisis: this.app_request_form.startWithCrisis,
      referredBy: this.app_request_form['referred-by'],
      helpKind: this.app_request_form.kind,
      empty: this.global_labels.empty,
      end: this.app_request_form.enddate,
      result: this.app_request_form.result,
      requestStoppedReason: this.global_labels.requestStoppedReason,
      status: this.app_request_form.status,
      firstName: this.app_request_form.firstname,
      lastName: this.app_request_form.lastname,
      title: this.app_request_form.title,
      requestKind: this.app_request_form.kind,
      referenceNumber: this.app_request_form.referenceNumber
    };

    this.exportRequestsLabels = {
      id: this.global_labels['id'],
      workingArea: this.global_labels['working-area'],
      service: this.global_labels.service,
      title: this.app_request_form.title,
      start: this.app_request_form.startdate,
      end: this.app_request_form.enddate,
      status: this.app_request_form.status,
      firstName: this.app_request_form.firstname,
      lastName: this.app_request_form.lastname,
      birthday: this.global_address_and_contact.birthday,
      personalNumber: this.global_address_and_contact.personalNumber,
      gender: this.global_address_and_contact.gender,
      executor: this.app_request_form.executor,
      coordinator: this.app_request_form.coordinator,
      closeRemark: this.app_request_form['close-remark'],
      result: this.app_request_form.result,
      requestStoppedReason: this.global_labels.requestStoppedReason,
      redirectedTo: this.app_request_form['redirected-to'],
      followupSteps: this.app_request_form['followup-steps'],
      referredBy: this.app_request_form['referred-by'],
      district: this.app_request_form.district,
      neighbourhood: this.global_address_and_contact.neighbourhood,
      municipality: this.global_address_and_contact.municipality,
      municipalityCode: this.global_address_and_contact.municipalityCode,
      createdAt: this.global_labels.createdAt,
      date: this.global_labels.date,
      customerNote: this.global_labels.note,
      budget: this.global_labels.budget,
      remainingBudget: this.global_labels.remainingBudget,
      totalSpent: this.global_labels.totalSpent,
      unitKind: this.global_labels.unitKind,
      referenceNumber: this.app_reporting_export.referenceNumber,
      productCode: this.global_labels.product_code,
      customerId: this.app_reporting_export.customerId,
      executorId: this.app_reporting_export.executorId,
      coordinatorId: this.app_reporting_export.coordinatorId,
      workingAreaId: this.app_reporting_export.workingAreaId,
      serviceId: this.app_reporting_export.serviceId,
      contactPerson: this.global_labels.contact_person,
      institution: this.global_labels.institution
    };


    this.exportUserOfRequestLabels = {
      firstname: this.global_address_and_contact.firstname,
      lastname: this.global_address_and_contact.lastname,
      birthday: this.global_address_and_contact.birthday,
      gender: this.global_address_and_contact.gender,
      phone: this.global_address_and_contact.phone,
      phone2: this.global_address_and_contact.phone2,
      email: this.global_address_and_contact.email,
      city: this.global_address_and_contact.city,
      number: this.global_address_and_contact.number,
      postalcode: this.global_address_and_contact.postalcode,
      street: this.global_address_and_contact.street,
      letter: this.global_address_and_contact.letter,
      customerNote: this.global_labels.note,
      userId: this.app_reporting_export.customerId
    };
  }

  public async makeServiceRequestExport(requests: Request[], spiegel?: boolean) {
    const servicesMap: IObjectMap<Service> = await this.getServicesMap(requests);
    const workareasMap: IObjectMap<WorkingArea> = await this.getWorkareasMap(requests);
    const customersMap: IObjectMap<User> = await this.getCustomersMap(requests);
    const coordinatorsMap: IObjectMap<User> = await this.getCoordinatorsMap(requests);

    let header: string[]
    if (spiegel) {
      header = this.getExportRequestRowSpiegel(this.exportRequestsLabelsSpiegel);
    } else {
      header = this.getExportRequestRow(this.exportRequestsLabels);
    }

    const fields: string[][] = [header];

    requests.forEach((request: Request) => {
      servicesMap[request.management.serviceId] = servicesMap[request.management.serviceId] || new Service();
      workareasMap[request.management.workareaId] = workareasMap[request.management.workareaId] || new WorkingArea();
      customersMap[request.management.customerId] = customersMap[request.management.customerId] || new User();

      let lastNamePlus: string;

      if (customersMap[request.management.customerId].decedent) {
        lastNamePlus = `${customersMap[request.management.customerId].lastname} - ${this.global_labels.decedent}`;
      } else {
        lastNamePlus = customersMap[request.management.customerId].lastname;
      }

      if (spiegel) {

        let resultSpiegel = request.result ? this.app_request_form[`result-${request.result}`] : '';

        if (request.result === RequestResult.completed) {
          resultSpiegel = "01";
        }

        if (request.result === RequestResult.stopped) {

          switch (request.requestStoppedReason) {
            case RequestStoppedReason.inAccordance: {
              resultSpiegel = "02";
            }
            case RequestStoppedReason.unilaterallyClient: {
              resultSpiegel = "03";
            }
            case RequestStoppedReason.unilaterallyProvider: {
              resultSpiegel = "04";
            }
            case RequestStoppedReason.externalCircumstances: {
              resultSpiegel = "05";
            }
          }
        }

        const row: IExportServiceRequestSpiegel = {
          personalNumber: customersMap[request.management.customerId].personalNumber,
          gender: upperCase(this.user_gender[customersMap[request.management.customerId].gender].substring(0, 1)),
          birthday: customersMap[request.management.customerId].birthday,
          postalcode: customersMap[request.management.customerId].address.postalcode,
          municipality: '',
          start: `${request.startDate || ''}`,
          startWithCrisis: '02',
          referredBy: request.referredBy ? request.referredBy.substring(0, 2) : '??',
          helpKind: '',
          empty: '',
          end: request.isClosed ? `${request.endDate || ''}` : '',
          result: resultSpiegel.toString(),
          requestStoppedReason: request.requestStoppedReason ? this.app_close_request_form['request_stopped_reason_' + RequestStoppedReason[request.requestStoppedReason]] : '',
          status: this.service_request_status[RequestStatus[request.status]],
          firstName: customersMap[request.management.customerId].firstname,
          lastName: customersMap[request.management.customerId].lastname,
          title: request.title,
          requestKind: request.requestKind,
          referenceNumber: request.referenceNumber
        };
        fields.push(this.getExportRequestRowSpiegel(row));

      } else {
        const row: IExportServiceRequest = {
          id: request.id,
          workingArea: workareasMap[request.management.workareaId].name,
          service: servicesMap[request.management.serviceId].name,
          title: request.title,
          start: `${request.startDate || ''}`,
          end: `${request.endDate || ''}`,
          status: this.service_request_status[RequestStatus[request.status]],
          firstName: customersMap[request.management.customerId].firstname,
          lastName: lastNamePlus,
          birthday: customersMap[request.management.customerId].birthday,
          personalNumber: customersMap[request.management.customerId].personalNumber,
          gender: this.user_gender[customersMap[request.management.customerId].gender],
          executor: request.management.executorId ? request.management.executor.firstname : '',
          coordinator: coordinatorsMap[request.management.coordinatorId] ? coordinatorsMap[request.management.coordinatorId].fullname : '',
          closeRemark: request.closeRemark,
          result: request.result ? this.app_request_form[`result-${request.result}`] : '',
          requestStoppedReason: request.requestStoppedReason ? this.app_close_request_form[RequestStoppedReason[request.requestStoppedReason]] : '',
          redirectedTo: request.redirectedTo,
          followupSteps: request.followupSteps,
          referredBy: request.referredBy,
          serviceId: request.management.serviceId,
          contactPerson: `${request.referredByContactPerson?.firstname || ''} ${request.referredByContactPerson?.lastname || ''}`,
          district: request.area?.district.text || '',
          neighbourhood: request.area?.neighbourhood.text || '',
          // municipality: request.area ? request.area.municipality.text : '',
          municipality: customersMap[request.management.customerId].area ? customersMap[request.management.customerId].area.municipality.text : '',
          municipalityCode: request.area ? request.area.municipality.code.substring(2, 6) : '',
          createdAt: request.log.createdAt?.toLocaleString() || '',
          customerNote: customersMap[request.management.customerId].customerDetails.remarks.substring(0, 10000),
          budget: request.budget,
          totalSpent: request.activity?.totalSpent,
          remainingBudget: request.activity?.remainingBudget,
          unitKind: request.activity?.unitKind ? this.global_labels[UnitTypes[request.activity.unitKind]] : '',
          referenceNumber: request.referenceNumber,
          productCode: request.productCode,
          executorId: request.management.executorId || '',
          customerId: request.management.customerId || '',
          coordinatorId: request.management.coordinatorId || '',
          workingAreaId: request.management.workareaId,
          institution: `${request.referredByInstitution?.name || ''}`
        };
        fields.push(this.getExportRequestRow(row));
      }


    });
    this.downloadArray(fields, 'report_serviceRequests');
  }

  public async makeUsersFromRequestsExport(requests: Request[], type: string) {
    let users: IObjectMap<User> = {};

    if (type === 'customers') {
      users = await this.getCustomersMap(requests);
    } else if (type === 'volunteer') {
      users = await this.getExecutorsMap(requests);
    }
    const header: string[] = this.getExportUsersOfRequestRow(this.exportUserOfRequestLabels);
    const fields: string[][] = [header];
    values(users).forEach((user: User) => {
      if (user) {
        if (user.decedent) {
          const row: IExportUserOfRequest = {
            firstname: user.firstname,
            lastname: `${user.lastname} - ${this.global_labels.decedent}`,
            birthday: user.birthday,
            gender: this.user_gender[user.gender],
            phone: '',
            phone2: '',
            email: '',
            city: user.address.city,
            number: '',
            postalcode: user.address.postalcode.substring(0, 4) + ' ' + user.address.postalcode.substring(4, 2),
            street: '',
            letter: '',
            customerNote: '',
            userId: ''
          };
          fields.push(this.getExportUsersOfRequestRow(row));

        } else {
          const row: IExportUserOfRequest = {
            firstname: user.firstname,
            lastname: user.lastname,
            birthday: user.birthday,
            gender: this.user_gender[user.gender],
            phone: user.phone ? `'${user.phone}` : null,
            phone2: user.phone2 ? `'${user.phone2}` : null,
            email: user.email,
            city: user.address.city,
            number: user.address.number > 0 ? user.address.number.toString() : '',
            postalcode: user.address.postalcode.substr(0, 4) + ' ' + user.address.postalcode.substr(4, 2),
            street: user.address.street,
            letter: user.address.letter,
            customerNote: user.customerDetails.remarks.substring(0, 10000),
            userId: user.id
          };
          fields.push(this.getExportUsersOfRequestRow(row));
        }
      }
    });
    this.downloadArray(fields, `report_${type}`);
  }

  public async getCustomersMap(requests: Request[]): Promise<IObjectMap<User>> {
    let customersIds: string[] = requests.map((request: Request) => request.management.customerId);
    customersIds = uniq(customersIds);
    const customers: User[] = await this.userService.getUsersFromIds(customersIds).pipe(take(1)).toPromise();

    return keyBy(customers, 'id');
  }

  public async getCoordinatorsMap(requests: Request[]): Promise<IObjectMap<User>> {
    let coordinatorsIds: string[] = requests.map((request: Request) => request.management.coordinatorId);
    coordinatorsIds = uniq(coordinatorsIds);
    const coordinators: User[] = await this.userService.getUsersFromIds(coordinatorsIds).pipe(take(1)).toPromise();

    return keyBy(coordinators, 'id');
  }

  public async getExecutorsMap(requests: Request[]): Promise<IObjectMap<User>> {
    let executorsIds: string[] = requests.map((request: Request) => request.management.executorId);
    executorsIds = uniq(executorsIds);
    const executors: User[] = await this.userService.getUsersFromIds(executorsIds).pipe(take(1)).toPromise();

    return keyBy(executors, 'id');
  }

  public async getWorkareasMap(requests: Request[]): Promise<IObjectMap<WorkingArea>> {
    let workareasIds: string[] = requests.map((request: Request) => request.management.workareaId);
    workareasIds = uniq(workareasIds);
    const workareas: WorkingArea[] = await this.workingAreaService.getWorkareasFromIds(workareasIds).pipe(take(1)).toPromise();

    return keyBy(workareas, 'id');
  }

  public async getServicesMap(requests: Request[]): Promise<IObjectMap<Service>> {
    let servicesIds: string[] = requests.map((request: Request) => request.management.serviceId);
    servicesIds = uniq(servicesIds);
    const services: Service[] = await this.orgServiceService.getServicesFromRefs(servicesIds).pipe(take(1)).toPromise();

    return keyBy(services, 'id');
  }

  public getExportRequestRowSpiegel(row: IExportServiceRequestSpiegel): any[] {
    return [
      row.personalNumber,
      row.gender,
      row.birthday,
      row.postalcode,
      row.municipality,
      row.start,
      row.startWithCrisis,
      row.referredBy,
      row.helpKind,
      row.empty,
      row.end,
      row.result,
      row.requestStoppedReason,
      row.status,
      row.firstName,
      row.lastName,
      row.title,
      row.requestKind,
      row.referenceNumber
    ];
  }

  public getExportRequestRow(row: IExportServiceRequest): any[] {
    return [
      row.id,
      row.workingArea,
      row.service,
      row.title,
      row.start,
      row.end,
      row.status,
      row.firstName,
      row.lastName,
      row.personalNumber,
      row.gender,
      row.birthday,
      row.executor,
      row.coordinator,
      row.budget,
      row.totalSpent,
      row.remainingBudget,
      row.unitKind,
      row.closeRemark,
      row.result,
      row.requestStoppedReason,
      row.redirectedTo,
      row.followupSteps,
      row.referredBy,
      row.contactPerson,
      row.institution,
      row.district,
      row.neighbourhood,
      row.municipality,
      row.municipalityCode,
      row.createdAt,
      row.date,
      row.customerNote,
      row.referenceNumber,
      row.productCode,
      row.customerId,
      row.executorId,
      row.coordinatorId,
      row.workingAreaId,
      row.serviceId
    ];
  }

  public getExportUsersOfRequestRow(row: IExportUserOfRequest): string[] {
    return [
      row.firstname,
      row.lastname,
      row.street,
      row.number,
      row.letter,
      row.postalcode,
      row.city,
      row.gender,
      row.phone,
      row.phone2,
      row.email,
      row.birthday,
      row.customerNote,
      row.userId
    ];
  }

  public downloadArray(data: string[][], fileName: string, WSoptions?: { raw: boolean, dateNF: string }) {
    const table = document.createElement('table');
    const thead = document.createElement('thead');
    const tbody = document.createElement('tbody');

    table.setAttribute('id', 'exportItemElement');

    data.forEach(function (rowArray, index) {
      const tr = document.createElement('tr');

      for (const row of rowArray) {
        const cell = document.createElement(index === 0 ? 'th' : 'td');
        cell.innerText = row || '';
        tr.appendChild(cell);
      }

      if (index === 0) {
        thead.appendChild(tr);
      } else {
        tbody.appendChild(tr);
      }
    });

    table.appendChild(thead);
    table.appendChild(tbody);

    const previous = document.getElementById(this.xlsxConfig.elementIdOrContent);
    if (previous) {
      previous.remove();
    }

    const tableData = document.getElementById('exportContentContainer').appendChild(table);
    const worksheet = XLSX.utils.table_to_book(tableData, WSoptions);

    //set auto size columns 
    // https://stackoverflow.com/questions/24395693/how-to-set-cell-width-when-export-xlsx-files-with-js-xlsx

    // Process Data (add a new row)
    // const ws = workbook.Sheets["Sheet1"];
    // XLSX.utils.sheet_add_aoa(ws, [["Exported On " + new Date().toLocaleString()]], { origin: -1 });
    // -----
    // trying below to make one column formatted..not working!

    // var colNum = XLSX.utils.decode_col("H"); //decode_col converts Excel col name to an integer for col #
    // var fmt = '$0.00'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format

    // /* get worksheet range */
    // var range = XLSX.utils.decode_range(worksheet['Sheet1']);
    // for(var i = range.s.r + 1; i <= range.e.r; ++i) {
    //   /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
    //   var ref = XLSX.utils.encode_cell({r:i, c:colNum});
    //   /* if the particular row did not contain data for the column, the cell will not be generated */
    //   if(!worksheet[ref]) continue;
    //   /* `.t == "n"` for number cells */
    //   if(worksheet[ref].t != 'n') continue;
    //   /* assign the `.z` number format */
    //   worksheet[ref].z = fmt;
    // }



    const fn = `${fileName.substring(0, 30)}.${this.xlsxConfig.type}`;
    XLSX.writeFile(worksheet, fn);


  }

}
