import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  LabelService, ServiceRegistrationService,
  UserPromptsService, CustomFormsService, UserService,
  OrganizationService,
  CloudFunctionService, UsageService, DocItem, WorkingAreaService, OrgServiceService, RequestService, UtilitiesService
} from '@core/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  WorkingArea, Service, ICustomFormModel,
  User, Request, Organization, UsageActionTypes, Management, ICustomFormModelData, Activity, ActivityKindTypes
} from '@shared/model';
import { take, takeUntil } from 'rxjs/operators';
import { FormUpdatePayload } from '@shared/components';
import { IEmailOption } from '@shared/interface';
import { DomSanitizer } from '@angular/platform-browser';
import { ServiceRegistration, ServiceRegStatusTypes, ServiceRegistrationTypes } from '@models/model/serviceRegistration';
import { NgSub } from 'ng-sub';

interface InputPayload {
  serviceId: string;
  workingAreaId: string;
  request?: Request;
  serviceRegistration?: ServiceRegistration;
}

@Component({
  selector: 'app-internal-service-registration-modal',
  templateUrl: './internal-service-registration-modal.component.html',
  styleUrls: ['./internal-service-registration-modal.component.css']
})
export class InternalServiceRegistrationModalComponent implements OnInit {
  public labels: any = {};
  public workingArea: WorkingArea;
  public service: Service;
  public form: FormGroup;
  public isEditing: boolean;
  public customForm: ICustomFormModel;
  public isUnsavedChanges: boolean;
  public serviceRegistration: ServiceRegistration;
  public ServiceRegStatusTypes = ServiceRegStatusTypes;
  public serviceFrom: Service;
  public termsAccepted: boolean = false;
  public closeOriginalRequest: boolean = true;

  private customer: User;
  private sub = new NgSub();

  constructor(
    private labelService: LabelService,
    private dialogRef: MatDialogRef<InternalServiceRegistrationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputPayload,
    private formBuilder: FormBuilder,
    private serviceRegistrationService: ServiceRegistrationService,
    private userPromptsService: UserPromptsService,
    private customFormsService: CustomFormsService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private utilitiesService: UtilitiesService,
    public domSanitizer: DomSanitizer,
    private cloudFunctionService: CloudFunctionService,
    private usageService: UsageService,
    private workingAreaService: WorkingAreaService,
    private orgServiceService: OrgServiceService,
    private requestService: RequestService,
  ) { }

  async ngOnInit() {
    // disables backdrop click
    this.dialogRef.disableClose = true;

    if (this.data.serviceRegistration) {
      this.isEditing = true;
      this.serviceRegistration = this.data.serviceRegistration;
    } else {
      this.serviceRegistration = new ServiceRegistration(
        this.data.workingAreaId,
        this.data.serviceId,
      );

      this.serviceRegistration.type = ServiceRegistrationTypes.internal;
      this.serviceRegistration.requestId = this.data.request.id;
    }



    const formId = this.getCustomFormId();
    if (formId) {
      this.customFormsService.getCustomFormById(formId).pipe(take(1)).toPromise().then(c => {
        this.customForm = c;
      });
    }

    // get customer user doc
    const customerId = this.data.request?.management?.customerId || this.serviceRegistration.customer?.id;
    this.userService.getUserById(customerId).pipe(takeUntil(this.sub)).subscribe({
      next: u => {
        this.customer = u;
      },
      error: e => console.log(e),
    });

    this.workingAreaService.getWorkingAreaById(this.data.workingAreaId).subscribe({
      next: w => {
        this.workingArea = w;
      },
      error: e => console.log(e),
    });

    if (this.data.request) {
      this.orgServiceService.getServiceById(this.data.request.management.serviceId).subscribe({
        next: s => {
          this.serviceFrom = s;
        },
        error: e => console.log(e),
      });
    }

    this.orgServiceService.getServiceById(this.data.serviceId).subscribe({
      next: s => {
        this.service = s;

        const formId = this.getCustomFormId();
        if (formId) {
          this.customFormsService.getCustomFormById(formId).pipe(take(1)).toPromise().then(c => {
            this.customForm = c;
          });
        }
      },
      error: e => console.log(e),
    });

    this.setupForm();

    this.labels = (await this.labelService.getLabels('app-internal-service-registration-modal')).data;
  }

  public onCustomFormUpdated(payload: FormUpdatePayload) {
    if (payload.init) {
      this.form.addControl('customForm', payload.form);
    } else {
      this.form.get('customForm').setValue(payload.form.value);
      this.isUnsavedChanges = true;
    }
  }

  private getCustomFormId(): string {
    if (this.serviceRegistration.customForm) {
      return this.serviceRegistration.customForm.id;
    } else {
      const settings: any = this.service && this.service.settings;
      return settings && settings.catalog.customFormId || null;
    }
  }

  private setupForm() {
    this.form = this.formBuilder.group({
      title: [this.serviceRegistration.title, [Validators.required]],
      additionalInformation: [this.serviceRegistration.additionalInformation],
      termsAccepted: [this.serviceRegistration.termsAccepted || false],
      closeOriginalRequest: true,
    });
  }

  private getUpdatedReg(): ServiceRegistration {
    const formModel = this.form.value;

    this.serviceRegistration.customer = {
      id: this.customer.id,
      firstname: this.customer.firstname,
      lastname: this.customer.lastname,
      gender: this.customer.gender,
      phone: this.customer.phone,
      address: this.customer.address,
      birthday: this.customer.birthday,
      email: this.customer.email,
      phone2: this.customer.phone2 || '',
    };

    const updated: ServiceRegistration = Object.assign({}, this.serviceRegistration, {
      title: formModel.title,
      status: ServiceRegStatusTypes.notSubmitted,
      additionalInformation: formModel.additionalInformation,
      termsAccepted: formModel.termsAccepted,
    });

    // add updates from custom forms view here
    const prevValues = this.serviceRegistration.customForm ? this.serviceRegistration.customForm.values : {};
    updated.customForm = {
      id: this.getCustomFormId(),
      values: Object.assign({}, prevValues, formModel.customForm)
    };

    return updated;
  }

  private sendStatusChangeEmail(reg: ServiceRegistration): Promise<void> {
    return new Promise(async (resolve) => {
      const remark = reg.additionalInformation;

      resolve();
      const currentUserEmail: User = await this.userService.getCurrentUser().pipe(take(1)).toPromise();
      const organization: Organization = await this.organizationService.getOrganizationById(
        localStorage.getItem('user_organization')
      ).pipe(take(1)).toPromise();

      const remarkMail = this.domSanitizer.sanitize(SecurityContext.HTML, remark || '').replace(new RegExp('&#10;', 'g'), '<br>');

      const msg = `
                    <h4>${this.labels.info}</h4>
                    ${this.labels.title}: ${reg.title}<br/>
                    ${this.labels.customer}: ${reg.customer.firstname} ${reg.customer.lastname}<br/>
                    ${this.labels.remark}: ${remarkMail}
                `;

      let emailOptions: IEmailOption

      if (reg.status === ServiceRegStatusTypes.submitted) {
        emailOptions = {
          subject: this.labels.mail_new_redirect_subject,
          bodySubject: this.labels.mail_new_redirect_subject,
          from: currentUserEmail.email,
          to: this.service.settings.catalog.emailNotification,
          logoUrl: organization?.webLogo || organization.fullLogoUrl,
          bodyText: "",
          orgName: organization.fullName,
          orgId: organization.id,
          actionHtml: msg,
          infoText: ""
        }
      } else {
        emailOptions = {
          subject: this.labels.mail_new_redirect_subject,
          bodySubject: this.labels.mail_new_redirect_subject,
          from: this.service.settings.catalog.emailNotification,
          to: currentUserEmail.email,
          logoUrl: organization?.webLogo || organization.fullLogoUrl,
          bodyText: "",
          orgName: organization.fullName,
          orgId: organization.id,
          actionHtml: msg,
          infoText: ""
        }

      }

      this.cloudFunctionService.sendEmails([emailOptions]).then(() => { }).catch(() => { });
    });
  }

  private addUsageActivity(reg: ServiceRegistration, type: UsageActionTypes, user?: User) {

    // create activity here
    const userInfo = {
      userId: '',
      firstName: reg.customer.firstname,
      lastName: reg.customer.lastname
    };

    if (user) {
      userInfo.userId = user.id;
      userInfo.firstName = user.firstname;
      userInfo.lastName = user.lastname;
    }

    return this.usageService.addActivity(type, userInfo);
  }


  private addActivity(): void {
    const newActivity: Activity = this.utilitiesService.toJSON<Activity>(new Activity());
    const rs = this.data.request;

    newActivity.userId = this.data.request.management.customerId;
    newActivity.orgId = localStorage.getItem('user_organization');
    newActivity.serviceId = this.serviceFrom.id;
    newActivity.workareaId = this.serviceFrom.workingAreaId;
    newActivity.title = this.service.name;
    newActivity.description = this.labels._translate('redirect_activity_description', {
      titleRequestFrom: this.data.request.title, serviceRedirectFrom: this.serviceFrom.name, serviceRedirectTo: this.service.name
    });;
    newActivity.kind = ActivityKindTypes.redirect;
    newActivity.subKind = 'intern';
    newActivity.titleFixed = true;
    newActivity.requestId = rs.id;

    this.userService
      .addActivityToUser(rs.management.customerId, newActivity).then(() => { });
  }

  private async save(): Promise<void> {
    const updated: ServiceRegistration = this.getUpdatedReg();
    updated.status = ServiceRegStatusTypes.submitted;

    if (updated.id) {
      await this.serviceRegistrationService.updateRegistration(updated as DocItem);
      this.userPromptsService.showToast(this.labels.service_registration_updated);
    } else {
      Promise.all([
        this.addUsageActivity(updated, UsageActionTypes.submit_service_registration),
        this.addActivity(),
        this.sendStatusChangeEmail(updated),
      ]).catch();

      await this.serviceRegistrationService.createRegistration(updated);
      this.userPromptsService.showToast(this.labels.service_registration_created);
    }

    return Promise.resolve();
  }

  public async saveAndClose() {
    await this.save();
    this.isUnsavedChanges = false;
    this.closeModal();
  }

  public async deleteServiceReg() {
    const sure = await this.userPromptsService.confirmPromise(
      this.labels.confirm_action,
      this.labels.sure_to_delete_service_registration
    );

    if (sure) {
      await this.serviceRegistrationService.deleteRegistration(this.serviceRegistration.id);
      this.isUnsavedChanges = false;
      this.closeModal();
    }
  }

  public async acceptServiceReg() {
    const user = this.customer;
    this.serviceRegistration.status = ServiceRegStatusTypes.accepted;
    await this.sendStatusChangeEmail(this.serviceRegistration);

    this.addUsageActivity(
      this.serviceRegistration,
      UsageActionTypes.accept_service_registration,
      user
    ).then(() => { });

    const management: Management = Object.assign({}, new Management(), {
      customer: {
        firstname: user.firstname,
        lastname: user.lastname,
        phone: user.phone
      },
      executorId: null,
      coordinatorId: null,
      workareaId: this.serviceRegistration.workAreaId,
      serviceId: this.serviceRegistration.serviceId,
      customerId: user.id
    });

    const request: Request = Object.assign({}, new Request(), {
      management: JSON.parse(JSON.stringify(management)),
      district: user.area?.district || null,
      neighborhood: user.area?.neighbourhood || null,
      title: this.serviceRegistration.title,
      description: this.serviceRegistration.additionalInformation,
      status: this.service.settings.defaultStatusCreated,
    });

    request.checklistItems = this.service?.settings?.defaultChecklist?.items || [];
    const requestId = await this.requestService.createRequest(request);

    this.serviceRegistration.requestId = requestId;
    this.serviceRegistrationService.updateRegistration(this.serviceRegistration as DocItem);

    // custom form
    if (this.serviceRegistration.customForm.id) {
      const userForm: ICustomFormModelData = {
        fields: this.serviceRegistration.customForm.values,
        userId: user.id,
        formId: this.customForm.id
      };

      this.customFormsService.createUserCustomForm(userForm);
    }

    this.userPromptsService.showToast(this.labels.service_registration_accepted);
    this.isUnsavedChanges = false;
    this.closeModal();
  }


  toggle(e: any) {
    e.checked ? this.termsAccepted = true : this.termsAccepted = false;
  }


  toggleCloseOriginalRequest(e: any) {
    e.checked ? this.closeOriginalRequest = true : this.closeOriginalRequest = false;
  }

  public async closeModal(isUICloseClicked: boolean = false) {
    if (this.isUnsavedChanges) {
      this.userPromptsService.showConfirmDialogue(
        this.labels.confirm_action,
        this.labels.unsaved_changes_text,
        (sure: boolean) => {
          if (sure) {
            this.dialogRef.close(isUICloseClicked ? null : this.closeOriginalRequest);
          }
        }
      );
    } else {
      this.dialogRef.close(isUICloseClicked ? null : this.closeOriginalRequest);
    }
  }
}
