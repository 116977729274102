<div *ngIf="tableDataConfig">
  <data-table [configOptions]="tableDataConfig" *ngIf="tableDataConfig.data.length > 0"
    (onSelectRowAction)="handleRowActionSelect($event)">
  </data-table>

  <div *ngIf="tableDataConfig.data.length === 0" style="padding: 50px auto;">
    {{labels.empty_list}}
  </div>
</div>

<ng-template #customRxnIcon let-row="row">
  <span [class]="rxnIconColor(row)" (click)="openRemarkIcon(row)">
    <mat-icon>sticky-note</mat-icon>
  </span>
</ng-template>