import { LabelService, UtilitiesService } from '@core/services';
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ICustomFormModel, ICustomFormField } from '@shared/model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IObjectMap } from '@shared/interface';
import { groupBy } from 'lodash';
import { distinctUntilChanged, debounceTime, take } from 'rxjs/operators';
import { Sub } from '@shared/subscriptions';

export interface FormUpdatePayload {
    form: FormGroup;
    init?: boolean;
}

@Component({
    selector: 'app-custom-form-group',
    templateUrl: './custom-form-group.component.html',
    styleUrls: ['./custom-form-group.component.scss']
})
export class CustomFormGroupComponent implements OnInit, OnChanges, OnDestroy {
    @Input() customForm: ICustomFormModel;
    @Input() formValues: IObjectMap<any>;
    @Input() disabled: boolean;

    @Output() formChanged = new EventEmitter<FormUpdatePayload>();

    public labels: any = {};
    public form: FormGroup;
    public mappedFields: IObjectMap<ICustomFormField[]>;

    private sub: Sub = new Sub();

    constructor(
        private labelService: LabelService,
        private fb: FormBuilder,
        private utilitiesService: UtilitiesService
    ) { }

    async ngOnInit() {
        this.labels = (await this.labelService.getLabels('app-custom-form-group')).data;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.utilitiesService.isNewChange(changes.customForm)) {
            this.createForm();
        }
    }

    private getValidValue(val1: any, val2: any) {
        if (!val1) {
            return val2;
        }

        if (!val2) {
            return val1;
        }

        if (typeof val1 === typeof val2) {
            return val1;
        } else {
            return val2;
        }
    }

    private createForm() {
        this.formValues = this.formValues || {};

        const controlsConfig = {};
        this.mappedFields = groupBy(this.customForm.fields, 'groupName');
        this.customForm.fields.forEach((field: ICustomFormField) => {
            controlsConfig[field.fieldName] = [this.getValidValue(this.formValues[field.fieldName], field.value)];
        });

        this.form = this.fb.group(controlsConfig);
        this.formChanged.emit({ form: this.form, init: true });

        if (this.disabled) {
            this.form.valueChanges.pipe(
                distinctUntilChanged(),
                take(1)
            ).subscribe(() => {
                this.formChanged.emit({ form: this.form });
                this.createForm();
            });
        } else {
            this.form.valueChanges.pipe(
                distinctUntilChanged(),
                debounceTime(700)
            ).subscribe(() => {
                this.formChanged.emit({ form: this.form });
            });
        }
    }

    public getFieldWidth(field: ICustomFormField): string {
        const width: number = field.width || 4; // default is 4
        const minus: number = width === 4 ? 0 : 1; // -1% of width for not full-width block

        return `${25 * width - minus}%`;
    }

    public getFieldLabel(field: ICustomFormField): string {
        const val = this.form.value;
        if (val && field && val[field.fieldName]) {
            if (val[field.fieldName].length || val > 0) {
                return field.label;
            }
        }
        return '';
    }

    public getIsRequiredField(field: ICustomFormField): boolean {
        if (field.hidden) {
            return false;
        }
        return field.required;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
