import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivityService, LabelService, LocalStorageService, RequestService, SearchService, UserPromptsService, UserService, UtilitiesService } from '@core/services';
import { IObjectMap } from '@models/interface';
import { Institution, Request, Role, Service, User } from '@models/model';
import { NewRequestFormComponent } from '@shared/entry-components/new-request-form/new-request-form.component';
import { isValid } from 'date-fns';
import { cloneDeep, entries, keys, orderBy, uniq, uniqBy } from 'lodash';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

export interface UserRequestFilter {
  limit?: number;
  coordinator?: string;
  executor?: string;
  teamMember?: string;
  customer?: string;
  lastDocRef?: any;
  referredBy?: string
}

@Component({
  selector: 'app-user-requests',
  templateUrl: './user-requests.component.html',
  styleUrl: './user-requests.component.scss'
})
export class UserRequestsComponent implements OnInit, OnDestroy {

  public labels = this.labelService.defaultProvider();
  protected filter = '';
  protected limit = 10;
  protected lastDocRef: any;
  protected requests: Request[] = [];
  protected user: User;
  public tableCfg: IObjectMap<any>[];
  private services: Service[] = [];
  public usersMap: IObjectMap<User> = {};
  protected isLoading = true;
  public currentUserConnectedServices: string[];
  public creatorName: string;
  public orgId = this.localStorageService.getItemSync('user_organization');
  private listSubscription: Subscription;
  protected fetchingMore = false;
  public userRole: Role;
  protected teamCacheRequest: Request[];
  protected toggleBtns: { label: string; value: string; }[];
  protected searchText = '';
  protected institution: Institution;

  constructor(
    private dialogRef: MatDialogRef<UserRequestsComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: { user: User, institution: Institution },
    private labelService: LabelService,
    private requestService: RequestService,
    private userService: UserService,
    private activityService: ActivityService,
    private utilitiesService: UtilitiesService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private userPromptService: UserPromptsService,
    private searchService: SearchService,
  ) { }


  async ngOnInit(): Promise<void> {
    this.user = cloneDeep(this.data.user);
    this.institution = cloneDeep(this.data.institution);
    this.userRole = this.userService.getCurrentUserRole();
    const [labels, counts] = await Promise.all([
      this.labelService.getLabels('app-employee-request-tab'),
      this.user ? this.requestService.getUserRequestCount(this.user.id) : null
    ])
    this.labels = labels.data;
    
    const availableCounts = entries(counts).map(c => {
      if (c[1]) {
        if (!this.filter) this.filter = c[0];
        return c;
      }
      return null;
    }).filter(d => !!d);
    this.toggleBtns = availableCounts.map(key => {
      return { label: this.labels[this.getButtonLabel(key[0])], value: key[0] }
    });

    this.activityService.getUserConnectedWorkingAreasAndServices().then(res => {
      this.currentUserConnectedServices = res[keys(res)[0]].userConnectedServices;
    })
    this.fetchRequests();
  }

  private getButtonLabel(label: string) {
    if (label == 'teamMember') return 'team_member';
    return label;
  }

  private fetchRequests() {
    this.listSubscription = this.requestService.getUserRequest(this.mapFilter()).subscribe(async requests => {
      this.requests = uniqBy(this.requests.concat(requests), 'id');
      this.lastDocRef = requests.length == this.limit ? this.requests[this.requests.length - 1]?.['__doc'] : null;
      await this.getOtherData(requests);
      this.updateTableData(this.requests);
    })
  }

  private mapFilter() {
    const opts: UserRequestFilter = {};
    if (this.user) {
      if (this.filter == 'coordinator') opts.coordinator = this.user.id;
      else if (this.filter == 'executor') opts.executor = this.user.id;
      else if (this.filter == 'customer') opts.customer = this.user.id;
      else opts.teamMember = this.user.id;
    } else {
      opts.referredBy = this.institution.id;
    }
    opts.limit = this.limit;
    opts.lastDocRef = this.lastDocRef;
    return opts;
  }

  private getOtherData(request: Request[]) {
    return Promise.all([
      this.getServices(request),
      this.fetchUsers(request)
    ])
  }

  private async getServices(requests: Request[]) {
    const requestsServiesId = requests.filter(req => !this.services.find(s => s.id == req.management.serviceId)).map(req => req.management.serviceId);
    if (requestsServiesId.length) this.services.push(...(await this.userService.getUserServices(uniq(requestsServiesId))));
  }

  private async fetchUsers(requests: Request[]) {
    const customerIds: string[] = requests.filter(req => !this.usersMap[req.management.customerId])
      .map((request: Request) => request.management.customerId);
    if (customerIds.length) {
      const users = await this.userService.getUsersFromIds(uniq(customerIds)).pipe(take(1)).toPromise();
      users.forEach((user: User) => {
        if (user) {
          this.usersMap[user.id] = user;
        }
      });
    }
  }

  private getTableData(requests: Request[]) {
    return requests.map((request: Request) => {
      const service = this.services.find(service => service.id == request.management.serviceId);

      const customer: User = this.usersMap[request.management.customerId];
      return {
        _rowClass: `status-${request.status}`,
        title: request.title,
        customerFullName: customer ? customer.fullname : '',
        service: service?.name || '',
        originalRequest: request,
        executorFullName: `${request.management?.executor?.firstname || ''} ${request.management?.executor?.lastname || ''}`
      }
    })
  }

  private async updateTableData(requests: Request[]) {
    this.tableCfg = this.getTableData(requests);
    this.isLoading = false;
    this.fetchingMore = false
  }

  public async getRequestCreatorName(creatorId: string) {
    this.creatorName = creatorId ? this.usersMap[creatorId] ? this.usersMap[creatorId].fullname : (await this.userService.getUserById(creatorId).pipe(take(1)).toPromise())?.fullname : '';
  }

  public toDate(d: any, skipYear?: boolean) {
    const date = new Date(d);

    if (isValid(date)) {
      return skipYear ? this.utilitiesService.getLocaleDateString(date) : this.utilitiesService.getLocaleDateWithYear(date)
    } else {
      return '';
    }
  }

  protected handleRowSelect(row: any) {
    const request = row.originalRequest as Request;
    if (this.currentUserConnectedServices.includes(request?.management?.serviceId)) {
      this.dialogRef.close();
      this.router.navigate([`/${this.orgId}/dashboard/requests/detail`, request.id]);
    } else {
      this.userPromptService.showAlertDialogue(this.labels.not_connected_service_title, this.labels.you_are_not_connected_to_this_service);
    }
  }

  protected onFilterChange() {
    this.requests = [];
    this.teamCacheRequest = [];
    this.updateTableData([]);
    this.lastDocRef = null;
    this.listSubscription.unsubscribe();
    this.isLoading = true;
    if (this.filter != 'teamMember') this.fetchRequests();
    else this.fetchTeamRequest()
  }

  private fetchTeamRequest() {
    this.isLoading = true;
    this.listSubscription = this.requestService.getUserRequest(this.mapFilter()).subscribe(async requests => {
      this.teamCacheRequest = this.orderRequests(uniqBy(requests, 'id'));
      this.requests = this.teamCacheRequest.slice(0, this.limit);
      await this.getOtherData(requests);
      this.updateTableData(this.requests);
    });
  }

  private orderRequests(requests: Request[]) {
    return orderBy(requests, ['countMatch', 'status'], ['desc', 'asc'])
  }

  protected async fetchMore() {
    this.fetchingMore = true;
    if (this.filter != 'teamMember') this.fetchRequests();
    else {
      this.fetchingMore = true;
      this.requests = uniqBy(this.requests.concat(this.teamCacheRequest.slice(this.requests.length - 1, this.limit + this.requests.length)), 'id');
      await this.getOtherData(this.requests);
      this.updateTableData(this.requests);
    }
  }

  public createRequest(): void {
    this.userPromptService.showDialogue(NewRequestFormComponent, {
      customer: this.user
    }, null, false);
  }

  protected clearSearch() {
    this.onFilterChange();
  }

  protected async handleSearchText() {
    this.isLoading = true;
    if (!this.searchText) return this.onFilterChange();
    if (this.filter != 'teamMember') {
      this.requestService.searchUserRequests(this.searchText?.toLowerCase(), this.mapFilter()).subscribe(async requests => {
        await this.getOtherData(requests);
        const filteredRequests: any[] = this.searchService.searchListBySearchIndex(requests, this.searchText); //returns based on relevancy
        this.requests = this.orderRequests(uniqBy(filteredRequests.concat(requests), 'id'));
        await this.getOtherData(this.requests);
        this.updateTableData(this.requests);
      })
    } else {
      const requests = this.searchRequestsFrontEnd(this.searchText);
      await this.getOtherData(requests);
      this.updateTableData(requests);
    }
  }

  private searchRequestsFrontEnd(text: string) {
    return orderBy(
      this.teamCacheRequest.filter(request => request.searchIndex.index[text?.toLowerCase()]),
      ['status'],
      ['asc']
    );
  }

  protected closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.listSubscription?.unsubscribe();
  }
}
