import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploadService, LabelService, UserPromptsService, UserService } from '@core/services';
import { AttachedDoc, Role } from '@models/model';
import { IDataTableConfig, ITableData, RowOptions } from '@shared/components';
import { DocInfoComponent } from '@shared/entry-components';
import { cloneDeep } from 'lodash';
import { NgSub } from 'ng-sub';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-documents-model',
  templateUrl: './documents-model.component.html',
  styleUrls: ['./documents-model.component.scss']
})
export class DocumentsModelComponent implements OnInit {

  public docTableConfig: IDataTableConfig;
  public labels: any = {};
  private sub = new NgSub();
  public role: Role;

  constructor(
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) private data: { userId: string, type: string },
    private labelService: LabelService,
    private fileUploadService: FileUploadService,
    private userPromptsService: UserPromptsService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.role = this.userService.getCurrentUserRole();
    this.labels = (await this.labelService.getLabels('app-documents-model')).data;
    if (this.data.type === 'employee') {
      this.sub.add(
        this.userService.getEmployeeDocs(this.data.userId).subscribe((docs: AttachedDoc[]) => {
          this.docToTableConfig(docs);
        })
      )
    } else {
      this.sub.add(
        this.userService.getUserDocs(this.data.userId).pipe(takeUntil(this.sub)).subscribe((docs: AttachedDoc[]) => {
          this.docToTableConfig(docs);
         })
      )
    }
  }


  private docToTableConfig(docs: AttachedDoc[]): void {
    const config: IDataTableConfig = {
      data: docs.map((doc: any) => {
        const r: ITableData = cloneDeep(doc);
        r._metadata = {
          originalData: doc
        }
        return r;
      }),
      displayProperties: ['name', 'size'],
      rowOptions: [
        {
          id: 'info',
          title: this.labels.info,
          icon: 'info'
        },
        {
          id: 'remove',
          title: this.labels.delete,
          icon: 'delete'
        }
      ],
      allowSelection: false,
      displayHeaders: false,
    }
    this.docTableConfig = config;
  }

  public handleDocRowAction(res: { option: RowOptions; data: any }) {
    const data = res.data._metadata.originalData;
    if (res.option.id === 'info') this.showFileInfo(data);
    else this.deleteFile(data);
  }

  async showFileInfo(file: AttachedDoc) {
    if (!file) {
      return;
    }

    const fileMeta = await this.fileUploadService.getFileInfo(file.ref);

    this.userPromptsService.showDialogue(DocInfoComponent, {
      fileName: file.name,
      serviceName: this.role.service?.name || '',
      areaName: this.role.workingArea?.name || '',
      uploadedAt: fileMeta.timeCreated,
    });
  }

  public deleteFile(file: AttachedDoc) {
    this.userPromptsService.showConfirmDialogue(
      this.labels.delete_doc_message_title,
      this.labels.delete_doc_message_body,
      async confirm => {
        if (confirm) {
          try {
            this.fileUploadService.deleteFile(file.ref).catch();
            await this.userService.deleteDoc(file.id, this.data.userId);
            this.userPromptsService.showToast(this.labels.document_deleted);
          } catch (e) {
            this.userPromptsService.showToast(this.labels.cannot_delete_doc);
          }
        }
      }
    );
  }

  public openDoc(doc: ITableData) {
    window.open(doc._metadata.originalData.url, '_blank');
  }
}
