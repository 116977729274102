import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Sub } from '@shared/subscriptions';
import { LabelService, UtilitiesService } from '@core/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormUpdatePayload } from '@shared/components';
import { isEqual } from 'lodash';
import { Address } from '@shared/model';
import firebase from 'firebase/compat/app';
import { distinctUntilChanged, debounceTime, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ServiceRegistration, ServiceRegCustomerDetails } from '@models/model/serviceRegistration';
import { AddressService } from '@core/services/address.service';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'app-service-catalog-customer-form',
    templateUrl: './service-catalog-customer-form.component.html',
    styleUrls: ['./service-catalog-customer-form.component.scss']
})
export class ServiceCatalogCustomerFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() serviceRegistration: ServiceRegistration;
    @Input() disabled: boolean;
    @Input() csr: boolean;

    @ViewChild('phoneNumber', { static: true }) phoneNumber: ElementRef;
    @ViewChild('letter') letterDropdown: MatSelect;

    @Output() formChanged = new EventEmitter<FormUpdatePayload>();

    public labels: any = {};
    public form: FormGroup;

    private sub: Sub = new Sub();
    private customer: ServiceRegCustomerDetails;
    protected letters: string[];
    private matchedAddress: any;
    private timeout: any;

    constructor(
        private labelService: LabelService,
        private fb: FormBuilder,
        private utilitiesService: UtilitiesService,
        private addressService: AddressService
    ) { }

    async ngOnInit() {
        this.labels = (await this.labelService.getLabels('app-service-catalog-customer-form')).data;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.utilitiesService.isNewChange(changes.serviceRegistration)
            && !isEqual(this.customer, this.serviceRegistration.customer)
        ) {
            this.customer = this.serviceRegistration.customer;
            this.customer.address = this.customer.address || new Address();

            this.createForm();
        }
    }

    private createForm() {
        this.form = this.fb.group({
            title: [this.serviceRegistration.title, Validators.required],
            firstname: [this.customer.firstname, Validators.required],
            lastname: [this.customer.lastname, Validators.required],
            birthday: [this.customer.birthday],
            gender: [this.customer.gender, Validators.required],
            phone: [this.customer.phone],
            phone2: [this.customer.phone2],
            email: [this.customer.email, Validators.email],
            address: this.fb.group({
                city: [this.customer.address.city],
                number: [this.customer.address.number, [Validators.min(0)]],
                postalcode: [this.customer.address.postalcode],
                street: [this.customer.address.street],
                letter: [this.customer.address.letter],
                geo: [this.customer.address.geo]
            }),
        });

        this.formChanged.emit({ form: this.form, init: true });
        this.form.get('address.letter').disable();
        if (this.disabled) {
            this.form.valueChanges.pipe(take(1)).subscribe(() => {
                this.formChanged.emit({ form: this.form });
                this.createForm();
            });
        } else {
            this.listenToFormChanges();
        }
    }

    private formValueChanges(field?: string, duration?: number): Observable<any> {
        const ctrl = field ? this.form.get(field) : this.form;

        return ctrl.valueChanges.pipe(
            distinctUntilChanged(),
            debounceTime(duration || 100)
        );
    }

    private listenToFormChanges() {
        this.formValueChanges('address.number').subscribe((data) => {
            if (data) {
                this.fetchAddressByPostalCode();
            }
        });

        this.formValueChanges('address.postalcode').subscribe((data) => {
            if (data) {
                const letters: string = data.substr(data.length - 2, 2);
                const isLettersSameCase = letters.toUpperCase() === letters;

                if (!isLettersSameCase) {
                    data = data.substr(0, data.length - 2) + letters.toUpperCase().replace(/^\_+|\_+$/g, '');
                    this.form.get('address.postalcode').setValue(data);
                }
            }
        });

        this.formValueChanges('firstname').subscribe((data: string) => {
            const val = this.utilitiesService.capitalizeFirstLetter(data, true);

            if (val) {
                this.form.get('firstname').setValue(val);
            }
        });

        this.formValueChanges('gender', 0).subscribe((data: string) => {
            if (data) {
                if (!this.form.get('firstname').value) {
                    this.form.get('firstname').setValue(this.labels[`title_${data}`]);
                }
            }
        });

        this.formValueChanges('lastname').subscribe((data: string) => {
            const val = this.utilitiesService.capitalizeFirstLetter(data, true);

            if (val) {
                this.form.get('lastname').setValue(val);
            }
        });

        this.formValueChanges('title').subscribe((data: string) => {
            const val = this.utilitiesService.capitalizeFirstLetter(data, false);

            if (val) {
                this.form.get('title').setValue(val);
            }
        });

        this.formValueChanges(null, 500).subscribe(() => {
            this.formChanged.emit({ form: this.form });
        });
    }

    public async fetchAddressByPostalCode() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const address = this.form.value.address;
            this.form.patchValue({
                address: {
                    postalcode: address.postalcode.slice(0, 6).toUpperCase()
                }
            });

            if (address.postalcode && address.postalcode.length === 6 && address.number >= 0) {
                this.sub.add(
                    this.addressService.getAddressByPostalCode(address.postalcode, address.number, address.letter).subscribe(async matchedAdrs => {
                        this.matchedAddress = matchedAdrs;
                        this.letters = (this.matchedAddress.houseNumberAdditions as string[]).filter(letter => !!letter);
                        if (this.letters.length) {
                            this.form.get('address.letter').enable();
                            setTimeout(() => {
                                this.letterDropdown.focus();
                                this.letterDropdown.open();
                            }, 0);
                        } else {
                            this.mapAddress();
                        }
                    })
                )
            }
        }, 500);
    }

    public selectLetter(letter: string) {
        this.matchedAddress.houseNumberAddition = letter;
        this.mapAddress();
    }

    protected mapAddress() {
        const geo: firebase.firestore.GeoPoint = new firebase.firestore.GeoPoint(this.matchedAddress.latitude, this.matchedAddress.longitude);
        this.form.patchValue({
            address: {
                city: this.matchedAddress.city,
                street: this.matchedAddress.street,
                geo: geo
            }
        });
        this.customer.address.mailLines = this.matchedAddress?.mailLines || [];
        if (!this.phoneNumber.nativeElement) {
            this.phoneNumber.nativeElement.focus();
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
