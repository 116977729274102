<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <h2>{{data.fileName}}</h2>
    </div>

    <div class="modal_body" #modal_body>
        <div fxLayout="column" class="info">
            <div class="field">
                <div class="field-label">Working area</div>
                <div class="field-value">
                   {{data.areaName}}
                </div>
            </div>
             <div class="field">
                <div class="field-label">Service</div>
                <div class="field-value">
                   {{data.serviceName}}
                </div>
            </div>
            <div class="field">
                <div class="field-label">Uploaded At</div>
                <div class="field-value">
                   {{data.uploadedAt | date}}
                </div>
            </div>
        </div>
    </div>
</app-modal>
