"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValid = void 0;
const isValid = val => {
  const date = new Date(val);
  return date instanceof Date && !isNaN(date.valueOf());
};
exports.isValid = isValid;
