import { Component, Inject, OnInit } from '@angular/core';
import { Request, Role, WorkingArea, Service, RepeatRequestTypes, RequestResult, RequestStoppedReason } from 'app/shared/model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService, UtilitiesService, LabelService, RequestService } from 'app/core';
import { format } from 'date-fns';
import moment from 'moment';
import { extend } from 'lodash';
import { RequestStatus } from '@models/enum';

@Component({
  selector: 'app-close-request-form',
  templateUrl: './close-request-form.component.html',
  styleUrls: ['./close-request-form.component.scss']
})
export class CloseRequestFormComponent extends ModalComponent implements OnInit {
  public form: FormGroup;
  public request: Request;
  public currentArea: WorkingArea;
  public results: RequestResult[] = [];
  public reasons: RequestStoppedReason[] = [];
  public userRole: Role = JSON.parse(this.localStorageService.getItemSync('user_role'));
  public requestResults = RequestResult;
  public requestStoppedReasons = RequestStoppedReason;
  public labels = this.labelService.defaultProvider();
  public loading = false;

  public service: Service;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { request: Request; area: WorkingArea; service: Service; },
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<CloseRequestFormComponent>,
    private localStorageService: LocalStorageService,
    private fb: FormBuilder,
    private utilitiesService: UtilitiesService,
    private labelService: LabelService,
    private requestService: RequestService,
  ) {
    super(dialogRef, breakpointObserver);
  }

  async ngOnInit() {
    this.labels = (await this.labelService.getLabels('app-close-request-form')).data;
    this.results = this.utilitiesService.getKeysForEnum(RequestResult);
    this.reasons = this.utilitiesService.getKeysForEnum(RequestStoppedReason);

    this.request = this.data.request;
    this.currentArea = this.data.area;
    this.service = this.data.service;
    this.createForm();
  }

  private createForm() {
    if (!this.service.settings.repeatRequest) {
      this.service.settings.repeatRequest = new Service().settings.repeatRequest;
    }

    const repeatOptionChecked = this.service.settings.repeatRequest.repeatOption === RepeatRequestTypes.always
      || this.service.settings.repeatRequest.repeatOption === RepeatRequestTypes.optinal_default_true;

    this.form = this.fb.group({
      result: ['', Validators.required],
      redirectedTo: [''],
      followupSteps: [''],
      closeRemark: [''],
      repeatOption: [repeatOptionChecked],
      requestStoppedReason: [null],
      endDate: [format(this.request.endDate || new Date(), 'YYYY-MM-DD')],
    });

    if (this.service.settings.repeatRequest.repeatOption === RepeatRequestTypes.always) {
      this.form.get('repeatOption').disable();
    }
  }

  public async save() {
    try {
      this.loading = true;
      const model = Object.assign({}, this.form.value);
      const result = {
        repeatOption: this.form.get('repeatOption').disabled ? true : model.repeatOption || this.service.settings.repeatRequest.repeatOption === RepeatRequestTypes.always,
        payload: model,
      };

      delete result.payload.repeatOption;
      extend(this.request, result.payload);
      this.request.endDate = this.request.endDate ? format(this.request.endDate, 'YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      this.request.startDate = this.request.startDate ? this.request.startDate : moment().format('YYYY-MM-DD');
      this.request.status = RequestStatus.closed;

      await this.requestService.handleCloseRequest(this.request, result.repeatOption)
      this.dialogRef.close(this.request);
    } catch (e) {
      this.loading = false;
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }
}
