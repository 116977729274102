"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InternalStore = void 0;
/**
 * This internal store class
 */
var InternalStore = /** @class */function () {
  function InternalStore() {
    this.store = {};
  }
  InternalStore.prototype.getItem = function (prop) {
    return this.store[prop];
  };
  InternalStore.prototype.setItem = function (prop, data) {
    this.store[prop] = data;
  };
  InternalStore.prototype.clear = function () {
    this.store = {};
  };
  InternalStore.prototype.removeItem = function (prop) {
    delete this.store[prop];
  };
  return InternalStore;
}();
exports.InternalStore = InternalStore;