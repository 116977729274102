import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {
  RouterService,
  UserPromptsService,
  UserService,
  LocalStorageService,
  LabelService, UtilitiesService, WorkingAreaService, OrgServiceService
} from 'app/core';
import { MenuItem, User, Role, Organization, UsageActionTypes, WorkingArea, Service, IMailinglistItem, Municipality, MunicipalityDistrict, IAreaItem, Request, Address, IArea } from 'app/shared/model';
import {
  UsageService, SearchService,
  OrganizationService, ExportUsersService, SettingsService
} from '@core/services';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { RoleTypes } from 'app/shared/enum';
import { ImportUsersComponent, ImportUserTypes } from '@users/components/import-users/import-users.component';
import { ExportUsersComponent } from '@users/components/export-users/export-users.component';
import { take, takeUntil } from 'rxjs/operators';
import { uniqBy, orderBy, isEqual } from 'lodash';
import { ITableData } from '@shared/components';
import { NgSub } from 'ng-sub';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
// import { NewRequestFormComponent } from '@shared/entry-components/new-request-form/new-request-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NewUserComponent } from '../new-user/new-user.component';
import { PageEvent } from '@angular/material/paginator';
import { AppStore } from '@core/store';
import { TimestampDate } from 'timestamp-date';
import { EmailConfigInput, EmailDialogueComponent } from '@shared/entry-components/email-dialogue/email-dialogue.component';
import { MailinglistService } from '@core/services/mailinglist/mailinglist.service';
import { MunicipalityService } from '@core/services/municipality/municipality.service';
import { BirthdayExportComponent } from '../birthday-export/birthday-export.component';
import { CustomerOpenRequestsComponent } from '@shared/components/customer-open-requests/customer-open-requests.component';
import { AddressService } from '@core/services/address.service';
import { MatSelect } from '@angular/material/select';
import { IObjectMap } from '@models/interface';
import { RequestUsersInfoDialogComponent } from '@shared/components/request-users-info-dialog/request-users-info-dialog.component';
import { MapDialogComponent } from '@shared/components/map-dialog/map-dialog.component';
import { UserRequestsComponent } from '@shared/components/user-requests/user-requests.component';

export interface UserFilter {
  defaultLoadLastSeen: boolean;
  houseNumberFilter?: string;
  postalCodeFilter?: string;
  rolesFilter?: string;
  textFilter?: string;
  serviceFilter?: string;
  workingAreaFilter?: string;
  orderByFilter?: string;
  membership?: string;
  mailingList?: string;
  districtFilter?: string,
  neighborhoodFilter?: string,
  municipalityFilter?: Municipality;
  statusFilter?: boolean;
  userConnectedServices?: string[];
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {

  @ViewChild('houseNumberInput') houseNumberInput: ElementRef<HTMLInputElement>;
  @ViewChild('letter') letterDropdown: MatSelect;

  public labels: ILabelsProvider = this.labelService.defaultProvider();
  public roleTypes = RoleTypes;
  public searchText: string;
  public isLoading = true;
  public isExtraSmall = false;
  public userRole: Role;
  public ImportUserTypes = ImportUserTypes;
  public dataTableConfig: IObjectMap<any>[];
  public isAllEmpty: boolean;
  public userFilter: UserFilter = { defaultLoadLastSeen: true, statusFilter: false /* underneath the hood is disabled: false */ };
  public paginationOptions = this.resetPaginatorOptions();
  public mailinglists: IMailinglistItem[];
  public org: Organization;
  private sub = new NgSub();
  private paginator$ = new NgSub();
  private fetchedUsersList: User[] = [];
  private appStoreUserListingState = 'last_filter_state_before_navigating_away';
  public currentUser: User;
  public timestamp = new TimestampDate();
  private count: number;
  isSearchButtonDisabled = false;
  private usersLastSeenSubscription: Subscription;
  private filteredUsersSubscription: Subscription;
  private searchTextUsersSubscription: Subscription;
  private allUsersSubscription: Subscription;
  private getUserIdsSubscription: Subscription;
  private combinedUserOrgSubscription: Subscription;
  public workingAreas: WorkingArea[];
  // selectedWorkingArea: WorkingArea;
  private serviceSub: Subscription;
  // selectedService: Service;
  public services: Service[];
  public advanceFilterEnabled = false;
  public municipalities: Municipality[];
  public canClearText: boolean = false;
  public districts: MunicipalityDistrict[];
  // private defaultRowOptions: RowOptions[] = [];
  private customerRequests: Request[] = [];
  protected userAddress: any;
  protected letters: string[] = [];
  protected houseLetter: string = '';
  protected addressString = '';
  private userAddressData: { address: Address, area: IArea } = {} as any;
  protected fetchingMunicipalities = false;

  constructor(
    private labelService: LabelService,
    private localStorageService: LocalStorageService,
    private routerService: RouterService,
    private usersService: UserService,
    private userPromptsService: UserPromptsService,
    private usageService: UsageService,
    private searchService: SearchService,
    private orgService: OrganizationService,
    private router: Router,
    private route: ActivatedRoute,
    private utilitiesService: UtilitiesService,
    private workingAreaService: WorkingAreaService,
    private serviceService: OrgServiceService,
    private exportUserService: ExportUsersService,
    private mailinglistService: MailinglistService,
    private municipalityService: MunicipalityService,
    private addressService: AddressService,
    private settingsService: SettingsService,
  ) {
  }

  async ngOnInit() {
    this.userRole = this.usersService.getCurrentUserRole();
    // if (this.utilitiesService.rolesMatch(RoleTypes.orgAdmin)) {
    //   this.getWorkingAreas();
    //   this.getServices();
    // }
    this.labels = (await this.labelService.getLabels('app-users')).data;
    const menuItem: MenuItem = new MenuItem(this.labels.users);
    menuItem.searchEnabled = false;
    this.routerService.setMainMenuItem(menuItem);
    this.getWorkingAreas();
    this.getServices();
    this.combinedUserOrgSubscription = combineLatest([
      this.orgService.getCurrentOrganization(),
      this.usersService.getCurrentUser()
    ]).subscribe(arr => {
      this.org = arr[0];
      this.currentUser = arr[1];

      if (this.org?.municipalities?.length) {
        this.municipalities = orderBy(this.org.municipalities, 'text', 'asc');
        this.getDistricts();
      }

      if (this.route.snapshot.queryParams['fromUserDetails'] === 'Y') {
        const state: { userFilter: UserFilter; paginationOptions: any } = AppStore.getDataSync(this.appStoreUserListingState);

        if (state) {
          this.userFilter = state.userFilter;
          this.paginationOptions = state.paginationOptions;

          const fromIndex = this.paginationOptions.pageIndex * this.paginationOptions.usersPerPage;
          const toIndex = (this.paginationOptions.pageIndex + 1) * this.paginationOptions.usersPerPage;

          if (this.userFilter.defaultLoadLastSeen) {
            this.loadUsersLastSeen(fromIndex, toIndex);
          } else {
            this.fetchUsersUsingFilter(true, true);
          }

        } else {
          this.loadUsersLastSeen(0, 30);
        }
      } else {
        this.loadUsersLastSeen(0, 30);
      }
    }),
      this.userPromptsService.activeMediaQuery$.subscribe(alias => {
        this.isExtraSmall = alias === 'xs';

        if (this.userRole && this.dataTableConfig) {
          const users = this.dataTableConfig as any[];
          this.updateTableConfig(users);
        }
      }),
      this.localStorageService.getItem('user_role').subscribe((role) => {
        if (role) {
          this.userRole = this.usersService.getCurrentUserRole();
        }
      });
    this.getMailingList();
  }

  private getMailingList() {
    this.mailinglistService.getAciveMailinglists().subscribe({
      next: m => {
        this.mailinglists = m;
      },
      error: e => this.utilitiesService.errHandler(e)
    })
  }
  private resetPaginatorOptions() {
    return {
      totalSize: this.count,
      usersPerPage: 30,
      pageIndex: 0,
      lastRef: null as any,
      firstRef: null as any
    };
  }

  public onPaginationChange(evt: PageEvent): void {
    if ((this.anyNonSearchTextFiltersSet() || this.userFilter.textFilter) && (this.fetchedUsersList.length > 0)) {
      const newFromIndex = evt.pageIndex * evt.pageSize;
      const newToIndex = (evt.pageIndex + 1) * evt.pageSize;
      this.paginationOptions.usersPerPage = evt.pageSize;
      this.paginationOptions.pageIndex = evt.pageIndex;
      this.updateTableConfig(this.usersSubset(newFromIndex, newToIndex));
    } else if (this.userFilter.defaultLoadLastSeen && this.fetchedUsersList.length > 0) {
      const newFromIndex = evt.pageIndex * evt.pageSize;
      const newToIndex = (evt.pageIndex + 1) * evt.pageSize;
      this.paginationOptions.usersPerPage = evt.pageSize;
      this.paginationOptions.pageIndex = evt.pageIndex;
      this.updateTableConfig(this.usersSubset(newFromIndex, newToIndex));
    } else {
      this.paginationOptions.pageIndex = evt.pageIndex;
      this.paginationOptions.usersPerPage = evt.pageSize;
      this.fetchUsersUsingFilter(evt.previousPageIndex <= evt.pageIndex, false); //= is for resize of page count scenario
    }
  }

  loadUsersLastSeen(fromIndex, toIndex) {
    this.usersLastSeenSubscription = this.getUsersForLastSeen().pipe(takeUntil(this.paginator$)).subscribe(users => {
      this.fetchedUsersList = users.filter(user => user !== undefined); //checks for deleted users
      this.paginationOptions.totalSize = this.fetchedUsersList.length;
      this.paginationOptions.usersPerPage = 10;
      this.updateTableConfig(this.usersSubset(fromIndex, toIndex));
      this.isLoading = false;
    });
    this.sub.add(this.usersLastSeenSubscription);
  }

  private getUsersForLastSeen(): Observable<User[]> {
    return new Observable((observer) => {
      if (this.currentUser.usersLastSeen?.length > 0) {
        const usersLastSeen = this.currentUser.usersLastSeen.filter(n => n)
        const getUserIdsSubscription = this.usersService.getUsersFromIds(usersLastSeen).pipe(takeUntil(this.paginator$)).subscribe(users => {
          observer.next(users);
        });
        this.sub.add(getUserIdsSubscription);
      } else {
        observer.next([]);
      }
    });
  }

  public usersSubset(from: number, to: number): User[] {
    return this.fetchedUsersList.filter(u => !!u?.id).slice(from, to);
  }

  public async fetchUsersUsingFilter(isForwardNavigation, isReloadedContext) {
    this.isLoading = true;
    const opts = this.paginationOptions;

    const fromIndex = opts.pageIndex * opts.usersPerPage;
    const toIndex = (opts.pageIndex + 1) * opts.usersPerPage;
    if (this.anyNonSearchTextFiltersSet()) { //any non-search text filter is set
      this.filteredUsersSubscription = this.usersService.getFilteredUsers(this.userFilter).subscribe(users => {
        if (this.userFilter.textFilter) {
          const filteredUsers: any[] = this.searchService.searchListBySearchIndex(users, this.userFilter.textFilter); //returns based on relevancy
          this.fetchedUsersList = orderBy(uniqBy(filteredUsers.concat(this.fetchedUsersList), 'id'), ['countMatch', 'lastname'], ['desc', 'asc']);
          this.paginationOptions.totalSize = filteredUsers.length;
          this.updateTableConfig(this.usersSubset(fromIndex, toIndex));
          this.isLoading = false;
        } else {
          if (users?.length > 0) {
            this.fetchedUsersList = this.orderUserList(users);
            //this.paginationOptions.lastRef = (this.fetchedUsersList[this.fetchedUsersList.length - 1] as any)['__doc'];
            this.paginationOptions.totalSize = users.length;
            this.updateTableConfig(this.usersSubset(fromIndex, toIndex));
          } else {
            if (this.userFilter.postalCodeFilter && this.userFilter.houseNumberFilter) this.getMatchingAddress();
            this.paginationOptions.totalSize = 0;
            this.updateTableConfig(this.usersSubset(fromIndex, toIndex));
          }
        }
        this.isLoading = false;
      });
      this.sub.add(this.filteredUsersSubscription);
    } else if (this.userFilter.textFilter) { //only search text filter is present
      this.searchTextUsersSubscription = this.usersService.getUsers(this.userFilter.textFilter, null, null, null, this.userFilter).subscribe(users => {
        const filteredUsers: any[] = this.searchService.searchListBySearchIndex(users, this.userFilter.textFilter); //returns based on relevancy
        this.fetchedUsersList = orderBy(uniqBy(filteredUsers.concat(this.fetchedUsersList), 'id'), ['countMatch', 'id'], ['desc', 'asc']);
        this.paginationOptions.totalSize = filteredUsers.length;
        this.updateTableConfig(this.usersSubset(fromIndex, toIndex));
        this.isLoading = false;
      });
      this.sub.add(this.searchTextUsersSubscription);
    } else if (!this.anyNonSearchTextFiltersSet() && !this.userFilter.textFilter && this.userRole.roleType == RoleTypes.coordinator) { 
      this.loadUsersLastSeen(0, 30);
    } else { //no filter is present
      this.count = await this.usersService.getCounts('users');
      this.usersLastSeenSubscription?.unsubscribe();
      this.allUsersSubscription?.unsubscribe();
      if (isForwardNavigation) {
        this.allUsersSubscription = this.usersService
          .getUsersWithPaginationNOrderBy(this.userFilter.orderByFilter ?? 'last_name', opts.usersPerPage,
            isReloadedContext ? this.paginationOptions.firstRef : this.paginationOptions.lastRef, isForwardNavigation, isReloadedContext)
          .subscribe(users => {
            if (!isReloadedContext) { //to prevent 'no users found' displayed on navigating back from user details page
              this.fetchedUsersList = [];
            }
            if (users?.length) {
              this.fetchedUsersList = users;
              this.paginationOptions.lastRef = (this.fetchedUsersList[this.fetchedUsersList.length - 1] as any)['__doc'];
              this.paginationOptions.firstRef = (this.fetchedUsersList[0] as any)['__doc'];
              this.paginationOptions.totalSize = this.count;
              this.updateTableConfig(this.fetchedUsersList);
            }
          });
      } else {
        this.allUsersSubscription = this.usersService
          .getUsersWithPaginationNOrderBy(this.userFilter.orderByFilter ?? 'last_name', opts.usersPerPage, this.paginationOptions.firstRef, isForwardNavigation, false)
          .subscribe(users => {
            this.fetchedUsersList = [];
            if (users?.length) {
              this.fetchedUsersList = users;
              this.paginationOptions.lastRef = (this.fetchedUsersList[this.fetchedUsersList.length - 1] as any)['__doc'];
              this.paginationOptions.firstRef = (this.fetchedUsersList[0] as any)['__doc'];
              this.paginationOptions.totalSize = this.count;
              this.updateTableConfig(this.fetchedUsersList);
            }
          });
      }
      this.sub.add(this.allUsersSubscription);
    }
  }

  // getExpectedCountForPage() {
  //   const remainingSize = this.count - ((this.paginationOptions.pageIndex) * this.paginationOptions.usersPerPage);
  //   let expectedCount;
  //   if (remainingSize >= this.paginationOptions.usersPerPage) {
  //     expectedCount = this.paginationOptions.usersPerPage;
  //   } else {
  //     expectedCount = this.paginationOptions.usersPerPage - remainingSize;
  //   }

  //   return expectedCount;
  // }

  /** Exiting functionality's methods - not part of requirement for user list 3.0- start **/
  public openImportUsersDialog(type: ImportUserTypes) {
    this.userPromptsService.showDialogue(ImportUsersComponent, { importType: type }, null, true);
  }

  public openExportUsersDialog() {
    this.userPromptsService.showDialogue(ExportUsersComponent, {}, null, true);
  }

  public openBirthdayExportUsersDialog() {
    this.userPromptsService.showDialogue(BirthdayExportComponent, this.fetchedUsersList, null, false, { width: '300px' });
  }

  public openUserRequests(user: User): void {
    this.userPromptsService.showDialogue(UserRequestsComponent, { user }, null, true);
  }

  public createNewUser() {
    const user: User = new User();
    if (this.userFilter.postalCodeFilter && this.userFilter.houseNumberFilter) {
      user.address = this.userAddressData.address;
      user.area = this.userAddressData.area;
    }

    this.userPromptsService.showDialogue(NewUserComponent, { user: user }, (newUser: User) => {
      if (newUser) {
        this.userPromptsService.showToast(
          this.labels.user_is_added
        );

        this.router.navigate(['../', 'detail', newUser.id], { relativeTo: this.route });
      }
    }, true);
  }

  /** Exiting functionality's methods - not part of requirement for user list 3.0- end **/

  public async handleRowAction(res) {
    const customerName = res.data.fullname;
    this.userPromptsService.showDialogue(CustomerOpenRequestsComponent, { requests: this.customerRequests, customerName }, (request: Request) => {
      if (request) {
        this.router.navigate([`../../requests/detail/${request.id}`], { relativeTo: this.route });
      }
    }, null, { width: '500px' })
  }

  public async handleRowSelect(res: ITableData, type: 'details' | 'address') {
    if (type == 'details') this.gotoDetailsPage(res);
    else {
      if (res.street) this.openMapDialog((res.originalData as User).address);
      else this.gotoDetailsPage(res);
    }
  }

  private gotoDetailsPage(res: ITableData) {
    // save state here
    const state = {
      userFilter: this.userFilter,
      paginationOptions: this.paginationOptions,
    };

    AppStore.setData(this.appStoreUserListingState, state);
    this.router.navigate([`/${this.org.id}/dashboard/users/detail`, res.id], { relativeTo: this.route, queryParams: { fromUserListing: 'Y' } });
    this.addUserActivity(res);
  }

  protected openMapDialog(address: Address) {
    this.userPromptsService.showDialogue(MapDialogComponent, { address });
  }

  private updateTableConfig(users: User[]): void {
    this.dataTableConfig = users.map((user) => {
      return {
        id: user.id,
        district: user.area ? user.area?.district?.text : '',
        firstname: user.firstname,
        lastname: user.lastname,
        neighborhood: user.area?.neighbourhood?.text,
        number: user.address?.number,
        street: user.address?.street,
        postalcode: user.address?.postalcode,
        usernote: user.usernote,
        userRoles: [
          user.roles?.isExecutor ? this.labels.executor : '',
          user.roles?.isCustomer ? this.labels.customer : '',
          user.roles?.isCoordinator ? this.labels.coordinator : '',
          user.roles?.isProfessional ? this.labels.professional : '',
          user.isOrgAdmin ? this.labels.org_admin : '',
          user.roles?.isInstitutionContact ? this.labels.institution_contact : '',
          user.roles?.isServicePoint ? this.labels.service_point : '',
        ].filter(Boolean).join(', '),
        decedent: user.decedent ? `- ${this.labels.decedent?.toUpperCase()} - ` : '',
        picture: user.picture,
        originalData: user
      }
    })

    this.isLoading = false;
  }

  private clearState() {
    const split = this.router.url.split('/').splice(2, 3);
    const nextUrl = split.join('/');
    const detailsUrl = 'dashboard/users/detail';
    if (nextUrl !== detailsUrl) {
      AppStore.remove(this.appStoreUserListingState);
    }
  }

  handleFilterSubmit() {
    if (this.isSearchButtonDisabled) {
      return;
    }
    this.usersLastSeenSubscription?.unsubscribe();
    this.filteredUsersSubscription?.unsubscribe();
    this.searchTextUsersSubscription?.unsubscribe();
    this.allUsersSubscription?.unsubscribe();
    this.getUserIdsSubscription?.unsubscribe();
    this.combinedUserOrgSubscription?.unsubscribe();
    this.userFilter.defaultLoadLastSeen = false;
    this.fetchedUsersList = [];
    this.updateTableConfig([]);
    this.paginationOptions = this.resetPaginatorOptions();
    this.fetchUsersUsingFilter(true, false);
  }

  anyNonSearchTextFiltersSet() {
    if ((this.userFilter.rolesFilter && (this.userFilter.rolesFilter !== '-1'))
      || (this.userFilter.houseNumberFilter?.length > 0)
      || (this.userFilter.postalCodeFilter)
      || (this.userFilter.serviceFilter && (this.userFilter.serviceFilter !== '-1') && this.userRole.roleType != RoleTypes.coordinator)
      || (this.userFilter.workingAreaFilter && (this.userFilter.workingAreaFilter !== '-1') && this.userRole.roleType != RoleTypes.coordinator)
      || (this.userFilter.membership && (this.userFilter.membership !== '-1'))
      || (this.userFilter.mailingList && (this.userFilter.mailingList !== '-1'))
      || (this.userFilter.municipalityFilter && (this.userFilter.municipalityFilter !== null))
      || (this.userFilter.districtFilter && (this.userFilter.districtFilter !== '-1'))
      || (this.userFilter.neighborhoodFilter && (this.userFilter.neighborhoodFilter !== '-1'))
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkTextFilterValid() {
    if (!this.userFilter.textFilter) {
      this.isSearchButtonDisabled = false;
      this.handleFilterSubmit();
    }
    if (this.userFilter.textFilter && this.userFilter.textFilter.length > 0 && this.userFilter.textFilter.length <= 1) {
      this.isSearchButtonDisabled = true;
    } else {
      this.isSearchButtonDisabled = false;
    }
  }

  checkPostalCodeValid() {
    let postalCode = this.userFilter.postalCodeFilter;
    if (!postalCode) {
      return this.isSearchButtonDisabled = false;
    }
    if (this.userFilter.postalCodeFilter.length == 6) {
      this.houseNumberInput.nativeElement.focus();
    }
    const letters: string = postalCode.substr(postalCode.length - 2, 2);
    const isLettersSameCase = letters.toUpperCase() === letters;

    if (!isLettersSameCase) {
      postalCode = postalCode.substr(0, postalCode.length - 2) + letters.toUpperCase().replace(/^\_+|\_+$/g, '');
      this.userFilter.postalCodeFilter = postalCode;
    }
    const postalCodeRegex = new RegExp('[0-9]{4}[A-Za-z]{2}');
    if (postalCodeRegex.test(postalCode)) {
      return this.isSearchButtonDisabled = false;
    } else {
      return this.isSearchButtonDisabled = true;
    }
  }

  addUserActivity(res: ITableData) {
    // update seen user
    const user = res;
    const userInfo = {
      userId: user.id,
      firstName: user.firstname,
      lastName: user.lastname
    };

    this.usageService.addActivity(UsageActionTypes.view_user, userInfo);
  }

  orderUserList(users: User[]) {
    if (!this.userFilter.orderByFilter) {
      return orderBy(uniqBy(users.concat(this.fetchedUsersList), 'id'), ['lastname', 'id'], ['desc', 'asc']);
    }

    if (this.userFilter.orderByFilter) {
      switch (this.userFilter.orderByFilter) {
        case 'last-name':
          return orderBy(uniqBy(users.concat(this.fetchedUsersList), 'id'), ['lastname', 'id'], ['asc', 'asc']) as User[];
        case 'first-name':
          return orderBy(uniqBy(users.concat(this.fetchedUsersList), 'id'), ['firstname', 'id'], ['asc', 'asc']) as User[]; //TODO -- check field name/location
        case 'street':
          return orderBy(uniqBy(users.concat(this.fetchedUsersList), 'id'), ['street', 'id'], ['asc', 'asc']) as User[]; //TODO -- check field name/location
        default:
          return orderBy(uniqBy(users.concat(this.fetchedUsersList), 'id'), ['lastname', 'id'], ['asc', 'asc']) as User[];
      }
    }
  }

  clearFilters() {
    this.userFilter.textFilter = '';
    this.userFilter.rolesFilter = null;
    this.userFilter.houseNumberFilter = null;
    this.userFilter.orderByFilter = '';
    this.userFilter.postalCodeFilter = '';
    this.userFilter.defaultLoadLastSeen = true;
    this.userFilter.mailingList = null;
    this.userFilter.membership = null;
    this.userFilter.statusFilter = false;
    this.userFilter.workingAreaFilter = '';
    this.userFilter.serviceFilter = ''
    this.userAddressData = {} as any;
    this.fetchedUsersList = [];
    this.isSearchButtonDisabled = false;
    this.paginationOptions = this.resetPaginatorOptions();
    this.loadUsersLastSeen(0, 15);
  }

  private getWorkingAreas(): void {
    let obs$: Observable<WorkingArea[]>;

    if (this.utilitiesService.rolesMatch(RoleTypes.orgAdmin) || !this.userRole.workingAreaId) {
      obs$ = this.workingAreaService.getWorkingAreas();
    } else {
      obs$ = this.workingAreaService.getWorkareasFromIds([this.userRole.workingAreaId]);
    }

    obs$.pipe(takeUntil(this.sub)).subscribe({
      next: (ws) => {
        this.workingAreas = orderBy(ws, ['name'], ['asc']);
      },
      error: e => console.log(e)
    });
  }

  public async getServices(): Promise<void> {
    let obs$: Observable<Service[]>;
    this.serviceSub?.unsubscribe();

    if (this.userRole.workingAreaId) {
      obs$ = this.serviceService.getServices(true, this.userRole.workingAreaId);
    }

    if (obs$) {
      this.serviceSub = obs$.pipe(takeUntil(this.sub)).subscribe({
        next: (ss) => {
          this.services = orderBy(ss, ['name'], ['asc']);

          if (this.utilitiesService.rolesMatch(RoleTypes.coordinator)) {
            this.services = ss.filter(s => this.currentUser.inServices.includes(s.id));
            this.userFilter.userConnectedServices = this.services.map(s => s.id);
          }
        },
        error: e => console.log(e)
      });
    }
  }

  ngOnDestroy() {
    this.clearState();
    this.usersLastSeenSubscription?.unsubscribe();
    this.filteredUsersSubscription?.unsubscribe();
    this.searchTextUsersSubscription?.unsubscribe();
    this.allUsersSubscription?.unsubscribe();
    this.getUserIdsSubscription?.unsubscribe();
    this.combinedUserOrgSubscription?.unsubscribe();
    if (!this.sub.closed) {
      this.sub.unsubscribe();
    }
  }

  handleRoleChange() {
    if (this.userFilter.rolesFilter === null) {
      this.userFilter.workingAreaFilter = null;
      this.userFilter.serviceFilter = null;
    }
    if (this.utilitiesService.rolesMatch(RoleTypes.professional, RoleTypes.coordinator)) {
      // this.selectedWorkingArea = ws[0];
      this.userFilter.workingAreaFilter = this.workingAreas.find(w => this.userRole.workingAreaId === w?.id)?.id;
    }
    this.handleFilterSubmit();
  }

  handleHouseNumberTabOut($event) {
    $event?.stopPropagation();
    $event?.preventDefault();
    this.handleFilterSubmit();
  }

  public async openEmailDialog() {
    const users: User[] = this.fetchedUsersList;

    const noEmail: User[] = [];
    const emailed: User[] = [];
    users.forEach(u => {
      if (u.email) {
        emailed.push(u);
      } else {
        noEmail.push(u);
      }
    });

    let proceed = true;

    const noEmailPersonsHtml = `<br><ul>${noEmail.map((u) => `<li>${u.firstname} ${u.lastname}</li>`).join('')}</ul>`;
    let template = `<p>${this.labels.users_selected}: ${users.length}</p>`;
    if (noEmail.length) template += `${this.labels.persons_no_email} ${noEmailPersonsHtml}`;

    proceed = await this.userPromptsService.confirmPromise(this.labels.confirm_action, template);

    if (proceed) {
      const config: EmailConfigInput = {
        title: this.labels.send_emails,
        recipients: emailed
      };

      this.userPromptsService.showDialogue(EmailDialogueComponent, config, null, true);
    }
  }

  // public advance() {
  //   this.advanceFilterEnabled = !this.advanceFilterEnabled;
  //   if (this.advanceFilterEnabled) this.loadMunicipalities();
  //   else {
  //     this.userFilter.districtFilter = '-1';
  //     this.userFilter.neighborhoodFilter = '-1';
  //   }
  // }

  public loadMunicipalities(): void {
    this.municipalities = [];
    this.fetchingMunicipalities = true;
    this.sub.add(
      this.municipalityService.getMunicipalities().subscribe(ms => {
        this.municipalities = orderBy(ms, 'text', 'asc');
        this.fetchingMunicipalities = false;
      })
    )
    this.getDistricts();
  }

  public displayMunicipalityName(m: Municipality): string {
    return m?.text || '';
  }

  public filterMunicipalities(): Municipality[] {
    if (!this.municipalities) {
      return [];
    }
    const text = this.userFilter.municipalityFilter?.text || this.userFilter.municipalityFilter || '';
    return this.municipalities.filter(m => {
      return m?.text?.toLowerCase().includes((text as any).toLowerCase() || '') || false;
    });
  }


  public onMunicipality(data: any) {
    this.utilitiesService.delay(400).subscribe(() => {
      if (this.municipalities) {
        this.getDistricts();
      }
      this.canClearText = !!data;
      if (!data) {
        this.handleFilterSubmit();
        return;
      }
      if (this.municipalities.indexOf(data) > -1) {
        this.handleFilterSubmit();
      }
    });
  }

  private getDistricts() {
    const ds = this.userFilter.municipalityFilter?.districts?.map(d => {
      d.neighbourhoods = orderBy(d.neighbourhoods, ['text'], ['asc']);
      return d;
    }) || [];

    const list = orderBy(ds, ['text'], ['asc']);

    if (!isEqual(this.districts, list)) {
      this.districts = list;
      this.userFilter.districtFilter = '';
    }
  }

  public clearSelection() {
    this.userFilter.municipalityFilter = null;
    this.handleFilterSubmit();
  }

  public getNeighborhoodList(): IAreaItem[] {
    return this.userFilter.districtFilter && this.districts?.length ? this.districts.find(d => d.code === this.userFilter.districtFilter)?.neighbourhoods || [] : [];
  }


  public async downloadAddress() {
    let selectedService = null;
    if (this.userFilter.rolesFilter == 'exec') selectedService = this.services.find(s => this.userFilter.serviceFilter == s.id);
    this.exportUserService.makeUserExport(this.fetchedUsersList, selectedService);
  }

  private getMatchingAddress() {
    const postalCode = this.userFilter.postalCodeFilter;
    const number = this.userFilter.houseNumberFilter;
    this.fetchAddressByPostalCode(postalCode, parseInt(number));
  }

  private async fetchAddressByPostalCode(postalCode: string, houseNumber: number) {
    if (postalCode && postalCode.length === 6 && houseNumber >= 1) {
      this.isLoading = true;
      try {
        const matchedAddress = await this.addressService.getAddressByPostalCode(postalCode.toUpperCase(), houseNumber, '').pipe(take(1)).toPromise();
        this.userAddress = matchedAddress;
        this.letters = (this.userAddress.houseNumberAdditions as string[]).filter(letter => !!letter);
        if (this.letters.length) {
          setTimeout(() => {
            this.letterDropdown.focus();
            this.letterDropdown.open();
          }, 0);
        }
        this.setFormValues();
      } catch (error) {
        this.addressString = `<br><br>${this.labels.no_address_found_on} : <strong>${postalCode} ${houseNumber}</strong>`;
        this.letters = [];
      }
    }
  }

  public selectLetter(letter: string) {
    this.userAddress.houseNumberAddition = letter;
    this.setFormValues();
  }

  private async setFormValues() {
    if (!Object.keys(this.userAddress).length) return;

    const address = new Address();
    let area: IArea;

    address.city = this.userAddress?.address?.locality || this.userAddress.city;
    address.street = this.userAddress?.address?.street || this.userAddress.street;
    address.postalcode = this.userAddress?.address?.postcode || this.userAddress.postcode;
    address.number = this.userAddress?.address?.buildingNumber || this.userAddress.houseNumber;
    address.letter = this.userAddress?.address?.buildingNumberAddition || this.userAddress.houseNumberAddition;
    address.geo = {
      latitude: this.userAddress?.location?.latitude || this.userAddress.latitude,
      longitude: this.userAddress?.location?.longitude || this.userAddress.longitude
    };
    address.mailLines = this.userAddress?.mailLines || [];

    this.addressString = `<br><br><strong>${address.street} ${address.number} ${address.letter || ''}\n${address.postalcode}  ${address.city}</strong>`;

    if (address.postalcode) {
      this.isLoading = true;
      area = await this.settingsService.getAreaFromPostalCode(address.postalcode);
    }
    this.userAddressData = { address, area };
    this.isLoading = false;
  }

  protected openUsersInfoDialog(user: User) {
    this.userPromptsService.showDialogue(RequestUsersInfoDialogComponent, { user });
  }

}
