import { Component, OnInit, Inject } from '@angular/core';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LabelService } from '@core/services/labels.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User, NetworkInstitution, Institution } from '@shared/model';
import { UtilitiesService, UserService, CustomerNetworkService, UserPromptsService, InstitutionService } from '@core/services';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-network-institution-modal',
    templateUrl: './network-institutions-modal.component.html',
    styleUrls: ['./network-institutions-modal.component.scss']
})
export class NetworkInstitutionModalComponent extends ModalComponent implements OnInit {
    public labels: any = {};
    public form: FormGroup;
    public networkInstitution: NetworkInstitution;
    public institutionsOptionsRef = '';
    public noteMaxLength = 100;
    public createdByUser: User;
    public selectedInstitution: Institution;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public breakpointObserver: BreakpointObserver,
        public dialogRef: MatDialogRef<NetworkInstitutionModalComponent>,
        public utilitiesService: UtilitiesService,
        private userPromptsService: UserPromptsService,
        private labelService: LabelService,
        private formBuilder: FormBuilder,
        private customerNetworkService: CustomerNetworkService,
        private userService: UserService,
        private institutionService: InstitutionService
    ) {
        super(dialogRef, breakpointObserver);
    }

    async ngOnInit() {
        this.networkInstitution = this.data.networkInstitution;
        this.institutionsOptionsRef = `/organizations/${localStorage.getItem('user_organization')}/institutions`;
        this.createForm();

        if (this.networkInstitution.id) {
            this.userService.getUserById(this.networkInstitution.log.createdBy).pipe(take(1)).subscribe(user => {
                this.createdByUser = user;
            });
        }

        this.labels = (await this.labelService.getLabels('app-network-institution')).data;
    }

    private createForm() {
        this.form = this.formBuilder.group({
            institutionId: [this.networkInstitution.institutionId, Validators.required],
            note: [this.networkInstitution.note, [Validators.required, Validators.maxLength(this.noteMaxLength)]]
        });

        if (this.networkInstitution.institutionId) {
            this.institutionService.getInstitutionById(this.networkInstitution.institutionId)
                .pipe(take(1))
                .subscribe(res => {
                    this.selectInstitution(res);
                });
        }
    }

    public deleteItem() {
        this.userPromptsService.showConfirmDialogue(
            this.labels.confirm_action,
            this.labels.sure_to_delete_network_institution_body,
            async (sure) => {
                if (sure) {
                    await this.customerNetworkService.deleteNetworkInstitution(this.networkInstitution.id);
                    this.userPromptsService.showToast(this.labels.network_institution_deleted);
                    this.dialogRef.close();
                }
            }
        );
    }

    public selectInstitution(institution: Institution) {
        this.form.controls.institutionId.setValue(institution ? institution.name : '');
        this.selectedInstitution = institution;
    }

    public async save() {
        if (this.selectedInstitution) {
            const model = this.form.value;
            const networkInstitution: NetworkInstitution = Object.assign({}, this.networkInstitution, {
                institutionId: this.selectedInstitution.id,
                note: model.note
            });

            if (networkInstitution.id) {
                await this.customerNetworkService.updateNetworkInstitution(networkInstitution);
                this.userPromptsService.showToast(this.labels.network_institution_updated);
            } else {
                await this.customerNetworkService.createNetworkInstitution(networkInstitution);
                this.userPromptsService.showToast(this.labels.network_institution_created);
            }
            this.dialogRef.close();
        }
    }
}
