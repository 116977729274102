import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { environment } from 'app/../environments/environment';
import { FirestoreService } from './firestore.service';
import { IObjectMap } from '@shared/interface';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { UploadMetadata, getDownloadURL, getStorage, ref, uploadString } from "@angular/fire/storage";

type ProgressHandler = (val: number) => void;

@Injectable({
    providedIn: 'root',
})
export class FileService {
    constructor(
        private afStorage: AngularFireStorage,
        private afDb: FirestoreService,
        private http: HttpClient
    ) { }

    public getID(): string {
        return this.afDb.getNewId();
    }

    public handleUploadWithTask(task: AngularFireUploadTask, progressHandler: Function): Promise<any> {
        if (!progressHandler) {
            progressHandler = () => { }
        }

        return new Promise((resolve, reject) => {
            const sub = task.percentageChanges().subscribe(value => {
                progressHandler(value);
            });

            task.then(snapshot => {
                sub.unsubscribe();
                return resolve(snapshot.ref.getDownloadURL());
            }, error => {
                console.log(error);
                return reject();
            });
        });
    }

    public uploadFile(file: File | Blob, path: string, progressHandler: ProgressHandler): Promise<string> {
        const task = this.afStorage.upload(path, file);
        return this.handleUploadWithTask(task, progressHandler);
    }

    public transcribeFileWithTaggun(file: File): Promise<IObjectMap<any>> {
        const url = 'https://api.taggun.io/api/receipt/v1/simple/file'; // 'https://api.taggun.io/api/receipt/v1/verbose/file';

        const formData = new FormData();
        formData.append('file', file);

        const headers = { apikey: environment.taggunApiKey };

        return this.http.post(url, formData, { headers }).pipe(take(1)).toPromise();
    }

    public deleteFileByUrl(url: string): Promise<void> {
        return new Promise(async (resolve) => {
            // this try-catch prevents throwing error if file is not in storage to be deleted
            try {
                await this.afStorage.storage.refFromURL(url).delete();
            } catch (e) { }

            resolve();
        });
    }

    public deleteFilesByUrl(urls: string[]): Promise<any> {
        const promises = [];

        urls.forEach(url => {
            promises.push(this.deleteFileByUrl(url));
        });

        return Promise.all(promises);
    }

    public getBaseStorageRef(): string {
        return `https://storage.googleapis.com/${environment.firebase.storageBucket}`;
    }

    public deleteFileByPath(path: string): Promise<void> {
        return new Promise(async (resolve) => {
            const storageBaseRoute = this.getBaseStorageRef();

            // remove base path from full file path for relative reference
            const filePath = path.substr(storageBaseRoute.length + 1);

            // this try-catch prevents throwing error if file is not in storage to be deleted
            try {
                await this.afStorage.storage.ref(filePath).delete();
            } catch (e) {
                console.log(filePath, e);
            }

            resolve();
        });
    }

    public deleteFilesByPath(paths: string[]): Promise<any> {
        const promises = [];

        paths.forEach(path => {
            promises.push(this.deleteFileByPath(path));
        });

        return Promise.all(promises);
    }

    public getFilesFromInput(input: any): Promise<File[]> {
        return new Promise((resolve) => {
            /* wire up file reader */
            const target: DataTransfer = <DataTransfer>(input.target);

            if (target.files.length > 0) {
                resolve(Array.from(target.files));
            }
        });
    }

    public blobTOBase64(blob: Blob): Promise<string> {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                const base64data = reader.result as string;

                resolve(base64data);
            }
        });
    }
    /**
     * 
     * @param url link to the file
     * @param others if its not an image file input true else false
     * @returns the file
     */
    public getFileFromUrl(url: string, others?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!url.startsWith('http') && !url.startsWith('//')) {
                url = `${location.origin}${url.startsWith('/') ? '' : '/'}${url}`;
            } else {
                const proxy = 'https://europe-west1-v4work-prod.cloudfunctions.net/getImageFromUrl/fetch/image';
                url = `${proxy}?resource=${encodeURIComponent(url)}`;
            }

            fetch(url).then(r => r.blob()).then(b => {
                if (others) resolve(this.blobTOBase64(b));
                else {
                    if (b.type.startsWith('image')) {
                        resolve(b as File);
                    } else {
                        reject('Resource is not an image');
                    }
                }
            }).catch(e => {
                reject('Error: Invalid image url. Response payload: ' + e?.toString());
            });
        });
    }

    public async createTextFile(path: string, data: string, metadata: UploadMetadata) {
        const storage = getStorage();
        const storageRef = ref(storage, path);
        const res = await uploadString(storageRef, data, 'raw', metadata);
        return res.metadata.fullPath;
    }

    public getDocFromUrl(url: string) {
        return new Promise<{ file: string, url: string }>((resolve, reject) => {
            const storage = getStorage();
            getDownloadURL(ref(storage, url))
                .then(async (url) => {
                    const file = await this.getFileFromUrl(url, true);
                    resolve({ file, url })

                })
                .catch((error) => {
                    reject(error);
                    console.log(error)
                    // Handle any errors
                });
        })
    }
}
