<app-modal [hideCloseButton]="true">
  <div class="modal_header" #modal_header>
    <span class="modal_title">
      {{labels.expiry_date}}
    </span>
  </div>

  <div class="modal_body" #modal_body style="overflow: hidden; padding: 15px;">
    <p>{{labels.edit_expiry_date}}</p>

    <div fxLayout="row">
      <mat-form-field appearance="outline" fxFlex="100%">
        <mat-label>{{labels.expiry_date}}</mat-label>
        <input matInput [matDatepicker]="expiryDatePicker" [(ngModel)]="date">
        <mat-datepicker-toggle matSuffix [for]="expiryDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #expiryDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="modal_footer" #modal_footer>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
      <button mat-stroked-button (click)="cancel()">{{labels.cancel}}</button>
      <button (click)="continue()" color="primary" mat-raised-button>{{labels.submit}}</button>
    </div>
  </div>
</app-modal>