import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UtilitiesService } from '@core/services';
import { countryDialingCodes } from '@models/model';
import { NgSub } from 'ng-sub';

@Component({
  selector: 'app-phone-field',
  templateUrl: './phone-field.component.html',
  styleUrls: ['./phone-field.component.scss']
})
export class PhoneFieldComponent implements OnInit, OnChanges {
  @Input() placeholder: string;
  @Input() val: string;
  @Input() message: string;

  @Output() update = new EventEmitter<string>();

  public phoneNumber = '';
  public errorMsg = '';
  public dirty = false;

  private countryCodes = countryDialingCodes;
  private sub = new NgSub();

  constructor(
    private utilitiesService: UtilitiesService
  ) { }

  ngOnInit(): void {
    this.utilitiesService.delay(200).subscribe(() => {
      this.onUpdate(this.val, true);
    });
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.phoneNumber = this.val;
  }

  private isValidPhoneNumber(phone: string): boolean {
    const code = this.countryCodes.find(c => phone.startsWith(c) && phone.length >= 9);
    const nonDigit = /\D/g.test(phone.substr(1));

    return code && !nonDigit;
  }

  public onUpdate(ph: string, dry?: boolean): void {
    const valid = this.isValidPhoneNumber(ph);

    if (!dry) this.dirty = true;
    this.errorMsg = '';

    this.update.emit(valid ? ph : '');
    this.errorMsg = valid ? '' : this.message || 'Invalid phone number';
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
