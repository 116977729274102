<app-modal [alignFooterStart]="true" [hideCloseButton]="false">
  <div class="modal_header" #modal_header fxLayout="column" fxLayoutGap="5px">
    <div class="modal_title">
      {{customer?.fullname}}
    </div>

    <div class="modal_subtitle">
      {{labels.loan_goods}}
    </div>
  </div>

  <div class="modal_body" #modal_body>
    <mat-progress-bar mode="indeterminate" *ngIf="!tableConfig"></mat-progress-bar>

    <ng-container *ngIf="tableConfig">
      <div *ngIf="tableConfig.data.length === 0" class="empty-list">
        {{labels.empty_inventories}}
      </div>

      <div *ngIf="tableConfig.data.length > 0">
        <data-table [configOptions]="tableConfig"></data-table>
      </div>
    </ng-container>
  </div>

  <div class="modal_footer" #modal_footer>
    <div fxFlex="stretch" fxLayoutAlign="flex-start">
      <button mat-raised-button color="primary" (click)="showLoanHistory()">{{labels.loan_history}}</button>
    </div>

    <div fxLayout="row" fxLayoutGap="4px">
      <button mat-raised-button color="primary" (click)="addItem()">{{labels.add_item}}</button>
    </div>
  </div>
</app-modal>

<ng-template #loanExtraBtn let-row="row">
  <button mat-raised-button color="primary" (click)="openLoanToFreeDialog(row)">
    {{labels.unloan}}
  </button>
</ng-template>