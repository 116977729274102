<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{labels.repeat}}</span>
    </div>

    <div class="modal_body" #modal_body style="padding: 1em;">
        <div>
            <mat-progress-bar mode="indeterminate" *ngIf="isCreating"></mat-progress-bar>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="repeatType" (ngModelChange)="canCreate()">
                <mat-radio-button value="daily">{{labels.daily}}</mat-radio-button>
                <mat-radio-button value="weekly">{{labels.weekly}}</mat-radio-button>
            </mat-radio-group>

            <div [ngSwitch]="repeatType">
                <div *ngSwitchCase="'weekly'">
                    <ng-container *ngTemplateOutlet="weekly"></ng-container>
                </div>
                <div *ngSwitchDefault>
                    <ng-container *ngTemplateOutlet="daily"></ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px">
        <button mat-raised-button color="primary" [disabled]="!canProceed" (click)="create()">
            {{labels.create}}
        </button>
    </div>
</app-modal>

<ng-template #daily>
    <div style="padding: 1em;" class="mt-3">
        <div fxLayout="row" fxLayoutGap="15px">
            <mat-form-field appearance="outline" style="width: 70px;">
                <input matInput type="number" max="10" min="0" [(ngModel)]="inputData.daily.repeatTimes" (ngModelChange)="canCreate()">
            </mat-form-field>
            <div><mat-label>{{labels.days}} ({{labels.max_10}})</mat-label></div>
        </div>
    </div>
</ng-template>

<ng-template #weekly>
    <div style="padding: 1em;" class="mt-3">
        <div fxLayout="row" fxLayoutGap="15px">
            <div><mat-label>{{labels.every}}</mat-label></div>
            <mat-form-field appearance="outline" style="width: 70px;">
                <input matInput type="number" max="10" min="0" [(ngModel)]="inputData.weekly.repeatInterval" (ngModelChange)="canCreate()">
            </mat-form-field>
            <div><mat-label>{{labels.week}}(s)</mat-label></div>
        </div>
        <div>
            <mat-label>{{labels.repeat_on}}</mat-label>
            <mat-chip-listbox multiple aria-label="Week selection" [(ngModel)]="inputData.weekly.repeatOn" (ngModelChange)="canCreate()">
                <mat-chip-option *ngFor="let week of repeatOn" [value]="week">{{week.text}}</mat-chip-option>
            </mat-chip-listbox>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" class="mt-3">
            <div><mat-label>{{labels.for}}</mat-label></div>
            <mat-form-field appearance="outline" style="width: 70px;">
                <input matInput type="number" max="10" min="0" [(ngModel)]="inputData.weekly.repeatTimes" (ngModelChange)="canCreate()">
            </mat-form-field>
            <div><mat-label>{{labels.weeks}} ({{labels.max_10}})</mat-label></div>
        </div>
    </div>
</ng-template>