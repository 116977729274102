<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        {{labels.export_birthday_user}}
    </div>

    <div class="modal_body" #modal_body>
        <div style="text-align: center; margin-top: 20px;">
            <mat-form-field appearance="outline" style="width: 80%;">
                <mat-label>{{labels.month}}</mat-label>
                <mat-select [(ngModel)]="selectedMonth"
                (ngModelChange)="filterUsers($event)">
                    <mat-option *ngFor="let month of months" [value]="month.id">
                        {{month.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px" fxLayout="row">

        <div fxLayout="row" fxLayoutGap="10px">
            <button mat-stroked-button [disabled]="selectedMonth == null" (click)="exportUsers()">
                {{labels.export}}
            </button>
        </div>
    </div>
</app-modal>