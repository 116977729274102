import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LabelService, UtilitiesService } from '@core/services';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit, OnChanges {
  @Input() value: string;
  @Input() placeholder: string;
  @Input() readonly = false;

  @Output() update = new EventEmitter<{ phone: string; valid: boolean; }>();

  public labels = this.labelService.defaultProvider();
  public countryCode = '';
  public phone = '';
  public countryCodes: { name: string; code: string; }[];

  constructor(
    private labelService: LabelService,
    private utilitiesService: UtilitiesService,
  ) { }

  async ngOnInit() {
    this.labels = (await this.labelService.getLabels('app-phone-input')).data;

    this.countryCodes = [
      { name: this.labels.belgium, code: '+32' },
      { name: this.labels.france, code: '+33' },
      { name: this.labels.germany, code: '+49' },
      { name: this.labels.spain, code: '+34' },
      { name: this.labels.netherlands, code: '+31' },
      { name: this.labels.nigeria, code: '+234' },
      { name: this.labels.united_states, code: '+1' },
    ];

    if (!this.countryCode) {
      this.applyFormatting(this.value || '', false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.utilitiesService.isNewChange(changes.value)) {
      if (this.value) {
        this.applyFormatting(this.value, false);
      }
    }
  }

  public isValidPhone(): boolean {
    if (!this.phone) {
      return true;
    }

    return this.countryCode && this.phone.length >= 9 && this.phone.length <= 11;
  }

  private forcePhoneNumber(phone: string): string {
    if (phone.length === 0) {
      return '';
    }

    const digits = phone.replace(/[^0-9\.]+/g, '');
    return digits.startsWith('0') ? digits : '0' + digits;
  }

  private applyFormatting(phone: any, force: boolean): void {
    phone = typeof phone == 'number' ? phone.toString() : phone;
    if (!this.countryCodes) {
      return;
    }
    const code = this.countryCodes.sort((a, b) => a.code.length > b.code.length ? -1 : 1)
      .find(c => phone.startsWith(c.code));

    if (code) {
      this.countryCode = code.code;

      const num = phone.substring(code.code.length);
      const valid = num.replace(/[^0-9\.]+/g, '') === num && num.length <= 11 && !num.startsWith('0');

      this.phone = force ? this.forcePhoneNumber(num) : valid ? '0' + num : num;
    } else {
      this.countryCode = '+31';
      this.phone = force ? this.forcePhoneNumber(phone) : phone;
    }

    if (force) {
      const upd = this.phone ? this.countryCode + this.phone.substring(this.phone.startsWith('0') ? 1 : 0) : null;
      this.update.emit({ phone: upd, valid: this.isValidPhone() });
    }
  }

  public onInputChange(): void {
    this.utilitiesService.delay(300).subscribe(() => {
      const phone = this.countryCode + this.phone;
      this.applyFormatting(phone, true);
    });
  }
}
